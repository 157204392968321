/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  DealsSearchDto,
  DealsSearchDtoFromJSON,
  DealsSearchDtoFromJSONTyped,
  DealsSearchDtoToJSON,
  MerchantSearchDTO,
  MerchantSearchDTOFromJSON,
  MerchantSearchDTOFromJSONTyped,
  MerchantSearchDTOToJSON,
  ProductSearchDTO,
  ProductSearchDTOFromJSON,
  ProductSearchDTOFromJSONTyped,
  ProductSearchDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface SearchResponseDTO
 */
export interface SearchResponseDTO {
  /**
   *
   * @type {MerchantSearchDTO}
   * @memberof SearchResponseDTO
   */
  merchants?: MerchantSearchDTO;
  /**
   *
   * @type {DealsSearchDto}
   * @memberof SearchResponseDTO
   */
  deals?: DealsSearchDto;
  /**
   *
   * @type {ProductSearchDTO}
   * @memberof SearchResponseDTO
   */
  products?: ProductSearchDTO;
}

export function SearchResponseDTOFromJSON(json: any): SearchResponseDTO {
  return SearchResponseDTOFromJSONTyped(json, false);
}

export function SearchResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    merchants: !exists(json, "merchants") ? undefined : MerchantSearchDTOFromJSON(json["merchants"]),
    deals: !exists(json, "deals") ? undefined : DealsSearchDtoFromJSON(json["deals"]),
    products: !exists(json, "products") ? undefined : ProductSearchDTOFromJSON(json["products"])
  };
}

export function SearchResponseDTOToJSON(value?: SearchResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    merchants: MerchantSearchDTOToJSON(value.merchants),
    deals: DealsSearchDtoToJSON(value.deals),
    products: ProductSearchDTOToJSON(value.products)
  };
}
