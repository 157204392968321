import { AuthorityModule } from "@app/api/modules/authority/authority.module";
import clsx from "clsx";
import * as React from "react";

import styles from "./authority-component.module.scss";

export interface IAuthorityComponentProps {
  authority: AuthorityModule;
}

const AuthorityComponent = (props: IAuthorityComponentProps): JSX.Element => (
  <div className={`uk-container`}>
    <div className={clsx(styles.authority, !props.authority.title && styles.noTitle)}>
      <div className={styles.title}>{props.authority.title}</div>
      <div className={styles.logos}>
        {props.authority.logos?.map((item, index) => (
          <span key={index}>
            <a href={item.link} target="_blank">
              <img title={item.logo.title} alt={item.logo.alt} src={item.logo.src} />
            </a>
          </span>
        ))}
      </div>
    </div>
  </div>
);

export { AuthorityComponent };
