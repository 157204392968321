import { BlogSingleHeaderModule } from "@app/api/modules/blog-single-header/blog-single-header";
import { IWPHeaderBlogSingleModuleFull } from "./header-blog-single-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { headerBlogOverviewModuleMapper } from "../header-blog-overview-module";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import { breadcrumbComponentMapper } from "../../component-mappers/breadcrumb-component/breadcrumb-component-mapper";
import Logger from "@app/util/logger";

export interface IHeaderBlogSingleModuleMapper extends IModuleFetcher {
  module: IWPHeaderBlogSingleModuleFull;
}

export const headerBlogSingleModuleMapper = async (
  props: IHeaderBlogSingleModuleMapper
): Promise<BlogSingleHeaderModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      pageData,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in headerBlogSingleModuleMapper");

    if (data.recentBlogPosts) {
      return headerBlogOverviewModuleMapper(props);
    }

    const breadcrumbProps = await breadcrumbComponentMapper({
      platformId,
      pageUrl,
      variant: "light",
      title: pageData.meta?.breadcrumbTitle
    });

    return {
      id: "4",
      name: "BlogSingleHeaderModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      title: data.title || "",
      image: {
        src: data.headerImage?.url || "",
        alt: data.headerImage?.alt || "",
        title: data.headerImage?.title || ""
      },
      breadcrumbProps
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerBlogSingleModuleMapper");

    return undefined;
  }
};
