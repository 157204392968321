/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface SeoDTO
 */
export interface SeoDTO {
  /**
   *
   * @type {string}
   * @memberof SeoDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SeoDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof SeoDTO
   */
  pageText?: string;
  /**
   *
   * @type {string}
   * @memberof SeoDTO
   */
  alternateHTML?: string;
  /**
   *
   * @type {string}
   * @memberof SeoDTO
   */
  jsonSchema?: string;
}

export function SeoDTOFromJSON(json: any): SeoDTO {
  return SeoDTOFromJSONTyped(json, false);
}

export function SeoDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): SeoDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: !exists(json, "title") ? undefined : json["title"],
    description: !exists(json, "description") ? undefined : json["description"],
    pageText: !exists(json, "pageText") ? undefined : json["pageText"],
    alternateHTML: !exists(json, "alternateHTML") ? undefined : json["alternateHTML"],
    jsonSchema: !exists(json, "jsonSchema") ? undefined : json["jsonSchema"]
  };
}

export function SeoDTOToJSON(value?: SeoDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    description: value.description,
    pageText: value.pageText,
    alternateHTML: value.alternateHTML,
    jsonSchema: value.jsonSchema
  };
}
