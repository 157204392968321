import * as React from "react";
import { ResourceTextComponent } from "@app/core/resource-text";
import { useInterval } from "@app/util/interval";
import { formatMessage } from "@app/translations/intl";

export interface ICountDownComponentProps {
  isFullVersion?: boolean;
  location?: "navbar" | "page";
  prefix?: string;
}

const millisToSeconds = (millis: number) => millis / 1000;

const calculateRemainingTimeUnits = (seconds: number) => {
  let countdown = Math.abs(seconds);
  const result: any = {};

  result.months = Math.floor(countdown / 2629743.83);
  countdown -= result.months * 2629743.83;

  result.days = Math.floor(countdown / 86400);
  countdown -= result.days * 86400;

  result.hours = Math.floor(countdown / 3600);
  countdown -= result.hours * 3600;

  result.minutes = Math.floor(countdown / 60);
  countdown -= result.minutes * 60;

  result.seconds = Math.ceil(countdown % 60);

  return result;
};

export const CountDownComponent = (props: ICountDownComponentProps) => {
  const [days, setDays] = React.useState<number>();
  const [hours, setHours] = React.useState<number>();
  const [minutes, setMinutes] = React.useState<number>();
  const [seconds, setSeconds] = React.useState<number>();
  const [months, setMonths] = React.useState<number>();
  const [monthInDays, setMonthInDays] = React.useState<number>();
  const [isTimer, setIsTimer] = React.useState<boolean>(false);
  const [prefix, setPrefix] = React.useState<string>("");

  const location = props.location ? props.location : "page";
  const intervaltime = 1000;
  const blackFridayString = "2020-11-26T23:00:00.000Z";
  const cyberMondayString = "2019-11-29T23:00:00.000Z";
  const teasingString = "2020-11-22T07:00:00.000Z";

  const currentDate = new Date();
  const blackFridayDate = new Date(blackFridayString);

  React.useEffect(() => {
    const blackFridayUnix = millisToSeconds(blackFridayDate.getTime());
    const currentUnix = millisToSeconds(currentDate.getTime());
    const countdownUnix = blackFridayUnix - currentUnix;

    const remainder: any = calculateRemainingTimeUnits(countdownUnix);
    const newMonthInDays = Number(remainder.months * 31) + Number(remainder.days);

    setMonths(remainder.months);
    setDays(remainder.days);
    setMonthInDays(newMonthInDays);
    setPrefix(props.prefix ? props.prefix : "");

    if (remainder.days <= 1 && remainder.months <= 1) {
      setIsTimer(true);
    } else {
      setIsTimer(false);
    }
  }, []);

  useInterval(
    () => {
      const blackFridayUnix = millisToSeconds(blackFridayDate.getTime());
      const currentUnix = millisToSeconds(currentDate.getTime());
      const countdownUnix = blackFridayUnix - currentUnix;

      const remainder: any = calculateRemainingTimeUnits(countdownUnix);
      const newMonthInDays = Number(remainder.months * 31) + Number(remainder.days);

      setMonths(remainder.months);
      setMonthInDays(newMonthInDays);
      setDays(remainder.days);
      setHours(remainder.hours);
      setMinutes(remainder.minutes);
      setSeconds(remainder.seconds);
    },
    isTimer ? intervaltime : null
  );

  const renderPrefix = (prefixString: string) => <span>{prefixString} </span>;

  const renderTime = () => {
    if (typeof days !== "undefined" && typeof months !== "undefined" && typeof monthInDays !== "undefined") {
      if (currentDate.toISOString() >= teasingString && currentDate.toISOString() < blackFridayString) {
        if (location === "navbar") {
          return (
            <span>
              <ResourceTextComponent resourceKey={"countDown.afterCustomDate"} />
            </span>
          );
        }

        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent resourceKey={"countDown.begin"} />
            {` ${days} `} <ResourceTextComponent resourceKey={"countDown.days.many"} />{" "}
            <span>
              <ResourceTextComponent resourceKey={"countDown.afterCustomDate.onPage"} />
            </span>
          </span>
        );
      }
      if (currentDate.toISOString() >= blackFridayString && currentDate.toISOString() < cyberMondayString) {
        if (location !== "navbar") {
          return (
            <span>
              <ResourceTextComponent resourceKey={"countDown.blackFridayStarted.onPage"} />
            </span>
          );
        }

        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent resourceKey={"countDown.blackFridayStarted"} />
          </span>
        );
      }

      if (
        new Date(currentDate).toDateString() === new Date(cyberMondayString).toDateString() &&
        location === "navbar"
      ) {
        return (
          <span>
            <ResourceTextComponent resourceKey={"countDown.cyberMonday"} />
          </span>
        );
      }

      if (days <= 1 && months < 1) {
        if (typeof hours !== "undefined" && typeof minutes !== "undefined" && typeof seconds !== "undefined") {
          const doubleDigit = (timeNumber: number) => `0${timeNumber}`.toString().slice(-2);

          return (
            <span>
              {renderPrefix(prefix)}
              {`${doubleDigit(hours)}:${doubleDigit(minutes)}:${doubleDigit(seconds)}`}
            </span>
          );
        }

        return <span style={{ visibility: "hidden" }}>Time</span>;
      }
      // Shows how many days left to Black friday if it is less than 1 month
      if (days <= 31 && months < 1) {
        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent resourceKey={"countDown.begin"} />
            {` ${days} `} <ResourceTextComponent resourceKey={"countDown.days.many"} />
          </span>
        );
      }
      // Special if statement for 'Ruim één maand' moment gives a 1 back instead of 0 if it is in same month.
      if (monthInDays >= 31 && monthInDays <= 41) {
        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent resourceKey={"countDown.oneMonthLeft"} />
          </span>
        );
      }
      // If it is less than 10 days till end of month, counter will show 'Ruim .. maanden'
      if (months >= 1 && days <= 10) {
        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent
              resourceKey={"countDown.moreThan.months"}
              values={{
                moreThan: formatMessage({ id: "countDown.moreThan" })
              }}
            />
            {` ${months} `}
            <ResourceTextComponent resourceKey={"countDown.months.many"} />
          </span>
        );
      }
      // If there are more than 10 days left in a month, counter will show how many days and months left
      if (months >= 1 && days >= 10) {
        return (
          <span>
            {renderPrefix(prefix)}
            <ResourceTextComponent resourceKey={"countDown.begin"} />
            {` ${months} `}
            {months > 1 ? (
              <ResourceTextComponent resourceKey={"countDown.months.many"} />
            ) : (
              <ResourceTextComponent resourceKey={"countDown.months.one"} />
            )}
            {` ${days} `}
            <ResourceTextComponent resourceKey={"countDown.days.many"} />
          </span>
        );
      }
    }

    return <span style={{ visibility: "hidden" }}>Time</span>;
  };

  return <React.Fragment>{renderTime()}</React.Fragment>;
};
