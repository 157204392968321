import * as React from "react";
import NumberFormat from "react-number-format";
import styles from "./currency-component.module.scss";
import clsx from "clsx";

interface ICurrencyProps {
  value: number;
  variant?: "old" | "new";
  className?: string;
}

const CurrencyComponent = (props: ICurrencyProps) => {
  const suffix = props.value % 1 === 0 ? "-" : "";
  const [eur, cent] = props.value.toString().split(".");

  if (props.variant === "new") {
    return (
      <div className={clsx(styles.fullPrice, props.className)}>
        <div>{eur}</div>
        <div className={styles.seperator}>.</div>
        {cent ? (
          <div className={styles.cent}>{cent.length === 1 ? `${cent}0` : cent}</div>
        ) : (
          <div className={styles.dash}>-</div>
        )}
      </div>
    );
  }

  if (props.variant === "old") {
    return (
      <NumberFormat
        className={props.className}
        value={props.value}
        displayType="text"
        suffix={suffix}
        decimalSeparator="."
        decimalScale={2}
      />
    );
  }

  return (
    <NumberFormat
      className={props.className}
      value={props.value}
      displayType="text"
      suffix={suffix}
      thousandSeparator="."
      decimalSeparator=","
      decimalScale={2}
    />
  );
};
export { CurrencyComponent };
