const getEnvironmentBaseUrl = (): string => {
  if (process.env.API_URL.startsWith("test")) {
    return "https://test.";
  }

  if (process.env.API_URL.startsWith("acceptatie")) {
    return "https://acceptatie.";
  }

  return "https://www.";
};

export { getEnvironmentBaseUrl };
