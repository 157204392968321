/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { SearchResponseDTO, SearchResponseDTOFromJSON, SearchResponseDTOToJSON } from "../models";

export interface SearchGetRequest {
  take: number;
  skip: number;
  platformId: number;
  filters?: string;
  searchEAN?: string;
  searchText?: string;
  modules?: string;
}

/**
 *
 */
export class SearchApi extends runtime.BaseAPI {
  /**
   */
  async searchGetRaw(requestParameters: SearchGetRequest): Promise<runtime.ApiResponse<SearchResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling searchGet."
      );
    }

    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling searchGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling searchGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.searchEAN !== undefined) {
      queryParameters["searchEAN"] = requestParameters.searchEAN;
    }

    if (requestParameters.searchText !== undefined) {
      queryParameters["searchText"] = requestParameters.searchText;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.modules !== undefined) {
      queryParameters["modules"] = requestParameters.modules;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/search`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => SearchResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async searchGet(
    take: number,
    skip: number,
    platformId: number,
    filters?: string,
    searchEAN?: string,
    searchText?: string,
    modules?: string
  ): Promise<SearchResponseDTO> {
    const response = await this.searchGetRaw({
      take: take,
      skip: skip,
      platformId: platformId,
      filters: filters,
      searchEAN: searchEAN,
      searchText: searchText,
      modules: modules
    });
    return await response.value();
  }
}
