/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutProductDTO, OutProductDTOFromJSON, OutProductDTOFromJSONTyped, OutProductDTOToJSON } from "./";

/**
 *
 * @export
 * @interface ProductSearchDTO
 */
export interface ProductSearchDTO {
  /**
   *
   * @type {Array<OutProductDTO>}
   * @memberof ProductSearchDTO
   */
  data?: Array<OutProductDTO>;
  /**
   *
   * @type {number}
   * @memberof ProductSearchDTO
   */
  count: number;
}

export function ProductSearchDTOFromJSON(json: any): ProductSearchDTO {
  return ProductSearchDTOFromJSONTyped(json, false);
}

export function ProductSearchDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductSearchDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : (json["data"] as Array<any>).map(OutProductDTOFromJSON),
    count: json["count"]
  };
}

export function ProductSearchDTOToJSON(value?: ProductSearchDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data === undefined ? undefined : (value.data as Array<any>).map(OutProductDTOToJSON),
    count: value.count
  };
}
