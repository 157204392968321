import { getLastArrayItem } from "@app/util/get-last-array-item";
import { singleMerchantGetApi } from "@app/middleware/api/single-merchant-get.api";
import Logger from "@app/util/logger";
import { moduleMapperThrowMessage } from "./module-mapper-error-handling";
import { OutDetailedMerchantDTO } from "@app/bf-api";

export const getMerchantSingleFilters = async (
  platformId: number,
  splittedUrl?: string[],
  shopPageUrlName?: string
): Promise<{ merchantId: string; filters?: string } | undefined> => {
  try {
    const isMerchantSinglePage = checkIfMerchantSinglePage(splittedUrl, shopPageUrlName);
    if (!isMerchantSinglePage || !splittedUrl || !shopPageUrlName) return undefined;

    const slug = getLastArrayItem(splittedUrl);
    const merchant: OutDetailedMerchantDTO | undefined | 302 = await singleMerchantGetApi(platformId, slug);

    if (!merchant || merchant === 302) return moduleMapperThrowMessage("Cannot get single merchant");

    const filterIds = merchant?.categories?.map(item => item.id);
    return { merchantId: `${merchant.id}`, filters: filterIds?.join(";") };
  } catch (e) {
    Logger.logError(e, "Error in: getMerchantSingleFilters");

    return undefined;
  }
};

export const checkIfMerchantSinglePage = (splittedUrl?: string[], shopPageUrlName?: string): boolean => {
  if (!splittedUrl || !shopPageUrlName) return false;
  return splittedUrl.includes(shopPageUrlName) && getLastArrayItem(splittedUrl) !== shopPageUrlName;
};
