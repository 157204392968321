import { IconComponent } from "@app/core";
import { useDispatch, useSelector } from "react-redux";
import * as React from "react";
import clsx from "clsx";
import { useIntl } from "react-intl";

import ChevronRightWhite from "@assets/icons/chevron-right-white.svg";
import ArrowDownLeft from "@assets/icons/arrow-down-left.svg";
import ArrowLongDownLeft from "@assets/icons/arrow-long-left.svg";
import DateRange from "@assets/icons/date-range.svg";
import { IWhenIsModule } from "@app/api/modules/when-is/when-is.module";
import { settingsState, ViewType } from "@app/redux/reducers/settings";
import { subscribeToNewsletter } from "@app/redux/thunks/newsletter.thunk";
import { getTotalAmountOfMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import Typography from "@app/components/typography";
import { INewNewsletterFormValues, NewNewsletterFormComponent } from "@app/core/newsletter";
import { ReCaptcha } from "@app/core/recaptcha/recaptcha.component";
import { ShadowCardComponent } from "@app/core/shadow-card";
import { ClickableComponent } from "@app/core/clickable";
import { formatMessage } from "@app/translations/intl";

import styles from "./when-is-component.module.scss";

export interface IWhenIsProps {
  whenIsModule: IWhenIsModule;
}

const WhenIs = (props: IWhenIsProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const settings = useSelector(settingsState);
  const { screenSize, platformId } = settings;
  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [isSubmitted, setIsSubmitted] = React.useState<boolean>(false);

  React.useEffect(() => {
    dispatch(getTotalAmountOfMerchants(platformId));
  }, []);

  const onSubmit = (values: INewNewsletterFormValues) => {
    if (values.emailAddress) {
      dispatch(subscribeToNewsletter(values, recaptchaToken));
      setIsSubmitted(true);
    }
  };

  const showMobile = (): boolean => {
    if (typeof window === "undefined") {
      if ((screenSize?.viewType || 0) <= ViewType.Tablet) {
        return true;
      }

      return false;
    }

    if (screenSize && screenSize.viewType <= ViewType.Desktop) {
      return true;
    }

    return false;
  };

  return (
    <div className={styles.whenIsHeader}>
      <div
        className={styles.imageContainer}
        style={{
          backgroundImage: showMobile()
            ? `url(${props.whenIsModule.imageMobile?.src})`
            : `url(${props.whenIsModule.imageDesktop?.src})`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover"
        }}
      />
      <div className={clsx(styles.content, "uk-container")}>
        <Typography className={styles.title} tag="h1" align="center">
          {props.whenIsModule.title}
        </Typography>

        <div className={styles.headerSubTitleSection}>
          <div className={styles.headerSubTitleContainer}>
            <div className={styles.headerSubTitleItem}>
              <IconComponent icon={DateRange} size="22px" />
              <div className={styles.headerSubTitleText}>{props.whenIsModule.primaryHeaderSubtitle}</div>
            </div>
            <div className={styles.headerSubTitleItem}>
              <IconComponent icon={DateRange} size="22px" />
              <div className={styles.headerSubTitleText}>{props.whenIsModule.secundaryHeaderSubtitle}</div>
            </div>
          </div>
        </div>

        <ShadowCardComponent backgroundColor="white" fullWidth height="100%" borderRadius={["2px"]}>
          <div className={styles.middleSectionContainer}>
            <div className={styles.middleSectionHeader}>
              <Typography className={styles.sectionTitle} tag="h2" variant="h1">
                {props.whenIsModule.sectionTitle}
              </Typography>

              <div className={styles.middleSectionSubTitleContainer}>
                <div className={styles.middleSectionSubTitleText}>{props.whenIsModule.sectionSubTitle}</div>
                <div className={styles.arrowIconMobile}>
                  {showMobile() && <IconComponent icon={ArrowDownLeft} size="22px" />}
                </div>
              </div>
            </div>

            <div className={styles.applyFormContainer}>
              <div className={styles.applyForm}>
                <ReCaptcha action="newsletter" callBackToken={setRecaptchaToken} />
                <NewNewsletterFormComponent intl={intl} onSubmit={onSubmit} isSubmitted={isSubmitted} whatIsVersion />
              </div>

              {!showMobile() && (
                <div className={styles.arrow}>
                  {<span className={styles.arrowText}>{props.whenIsModule.subscribeArrowText}</span>}
                  <IconComponent icon={ArrowLongDownLeft} size="22px" />
                </div>
              )}
            </div>

            <hr />

            <div className={styles.ctaButtonContainer}>
              <div className={styles.ctaButton}>
                <ClickableComponent
                  iconRight={ChevronRightWhite}
                  title={props.whenIsModule.leftCTAButtonTitle}
                  href={formatMessage({ id: "global.merchantOverviewLink" })}
                  variant="primary-generic"
                  textRight={props.whenIsModule.leftCTAButtonArrowText}
                  fullWidth
                />
              </div>
              <div className={styles.ctaButton}>
                <ClickableComponent
                  iconRight={ChevronRightWhite}
                  title={props.whenIsModule.rightCTAButtonTitle}
                  href={formatMessage({ id: "global.dealOverviewLink" })}
                  variant="primary-generic"
                  fullWidth
                />
              </div>
            </div>
          </div>
        </ShadowCardComponent>
      </div>
    </div>
  );
};

export { WhenIs };
