/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { SubscriberDTO, SubscriberDTOFromJSON, SubscriberDTOToJSON } from "../models";

export interface NewslettersSubscribePostRequest {
  platformId: number;
  xRecaptchaToken: string;
  subscriberDTO: SubscriberDTO;
}

/**
 *
 */
export class NewslettersApi extends runtime.BaseAPI {
  /**
   */
  async newslettersSubscribePostRaw(
    requestParameters: NewslettersSubscribePostRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling newslettersSubscribePost."
      );
    }

    if (requestParameters.xRecaptchaToken === null || requestParameters.xRecaptchaToken === undefined) {
      throw new runtime.RequiredError(
        "xRecaptchaToken",
        "Required parameter requestParameters.xRecaptchaToken was null or undefined when calling newslettersSubscribePost."
      );
    }

    if (requestParameters.subscriberDTO === null || requestParameters.subscriberDTO === undefined) {
      throw new runtime.RequiredError(
        "subscriberDTO",
        "Required parameter requestParameters.subscriberDTO was null or undefined when calling newslettersSubscribePost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    if (requestParameters.xRecaptchaToken !== undefined && requestParameters.xRecaptchaToken !== null) {
      headerParameters["x-recaptcha-token"] = String(requestParameters.xRecaptchaToken);
    }

    const response = await this.request({
      path: `/newsletters/subscribe`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: SubscriberDTOToJSON(requestParameters.subscriberDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async newslettersSubscribePost(
    platformId: number,
    xRecaptchaToken: string,
    subscriberDTO: SubscriberDTO
  ): Promise<void> {
    await this.newslettersSubscribePostRaw({
      platformId: platformId,
      xRecaptchaToken: xRecaptchaToken,
      subscriberDTO: subscriberDTO
    });
  }
}
