import { htmlToText } from "html-to-text";

export const stripHtmlString = (html: string): string => {
  const characterSize = 300;

  const newText = htmlToText(html, {
    wordwrap: false,
    uppercaseHeadings: false,
    tags: {
      a: {
        options: {
          ignoreHref: true
        }
      }
    }
  });

  return newText.substring(0, characterSize);
};
