import { IBreadcrumbComponentProps, IBreadcrumbLink } from "@app/core/breadcrumb";
import { urlSplitter } from "@app/api/wp-page-fetcher/utils/url-splitter";
import { toTitleCase } from "@app/util/sentence-case";
import { removeLastArrayItem } from "@app/util/remove-last-array-item";
import Logger from "@app/util/logger";
import { PlatformType } from "@app/constants/platform";
import { formatMessage } from "@app/translations/intl";

export interface IBreadcrumbComponentMapper {
  platformId: number;
  pageUrl?: string;
  title?: string;
  variant?: "dark" | "light";
}

export const breadcrumbComponentMapper = async (
  props: IBreadcrumbComponentMapper
): Promise<IBreadcrumbComponentProps | undefined> => {
  try {
    const { platformId, pageUrl, variant, title } = props;
    if (!pageUrl) return undefined;

    const splittedUrl = urlSplitter(pageUrl);
    const backButton = getBackButton(splittedUrl, platformId);
    const breadcrumbs = getBreadcrumbLinks(splittedUrl, platformId, title);

    return {
      variant: variant || "light",
      breadcrumbs,
      backButton
    };
  } catch (e) {
    Logger.logError(e, "Error in: breadcrumbComponentMapper");

    return undefined;
  }
};

const getBackButton = (splittedUrl: string[], platformId: number): IBreadcrumbLink => {
  const removedLastSlug = removeLastArrayItem(splittedUrl).join("/");
  const url = removedLastSlug.length > 0 ? `/${removedLastSlug}/` : "/";

  return {
    name: formatMessage({ id: "default.breadcrumb.backButton.name" }),
    url
  };
};

const getBreadcrumbLinks = (splittedUrl: string[], platformId: number, title?: string): IBreadcrumbLink[] => {
  const cleanedUrls = cleanHomeUrl(splittedUrl, platformId);

  const homeBreadcrumb = [
    {
      name: formatMessage({ id: "default.breadcrumb.home.name" }),
      url: formatMessage({ id: "default.breadcrumb.home.url" })
    }
  ];

  if (cleanedUrls.length === 0) {
    return homeBreadcrumb;
  }

  const breadrumbLinks = cleanedUrls.map((slug, index) => {
    const useCustomTitle = index === cleanedUrls.length - 1 && title;
    const betterName = useCustomTitle || toTitleCase(slug.replace(/-/g, " "));
    const removedSlugs = splittedUrl
      .filter(
        (_, slugIndex) =>
          slugIndex <=
          (platformId === PlatformType.BELGIANDUTCH ||
          platformId === PlatformType.NETHERLANDS ||
          platformId === PlatformType.LANDINGPAGE
            ? index
            : index + 1)
      ) // +1 to ignore the /locale/ prefix.
      .join("/");

    const url = removedSlugs.length > 0 ? `/${removedSlugs}/` : "/";

    return {
      name: betterName,
      url
    };
  });

  return [...homeBreadcrumb, ...breadrumbLinks];
};

const cleanHomeUrl = (splittedUrl: string[], platformId: number) => {
  const platforms = [PlatformType.NETHERLANDS, PlatformType.BELGIANDUTCH, PlatformType.LANDINGPAGE];
  let url = splittedUrl;

  // Remove first bit of splitted url as it is the locale
  if (!platforms.includes(platformId)) {
    url = splittedUrl.slice(1);
  }

  return url;
};
