import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import { ClickableComponent, IClickableComponentProps } from "@app/core/clickable";
import IconChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { FlexGrid } from "@app/components/flex-grid/flex-grid";
import { ViewType } from "@app/redux/reducers/settings";
import { DealCardWrapper } from "@app/modules/deal-card-wrapper/deal-card-wrapper";
import { useAppSelector } from "@app/redux/store";
import { formatMessage } from "@app/translations/intl";

import styles from "../search-results.module.scss";

interface IProps {
  headerText: string;
  button: IClickableComponentProps;
  deals: IOutFeaturedDealDTO[];
  count?: number;
  closeSearch: () => void;
}

const component = (props: IProps & RouteComponentProps) => {
  const { screenSize } = useAppSelector(state => state.settings);
  const isMobile = screenSize && screenSize?.viewType <= ViewType.Tablet;

  const getColumns = (viewType: ViewType | undefined) => {
    switch (viewType) {
      case ViewType.Desktop:
        return 2;
      case ViewType.DesktopFull:
        return 3;
      case ViewType.DesktopLarge:
        return 3;
      case ViewType.Tablet:
        return 1;
      case ViewType.Mobile:
        return 1;
      case ViewType.MobileBig:
        return 2;
      default:
        return 3;
    }
  };

  const gutter = isMobile ? 0 : 8;
  const columns = getColumns(screenSize?.viewType);

  const handleClick = () => {
    props.closeSearch();
    props.history.push(formatMessage({ id: "global.dealOverviewLink" }));
  };

  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.header}>
        {props.headerText} {(props.count || 0) > 0 ? `(${props.count})` : ""}
      </div>
      <div className={styles.body}>
        <FlexGrid columns={columns} gutter={gutter}>
          {props.deals?.map(deal => (
            <DealCardWrapper key={deal.id} deal={deal} responsive variant="mobile-search" />
          ))}
        </FlexGrid>
      </div>
      <div className={styles.button}>
        <ClickableComponent
          {...props.button}
          iconStyle="filled"
          iconRight={IconChevronRight}
          iconFillColor="white"
          iconSize="14px"
          variant="primary-new-colored-simple"
          onClick={handleClick}
        />
      </div>
    </div>
  );
};

export const NavBarResultDeals = withRouter(component);
