/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  MerchantDealUsageDTO,
  MerchantDealUsageDTOFromJSON,
  MerchantDealUsageDTOFromJSONTyped,
  MerchantDealUsageDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface DealMerchantsResponseDTO
 */
export interface DealMerchantsResponseDTO {
  /**
   *
   * @type {Array<MerchantDealUsageDTO>}
   * @memberof DealMerchantsResponseDTO
   */
  merchants?: Array<MerchantDealUsageDTO>;
}

export function DealMerchantsResponseDTOFromJSON(json: any): DealMerchantsResponseDTO {
  return DealMerchantsResponseDTOFromJSONTyped(json, false);
}

export function DealMerchantsResponseDTOFromJSONTyped(
  json: any,
  ignoreDiscriminator: boolean
): DealMerchantsResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    merchants: !exists(json, "merchants")
      ? undefined
      : (json["merchants"] as Array<any>).map(MerchantDealUsageDTOFromJSON)
  };
}

export function DealMerchantsResponseDTOToJSON(value?: DealMerchantsResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    merchants:
      value.merchants === undefined ? undefined : (value.merchants as Array<any>).map(MerchantDealUsageDTOToJSON)
  };
}
