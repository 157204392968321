import { connect, MapStateToProps } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import { IPagebuilderComponentProps, PageBuilderComponent } from "@app/core/pagebuilder";
import { PageModel } from "@app/api/pagebuilder/page.model";
import { IOutSingleMerchantDTO } from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { PlatformDTO } from "@app/bf-api";
import { RootState } from "@app/redux/store";
import { getPage } from "@app/redux/thunks/page.thunk";
import { IScreenSize, setOptimizeVariant, setScreenSize } from "@app/redux/reducers/settings";
import { IBaseModules } from "@app/redux/reducers/page";

interface IStateProps {
  baseModules?: IBaseModules;
  blackFridayRootUrl?: string;
  currentPage: PageModel | undefined;
  isLoading?: boolean;
  merchant?: IOutSingleMerchantDTO;
  platformId: number;
  recaptchaSiteKey: string;
  screenSize: IScreenSize | undefined;
  platform?: PlatformDTO;
}
interface IDispatchProps {
  getPage: (route: string, platformId: number) => void;
  setOptimizeVariant: (variant: number) => void;
  setScreenSize: (screenSize: IScreenSize) => void;
}

const mapStateToProps: MapStateToProps<
  IStateProps,
  IPagebuilderComponentProps & RouteComponentProps,
  RootState
> = state => {
  const { currentPage, baseModules, isLoading } = state.pages;
  const { screenSize, platformId, recaptchaSiteKey } = state.settings;
  const blackFridayRootUrl = state.settings.blackFridayRootUrl;
  const { currentMerchant } = state.merchantOverview;
  const { platform } = state.platform;

  return {
    currentPage,
    screenSize,
    blackFridayRootUrl,
    currentMerchant,
    platformId,
    recaptchaSiteKey,
    baseModules,
    isLoading,
    platform
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  getPage: (route: string, platformId: number) => dispatch(getPage(route, platformId)),
  setScreenSize: (screenSize: IScreenSize) => dispatch(setScreenSize(screenSize)),
  setOptimizeVariant: (variant: number) => dispatch(setOptimizeVariant(variant))
});

export type PagebuilderContainerProps = IStateProps & IDispatchProps;
export const PagebuilderContainer = connect(mapStateToProps, mapDispatchToProps)(PageBuilderComponent);
