import React from "react";
import { IconComponent } from "@app/core/icon";
import percentageSvg from "@assets/icons/percentage.svg";
import styles from "./platform-alert.module.scss";
import { getPlatformAlertInfo } from "@app/util/platform-alert-checker";
import { PlatformAlertTimer } from "@app/core/platform-alert-timer/components/platform-alert-timer";
import { PlatformDTO } from "@app/bf-api";
import clsx from "clsx";

interface IPlatformAlertProps {
  platform?: PlatformDTO;
  absolute?: boolean;
}
export const PlatformAlert = (props: IPlatformAlertProps) => {
  const alertInfo = getPlatformAlertInfo(props.platform);

  return (
    <>
      {alertInfo && (
        <div className={clsx(styles.wrapper, props.absolute && styles.absolute)}>
          <div className={clsx("uk-container", styles.contentWrapper)}>
            <div className={styles.titleWrapper}>
              <div className={styles.icon}>
                <IconComponent icon={percentageSvg} size={"12px"} />
              </div>
              <span className={styles.title}>{alertInfo.alertMessage}</span>
            </div>

            <PlatformAlertTimer alertInfo={alertInfo} />
          </div>
        </div>
      )}
    </>
  );
};
