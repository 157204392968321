import { SearchOptions } from "@app/api/wp-page-fetcher/module-selector/module-mappers/search-results-module";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import { OutProductDTO } from "@app/bf-api";
import { ISearchResults } from "@app/redux/reducers/search";
import { IFeaturedWPPage } from "@app/middleware/api/featured-pages-get.api";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { WordPressPostModule } from "../wordpress-module/wordpress-module";

export interface SearchResultsModule extends WordPressPostModule {
  searchResults?: ISearchResults;
  modules: SearchItemsModules[];
  isCollapsible?: {
    deals?: boolean;
    merchants?: boolean;
    products?: boolean;
    pages?: boolean;
  };
  resultLimits: {
    deals: number;
    merchants: number;
    products: number;
    pages: number;
  };
  collapsedLimits: {
    deals: number;
    merchants: number;
    products: number;
    pages: number;
  };
  defaults?: {
    deals?: IOutFeaturedDealDTO[];
    merchants?: IOutFeaturedMerchantDTO[];
    products?: OutProductDTO[];
    pages?: IFeaturedWPPage[];
  };
  translations?: {
    searchBarPlaceholder?: string;
    anchorLabel?: string;
    merchants?: ISearchResultsTranslations;
    products?: ISearchResultsTranslations;
    deals?: ISearchResultsTranslations;
    pages?: ISearchResultsTranslations;
  };
}

export interface ISearchResultsTranslations {
  title?: string;
  showMore?: string;
  showLess?: string;
  anchorLabel?: string;
  defaultTitle?: string;
}

export type SearchItemsModules = "deals" | "merchants" | "products" | "pages";

export const SearchItemsModulesMap: Record<SearchOptions, SearchItemsModules> = {
  deal: "deals",
  shop: "merchants",
  product: "products",
  page: "pages"
} as const;
