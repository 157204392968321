import React, { forwardRef, Ref, useState } from "react";
import clsx from "clsx";

import ChevronDownIcon from "@assets/icons/chevron-down-navbar.svg";
import { IconComponent } from "@app/core";
import { ISearchResultsTranslations } from "@app/api/modules/search-results/search-results.module";

import styles from "./card-results.module.scss";

interface IProps {
  anchorId: string;
  translations?: ISearchResultsTranslations;
  count?: number;
  cards?: JSX.Element[];
  maxColumns?: 3 | 4;
  isCollapsible?: boolean;
  defaultShowLimit?: number;
}

type CardResultsRefType = (props: IProps, ref?: Ref<HTMLDivElement>) => JSX.Element;

const CardResultsRef: CardResultsRefType = (
  { anchorId, defaultShowLimit, isCollapsible, translations, count, cards, maxColumns = 3 },
  ref
) => {
  const [isExpanded, setIsExpanded] = useState(isCollapsible ? false : true);
  const baseShowCount = isCollapsible ? defaultShowLimit || maxColumns * 2 : undefined;
  const itemShowCount = isExpanded ? undefined : baseShowCount;
  const title = getTitle(translations?.title, translations?.defaultTitle, count);

  return (
    <div ref={ref} className={styles.searchResultContainer}>
      {title && (
        <h2 id={anchorId} className={styles.header}>
          {title}
        </h2>
      )}
      <div className={clsx(styles.grid, maxColumns === 4 && styles.four, isExpanded && styles.isOpen)}>
        {(cards || []).slice(0, itemShowCount)}
      </div>

      {isCollapsible &&
        translations?.showMore &&
        translations?.showLess &&
        (cards || []).length > (baseShowCount || 0) && (
          <button
            type="button"
            className={clsx(styles.toggleView, isExpanded && styles.open)}
            onClick={() => setIsExpanded(p => !p)}
          >
            <span>{isExpanded ? translations.showLess : translations.showMore}</span>

            <div className={styles.icon}>
              <IconComponent size="8px" icon={ChevronDownIcon} />
            </div>
          </button>
        )}
    </div>
  );
};

const CardResults = forwardRef(CardResultsRef);

export { CardResults };

const getTitle = (title?: string, defaultTitle?: string, count?: number): string => {
  if (typeof count === "undefined") {
    return defaultTitle || "";
  }

  return (title || "").replaceAll("{count}", typeof count === "number" ? count.toString() : "");
};
