import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useWindowScroll } from "react-use";

import ChevronRightWhite from "@assets/icons/chevron-right-white.svg";
import { ImageComponent } from "@app/core";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { IOutSingleMerchantDTO } from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { CouponButtonComponent } from "@app/core/discount-card";
import { ClickableComponent } from "@app/core/clickable";
import { formatMessage } from "@app/translations/intl";
import { RelAttributes } from "@app/constants";

import { getMerchantOutLink } from "../main/merchant-header-main";
import styles from "./merchant-header-sticky.module.scss";

export interface IMerchantHeaderStickyProps {
  merchant: IOutSingleMerchantDTO;
  shopSingleHeaderModule: ShopSingleHeaderModule;
  dealsButtonRef: React.RefObject<HTMLDivElement>;
}

export const MerchantHeaderSticky = (props: IMerchantHeaderStickyProps) => {
  const [showSticky, setShowSticky] = useState<boolean>(false);
  const { y: scrollYPos } = useWindowScroll();

  const {
    merchant,
    dealsButtonRef,
    shopSingleHeaderModule: { toCampaignButtonText }
  } = props;

  useEffect(() => {
    const elementIsOutOfView = checkIfElementOutOfView(scrollYPos, dealsButtonRef);
    setShowSticky(elementIsOutOfView);
  }, [scrollYPos, dealsButtonRef]);

  const discountSubtitle = formatMessage({ id: "discountCard.discountSubtitle" });
  const merchantOutLink = getMerchantOutLink(merchant);

  return (
    <div className={clsx(styles.merchantHeaderSticky, showSticky && styles.show)}>
      <div className="uk-container">
        <div className={styles.container}>
          <div className={styles.logoExcerptContainer}>
            {merchant.logoImage?.url && (
              <div className={styles.logoContainer}>
                <ImageComponent
                  className={styles.logo}
                  alt={merchant.logoImage?.alt}
                  title={merchant.logoImage?.title}
                  sizes="72px"
                  src={merchant.logoImage.url}
                  forceImageTag
                  isBlocking
                />
              </div>
            )}

            {merchant.campaign?.excerpt && (
              <div className={styles.head}>
                <span className={styles.excerpt}>{merchant.campaign.excerpt}</span>
              </div>
            )}
          </div>

          <div className={styles.buttons}>
            {merchant.campaign?.couponCode && (
              <>
                <span className={styles.discountSubtitle}>{discountSubtitle}</span>
                <CouponButtonComponent classNames={styles.coupon} coupon={merchant.campaign.couponCode} hideIcon />
              </>
            )}

            <ClickableComponent
              className={styles.dealsButton}
              iconRight={ChevronRightWhite}
              title={toCampaignButtonText}
              href={merchantOutLink}
              target="_blank"
              variant="primary-new-colored-medium"
              forceExternal
              relAttribute={RelAttributes.SPONSORED}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const checkIfElementOutOfView = (scrollYPos: number, ref?: React.RefObject<HTMLDivElement>): boolean => {
  if (!ref?.current?.getBoundingClientRect()) {
    return false;
  }

  const rect = ref.current.getBoundingClientRect();
  const rectPosition = rect.bottom + scrollYPos;

  return scrollYPos >= rectPosition;
};
