import React from "react";
import { IImage } from "@app/api/core/image";
import { Link as ILink } from "@app/api/core/link";
import { ImageComponent } from "../image";
import { Link } from "react-router-dom";
import styles from "./link-box-component.module.scss";
import { IconComponent } from "../icon";
import IcnChevronRight from "@assets/icons/chevron-right-small.svg";

export interface ILinkBox {
  image?: IImage;
  links: ILink[];
  title?: string;
}

export interface ILinkBoxComponentProps extends ILinkBox {
  fullHeight?: boolean;
}

const LinkBoxComponent = (props: ILinkBoxComponentProps) => (
  <div className={styles.linkBox} style={{ height: props.fullHeight ? "100%" : "auto" }}>
    {props.image?.src && (
      <div className={styles.imageWrapper}>
        <ImageComponent src={props.image.src} alt={props.image.alt} title={props.image.title} />
      </div>
    )}
    <div className={styles.content}>
      {props.title && <h3>{props.title}</h3>}
      <ul className={styles.linkList}>
        {props.links?.map((link, index) => (
          <li key={index} className={styles.linkItem}>
            <Link to={link.url}>
              <span className={styles.icon}>
                <IconComponent icon={IcnChevronRight} size="10px" />
              </span>
              {link.title}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export { LinkBoxComponent };
