export const urlSplitter = (url: string): string[] => {
  const splittedUrl = url.split("/").filter(urlItem => urlItem);
  const splittedUrlCleaned = removeObjectInUrl(splittedUrl);

  return splittedUrlCleaned;
};

const removeObjectInUrl = (splittedUrl: string[]): string[] => {
  const filterOutString = "[object%20object]";

  return splittedUrl.filter(url => url !== filterOutString);
};
