import { ResourceTextComponent } from "@app/core";
import { DropdownComponent } from "@app/core/dropdown-new/dropdown.component";
import { useAppSelector } from "@app/redux/store";
import React, { useEffect, useState } from "react";

import styles from "../new-nav-bar-component.module.scss";

export const LanguageSwitcher = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [languageSwitcherTitle, setLanguageSwitcherTitle] = useState("");
  const settings = useAppSelector(state => state.settings);
  const { platformId: rdxPlatformId } = settings;
  const locales = useAppSelector(state => state.locales);
  const { locale } = locales;

  const [platformId, setPlatformId] = useState(rdxPlatformId);

  useEffect(() => {
    if (locale === "nl-BE") {
      setLanguageSwitcherTitle("NL");
    }

    if (locale === "fr-BE") {
      setLanguageSwitcherTitle("FR");
    }
  }, [locale]);

  useEffect(() => {
    if (rdxPlatformId !== platformId) {
      setPlatformId(platformId);
    }
  }, [rdxPlatformId]);

  const toggleIsOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <DropdownComponent isOpen={isOpen} setIsOpen={toggleIsOpen} title={languageSwitcherTitle}>
      <div className={styles.languageSwitcher}>
        <a href="/" className={styles.languageSwitcherItem}>
          <ResourceTextComponent resourceKey="languageSwitcher.BENL" />
        </a>
        <a href="/fr/" className={styles.languageSwitcherItem}>
          <ResourceTextComponent resourceKey="languageSwitcher.BEFR" />
        </a>
      </div>
    </DropdownComponent>
  );
};
