import { IWPNavItem, IMenus } from "@app/api/wp-page-fetcher/module-selector/main-wp.interface";
import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { menuWordPressApiGet } from "../wordpress-api/base-wp-api";
import { moduleMapperThrowMessage } from "./module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getMenuItems = async (platformId: number): Promise<IMenus | undefined> => {
  try {
    const allMenus = await menuWordPressApiGet(platformId);
    if (!allMenus || allMenus === 404 || allMenus === 503) return moduleMapperThrowMessage("Cannot get menus from api");

    return {
      header: { topMenu: mapWPMenuItems(allMenus.header.topMenu) },
      sideMenu: { sideMenu: mapWPMenuItems(allMenus.sideMenu.sideMenu) },
      footer: {
        footerColumnOne: mapWPMenuItems(allMenus.footer.footerColumnOne),
        footerColumnTwo: mapWPMenuItems(allMenus.footer.footerColumnTwo),
        footerColumnThree: mapWPMenuItems(allMenus.footer.footerColumnThree)
      }
    };
  } catch (e) {
    Logger.logError(e, "Error in: GetMenuItems api call");
    return undefined;
  }
};

const mapWPMenuItems = (wpMenuItems?: IWPNavItem[]): MenuItem[] | undefined => {
  if (!wpMenuItems || wpMenuItems.length === 0) return undefined;

  const mapMenuItem = (wpMenuItem: IWPNavItem): MenuItem => {
    let url = wpMenuItem.url;
    if (wpMenuItem.url) {
      url = wpMenuItem.url;
    }

    const baseObject: MenuItem = { title: wpMenuItem.title || "", ...(url && { url }) };

    if (wpMenuItem.child_items && wpMenuItem.child_items.length > 0) {
      const children: MenuItem[] = wpMenuItem.child_items.map((childItem: IWPNavItem) => mapMenuItem(childItem));

      return { ...baseObject, children };
    }

    return { ...baseObject };
  };

  return wpMenuItems.map(wpMenuItem => mapMenuItem(wpMenuItem));
};
