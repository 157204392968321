import React from "react";
import clsx from "clsx";

import { ILinkBoxesModule } from "@app/api/modules/link-boxes/link-boxes.module";
import { LinkBoxComponent } from "@app/core/link-box/link-box.component";
import Typography from "@app/components/typography";

import styles from "./link-boxes-component.module.scss";

export interface IHeaderInfoComponentProps {
  linkBoxes: ILinkBoxesModule;
}

export const LinkBoxesComponent = (props: IHeaderInfoComponentProps) => {
  const { title, linkBoxes } = props.linkBoxes;

  return (
    <div className={clsx(styles.linkBoxes, "uk-container")}>
      {title && (
        <Typography tag="h3" variant="h2" align="center">
          {title}
        </Typography>
      )}
      {linkBoxes && linkBoxes.length > 0 && (
        <div className={styles.outerWrapper}>
          <div className={styles.innerWrapper}>
            {linkBoxes.map((linkBox, index) => (
              <div key={index} className={styles.linkBox}>
                <LinkBoxComponent title={linkBox.title} image={linkBox.image} links={linkBox.links} fullHeight />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
