import { OutProductExtendedViewDTO, ProductsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const singleProductGetApi = async (
  slug: string,
  platformId: number
): Promise<OutProductExtendedViewDTO | undefined | 302> => {
  try {
    const api = new ProductsApi();

    const product = await api.productsSlugProductSlugGet(true, platformId, slug);

    if (!product) return moduleMapperThrowMessage("Cannot get single product");

    if (!product.live) return moduleMapperThrowMessage("Product is offline, send [302]");

    return product;
  } catch (e) {
    Logger.logError(e, "Error in: singleProductGetApi");

    if ((e as any)?.toString().includes("[302]")) return 302;

    return undefined;
  }
};
