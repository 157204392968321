/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface GenericIdDTO
 */
export interface GenericIdDTO {
  /**
   *
   * @type {number}
   * @memberof GenericIdDTO
   */
  id: number;
}

export function GenericIdDTOFromJSON(json: any): GenericIdDTO {
  return GenericIdDTOFromJSONTyped(json, false);
}

export function GenericIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): GenericIdDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"]
  };
}

export function GenericIdDTOToJSON(value?: GenericIdDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id
  };
}
