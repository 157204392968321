import { FilterItem } from "@app/api/core/filter/filter-item";

export const replaceOrAdd = (currentFilters: FilterItem[], filtersToAddOrReplace: FilterItem[]): FilterItem[] => {
  const result = [...currentFilters];

  filtersToAddOrReplace.forEach(filterToAddorReplace => {
    const foundIndex = result.findIndex(item => item.id === filterToAddorReplace.id);
    if (foundIndex > -1) {
      result[foundIndex] = filterToAddorReplace;
    } else {
      result.push(filterToAddorReplace);
    }
  });

  return result;
};

export const filtersAreDifferent = (oldFilterItems: FilterItem[], newFilterItems: FilterItem[]) => {
  if (!oldFilterItems || oldFilterItems.length <= 0) return true;
  const oldSelectedIds = getSelectedIds(oldFilterItems);
  const newSelectedIds = getSelectedIds(newFilterItems);

  return oldSelectedIds !== newSelectedIds;
};

export const getSelectedIds = (filterItems: FilterItem[]): string => {
  const filteredFilterItems = filterItems.filter(filter => filter.isSelected).map(filter => filter.id);

  const filtersIds = [...filteredFilterItems];
  const result = filtersIds.join(";");

  return result;
};

export const getSelectedFilters = (originalFilters: FilterItem[], selectedFilters: FilterItem[]): FilterItem[] => {
  const result = originalFilters.map(item => {
    const isSelected = !!selectedFilters.find(filter => item.id === filter.id);

    return { ...item, isSelected };
  });

  return result;
};

export const addFiltersToGTM = (allFiltersCombined: FilterItem[], customString?: string) => {
  if (allFiltersCombined.length > 0 || customString) {
    const allFilterNames = allFiltersCombined.map(filter => filter.displayName);
    if (customString) {
      allFilterNames.push(customString);
    }
    const sortedFilterNames = allFilterNames.sort((a: string, b: string) => (a < b ? -1 : a > b ? 1 : 0));
    const joinedFilters = sortedFilterNames.join(", ");

    if (window && window.dataLayer) {
      const lastGTMPush = window.dataLayer[window.dataLayer.length - 1];
      const checkPreviousGTMPush = lastGTMPush.filterwaarde && lastGTMPush.filterwaarde === joinedFilters;
      if (!checkPreviousGTMPush) {
        window.dataLayer.push({ event: "filter", filterwaarde: joinedFilters });
      }
    }
  }
};

export const addSortToGTM = (sortBy: string) => {
  if (window && window.dataLayer) {
    const lastGTMPush = window.dataLayer[window.dataLayer.length - 1];
    const checkPreviousGTMPush = lastGTMPush.filterwaarde && lastGTMPush.sorteerwaarde === sortBy;
    if (!checkPreviousGTMPush) {
      window.dataLayer.push({ event: "sort", sorteerwaarde: sortBy });
    }
  }
};
