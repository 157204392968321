import React, { useState } from "react";
import clsx from "clsx";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import styles from "./anchor-tag.module.scss";

export interface IAnchorTagProps {
  className?: string;
  children?: React.ReactNode;
  onClick?: () => void;
}

export const component = ({ className, children, onClick, history }: IAnchorTagProps & RouteComponentProps) => {
  return (
    <button type="button" onClick={onClick} className={clsx(styles.tag, className)}>
      {children}
    </button>
  );
};

const AnchorTag = withRouter(component);
export { AnchorTag };
