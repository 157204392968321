import { ErrorPageModule, ErrorPageVariants } from "@app/api/modules/error-page/error-page";
import Logger from "@app/util/logger";

export interface IErrorPageModuleMapper {
  variant?: ErrorPageVariants;
}

export const errorPageModuleMapper = async (props: IErrorPageModuleMapper): Promise<ErrorPageModule | undefined> => {
  try {
    const { variant } = props;

    return {
      id: "16",
      name: "NotFoundModule",
      variant: variant
    };
  } catch (e) {
    Logger.logError(e, "Error in: errorPageModuleMapper");

    return undefined;
  }
};
