import IntlFormatMessage from "@app/translations/intl-singleton";
import React from "react";
import { useIntl } from "react-intl";

export interface IIntlSetter {
  locale: string;
  translations: any;
}

const IntlSetter = (props: IIntlSetter): JSX.Element => {
  const intl = useIntl();
  IntlFormatMessage.getInstance().setIntl(intl);

  return <></>;
};

export { IntlSetter };
