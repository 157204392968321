export class DirtySingleton {
  private static instance: DirtySingleton;
  public getNameSpace: any;

  public static getInstance(): DirtySingleton {
    if (!DirtySingleton.instance) {
      DirtySingleton.instance = new DirtySingleton();
    }

    return DirtySingleton.instance;
  }

  public setGetNameSpace(fn: any) {
    this.getNameSpace = fn;
  }
}
