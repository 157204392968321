// tslint:disable

import { useEffect, useState } from "react";

import { AnchorId } from "../anchor-id";
import { useRefList } from "./use-ref-list";
import { useTimeout } from "./use-timeout";
import { RouteComponentProps } from "react-router-dom";

export interface IUseRefListScrollIntoView<T> {
  setRef: (node: T | null, refId?: string) => void;
  scrollToId: (itemId: string) => Promise<void>;
}

export const useRefListScrollIntoView = <T extends HTMLElement>(
  history?: RouteComponentProps["history"]
): IUseRefListScrollIntoView<T> => {
  const [path, setPath] = useState<string>("");
  const { itemsRef, getNode, setRef } = useRefList<T>();
  // The render is sometimes a bit late so we delay the scroll
  const { start } = useTimeout(scrollNodeIntoView, 0);

  useEffect(() => {
    if (history?.location?.hash) {
      setPath(history?.location?.hash);
    }
  }, [history?.location?.hash]);

  useEffect(() => {
    const anchor = AnchorId.get(path);

    scrollToId(anchor);
  }, [path, itemsRef.current]);

  const scrollToId = async (itemId: string): Promise<void> => {
    const node = getNode(itemId);

    if (node) {
      start(node);
    }
  };

  return { setRef, scrollToId };
};

const scrollNodeIntoView = <T extends HTMLElement>([node]: [T | undefined]): void => node?.scrollIntoView();
