import { IPlatformSpecificInfo, platforms } from "./../../../constants/platform";
import { getEnvironmentBaseUrl } from "./get-environment";

const findPlatformContent = (platformId: number): IPlatformSpecificInfo => {
  const platform = getPlatform(platformId);
  const url = getPlatformUrl(platformId);

  return {
    ...platform,
    url
  } as IPlatformSpecificInfo;
};

const getPlatform = (platformId: number): IPlatformSpecificInfo =>
  platforms.find(p => p.id === platformId) as IPlatformSpecificInfo;

const getPlatformUrl = (platformId: number) => {
  const platform = getPlatform(platformId);

  const baseUrl = `${getEnvironmentBaseUrl()}${platform.domain}`;

  return [baseUrl, platform.urlAddition].filter(Boolean).join("/");
};

export { findPlatformContent, getPlatformUrl };
