import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";
import Carousel, { ScrollMode } from "nuka-carousel";

import { ICategoryBlocksModule } from "@app/api/modules/category-blocks/category-blocks.modules";
import { IconComponent, ImageComponent } from "@app/core";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import ChevronRightIcon from "@assets/icons/chevron-right.svg";
import { carouselBreakPoints } from "@app/util/detect-view";

import styles from "./category-blocks-component.module.scss";

export interface ICategoryBlocksProps {
  categoryBlocksModule: ICategoryBlocksModule;
}

const CategoryBlocks = (props: ICategoryBlocksProps) => {
  const { categoryBlocks, moduleTitle } = props.categoryBlocksModule;
  const [viewType, setViewType] = useState(8);
  const [width, setWidth] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (typeof window === "object") {
      setWidth(window.innerWidth);
    }
  }, []);

  useEffect(() => {
    if (typeof window === "object") {
      const handleResize = () => setWidth(window.innerWidth);
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  });

  const getCarouselView = (w: number): void => {
    if (w > carouselBreakPoints.carouselDesktopFull) {
      setViewType(8);
    }
    if (w < carouselBreakPoints.carouselDesktop) {
      setViewType(7);
    }
    if (w < carouselBreakPoints.carouselDesktopSmall) {
      setViewType(6);
    }
    if (w < carouselBreakPoints.CarouselTablet) {
      setViewType(5);
    }
    if (w < carouselBreakPoints.CarouselTabletSmall) {
      setViewType(4);
    }
    if (w < carouselBreakPoints.carouselMobileBig) {
      setViewType(3);
    }
    if (w < carouselBreakPoints.carouselMobile) {
      setViewType(2.2);
    }
    if (w < carouselBreakPoints.carouselMobileSmall) {
      setViewType(1.5);
    }
  };

  useEffect(() => {
    if (width) {
      getCarouselView(width);
    }
  }, [width]);

  return (
    <div className={clsx("uk-container", styles.categoryBlocks)}>
      <div className={styles.moduleTitle}>{moduleTitle}</div>
      <Carousel
        renderBottomCenterControls={null}
        slidesToShow={viewType}
        dragging={false}
        cellSpacing={12}
        scrollMode={ScrollMode.remainder}
        renderBottomLeftControls={({ previousDisabled, previousSlide }) => (
          <div className={styles.prevButton}>
            <button onClick={previousSlide} style={{ marginLeft: previousDisabled ? "6px" : 0 }} type="button">
              <div className={styles.icon}>
                <IconComponent icon={ChevronLeftIcon} strokeColor="white" size="11px" />
              </div>
            </button>
          </div>
        )}
        renderBottomRightControls={({ nextSlide }) => (
          <div className={styles.nextButton}>
            <button onClick={nextSlide} type="button">
              <div className={styles.icon}>
                <IconComponent icon={ChevronRightIcon} strokeColor="white" size="11px" />
              </div>
            </button>
          </div>
        )}
        defaultControlsConfig={{
          prevButtonStyle: {
            display: "none"
          },
          nextButtonStyle: {
            display: "none"
          }
        }}
      >
        {categoryBlocks.map((category, index) => (
          <div tabIndex={-1} key={index}>
            <div className={styles.categoryContainer}>
              <Link to={category.categoryLink?.url || ""}>
                <div className={styles.categoryimage}>
                  <ImageComponent
                    width="128px"
                    src={category.categoryImage?.url}
                    alt={category.categoryImage?.alt}
                    title={category.categoryImage?.title}
                  />
                </div>
              </Link>
              <div className={styles.categoryTitle}>{category.categoryTitle}</div>
            </div>
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export { CategoryBlocks };
