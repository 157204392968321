import React from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";

import { OutDealDTO } from "@app/bf-api";
import { IconComponent, ImageComponent } from "@app/core";
import { getOptimizedImageUrl } from "@app/util/image-optimization";
import CheckIcon from "@assets/icons/check.svg";
import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { formatMessage } from "@app/translations/intl";
import { ClickableComponent } from "@app/core/clickable";
import { RelAttributes } from "@app/constants";
import { CurrencyComponent } from "@app/core/currency/currency.component";
import { PlatformType } from "@app/constants/platform";
import { FeaturedFilterLabel } from "@app/core/featured-filter-label/featured-filter-label";
import { SHOW_DISCOUNT_GTI } from "@app/core/new-deal-card";

import styles from "./deal-card-landscape.module.scss";

interface IDealCardLandscape extends OutDealDTO {
  platformId: number;
}

const DealCardLandscape = ({
  name,
  id,
  platformId,
  beforePrice,
  price,
  featuredFilter,
  discount,
  merchant,
  usp
}: IDealCardLandscape) => {
  const usps = merchant?.features?.split(";");
  const showBeforePrice = !!beforePrice && !!price && price - beforePrice !== 0;
  const showDiscount = showBeforePrice && (discount || 0) >= SHOW_DISCOUNT_GTI;

  const { inMerchantLink, outMerchantLink, outDealLink } = getAffiliateDealLinks(id, merchant?.slug);

  return (
    <div className={styles.card}>
      {(featuredFilter?.name || showDiscount) && (
        <div className={styles.labels}>
          {/* Empty divs are for alignment */}
          <div />
          {featuredFilter?.name ? (
            <FeaturedFilterLabel label={featuredFilter.label} text={featuredFilter.name} />
          ) : (
            <div />
          )}
          {showDiscount ? <div className={styles.discountLabel}>{`-${discount}%`}</div> : <div />}
        </div>
      )}
      <div className={styles.merchant}>
        <a className={styles.merchantLogo} href={outDealLink} rel={RelAttributes.SPONSORED} target="_blank">
          {merchant?.logoImage?.url && (
            <ImageComponent
              alt={merchant.logoImage?.alt || ""}
              src={getOptimizedImageUrl({ url: merchant.logoImage.url, height: 120, quality: 100 })}
              title={merchant.logoImage?.title || ""}
              forceImageTag
              isBlocking
            />
          )}
        </a>
        {merchant?.name && (
          <Link className={styles.merchantName} to={inMerchantLink}>
            {merchant.name}
          </Link>
        )}
        {name && <h4 title={name}>{name}</h4>}
      </div>
      <div className={styles.divider} />
      <div className={styles.usps}>
        {usps?.map((feature, index) => (
          <div key={index}>
            <IconComponent icon={CheckIcon} size="16px" strokeColor="currentColor" />

            <span>{feature}</span>
          </div>
        ))}
      </div>
      <div className={styles.actions}>
        <a className={styles.dealLink} rel={RelAttributes.SPONSORED} target="_blank" href={outDealLink}>
          {!!price && (
            <div className={styles.price}>
              <div className={styles.priceBox}>
                {showBeforePrice && <CurrencyComponent className={styles.priceOld} value={beforePrice} variant="old" />}

                <CurrencyComponent
                  className={clsx(styles.priceNew, platformId === PlatformType.FRENCH && styles.redPrice)}
                  value={price}
                  variant="new"
                />
              </div>
            </div>
          )}
          {usp && <span className={styles.dealUsp}>{usp}</span>}
        </a>
        <div className={styles.buttons}>
          <ClickableComponent
            variant="primary-new"
            iconStyle="filled"
            title={formatMessage({ id: "dealCard.button.toCampaignPageOut" })}
            iconRight={IcnChevronRight}
            href={outDealLink}
            fullWidth
            forceExternal
            target="_blank"
            relAttribute={RelAttributes.SPONSORED}
          />
          <a className={styles.outMerchantLink} target="_blank" rel="sponsored" href={outMerchantLink}>
            {formatMessage({ id: "dealCard.showAllMerchantDeals" })}
            <IconComponent icon={IcnChevronRight} fillColor="currentColor" size="9px" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { DealCardLandscape };

export const getAffiliateDealLinks = (dealId?: number, merchantSlug?: string) => {
  if (!dealId)
    return {
      outMerchantLink: "",
      inMerchantLink: "",
      outDealLink: ""
    };

  const urlAddition = formatMessage({ id: "global.urlAddition" });

  const outMerchantLink = urlAddition ? `/out/store/${urlAddition}/${merchantSlug}/` : `/out/store/${merchantSlug}/`;
  const inMerchantLink = `${formatMessage({ id: "global.merchantOverviewLink" })}${merchantSlug}/`;
  const outDealLink = merchantSlug ? `/out/offer/${merchantSlug}/${dealId}/` : `/out/offer/${dealId}/`;

  return {
    outMerchantLink,
    inMerchantLink,
    outDealLink
  };
};
