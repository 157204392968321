import React, { useState } from "react";
import { useInterval } from "react-use";
import { padLeadingZeros } from "@app/util/pad-leading-zeroes";
import { IRemaingingTime, calculateRemainingTimeUnits } from "@app/util/calculate-remaining-time-units";
import { IPlatformAlertInfo } from "@app/util/platform-alert-checker";
import styles from "./platform-alert-timer.module.scss";
import { formatMessage } from "@app/translations/intl";

interface IPLatformAlertTimer {
  alertInfo: IPlatformAlertInfo;
}
export const PlatformAlertTimer = (props: IPLatformAlertTimer) => {
  const [alertTimer, setAlertTimer] = useState<IRemaingingTime | undefined>(undefined);
  const [isTimer, setIsTimer] = useState<boolean>(true);

  const currentDate = new Date();
  const intervaltime = 1000;

  const updateTimer = () => {
    const alertEndDateUnix = millisToSeconds(props.alertInfo.alertEndDate.getTime());
    const currentUnix = millisToSeconds(currentDate.getTime());
    const countdownUnix = alertEndDateUnix - currentUnix;

    if (countdownUnix < 0) {
      setIsTimer(false);
    } else {
      setAlertTimer(calculateRemainingTimeUnits(countdownUnix));
    }
  };

  useInterval(
    () => {
      updateTimer();
    },
    isTimer ? intervaltime : null
  );

  return (
    <div className={styles.timer}>
      <span className={styles.time}>
        <span>
          {padLeadingZeros(alertTimer?.days)}
          <span className={styles.timeUnit}>{formatMessage({ id: "global.abbreviation.days" })}</span>
        </span>
      </span>
      <span>:</span>

      <span className={styles.time}>
        <span>
          {padLeadingZeros(alertTimer?.hours)}
          <span className={styles.timeUnit}>{formatMessage({ id: "global.abbreviation.hours" })}</span>
        </span>
      </span>
      <span>:</span>

      <span className={styles.time}>
        <span>
          {padLeadingZeros(alertTimer?.minutes)}
          <span className={styles.timeUnit}>{formatMessage({ id: "global.abbreviation.minutes" })}</span>
        </span>
      </span>
      <span>:</span>

      <span className={styles.time}>
        <span>
          {padLeadingZeros(alertTimer?.seconds)}
          <span className={styles.timeUnit}>{formatMessage({ id: "global.abbreviation.seconds" })}</span>
        </span>
      </span>
    </div>
  );
};

const millisToSeconds = (millis: number) => millis / 1000;
