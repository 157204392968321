/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ContainsIdDTO,
  ContainsIdDTOFromJSON,
  ContainsIdDTOFromJSONTyped,
  ContainsIdDTOToJSON,
  InImageDTO,
  InImageDTOFromJSON,
  InImageDTOFromJSONTyped,
  InImageDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface CreateDealDTO
 */
export interface CreateDealDTO {
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  ean?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  sku?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  slug?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  usp?: string;
  /**
   *
   * @type {boolean}
   * @memberof CreateDealDTO
   */
  live: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  currency?: string;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  stock?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  scoreModifier?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  price?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  beforePrice?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  discount?: number;
  /**
   *
   * @type {number}
   * @memberof CreateDealDTO
   */
  sponsoredPosition?: number;
  /**
   *
   * @type {PlatformDTO}
   * @memberof CreateDealDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {InImageDTO}
   * @memberof CreateDealDTO
   */
  image?: InImageDTO;
  /**
   *
   * @type {ContainsIdDTO}
   * @memberof CreateDealDTO
   */
  merchant?: ContainsIdDTO;
  /**
   *
   * @type {Array<ContainsIdDTO>}
   * @memberof CreateDealDTO
   */
  brands?: Array<ContainsIdDTO>;
  /**
   *
   * @type {Array<ContainsIdDTO>}
   * @memberof CreateDealDTO
   */
  categories?: Array<ContainsIdDTO>;
  /**
   *
   * @type {ContainsIdDTO}
   * @memberof CreateDealDTO
   */
  featuredFilter?: ContainsIdDTO;
  /**
   *
   * @type {Array<ContainsIdDTO>}
   * @memberof CreateDealDTO
   */
  externalDealFilters?: Array<ContainsIdDTO>;
  /**
   *
   * @type {string}
   * @memberof CreateDealDTO
   */
  createdBy: CreateDealDTOCreatedByEnum;
}

export function CreateDealDTOFromJSON(json: any): CreateDealDTO {
  return CreateDealDTOFromJSONTyped(json, false);
}

export function CreateDealDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateDealDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    ean: !exists(json, "ean") ? undefined : json["ean"],
    sku: !exists(json, "sku") ? undefined : json["sku"],
    name: json["name"],
    slug: !exists(json, "slug") ? undefined : json["slug"],
    url: !exists(json, "url") ? undefined : json["url"],
    usp: !exists(json, "usp") ? undefined : json["usp"],
    live: json["live"],
    currency: !exists(json, "currency") ? undefined : json["currency"],
    stock: !exists(json, "stock") ? undefined : json["stock"],
    scoreModifier: !exists(json, "scoreModifier") ? undefined : json["scoreModifier"],
    score: !exists(json, "score") ? undefined : json["score"],
    price: !exists(json, "price") ? undefined : json["price"],
    beforePrice: !exists(json, "beforePrice") ? undefined : json["beforePrice"],
    discount: !exists(json, "discount") ? undefined : json["discount"],
    sponsoredPosition: !exists(json, "sponsoredPosition") ? undefined : json["sponsoredPosition"],
    platform: PlatformDTOFromJSON(json["platform"]),
    image: !exists(json, "image") ? undefined : InImageDTOFromJSON(json["image"]),
    merchant: !exists(json, "merchant") ? undefined : ContainsIdDTOFromJSON(json["merchant"]),
    brands: !exists(json, "brands") ? undefined : (json["brands"] as Array<any>).map(ContainsIdDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(ContainsIdDTOFromJSON),
    featuredFilter: !exists(json, "featuredFilter") ? undefined : ContainsIdDTOFromJSON(json["featuredFilter"]),
    externalDealFilters: !exists(json, "externalDealFilters")
      ? undefined
      : (json["externalDealFilters"] as Array<any>).map(ContainsIdDTOFromJSON),
    createdBy: json["createdBy"]
  };
}

export function CreateDealDTOToJSON(value?: CreateDealDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    ean: value.ean,
    sku: value.sku,
    name: value.name,
    slug: value.slug,
    url: value.url,
    usp: value.usp,
    live: value.live,
    currency: value.currency,
    stock: value.stock,
    scoreModifier: value.scoreModifier,
    score: value.score,
    price: value.price,
    beforePrice: value.beforePrice,
    discount: value.discount,
    sponsoredPosition: value.sponsoredPosition,
    platform: PlatformDTOToJSON(value.platform),
    image: InImageDTOToJSON(value.image),
    merchant: ContainsIdDTOToJSON(value.merchant),
    brands: value.brands === undefined ? undefined : (value.brands as Array<any>).map(ContainsIdDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(ContainsIdDTOToJSON),
    featuredFilter: ContainsIdDTOToJSON(value.featuredFilter),
    externalDealFilters:
      value.externalDealFilters === undefined
        ? undefined
        : (value.externalDealFilters as Array<any>).map(ContainsIdDTOToJSON),
    createdBy: value.createdBy
  };
}

/**
 * @export
 * @enum {string}
 */
export enum CreateDealDTOCreatedByEnum {
  API = "API",
  DASHBOARD = "DASHBOARD",
  UNKNOWN = "UNKNOWN"
}
