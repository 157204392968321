// tslint:disable

export const AnchorId = {
  convert: (anchor?: string) => encodeURI(sanitizeAnchor(anchor)),
  get: (pageUrl?: string) => pageUrl?.split("#")?.[1] || "",
  set: function (anchor: string, pageUrl?: string) {
    return pageUrl?.split("#")?.[0] + "#" + this.convert(anchor);
  },
  compare: function (anchor?: string, pageUrl?: string) {
    return this.convert(anchor) === this.get(pageUrl);
  }
};

const sanitizeAnchor = (anchor?: string): string =>
  (anchor || "")
    // Replace special characters with their meaning
    .replaceAll("&", "en")
    .replaceAll("@", "at")
    // Remove special characters (only allow letters, numbers, spaces and dashes)
    .replaceAll(/[^\d A-Za-z-]/g, "")
    // Replace multiple spaces with one space
    .replaceAll(/\s{2,}/g, " ")
    // Replace spaces with dashes
    .replaceAll(" ", "-")
    // Replace multiple dashes with one dash
    .replaceAll(/-{2,}/g, " ")
    .toLowerCase();
