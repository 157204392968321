import { ProductListResponseDTO, ProductsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

const DEFAULT_TAKE = 8;
const DEFAULT_SKIP = 0;

export interface IGetProductsApiProps {
  take?: number;
  skip?: number;
  platformId: number;
}

export const getProductsApi = async ({
  take,
  skip,
  platformId
}: IGetProductsApiProps): Promise<ProductListResponseDTO | undefined> => {
  try {
    const api = new ProductsApi();
    const results = await api.productsGet(take || DEFAULT_TAKE, skip || DEFAULT_SKIP, platformId);

    if (!results || !results.products) return moduleMapperThrowMessage("Cannot get products");

    return results;
  } catch (e) {
    Logger.logError(e, "Error in: getProductsApi");

    return undefined;
  }
};
