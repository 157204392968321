import { IWhenIsModule } from "@app/api/modules/when-is/when-is.module";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "../../module-selector";
import { IWPWhenIsModuleFull } from "./when-is-module.interface";
import Logger from "@app/util/logger";

export interface IWhenIsModuleMapper extends IModuleFetcher {
  module: IWPWhenIsModuleFull;
}

export const whenIsModuleMapper = async (props: IWhenIsModuleMapper): Promise<IWhenIsModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) {
      return moduleMapperThrowMessage("No data found in whenIsModuleMapper");
    }
    return {
      id: "28",
      name: "WhenIsModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data?.bgColor },
      title: module.data?.title,
      primaryHeaderSubtitle: module.data?.primaryHeaderSubtitle,
      secundaryHeaderSubtitle: module.data?.secundaryHeaderSubtitle,
      sectionTitle: module.data?.sectionTitle,
      sectionSubTitle: module.data?.sectionSubTitle,
      subscribeArrowText: module.data?.subscribeArrowText,
      leftCTAButtonTitle: module.data?.leftCTAButtonTitle,
      leftCTAButtonArrowText: module.data?.leftCTAButtonArrowText,
      rightCTAButtonTitle: module.data?.rightCTAButtonTitle,
      imageDesktop: {
        alt: data.imageDesktop?.alt || "",
        src: data.imageDesktop?.url || "",
        title: data.imageDesktop?.title
      },
      imageMobile: {
        alt: data.imageMobile?.alt || "",
        src: data.imageMobile?.url || "",
        title: data.imageMobile?.title
      }
    };
  } catch (e) {
    Logger.logError(e, "Error in: whenIsModuleMapper");

    return undefined;
  }
};
