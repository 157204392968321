import React from "react";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { MerchantSingleHeaderV2Component } from "../merchant-single-header-v2";

interface IMerchantSingleHeaderWrapper {
  shopSingleHeaderModule: ShopSingleHeaderModule;
}

export const MerchantSingleHeaderWrapper = ({ shopSingleHeaderModule }: IMerchantSingleHeaderWrapper): JSX.Element => (
  <MerchantSingleHeaderV2Component shopSingleHeaderModule={shopSingleHeaderModule} />
);
