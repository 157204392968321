import React, { useEffect, useState } from "react";
import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import { MenuItem } from "../menu-item/menu-item";
import CloseIcon from "@assets/icons/close.svg";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import { Icon } from "@app/components/icon/icon";
import { SubMenuItem } from "../sub-menu-item/sub-menu-item";
import { formatMessage } from "@app/translations/intl";
import { PlatformType } from "@app/constants/platform";
import { useAppSelector } from "@app/redux/store";
import { NewMenuItem } from "../new-menu-item/new-menu-item";
import GreenCheckmarkIcon from "@assets/icons/new-check.svg";
import ArrowIcon from "@assets/icons/menu-arrow.svg";
import { IconComponent, ImageComponent } from "@app/core";
import { IImage } from "@app/api/core/image";

import styles from "../new-mobile-nav-bar-component.module.scss";

interface IProps {
  menuItems?: IMenuItem[];
  closeMenu: () => void;
  logo?: IImage;
}

export const NewMenuItems = (props: IProps) => {
  const { platform } = useAppSelector(state => state.platform);
  const platformId = platform?.id;

  const renderLanguageSwitcher = platformId === PlatformType.BELGIANDUTCH || platformId === PlatformType.BELGIANFRENCH;

  const [selectedMenuItem, setSelectedMenuItem] = useState<IMenuItem | null>(null);

  const showMenuItems = selectedMenuItem === null;
  const [languageSwitcherTitle, setLanguageSwitcherTitle] = useState("");

  const expandMenuItem = (menuItem: IMenuItem) => {
    setSelectedMenuItem(menuItem);
  };

  const closeMenu = () => {
    setSelectedMenuItem(null);
    props.closeMenu();
  };

  const goBack = () => {
    setSelectedMenuItem(null);
  };

  useEffect(() => {
    if (platformId === PlatformType.BELGIANDUTCH) {
      setLanguageSwitcherTitle("Taal: Nederlands (NL)");
    }

    if (platformId === PlatformType.BELGIANFRENCH) {
      setLanguageSwitcherTitle("Langue: Français (FR)");
    }
  }, [platformId]);

  return (
    <>
      <div className={styles.top}>
        {props.logo?.src && (
          <div className={styles.logo}>
            <ImageComponent src={props.logo.src} alt={props.logo.alt} title={props.logo.title} isBlocking />
          </div>
        )}
        <button type="button" className={styles.close} onClick={closeMenu}>
          <Icon icon={CloseIcon} />
        </button>
      </div>

      {formatMessage({ id: "mobileNavMenu.firstCheckMark.title" }) !== "mobileNavMenu.firstCheckMark.title" &&
        formatMessage({ id: "mobileNavMenu.secondCheckMark.title" }) !== "mobileNavMenu.secondCheckMark.title" &&
        !selectedMenuItem && (
          <div className={styles.topBannerContainer}>
            <div className={styles.labelContainer}>
              <div className={styles.checkLabel}>
                <IconComponent icon={GreenCheckmarkIcon} size="8px" />
              </div>
              <span className={styles.checkLabelText}>
                {formatMessage({ id: "mobileNavMenu.firstCheckMark.title" })}
              </span>
              <div className={styles.checkLabel}>
                <IconComponent icon={GreenCheckmarkIcon} size="8px" />
              </div>
              <span className={styles.checkLabelText}>
                {formatMessage({ id: "mobileNavMenu.secondCheckMark.title" })}
              </span>
            </div>
            <div className={styles.arrowIcon}>
              <IconComponent icon={ArrowIcon} size="32px" />
            </div>
          </div>
        )}

      <div className={styles.menuItems}>
        {showMenuItems ? (
          <>
            {props.menuItems?.map((item, idx) => (
              <NewMenuItem
                key={idx}
                menuItem={item}
                index={idx}
                expandMenuItem={expandMenuItem}
                closeMenu={closeMenu}
              />
            ))}
            {renderLanguageSwitcher && (
              <MenuItem
                menuItem={{
                  title: languageSwitcherTitle,
                  children: [
                    {
                      title: formatMessage({ id: "languageSwitcher.children.title" }),
                      children: [
                        {
                          title: formatMessage({ id: "languageSwitcher.BENL" }),
                          url: "/",
                          isExternalLink: true
                        },
                        {
                          title: formatMessage({ id: "languageSwitcher.BEFR" }),
                          url: "/fr/",
                          isExternalLink: true
                        }
                      ]
                    }
                  ]
                }}
                expandMenuItem={expandMenuItem}
                closeMenu={closeMenu}
              />
            )}
          </>
        ) : (
          <>
            <div className={styles.header}>
              <button type="button" className={styles.backButton} onClick={goBack}>
                <Icon icon={ChevronLeftIcon} />
                <span className={styles.backText}>{formatMessage({ id: "navBar.backButton.text" })}</span>
              </button>
              <div className={styles.title}>{selectedMenuItem?.title}</div>
            </div>
            <div className={styles.subMenuItems}>
              {selectedMenuItem?.children?.map((subMenuItem, i) => (
                <SubMenuItem key={i} subMenuItem={subMenuItem} closeMenu={closeMenu} />
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};
