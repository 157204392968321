export type statusColorOptions = "green" | "orange" | "none";

export const getStatusColor = (statusName?: string): statusColorOptions => {
  if (statusName === "ONGOING") {
    return "green";
  }

  if (statusName === "ABOUT_TO_EXPIRE") {
    return "orange";
  }

  return "none";
};
