import { IScreenSize, ViewType } from "@app/redux/reducers/settings";

export const breakPointMobile = 360;
export const breakPointMobileBig = 640;
export const breakPointTablet = 769;
export const breakPointDesktop = 960;
export const breakPointDesktopLarge = 1200;
export const breakPointDesktopFull = 1920;

export const carouselBreakPoints = {
  carouselMobileSmall: 300,
  carouselMobile: 450,
  carouselMobileBig: 570,
  CarouselTabletSmall: 710,
  CarouselTablet: 860,
  carouselDesktopSmall: 1020,
  carouselDesktop: 1200,
  carouselDesktopFull: 1400
};

export const isMobileView = (width: number): boolean => (width < breakPointMobile ? true : false);
export const isMobileBigView = (width: number): boolean => (width < breakPointMobileBig ? true : false);
export const isTabletView = (width: number): boolean => (width < breakPointTablet ? true : false);
export const isDesktopView = (width: number): boolean => (width < breakPointDesktop ? true : false);
export const isDesktopLargeView = (width: number): boolean => (width < breakPointDesktopLarge ? true : false);

export const getViewType = (width: number): IScreenSize => {
  if (width < breakPointMobile) {
    return {
      breakpointPixels: breakPointMobile,
      viewType: ViewType.Mobile
    };
  }
  if (width >= breakPointMobile && width < breakPointMobileBig) {
    return {
      breakpointPixels: breakPointMobileBig,
      viewType: ViewType.MobileBig
    };
  }
  if (width >= breakPointMobileBig && width < breakPointTablet) {
    return {
      breakpointPixels: breakPointTablet,
      viewType: ViewType.Tablet
    };
  }
  if (width >= breakPointTablet && width < breakPointDesktop) {
    return {
      breakpointPixels: breakPointDesktop,
      viewType: ViewType.Desktop
    };
  }
  if (width >= breakPointDesktop && width < breakPointDesktopLarge) {
    return {
      breakpointPixels: breakPointDesktopLarge,
      viewType: ViewType.DesktopLarge
    };
  }
  if (width >= breakPointDesktopLarge) {
    return {
      breakpointPixels: breakPointDesktopFull,
      viewType: ViewType.DesktopFull
    };
  }

  return {
    breakpointPixels: breakPointDesktopFull,
    viewType: ViewType.DesktopFull
  };
};
