import React from "react";
import clsx from "clsx";

import { ProductSingleModule } from "@app/api/modules/product-single/product-single.module";
import { BreadcrumbComponent } from "@app/core/breadcrumb";
import { ImageComponent } from "@app/core";
import {
  DealCardLandscape,
  getAffiliateDealLinks
} from "@app/components/deal-cards/deal-card-landscape/deal-card-landscape";
import { RelAttributes } from "@app/constants";

import styles from "./product-single-component.module.scss";

export interface IProductSingleProps {
  module: ProductSingleModule;
}

export const ProductSingle = (props: IProductSingleProps) => {
  const { product, breadcrumbProps, compareTitle, noResultText } = props.module;

  return (
    <div className="uk-container">
      <div className={clsx(styles.header)}>
        {breadcrumbProps && (
          <BreadcrumbComponent
            variant={breadcrumbProps.variant}
            breadcrumbs={breadcrumbProps.breadcrumbs}
            backButton={breadcrumbProps.backButton}
          />
        )}
        {product.title && <h1>{product.title}</h1>}
      </div>
      <div className={styles.details}>
        <CardImage {...props} />
        <div className={styles.compare}>
          {compareTitle && <h2>{compareTitle}</h2>}
          <div className={styles.list}>
            {product.deals && product.deals?.length > 0 ? (
              product.deals?.map(deal => <DealCardLandscape key={deal.id} platformId={product.platform.id} {...deal} />)
            ) : (
              <p>{noResultText}</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const CardImage = (props: IProductSingleProps) => {
  const { product } = props.module;
  const { outDealLink } = getAffiliateDealLinks(product.deals?.[0]?.id, product.deals?.[0]?.merchant?.slug);

  const img = (
    <ImageComponent
      forceImageTag
      alt={product.image?.alt || ""}
      src={product.image?.url || ""}
      title={product.image?.title || ""}
      isBlocking
    />
  );

  if (!product.image?.url) {
    return <></>;
  }

  if (outDealLink) {
    return (
      <a className={styles.image} rel={RelAttributes.SPONSORED} target="_blank" href={outDealLink}>
        {img}
      </a>
    );
  }

  return <div className={styles.image}>{img}</div>;
};
