import * as React from "react";
import clsx from "clsx";
import styles from "./bodytext-component.module.scss";
import { BodyTextModule } from "@app/api/modules/body-text/body-text";
import { HtmlRenderComponent } from "../html-render";

export interface IBodyTextComponentProps {
  bodyTextModule: BodyTextModule;
}

export const BodyTextComponent = (props: IBodyTextComponentProps) => {
  const { containerAlignment = "left", containerSize = "full" } = props.bodyTextModule;

  const alignerClassNames = clsx(styles["body-text"], styles[`body-text--${containerAlignment}`]);
  const bodyTextSizeClassNames = clsx(styles["aligner"], styles[containerSize]);

  const backgroundColorStyle = {
    ...(props.bodyTextModule.background?.backgroundColour && {
      backgroundColor: props.bodyTextModule.background.backgroundColour
    })
  };

  return (
    <div id="bodytext" style={backgroundColorStyle} className="uk-container">
      <div className={alignerClassNames}>
        <div className={bodyTextSizeClassNames}>
          <HtmlRenderComponent html={props.bodyTextModule.html} />
        </div>
      </div>
    </div>
  );
};
