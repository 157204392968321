import Logger from "@app/util/logger";

import { IWPAuthorComponent } from "./author-component.interface";
import { postsWordPressApiGet } from "@app/api/wp-page-fetcher/wordpress-api/base-wp-api";
import { WP_MODULES } from "../../module-names";
import { IWPBlogBodyTextModule } from "../../module-mappers";
import { IAuthorComponentProps } from "@app/core/author/author.component";

export interface IAuthorComponentMapper {
  platformId: number;
  authorData?: IWPAuthorComponent;
}

export const authorBlockComponentMapper = async (
  props: IAuthorComponentMapper
): Promise<IAuthorComponentProps | undefined> => {
  try {
    const { platformId, authorData } = props;

    if (!authorData || !authorData?.post_type || !authorData?.post_name) {
      return undefined;
    }

    const authorPermalink = `/${[authorData.post_type, authorData.post_name].join("/")}/`;

    const authorResult = await postsWordPressApiGet(platformId, authorPermalink);

    if (!authorResult || authorResult === 404 || authorResult === 503 || authorResult.length === 0) {
      return undefined;
    }

    const firstBodyText: { bodyText?: IWPBlogBodyTextModule } = authorResult[0].data?.modules?.find(
      module => module.acf_fc_layout === WP_MODULES.BODY_TEXT.wpName
    );

    return {
      name: authorData.post_title || "",
      description: firstBodyText.bodyText?.bodyText || "",
      image: authorResult[0].meta?.featuredImage || "",
      url: authorPermalink
    };
  } catch (e) {
    Logger.logError(e, "Error in: authorComponentMapper");

    return undefined;
  }
};
