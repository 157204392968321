import { IWPHeaderInfoModuleFull } from "./header-info-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { HeaderInfoModule } from "@app/api/modules/header-info/header-info.module";
import { breadcrumbComponentMapper } from "../../component-mappers/breadcrumb-component/breadcrumb-component-mapper";
import { getPlatformInformation } from "@app/util/api/get-platform-info";

export interface IHeaderInfoModuleMapper extends IModuleFetcher {
  module: IWPHeaderInfoModuleFull;
}

export const headerInfoModuleMapper = async (props: IHeaderInfoModuleMapper): Promise<HeaderInfoModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in headerInfoModuleMapper");

    const breadcrumbProps = await breadcrumbComponentMapper({
      platformId: props.platformId,
      pageUrl: props.pageUrl,
      variant: "dark",
      title: props.pageData.meta?.breadcrumbTitle
    });

    const platform = await getPlatformInformation(props.platformId);

    return {
      id: "13",
      name: "HeaderInfo",
      title: data.title,
      content: data?.undertitle,
      image: {
        alt: data?.headerImage?.alt || "",
        src: data?.headerImage?.url || "",
        title: data?.headerImage?.title || ""
      },
      icon: {
        alt: data?.icon?.alt || "",
        src: data?.icon?.url || "",
        title: data?.icon?.title || ""
      },
      background: {
        backgroundColour: data?.bgColor
      },
      breadcrumbProps,
      platform,
      ...getWPModuleSpacing(module.data, module.setMargins)
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerInfoMapper");

    return undefined;
  }
};
