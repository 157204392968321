import React from "react";
import { useAppSelector } from "@app/redux/store";
import { IntlProvider } from "react-intl";
import { IntlSetter } from "@app/components/intl-setter/intl-setter.component";
interface IProps {
  children: React.ReactNode;
}

export const IntlProviderWrapper = (props: IProps) => {
  const { locale, messages } = useAppSelector(state => state.locales);

  return (
    <IntlProvider locale={locale} messages={messages}>
      <IntlSetter locale={locale} translations={messages} />
      {props.children}
    </IntlProvider>
  );
};
