import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { IconComponent } from "@app/core";
import IconChevronRight from "@assets/icons/chevron-right.svg";
import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import IconArrowDown from "@assets/icons/arrow-down.svg";
import IconRoundCheckmark from "@assets/icons/round-checkmark.svg";
import { LinkComponent } from "@app/core/link";
import { ClickableComponent } from "@app/core/clickable";
import { CtaHeaderModule } from "@app/api/modules/cta-header/cta-header.module";
import { settingsState } from "@app/redux/reducers/settings";
import { merchantsState } from "@app/redux/reducers/merchants";
import { platformState } from "@app/redux/reducers/platforms";
import { getTotalAmountOfMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { PlatformType } from "@app/constants/platform";
import Typography from "@app/components/typography";

import styles from "./cta-header-component.module.scss";

export interface ICtaHeaderProps {
  module: CtaHeaderModule;
}

const CtaHeader = (props: ICtaHeaderProps) => {
  const dispatch = useDispatch();
  const settings = useSelector(settingsState);
  const { platformId } = settings;
  const merchants = useSelector(merchantsState);
  const { totalAmountOfMerchants } = merchants;
  const platform = useSelector(platformState);
  const customCtaButton = platform.platform?.id === PlatformType.UNITED_KINGDOM;

  useEffect(() => {
    dispatch(getTotalAmountOfMerchants(platformId));
  }, []);

  return (
    <div
      style={{
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover"
      }}
      className={styles.homepageHeader}
    >
      <CtaImageStyle {...props} />
      <div className={styles.cta}>
        <div className={styles.arrow}>
          <IconComponent icon={IconArrowDown} size="22px" />
          <span className={styles.arrowText}>{props.module.payOff}</span>
        </div>
        <Typography className={styles.ctaTitle} tag="h1" align="center">
          {props.module.title}
        </Typography>
        <div className={styles.ctaButton}>
          <ClickableComponent
            title={props.module.ctaMainContent}
            // For now this is the only exception. When another button needs to be customized I'll edit
            // the Wordpress module to accept custom colors.
            variant={customCtaButton ? "primary-new-colored-uk-custom" : "primary-new-colored"}
            iconStyle="filled"
            iconRight={IcnChevronRight}
            iconFillColor="white"
            iconSize="14px"
            href={props.module.ctaMainUrl}
          />
          <span className={styles.merchantCount}>{totalAmountOfMerchants}</span>
        </div>
        <div className={customCtaButton ? styles.ctaSubtitleCustom : styles.ctaSubtitle}>
          <LinkComponent to={props.module.ctaSecondaryUrl} icon={IconChevronRight} animated variant="primary">
            {props.module.ctaSecondaryContent}
          </LinkComponent>
        </div>
      </div>
      <div className={styles.usps}>
        {props.module.usps.map((usp, index) => (
          <div className={styles.usp} key={index}>
            <span className={styles.uspIcon}>
              <IconComponent icon={IconRoundCheckmark} size="24px" />
            </span>
            {usp.usp}
          </div>
        ))}
      </div>
    </div>
  );
};

export { CtaHeader };

const CtaImageStyle = (props: ICtaHeaderProps) => {
  return (
    <style>
      {`
        .${styles.homepageHeader} {
          background-image: url(${props.module.imageDesktop?.src});

          @media (max-width: 959px) {
            background-image: url(${props.module.imageMobile?.src});
          }
        }
      `}
    </style>
  );
};
