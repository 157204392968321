import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { getMenuItems } from "@app/api/wp-page-fetcher/utils/get-wp-menu-items";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { findPlatformContent } from "@app/api/wp-page-fetcher/utils/platform-content-array";
import { formatMessage } from "@app/translations/intl";
import Logger from "@app/util/logger";
import { getPlatformInformation } from "@app/util/api/get-platform-info";

import { getFeaturedMerchantsMapperWithDto } from "../featured-merchants-module";
import { getFeaturedDealsMapperWithDto } from "../featured-deals-module";

export const navBarModuleMapper = async (platformId: number): Promise<INavBarModule | undefined> => {
  try {
    const [popularDeals, popularMerchants, allMappedMenus, platformInformation] = await Promise.all([
      getFeaturedDealsMapperWithDto({ platformId, limit: 3 }),
      getFeaturedMerchantsMapperWithDto({ platformId, limit: 3 }),
      getMenuItems(platformId),
      getPlatformInformation(platformId)
    ]);

    if (!allMappedMenus) return moduleMapperThrowMessage("No menu items found in navBarModuleMapper");

    return {
      id: "11",
      name: "NavBarModule",
      background: { backgroundColour: "#f7f7f7" },
      logo: findPlatformContent(platformId).logo || emptyLogo,
      navBarTitle: platformInformation?.navBarTitle,
      navBarSubTitle: platformInformation?.navBarSubTitle,
      navBarLink: platformInformation?.navBarLink || formatMessage({ id: "global.homepageLink" }),
      popularDeals,
      popularMerchants,
      menuItems: allMappedMenus?.header?.topMenu?.map(item => ({
        title: item.title,
        url: item.url,
        children: item.children
      }))
    };
  } catch (e) {
    Logger.logError(e, "Error in: newNavBarModuleMapper");
    return undefined;
  }
};

const emptyLogo = {
  src: "",
  alt: "",
  title: ""
};
