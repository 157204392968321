/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutDealDTO, OutDealDTOFromJSON, OutDealDTOFromJSONTyped, OutDealDTOToJSON } from "./";

/**
 *
 * @export
 * @interface DealsSearchDto
 */
export interface DealsSearchDto {
  /**
   *
   * @type {Array<OutDealDTO>}
   * @memberof DealsSearchDto
   */
  data?: Array<OutDealDTO>;
  /**
   *
   * @type {number}
   * @memberof DealsSearchDto
   */
  count: number;
}

export function DealsSearchDtoFromJSON(json: any): DealsSearchDto {
  return DealsSearchDtoFromJSONTyped(json, false);
}

export function DealsSearchDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealsSearchDto {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : (json["data"] as Array<any>).map(OutDealDTOFromJSON),
    count: json["count"]
  };
}

export function DealsSearchDtoToJSON(value?: DealsSearchDto | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data === undefined ? undefined : (value.data as Array<any>).map(OutDealDTOToJSON),
    count: value.count
  };
}
