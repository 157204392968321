import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";
import clsx from "clsx";

import { ResourceTextComponent, IconComponent } from "@app/core";
import { setBodyFixed } from "@app/util/set-body-fixed";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { CheckboxComponent } from "@app/core/checkbox";
import { ClickableComponent } from "@app/core/clickable";
import { SearchInputFieldComponent } from "@app/core/search-input-field";
import ChevronDown from "@assets/icons/chevron-down.svg";
import Cross from "@assets/icons/cross.svg";
import { formatMessage } from "@app/translations/intl";
import { usePrevious } from "@app/util";

import styles from "./mobile-filter-component.module.scss";

export interface IMobileFilterItem {
  filterItems: FilterItem[];
  isResourceKey?: boolean;
  isSingleSelection?: boolean;
  name: string;
  searchPlaceholder: string;
}

export interface IMobileFilterProps {
  filters: FilterItem[];
  filterType: "merchants" | "brands" | "categories";
  onMobileFilterOpen: (isOpen: boolean) => void;
  onMobileFiltersChanged: (filters: FilterItem[]) => void;
  onMobileFilterSearch: (value: string) => void;
  totalAmountOfResults?: number;
  typeOfFilters: "merchants" | "deals";
}

export const SingleMobileFilter = (props: IMobileFilterProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const prevIsOpen = usePrevious(isOpen);
  const [numberOfSelectedItems, setNumberOfSelectedItems] = useState<number>(0);
  const [searchValue, setSearchValue] = useState<string>("");

  const typeOfFilters = props.typeOfFilters ? props.typeOfFilters : "merchants";

  let savedScrollPosition = 0;
  useEffect(() => {
    // Mobile filters opened or closed.
    savedScrollPosition = window.scrollY;

    if (props.onMobileFilterOpen) {
      if (prevIsOpen !== undefined) {
        props.onMobileFilterOpen(isOpen);
        if (isOpen) {
          props.onMobileFiltersChanged(props.filters);
        }
      }
    }

    if (isOpen) {
      setBodyFixed(true);
    } else {
      setBodyFixed(false);
    }

    if (savedScrollPosition) {
      window.scrollTo(0, savedScrollPosition);
    }
  }, [isOpen]);

  useEffect(() => {
    // Internal filter items changed, check if anything has been selected.
    const selectedFilterItems = props.filters.filter(filterItem => filterItem.isSelected);

    setNumberOfSelectedItems(selectedFilterItems.length);
  }, [props.filters]);

  useEffect(() => {
    // Search value changed by user input, get new filters.
    props.onMobileFilterSearch(searchValue);
  }, [searchValue]);

  const onClear = () => {
    const newMobileFilters = props.filters.map(filterItem => {
      filterItem.isSelected = false;
      return filterItem;
    });

    props.onMobileFiltersChanged(newMobileFilters);
  };

  const onFilterItemSelected = (newSelectedFilterItem: FilterItem, isSingleSelection?: boolean) => {
    const newFilterItems = props.filters.map(filter => {
      const newFilter = filter;
      if (filter.id === newSelectedFilterItem.id) {
        newFilter.isSelected = !newFilter.isSelected;
      } else {
        if (isSingleSelection) {
          newFilter.isSelected = false;
        }
      }

      return newFilter;
    });
    props.onMobileFiltersChanged(newFilterItems);
  };

  if (isOpen) {
    return createPortal(
      <div className={styles.mobileFilter}>
        <div className={styles.header}>
          <span
            role="button"
            className={styles.navButton}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <IconComponent strokeColor="#fff" icon={Cross} size={"12px"} />

            <span className={styles.middleTitle}>
              <ResourceTextComponent resourceKey={"mobileFilter.barOpenTitle"} />
            </span>
          </span>
          <span>
            {!!numberOfSelectedItems && (
              <a
                role="button"
                onClick={() => {
                  onClear();
                }}
                className={styles.clear}
              >
                <ResourceTextComponent resourceKey={"mobileFilter.clear"} />
                {!!numberOfSelectedItems && (
                  <span className={styles.numberOfSelectedItems}>{numberOfSelectedItems}</span>
                )}
              </a>
            )}
          </span>
        </div>
        <div className={styles.list}>
          {renderFilterSelection(props.filterType, props.filters, onFilterItemSelected, searchValue, setSearchValue)}
        </div>
        <div className={styles.footer}>
          <ClickableComponent
            variant="primary-brand"
            title={
              !!numberOfSelectedItems
                ? typeOfFilters === "merchants"
                  ? formatMessage({ id: "mobileFilter.storeButton" }, { amountOfStores: props.totalAmountOfResults })
                  : formatMessage({ id: "mobileFilter.dealButton" }, { amountOfDeals: props.totalAmountOfResults })
                : typeOfFilters === "merchants"
                ? formatMessage({ id: "mobileFilter.storeButtonAll" })
                : formatMessage({ id: "mobileFilter.dealButtonAll" })
            }
            onClick={() => {
              setIsOpen(false);
            }}
          />
        </div>
      </div>,
      document.body
    );
  }

  // Render Filter button. (filter selection closed)
  return (
    <div
      role="button"
      onClick={() => {
        setIsOpen(true);
      }}
      className={clsx(styles.filterBar, numberOfSelectedItems > 0 && styles.selected)}
    >
      <span className={styles.filterBarTitle}>
        {<ResourceTextComponent resourceKey={getResourceKey(props.filterType)} />}
      </span>
      {!!numberOfSelectedItems && (
        <span className={clsx(styles.numberOfSelectedItems, styles.rightMargin)}>{numberOfSelectedItems}</span>
      )}

      <span className={styles.chevron}>
        <IconComponent icon={ChevronDown} size={"12px"} />
      </span>
    </div>
  );
};

const renderFilterSelection = (
  selectedFilterItem: "merchants" | "brands" | "categories",
  filterItems: FilterItem[],
  onFilterItemSelected: (filterItem: FilterItem) => void,
  searchValue: string,
  setSearchValue: (value: string) => void
) => (
  <React.Fragment>
    <div className={styles.searchInput}>
      <SearchInputFieldComponent
        value={searchValue}
        onChange={setSearchValue}
        placeholder={selectedFilterItem}
        autoFocus={true}
      />
    </div>
    {filterItems.length > 0 ? (
      filterItems.map((filterItem, index) => (
        <div
          role="button"
          className={styles.subFilterItem}
          key={index}
          onClick={(event: any) => {
            // Neccesary to make sure the click event isn't used multiple times.
            if (event.target.id === "checkbox") {
              return;
            }

            onFilterItemSelected(filterItem);
            event.preventDefault();
          }}
        >
          <span>
            <CheckboxComponent
              isChecked={filterItem.isSelected}
              id="checkbox"
              onClick={(e: any) => {
                e.stopPropagation();
                onFilterItemSelected(filterItem);
              }}
            >
              <span id="checkboxSpan" role="button" className={styles.checkbox}>
                {filterItem.displayName}
              </span>
            </CheckboxComponent>
          </span>
        </div>
      ))
    ) : (
      <span className={styles.noResults}>
        <ResourceTextComponent resourceKey={"searchableCheckboxDropdown.noResults"} />
      </span>
    )}
  </React.Fragment>
);

const getResourceKey = (filterType: "merchants" | "brands" | "categories") => {
  switch (filterType) {
    case "merchants":
      return "filterBarDeals.checkboxDropdown.merchantFilterName";
    case "brands":
      return "filterBarDeals.checkboxDropdown.brandFilterName";
    case "categories":
      return "filterBarDeals.checkboxDropdown.categoryFilterName";

    default:
      return "";
  }
};
