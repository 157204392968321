/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CampaignDurationStatusDTO,
  CampaignDurationStatusDTOFromJSON,
  CampaignDurationStatusDTOFromJSONTyped,
  CampaignDurationStatusDTOToJSON,
  GenericIdDTO,
  GenericIdDTOFromJSON,
  GenericIdDTOFromJSONTyped,
  GenericIdDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface InCampaignDTO
 */
export interface InCampaignDTO {
  /**
   *
   * @type {number}
   * @memberof InCampaignDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof InCampaignDTO
   */
  isFeatured: boolean;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  excerpt?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  subtitle?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  couponCode?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  startDateStatusShow?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  startDatePublish?: string;
  /**
   *
   * @type {CampaignDurationStatusDTO}
   * @memberof InCampaignDTO
   */
  durationStatus?: CampaignDurationStatusDTO;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof InCampaignDTO
   */
  trackingUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof InCampaignDTO
   */
  usps?: Array<string>;
  /**
   *
   * @type {number}
   * @memberof InCampaignDTO
   */
  merchantId: number;
  /**
   *
   * @type {GenericIdDTO}
   * @memberof InCampaignDTO
   */
  featuredFilter?: GenericIdDTO;
  /**
   *
   * @type {boolean}
   * @memberof InCampaignDTO
   */
  isFeaturedFilterActive?: boolean;
}

export function InCampaignDTOFromJSON(json: any): InCampaignDTO {
  return InCampaignDTOFromJSONTyped(json, false);
}

export function InCampaignDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InCampaignDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    isFeatured: json["isFeatured"],
    excerpt: !exists(json, "excerpt") ? undefined : json["excerpt"],
    subtitle: !exists(json, "subtitle") ? undefined : json["subtitle"],
    description: !exists(json, "description") ? undefined : json["description"],
    couponCode: !exists(json, "couponCode") ? undefined : json["couponCode"],
    startDate: !exists(json, "startDate") ? undefined : json["startDate"],
    endDate: !exists(json, "endDate") ? undefined : json["endDate"],
    startDateStatusShow: !exists(json, "startDateStatusShow") ? undefined : json["startDateStatusShow"],
    startDatePublish: !exists(json, "startDatePublish") ? undefined : json["startDatePublish"],
    durationStatus: !exists(json, "durationStatus")
      ? undefined
      : CampaignDurationStatusDTOFromJSON(json["durationStatus"]),
    url: !exists(json, "url") ? undefined : json["url"],
    trackingUrl: !exists(json, "trackingUrl") ? undefined : json["trackingUrl"],
    usps: !exists(json, "usps") ? undefined : json["usps"],
    merchantId: json["merchantId"],
    featuredFilter: !exists(json, "featuredFilter") ? undefined : GenericIdDTOFromJSON(json["featuredFilter"]),
    isFeaturedFilterActive: !exists(json, "isFeaturedFilterActive") ? undefined : json["isFeaturedFilterActive"]
  };
}

export function InCampaignDTOToJSON(value?: InCampaignDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    isFeatured: value.isFeatured,
    excerpt: value.excerpt,
    subtitle: value.subtitle,
    description: value.description,
    couponCode: value.couponCode,
    startDate: value.startDate,
    endDate: value.endDate,
    startDateStatusShow: value.startDateStatusShow,
    startDatePublish: value.startDatePublish,
    durationStatus: CampaignDurationStatusDTOToJSON(value.durationStatus),
    url: value.url,
    trackingUrl: value.trackingUrl,
    usps: value.usps,
    merchantId: value.merchantId,
    featuredFilter: GenericIdDTOToJSON(value.featuredFilter),
    isFeaturedFilterActive: value.isFeaturedFilterActive
  };
}
