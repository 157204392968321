import { OutProductDTO } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

import { getProductsApi, IGetProductsApiProps } from "./products-get.api";

export const getFeaturedProductsApi = async (props: IGetProductsApiProps): Promise<OutProductDTO[] | undefined> => {
  try {
    const resultFromApi = await getProductsApi(props);

    if (!resultFromApi || !resultFromApi.products) return moduleMapperThrowMessage("Cannot get products");

    return resultFromApi.products;
  } catch (e) {
    Logger.logError(e, "Error in: getFeaturedProductsApi");

    return undefined;
  }
};
