import React, { useEffect } from "react";
import styles from "../new-mobile-nav-bar-component.module.scss";
import { useSelector } from "react-redux";
import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import { Icon } from "@app/components/icon/icon";
import IconChevronRight from "@assets/icons/chevron-right.svg";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { getDealCount, getMerchantCount } from "@app/util";
import { settingsState } from "@app/redux/reducers/settings";
import { formatMessage } from "@app/translations/intl";

interface IProps {
  menuItem: IMenuItem;
  expandMenuItem: (menuItem: IMenuItem) => void;
  closeMenu: () => void;
  index: number;
}

export const component = (props: IProps & RouteComponentProps) => {
  const settings = useSelector(settingsState);
  const { platformId } = settings;
  const hasChildren = props.menuItem.children && props.menuItem.children.length > 0;

  const [amountOfStores, setAmountOfStores] = React.useState<number | undefined>(undefined);
  const [amountOfDeals, setAmountOfDeals] = React.useState<number | undefined>(undefined);

  useEffect(() => {
    getAmountOfMerchants();
    getAmountOfDeals();
  }, []);

  const expandMenuItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (hasChildren) {
      props.expandMenuItem(props.menuItem);

      return;
    }

    props.history.push(props.menuItem.url || "#");
    props.closeMenu();
  };

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.closeMenu();
  };

  const getAmountOfMerchants = async () => {
    const total = await getMerchantCount(platformId);
    setAmountOfStores(total);
  };

  const getAmountOfDeals = async () => {
    const total = await getDealCount(platformId);
    setAmountOfDeals(total);
  };

  return (
    <>
      {props.index === 0 ? (
        <button className={styles.menuItem} onClick={expandMenuItem}>
          <Link onClick={onClick} className={styles.title} to={props.menuItem.url || ""}>
            <div className={styles.titleContainer}>
              {props.menuItem.title}
              <span className={styles.amountLabel}>{amountOfStores}</span>
            </div>
          </Link>
          <Link onClick={onClick} className={styles.title} to={props.menuItem.url || ""}>
            <div className={styles.merchantItemContainer}>
              <span className={styles.discountText}>{formatMessage({ id: "mobileNavMenu.discount.title" })}</span>
              <span className={styles.merchantChevron}>
                <Icon icon={IconChevronRight} />
              </span>
            </div>
          </Link>
        </button>
      ) : props.index === 1 ? (
        <button className={styles.menuItem} onClick={expandMenuItem}>
          <Link onClick={onClick} className={styles.title} to={props.menuItem.url || ""}>
            {props.menuItem.title}
            <span className={styles.amountLabel}>{amountOfDeals}</span>
          </Link>
          <span className={styles.chevron}>
            <Icon icon={IconChevronRight} />
          </span>
        </button>
      ) : (
        <button className={styles.menuItem} onClick={expandMenuItem}>
          <Link onClick={onClick} className={styles.title} to={props.menuItem.url || ""}>
            {props.menuItem.title}
          </Link>
          <span className={styles.chevron}>
            <Icon icon={IconChevronRight} />
          </span>
        </button>
      )}
    </>
  );
};

const NewMenuItem = withRouter(component);
export { NewMenuItem };
