import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { NotYetImplementedModule } from "@app/api/modules/not-implemented/not-yet-implemented-module";
import Logger from "@app/util/logger";

export const notYetImplementedModuleMapper = (moduleName: string): NotYetImplementedModule | undefined => {
  try {
    return {
      id: "1",
      name: "notYetImplemented",
      ...getWPModuleSpacing(),
      background: { backgroundColour: "white" },
      moduleName
    };
  } catch (e) {
    Logger.logError(e, "Error in: notYetImplemented");

    return undefined;
  }
};
