import { PlatformDTO } from "@app/bf-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IPlatformState {
  platform?: PlatformDTO;
}

const initialState: IPlatformState = {
  platform: undefined
};

export const slice = createSlice({
  name: "platforms",
  initialState,
  reducers: {
    setPlatformInfo: (state, action: PayloadAction<PlatformDTO>) => {
      state.platform = action.payload;
    }
  }
});

export const platformState = (state: RootState) => state.platform;

export const { setPlatformInfo } = slice.actions;

export default slice.reducer;
