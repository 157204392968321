import { MessageDescriptor, IntlShape } from "react-intl";
import { PrimitiveType } from "intl-messageformat";
import { DirtySingleton } from "./dirty-singleton";
import IntlFormatMessage from "./intl-singleton";

export const formatMessage = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>): string => {
  if (typeof document === "undefined") {
    const getNameSpaceFn = DirtySingleton.getInstance().getNameSpace;
    const session = getNameSpaceFn("my request");
    if (!session) return "";
    const intlShape: IntlShape | undefined = session?.get("intl");

    return getIntlMessage(intlShape, descriptor, values);
  }
  const intl = IntlFormatMessage.getInstance().INTL_OBJECT;

  return getIntlMessage(intl, descriptor, values);
};

const getIntlMessage = (
  intl: IntlShape | undefined,
  descriptor: MessageDescriptor,
  values?: Record<string, PrimitiveType>
) => {
  if (!intl || !descriptor.id) return "";

  const message = intl.formatMessage(descriptor, values);
  const keyIsAvailable = (descriptor.id && intl.messages[descriptor.id]) !== undefined;

  if ((keyIsAvailable && message === descriptor.id) || message === " ") {
    return "";
  }

  return message;
};
