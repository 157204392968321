/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InImageDTO,
  InImageDTOFromJSON,
  InImageDTOFromJSONTyped,
  InImageDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  SeoDTO,
  SeoDTOFromJSON,
  SeoDTOFromJSONTyped,
  SeoDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface CreateProductDTO
 */
export interface CreateProductDTO {
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  ean?: string;
  /**
   *
   * @type {PlatformDTO}
   * @memberof CreateProductDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {boolean}
   * @memberof CreateProductDTO
   */
  live: boolean;
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  slug: string;
  /**
   *
   * @type {InImageDTO}
   * @memberof CreateProductDTO
   */
  image?: InImageDTO;
  /**
   *
   * @type {SeoDTO}
   * @memberof CreateProductDTO
   */
  seo?: SeoDTO;
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  specification?: string;
  /**
   *
   * @type {string}
   * @memberof CreateProductDTO
   */
  createdAt?: string;
}

export function CreateProductDTOFromJSON(json: any): CreateProductDTO {
  return CreateProductDTOFromJSONTyped(json, false);
}

export function CreateProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateProductDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: json["title"],
    ean: !exists(json, "ean") ? undefined : json["ean"],
    platform: PlatformDTOFromJSON(json["platform"]),
    live: json["live"],
    slug: json["slug"],
    image: !exists(json, "image") ? undefined : InImageDTOFromJSON(json["image"]),
    seo: !exists(json, "seo") ? undefined : SeoDTOFromJSON(json["seo"]),
    description: !exists(json, "description") ? undefined : json["description"],
    specification: !exists(json, "specification") ? undefined : json["specification"],
    createdAt: !exists(json, "createdAt") ? undefined : json["createdAt"]
  };
}

export function CreateProductDTOToJSON(value?: CreateProductDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    ean: value.ean,
    platform: PlatformDTOToJSON(value.platform),
    live: value.live,
    slug: value.slug,
    image: InImageDTOToJSON(value.image),
    seo: SeoDTOToJSON(value.seo),
    description: value.description,
    specification: value.specification,
    createdAt: value.createdAt
  };
}
