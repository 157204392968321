import * as React from "react";

import styles from "./share-social-component.module.scss";

import { IconComponent } from "@app/core/icon";
import FacebookSVG from "@assets/icons/facebook.svg";
import TwitterSVG from "@assets/icons/twitter.svg";
import MailSVG from "@assets/icons/mail.svg";
import WhatsApp from "@assets/icons/whatsapp.svg";

import { FacebookShareButton, TwitterShareButton, EmailShareButton, WhatsappShareButton } from "react-share";
import { useSelector } from "react-redux";
import { pagesState } from "@app/redux/reducers/page";

const getShareControl = (icon: string, socialType: string, nameSocial?: string) => (
  <div className={styles["link"]}>
    <div className={`${styles["icon"]} ${styles[socialType]}`}>
      <IconComponent icon={icon} size="15px" />
    </div>
    {nameSocial && (
      <div className={styles["text"]}>
        <div className={styles["title"]}>{nameSocial}</div>
      </div>
    )}
  </div>
);

const FacebookShareButtonComponent = (props: {
  hashtag: string;
  shareButtonTitle?: string;
  title: string;
  url: string;
}) => (
  <FacebookShareButton quote={props.title} url={props.url}>
    {getShareControl(FacebookSVG, "facebook", props.shareButtonTitle)}
  </FacebookShareButton>
);

const TwitterShareButtonComponent = (props: { shareButtonTitle?: string; title: string; url: string }) => (
  <TwitterShareButton windowWidth={1024} title={props.title} url={props.url}>
    {getShareControl(TwitterSVG, "twitter", props.shareButtonTitle)}
  </TwitterShareButton>
);

const MailShareButtonComponent = (props: { body: string; shareButtonTitle?: string; subject: string; url: string }) => (
  // openWindow not yet supported in typings, added manually for now. add to typings
  // tslint:disable-next-line
  //@ts-ignore
  <EmailShareButton subject={props.subject} body={props.body} url={props.url} openWindow={true}>
    {getShareControl(MailSVG, "mail", props.shareButtonTitle)}
  </EmailShareButton>
);

const WhatsappShareButtonComponent = (props: { shareButtonTitle?: string; title: string; url: string }) => (
  <WhatsappShareButton windowHeight={700} windowWidth={1024} title={props.title} url={props.url}>
    {getShareControl(WhatsApp, "whatsapp", props.shareButtonTitle)}
  </WhatsappShareButton>
);

export interface ISocialComponentProps {
  withTitles?: boolean;
}

export const ShareSocialComponent = (props: ISocialComponentProps) => {
  const pages = useSelector(pagesState);
  const { currentPage } = pages;

  const location = typeof window !== "undefined" ? window.location.href : "";
  const isWithTitles = typeof props.withTitles === "undefined" ? true : props.withTitles ? props.withTitles : false;

  return (
    <div className={`${styles["share"]} ${!isWithTitles && styles["badges"]}`}>
      <FacebookShareButtonComponent
        title={currentPage ? currentPage.metaData.title : ""}
        hashtag={currentPage ? `#${currentPage.metaData.title}` : ""}
        url={location}
        shareButtonTitle={isWithTitles ? "Facebook" : ""}
      />

      <TwitterShareButtonComponent
        title={currentPage ? currentPage.metaData.title : ""}
        url={location}
        shareButtonTitle={isWithTitles ? "Twitter" : ""}
      />

      <MailShareButtonComponent
        body={currentPage ? currentPage.metaData.description : ""}
        subject={currentPage ? currentPage.metaData.title : ""}
        url={location}
        shareButtonTitle={isWithTitles ? "Email" : ""}
      />

      <WhatsappShareButtonComponent
        title={currentPage ? currentPage.metaData.title : ""}
        url={location}
        shareButtonTitle={isWithTitles ? "WhatsApp" : ""}
      />
    </div>
  );
};
