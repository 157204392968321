import { IImage } from "@app/api/core/image";
import { pagesWordPressApiGet } from "@app/api/wp-page-fetcher/wordpress-api/base-wp-api";

const DEFAULT_SKIP = 0;
const DEFAULT_LIMIT = 8;

interface IGetFeaturedPagesMapperProps {
  platformId: number;
  searchValue?: string;
  limit?: number;
  skip?: number;
}

export interface IFeaturedWPPage {
  id: number;
  icon: IImage;
  permalink: string;
  title: string;
}

export interface IFeaturedWPPageCollection {
  data: IFeaturedWPPage[];
  lastPage: number;
  page: number;
  total: number;
}

export const getFeaturedPagesMapper = async ({
  platformId,
  searchValue,
  limit = DEFAULT_LIMIT,
  skip = DEFAULT_SKIP
}: IGetFeaturedPagesMapperProps): Promise<IFeaturedWPPageCollection | (404 | 503)> => {
  const result = await pagesWordPressApiGet(platformId, searchValue, limit, skip);

  if (result === 404 || result === 503) {
    return result;
  }

  const featuredPages: IFeaturedWPPage[] = result.data.map(item => ({
    id: item.data?.post?.ID || 0,
    icon: {
      alt: item?.data?.modules?.[0]?.informativeHeader?.icon?.title || "",
      src: item?.data?.modules?.[0]?.informativeHeader?.icon?.url || ""
    },
    permalink: item.data?.permalink || "",
    title: item.data?.post?.post_title || ""
  }));

  return { ...result, data: featuredPages };
};
