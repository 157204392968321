import { NewslettersApi } from "@app/bf-api";
import { INewNewsletterFormValues } from "@app/core/newsletter";
import { AppThunk } from "../store";

export const subscribeToNewsletter = (values: INewNewsletterFormValues, recaptchaToken: string): AppThunk => async (
  dispatch,
  getState
) => {
  try {
    const api = new NewslettersApi();
    const platformId = getState().settings.platformId;

    await api.newslettersSubscribePost(platformId, recaptchaToken, {
      email: values.emailAddress
    });
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const newsletterThunks = {
  subscribeToNewsletter
};
