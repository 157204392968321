import { OutDetailedMerchantDTO, MerchantsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const singleMerchantGetApi = async (
  platformId: number,
  slug: string
): Promise<OutDetailedMerchantDTO | undefined | 302> => {
  try {
    const api = new MerchantsApi();
    const merchant = await api.merchantsSlugMerchantSlugGet(platformId, slug);

    if (!merchant) return moduleMapperThrowMessage("Cannot get single merchant");

    if (!merchant.live) return moduleMapperThrowMessage("Merchant is offline, send [302]");

    return merchant;
  } catch (e) {
    Logger.logError(e, "Error in: singleMerchantGetApi");

    if ((e as any)?.toString().includes("[302]")) return 302;

    return undefined;
  }
};
