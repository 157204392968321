import React, { useEffect, useState } from "react";

import { SearchResultsModule } from "@app/api/modules/search-results/search-results.module";
import { IconComponent } from "@app/core";
import { SimpleProductCard } from "@app/components/product-cards/simple-product-card/simple-product-card";
import { PageCard } from "@app/components/page-card/page-card";
import SearchIcon from "@assets/icons/search.svg";
import CrossIcon from "@assets/icons/search-cross.svg";
import { SearchInput } from "@app/components/search-input/search-input";
import { useDebouncedValue } from "@app/util/hooks/use-debounced-value";
import { useRefListScrollIntoView } from "@app/util/hooks/use-ref-list-scroll-into-view";
import { RouteComponentProps, withRouter } from "react-router";
import { getSearchItems, SearchItemsModules } from "@app/redux/thunks/navbar-search.thunk";
import { useAppSelector } from "@app/redux/store";
import { ISearchResults } from "@app/redux/reducers/search";
import { SEARCH_QUERY_PARAM } from "@app/util/search-page-link";
import { getCorrectUrl } from "@app/api/wp-page-fetcher/utils/get-correct-url";

import { DealCardWrapper } from "../deal-card-wrapper/deal-card-wrapper";
import { CardResults } from "./card-results/card-results";
import { MerchantCardWrapper } from "../merchant-card-wrapper/merchant-card-wrapper";
import { NoResults } from "./no-results/no-results";
import { AnchorTag } from "./anchor-tag/anchor-tag";
import styles from "./search-results-component.module.scss";

export interface ISearchResultsProps {
  module: SearchResultsModule;
}

const component = (props: ISearchResultsProps & RouteComponentProps) => {
  const { translations: tl, modules, isCollapsible, collapsedLimits, resultLimits } = props.module;
  const { platformId } = useAppSelector(state => state.settings);
  const [searchResults, setSearchResults] = useState(props.module.searchResults);
  const [isLoading, setIsLoading] = useState(false);
  const sections = getModulesToShow(modules, searchResults);
  const { showMerchants, showProducts, showDeals, showPages } = sections;

  const [searchValue, setSearchValue] = useState<string>(getSearchQueryFromSearch(props.history.location.search));
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);
  const { setRef, scrollToId } = useRefListScrollIntoView(props.history);
  const showAnchors = getShowAnchors(modules, tl, searchResults);

  useEffect(() => {
    if (
      typeof debouncedSearchValue === "string" &&
      debouncedSearchValue !== getSearchQueryFromSearch(window.location.search)
    ) {
      const pathname = getCorrectUrl(window.location.pathname);
      const url = pathname.endsWith("/") ? pathname.slice(0, -1) : pathname;
      const newState = debouncedSearchValue ? `${url}?${SEARCH_QUERY_PARAM}=${debouncedSearchValue}` : url;

      window.history.pushState({}, "", newState);

      handleSearch(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  // Make sure the search value is updated when the user searches in the navigation searchbar
  useEffect(() => {
    const search = getSearchQueryFromSearch(props.history.location.search);
    if (debouncedSearchValue !== search) {
      setSearchValue(search);
      handleSearch(search);
    }
  }, [props.history.location.search]);

  const handleSearch = async (s: string) => {
    if (typeof debouncedSearchValue === "string") {
      setIsLoading(true);
      const results = await getSearchItems({
        limit: {
          merchants: resultLimits.merchants,
          deals: resultLimits.deals,
          products: resultLimits.products,
          pages: resultLimits.pages
        },
        platformId,
        searchValue: s,
        modules
      });

      setSearchResults(results);
      setIsLoading(false);
    }
  };

  return (
    <div className="uk-container">
      <div className={styles.module}>
        <div className={styles.searchBar}>
          <SearchInput
            id="searchInputSearchPage"
            icon={<IconComponent fillColor="white" size="14px" icon={SearchIcon} />}
            iconPosition="right"
            onChange={e => setSearchValue(e.target.value)}
            placeholder={tl?.searchBarPlaceholder}
            type="text"
            value={searchValue || ""}
            deleteIcon={<IconComponent fillColor="white" size="14px" icon={CrossIcon} />}
            onDelete={() => setSearchValue("")}
          />
          {showAnchors.any && (
            <div className={styles.anchors}>
              {tl?.anchorLabel && <span className={styles.text}>{tl.anchorLabel}</span>}
              <div className={styles.anchorTags}>
                {showAnchors.merchants && (
                  <AnchorTag onClick={() => scrollToId("results-merchants")}>{tl?.merchants?.anchorLabel}</AnchorTag>
                )}
                {showAnchors.products && (
                  <AnchorTag onClick={() => scrollToId("results-products")}>{tl?.products?.anchorLabel}</AnchorTag>
                )}
                {showAnchors.deals && (
                  <AnchorTag onClick={() => scrollToId("results-deals")}>{tl?.deals?.anchorLabel}</AnchorTag>
                )}
                {showAnchors.pages && (
                  <AnchorTag onClick={() => scrollToId("results-pages")}>{tl?.pages?.anchorLabel}</AnchorTag>
                )}
              </div>
            </div>
          )}
        </div>
        <div className={styles.results} style={{ opacity: isLoading ? 0.5 : 1 }}>
          {showMerchants && (
            <CardResults
              ref={node => setRef(node, "results-merchants")}
              anchorId="results-merchants"
              translations={tl?.merchants}
              count={searchResults?.merchants?.count}
              defaultShowLimit={collapsedLimits.merchants}
              isCollapsible={isCollapsible?.merchants}
              cards={searchResults?.merchants?.data?.map(merchant => (
                <MerchantCardWrapper key={merchant.id} merchant={merchant} variant="mobile-search" responsive />
              ))}
            />
          )}
          {showProducts && searchResults?.products?.data && searchResults?.products?.data.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-products")}
              anchorId="results-products"
              maxColumns={4}
              translations={tl?.products}
              count={searchResults.products.count}
              defaultShowLimit={collapsedLimits.products}
              isCollapsible={isCollapsible?.products}
              cards={searchResults.products.data.map(product => (
                <SimpleProductCard key={product.id} product={product} />
              ))}
            />
          )}
          {showDeals && searchResults?.deals?.data && searchResults?.deals?.data.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-deals")}
              anchorId="results-deals"
              translations={tl?.deals}
              count={searchResults.deals.count}
              defaultShowLimit={collapsedLimits.deals}
              isCollapsible={isCollapsible?.deals}
              cards={searchResults.deals.data.map(deal => (
                <DealCardWrapper key={deal.id} deal={deal} responsive variant="mobile-search" />
              ))}
            />
          )}
          {showPages && searchResults?.pages?.data && searchResults?.pages?.data.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-pages")}
              anchorId="results-pages"
              maxColumns={4}
              translations={tl?.pages}
              count={searchResults.pages.count}
              defaultShowLimit={collapsedLimits.pages}
              isCollapsible={isCollapsible?.pages}
              cards={searchResults.pages.data.map(page => (
                <PageCard key={page.id} page={page} />
              ))}
            />
          )}
          <NoResults
            {...props.module}
            setRef={setRef}
            searchResults={searchResults}
            searchValue={searchValue}
            isLoading={isLoading}
            sections={sections}
          />
        </div>
      </div>
    </div>
  );
};

const getSearchQueryFromSearch = (search?: string) => (search || "").split("=")?.[1];

const getShowAnchors = (
  modules?: SearchItemsModules[],
  translations?: SearchResultsModule["translations"],
  searchResults?: ISearchResults
) => {
  const { showMerchants, showProducts, showDeals, showPages } = getModulesToShow(modules, searchResults, true);

  const showMerchantAnchor = showMerchants && translations?.merchants?.anchorLabel;
  const showProductsAnchor = showProducts && translations?.products?.anchorLabel;
  const showDealsAnchor = showDeals && translations?.deals?.anchorLabel;
  const showPagesAnchor = showPages && translations?.pages?.anchorLabel;

  return {
    any: showMerchantAnchor || showProductsAnchor || showDealsAnchor || showPagesAnchor,
    merchants: showMerchantAnchor,
    products: showProductsAnchor,
    deals: showDealsAnchor,
    pages: showPages
  };
};

export const getModulesToShow = (
  modules?: SearchItemsModules[],
  searchResults?: ISearchResults,
  showWhenNoData?: boolean
) => {
  const noData =
    (!searchResults?.merchants?.data || searchResults.merchants.data.length === 0) &&
    (!searchResults?.products?.data || searchResults.products.data.length === 0) &&
    (!searchResults?.deals?.data || searchResults.deals.data.length === 0) &&
    (!searchResults?.pages?.data || searchResults.pages.data.length === 0);

  return {
    showMerchants:
      modules?.includes("merchants") &&
      ((searchResults?.merchants?.data && searchResults.merchants.data.length > 0) || (showWhenNoData && noData)),
    showProducts:
      modules?.includes("products") &&
      ((searchResults?.products?.data && searchResults.products.data.length > 0) || (showWhenNoData && noData)),
    showDeals:
      modules?.includes("deals") &&
      ((searchResults?.deals?.data && searchResults.deals.data.length > 0) || (showWhenNoData && noData)),
    showPages:
      modules?.includes("pages") &&
      ((searchResults?.pages?.data && searchResults.pages.data.length > 0) || (showWhenNoData && noData))
  };
};

const SearchResults = withRouter(component);
export { SearchResults };
