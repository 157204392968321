/* tslint:disable */
import { logCustomErrorMessage, logOnlyMessage } from "./sentry-logging";

export let LOGGER_OBJECT: any | undefined;

export default class Logger {
  public static logError(e: any, message: string) {
    console.log("ERROR LOGGED", `MESSAGE: ${message} ERROR: ${e}`);
    if (LOGGER_OBJECT) {
      logCustomErrorMessage(LOGGER_OBJECT, e, message);
    }
  }
  public static logMessage(message: string) {
    console.log("MESSAGE LOGGED", message);
    if (LOGGER_OBJECT) {
      logOnlyMessage(LOGGER_OBJECT, message);
    }
  }
  public static setLogger(loggerObject: any) {
    LOGGER_OBJECT = loggerObject;
  }
}
