import * as React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { TextFieldComponent } from "@app/core";
import { validateEmail } from "@app/util";
import Paper from "@assets/icons/paper.svg";
import { ClickableComponent } from "../clickable";

import styles from "./newsletter-forms-component.module.scss";
import { Link } from "react-router-dom";
import { IntlShape } from "react-intl";
import { formatMessage } from "@app/translations/intl";

interface INewsletterFormErrorMessages {
  emailAddress: string;
}
export interface INewsletterFormValues {
  emailAddress: string;
}

interface IOtherProps {
  title?: string;
}

const InnerForm = (props: IOtherProps & FormikProps<INewsletterFormValues>) => {
  const { touched, errors } = props;

  return (
    <Form style={{ flex: "1 1 auto", display: "flex", flexDirection: "column" }}>
      <h2>{props.title}</h2>
      <div className={styles["form-wrapper"]}>
        <div className={styles["inputs"]}>
          <div>
            <TextFieldComponent
              label={formatMessage({ id: "newsLetterModalForm.input.email.label" })}
              placeholder={formatMessage({ id: "newsLetterModalForm.input.name.placeholder" })}
              value={props.values.emailAddress}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              type="email"
              id="emailAddress"
              errorMessage={touched.emailAddress === true && errors.emailAddress}
            />
          </div>
        </div>
      </div>
      <div className={styles["newsletter-forms__spam"]}>
        <p className={styles["newsletter__infoText"]}>
          {formatMessage(
            { id: "newsletterModalForm.infoText" },
            {
              // tslint:disable-next-line: ban-ts-ignore
              // @ts-ignore
              conditions: (
                <Link rel="nofollow" to={formatMessage({ id: "global.conditionsLink" })}>
                  {formatMessage({ id: "newsletterModalForm.conditions" })}
                </Link>
              )
            }
          )}
        </p>
        <div className={styles.newsletterBottom}>
          <ClickableComponent
            variant="primary-brand"
            iconStyle="filled"
            buttonType="submit"
            title={formatMessage({ id: "newsletterModalForm.button.sendTitle" })}
            iconRight={Paper}
          />
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  onSubmit: (values: INewsletterFormValues) => void;
  title?: string;
}

export const NewsletterFormComponent = withFormik<IFormProps, INewsletterFormValues>({
  mapPropsToValues: () => ({
    emailAddress: ""
  }),

  validate: (values: INewsletterFormValues, bag) => {
    const errors: FormikErrors<INewsletterFormErrorMessages> = {};

    if (!values.emailAddress) {
      errors.emailAddress = formatMessage({ id: "newsLetterModalForm.input.email.errorMessageEmpty" });
    } else if (!validateEmail(values.emailAddress)) {
      errors.emailAddress = formatMessage({ id: "newsLetterModalForm.input.email.errorMessageWrong" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
