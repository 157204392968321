import { useRef } from "react";
import { AnchorId } from "../anchor-id";

export interface IUseRefList<T> {
  getNode: (id: string) => T | undefined;
  itemsRef: React.MutableRefObject<Map<string, T> | null>;
  setRef: (node: T | null, refId?: string) => void;
}

export const useRefList = <T>(): IUseRefList<T> => {
  const itemsRef: React.MutableRefObject<Map<string, T> | null> = useRef(null);

  const getRefs = (): Map<string, T> => {
    if (!itemsRef.current) {
      itemsRef.current = new Map<string, T>();
    }
    return itemsRef.current;
  };

  const getNode = (id: string): T | undefined => {
    const map = getRefs();

    return map.get(id);
  };

  const setRef = (node: T | null, refId?: string): void => {
    const id = AnchorId.convert(refId);

    const map = getRefs();
    if (id) {
      if (node) {
        map.set(id, node);
      } else {
        map.delete(id);
      }
    }
  };

  return { getNode, itemsRef, setRef };
};
