/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GenericIdDTO,
  GenericIdDTOFromJSON,
  GenericIdDTOFromJSONTyped,
  GenericIdDTOToJSON,
  ImageDTO,
  ImageDTOFromJSON,
  ImageDTOFromJSONTyped,
  ImageDTOToJSON,
  InCampaignDTO,
  InCampaignDTOFromJSON,
  InCampaignDTOFromJSONTyped,
  InCampaignDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  SeoDTO,
  SeoDTOFromJSON,
  SeoDTOFromJSONTyped,
  SeoDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface InDetailedMerchantDTO
 */
export interface InDetailedMerchantDTO {
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof InDetailedMerchantDTO
   */
  live?: boolean;
  /**
   *
   * @type {PlatformDTO}
   * @memberof InDetailedMerchantDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  features?: string;
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  buyInPosition?: number;
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  eCPC?: number;
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  approvalRate?: number;
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof InDetailedMerchantDTO
   */
  minimumDealScoreModifier?: number;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  trackingUrl?: string;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  adSenseScript?: string;
  /**
   *
   * @type {boolean}
   * @memberof InDetailedMerchantDTO
   */
  inOverview?: boolean;
  /**
   *
   * @type {ImageDTO}
   * @memberof InDetailedMerchantDTO
   */
  headerImage?: ImageDTO;
  /**
   *
   * @type {ImageDTO}
   * @memberof InDetailedMerchantDTO
   */
  logoImage?: ImageDTO;
  /**
   *
   * @type {SeoDTO}
   * @memberof InDetailedMerchantDTO
   */
  seo?: SeoDTO;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  lastEdit?: string;
  /**
   *
   * @type {boolean}
   * @memberof InDetailedMerchantDTO
   */
  approved?: boolean;
  /**
   *
   * @type {GenericIdDTO}
   * @memberof InDetailedMerchantDTO
   */
  editedBy?: GenericIdDTO;
  /**
   *
   * @type {string}
   * @memberof InDetailedMerchantDTO
   */
  name: string;
  /**
   *
   * @type {Array<InCampaignDTO>}
   * @memberof InDetailedMerchantDTO
   */
  campaigns?: Array<InCampaignDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof InDetailedMerchantDTO
   */
  filters?: Array<GenericIdDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof InDetailedMerchantDTO
   */
  categories?: Array<GenericIdDTO>;
}

export function InDetailedMerchantDTOFromJSON(json: any): InDetailedMerchantDTO {
  return InDetailedMerchantDTOFromJSONTyped(json, false);
}

export function InDetailedMerchantDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InDetailedMerchantDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    live: !exists(json, "live") ? undefined : json["live"],
    platform: PlatformDTOFromJSON(json["platform"]),
    features: !exists(json, "features") ? undefined : json["features"],
    buyInPosition: !exists(json, "buyInPosition") ? undefined : json["buyInPosition"],
    eCPC: !exists(json, "eCPC") ? undefined : json["eCPC"],
    approvalRate: !exists(json, "approvalRate") ? undefined : json["approvalRate"],
    score: !exists(json, "score") ? undefined : json["score"],
    minimumDealScoreModifier: !exists(json, "minimumDealScoreModifier") ? undefined : json["minimumDealScoreModifier"],
    url: !exists(json, "url") ? undefined : json["url"],
    trackingUrl: !exists(json, "trackingUrl") ? undefined : json["trackingUrl"],
    adSenseScript: !exists(json, "adSenseScript") ? undefined : json["adSenseScript"],
    inOverview: !exists(json, "inOverview") ? undefined : json["inOverview"],
    headerImage: !exists(json, "headerImage") ? undefined : ImageDTOFromJSON(json["headerImage"]),
    logoImage: !exists(json, "logoImage") ? undefined : ImageDTOFromJSON(json["logoImage"]),
    seo: !exists(json, "seo") ? undefined : SeoDTOFromJSON(json["seo"]),
    lastEdit: !exists(json, "lastEdit") ? undefined : json["lastEdit"],
    approved: !exists(json, "approved") ? undefined : json["approved"],
    editedBy: !exists(json, "editedBy") ? undefined : GenericIdDTOFromJSON(json["editedBy"]),
    name: json["name"],
    campaigns: !exists(json, "campaigns") ? undefined : (json["campaigns"] as Array<any>).map(InCampaignDTOFromJSON),
    filters: !exists(json, "filters") ? undefined : (json["filters"] as Array<any>).map(GenericIdDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(GenericIdDTOFromJSON)
  };
}

export function InDetailedMerchantDTOToJSON(value?: InDetailedMerchantDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    live: value.live,
    platform: PlatformDTOToJSON(value.platform),
    features: value.features,
    buyInPosition: value.buyInPosition,
    eCPC: value.eCPC,
    approvalRate: value.approvalRate,
    score: value.score,
    minimumDealScoreModifier: value.minimumDealScoreModifier,
    url: value.url,
    trackingUrl: value.trackingUrl,
    adSenseScript: value.adSenseScript,
    inOverview: value.inOverview,
    headerImage: ImageDTOToJSON(value.headerImage),
    logoImage: ImageDTOToJSON(value.logoImage),
    seo: SeoDTOToJSON(value.seo),
    lastEdit: value.lastEdit,
    approved: value.approved,
    editedBy: GenericIdDTOToJSON(value.editedBy),
    name: value.name,
    campaigns: value.campaigns === undefined ? undefined : (value.campaigns as Array<any>).map(InCampaignDTOToJSON),
    filters: value.filters === undefined ? undefined : (value.filters as Array<any>).map(GenericIdDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(GenericIdDTOToJSON)
  };
}
