import React from "react";

import { useAppSelector } from "@app/redux/store";
import { formatMessage } from "@app/translations/intl";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";

import styles from "./search-results.module.scss";
import { NavBarResultMerchants } from "./navbar-result-merchants/navbar-result-merchants";
import { NavBarResultDeals } from "./navbar-result-deals/navbar-result-deals";
import { NavBarResultPages } from "./navbar-result-pages/navbar-result-pages";
import { NavBarResultProducts } from "./navbar-result-products/navbar-result-products";
import { NavbarNoResults } from "./navbar-no-results/navbar-no-results";

export interface ISearchResultsProps {
  popularDeals?: IOutFeaturedDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
  searchValue?: string;
  closeSearch: () => void;
}

export const SearchResults = (props: ISearchResultsProps) => {
  const { closeSearch } = props;
  const { foundDeals, foundMerchants, foundPages, foundProducts, isLoading } = useAppSelector(state => state.search);

  return (
    <div className={styles.searchResults} style={{ opacity: isLoading ? 0.5 : 1 }}>
      {foundMerchants.data && foundMerchants.data?.length > 0 && (
        <NavBarResultMerchants
          button={{
            title: formatMessage({ id: "navbar.search.result.button.merchants" })
          }}
          merchants={foundMerchants.data}
          count={foundMerchants.count}
          headerText={formatMessage({ id: "navbar.search.result.merchants" })}
          closeSearch={closeSearch}
        />
      )}
      {foundProducts.data && foundProducts.data?.length > 0 && (
        <NavBarResultProducts
          products={foundProducts.data}
          count={foundProducts.count}
          headerText={formatMessage({ id: "navbar.search.result.products" })}
          closeSearch={closeSearch}
        />
      )}

      {foundDeals.data && foundDeals.data?.length > 0 && (
        <NavBarResultDeals
          button={{
            title: formatMessage({ id: "navbar.search.result.button.deals" })
          }}
          deals={foundDeals.data}
          count={foundDeals.count}
          headerText={formatMessage({ id: "navbar.search.result.deals" })}
          closeSearch={closeSearch}
        />
      )}

      {foundPages.data && foundPages.data.length > 0 && (
        <NavBarResultPages
          pages={foundPages.data}
          count={foundPages.count}
          headerText={formatMessage({ id: "navbar.search.result.pages" })}
          closeSearch={closeSearch}
        />
      )}
      <NavbarNoResults {...props} />
    </div>
  );
};
