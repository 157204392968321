/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutBannerDTO, OutBannerDTOFromJSON, OutBannerDTOFromJSONTyped, OutBannerDTOToJSON } from "./";

/**
 *
 * @export
 * @interface BannerListResponseDTO
 */
export interface BannerListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof BannerListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<OutBannerDTO>}
   * @memberof BannerListResponseDTO
   */
  banners: Array<OutBannerDTO>;
}

export function BannerListResponseDTOFromJSON(json: any): BannerListResponseDTO {
  return BannerListResponseDTOFromJSONTyped(json, false);
}

export function BannerListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): BannerListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    banners: (json["banners"] as Array<any>).map(OutBannerDTOFromJSON)
  };
}

export function BannerListResponseDTOToJSON(value?: BannerListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    banners: (value.banners as Array<any>).map(OutBannerDTOToJSON)
  };
}
