import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import { OutDealDTO, SearchApi } from "@app/bf-api";
import Logger from "@app/util/logger";
import { FeaturedDealsSearchModule } from "@app/api/modules/featured-deals-search/featured-deals-search.module";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";

import { IWPFeaturedDealsSearchModuleFull } from "./featured-deals-search-module.interface";
import { mapDealsToFeaturedDeals } from "../featured-deals-module";

const DEFAULT_AMOUNT_OF_DEALS = 9;

export interface IFeaturedDealsSearchModuleMapper extends IModuleFetcher {
  module: IWPFeaturedDealsSearchModuleFull;
}

export const featuredDealsSearchModuleMapper = async (
  props: IFeaturedDealsSearchModuleMapper
): Promise<FeaturedDealsSearchModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      res,
      platformId,
      pageUrl
    } = props;

    if (!data || (!data.searchValue && !data.searchValueEAN && !data.brandSelection && !data.categorySelection))
      return moduleMapperThrowMessage("No data found in featuredDealsSearchModuleMapper");

    const filterIds = combineFilters([data.brandSelection, data.categorySelection]);

    let featuredDeals: OutDealDTO[] | undefined = [];

    if (res) {
      const api = new SearchApi();
      const result = await api.searchGet(
        data.amountOfDeals || DEFAULT_AMOUNT_OF_DEALS,
        0,
        platformId,
        filterIds || undefined,
        data.searchValueEAN || undefined,
        data.searchValue || undefined,
        "deals"
      );
      featuredDeals = result.deals?.data;
    }

    return {
      id: "27",
      name: "FeaturedDealsSearchModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      useSeeMoreCard: data.useSeeMoreCard,
      topCtaTitle: {
        title: data.topCtaTitle?.title || "",
        url: data.topCtaTitle?.url || ""
      },
      ctaButton: {
        title: data.cta?.title || "",
        url: data.cta?.url || ""
      },
      ctaUnderButton: {
        title: data.ctaUnderButton?.title || "",
        url: data.ctaUnderButton?.url || ""
      },
      ctaDescription: data.ctaDescription || "",
      title: data.title || "",
      searchValue: data.searchValue || undefined,
      searchValueEAN: data.searchValueEAN || undefined,
      amountOfDeals: data.amountOfDeals,
      featuredDeals: mapDealsToFeaturedDeals(featuredDeals || []),
      amountOfDealsMobile: data.amountOfDealsMobile,
      brandSelection: data?.brandSelection,
      categorySelection: data?.categorySelection,
      pageUrl
    };
  } catch (e) {
    Logger.logError(e, "Error in: featuredDealsSearchModuleMapper");

    return undefined;
  }
};
