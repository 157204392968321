export const LOCALES = {
  EN: "en-GB",
  NL: "nl-NL",
  BENL: "nl-BE",
  BEFR: "fr-BE",
  FR: "fr-FR",
  SE: "sv-SE",
  DE: "de-DE",
  IT: "it-IT",
  ES: "es-ES"
};
