import clsx from "clsx";
import React, { useEffect, useRef, useState } from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";

import { ISidebarComponentProps, SidebarComponent } from "@app/core/sidebar";
import { BodyTextComponent } from "@app/core/bodytext";
import { BlogBodyTextModule } from "@app/api/core/blog-bodytext/blog-bodytext";
import { ShareSocialDropdownComponent } from "@app/core/share-social-dropdown";
import { IconComponent } from "@app/core";
import ClockIcon from "@assets/icons/rounded/clock.svg";
import CalendarIcon from "@assets/icons/rounded/calendar.svg";
import PersonIcon from "@assets/icons/rounded/person.svg";
import { formatMessage } from "@app/translations/intl";
import { useAppSelector } from "@app/redux/store";
import { stripHtmlString } from "@app/api/wp-page-fetcher/utils/strip-html-string";
import { IBaseModuleProps } from "@app/core/pagebuilder/components/module.component";
import { AuthorComponent } from "@app/core/author";
import { OutProductExtendedViewDTO } from "@app/bf-api";

import styles from "./blog-body-text-component.module.scss";
import { IOutSingleMerchantDTO } from "../merchant-single-header/merchant-single-header.interface";

export interface IBlogBodyTextComponentProps extends IBaseModuleProps {
  blogBodyTextModule: BlogBodyTextModule;
}

export const BlogBodyTextComponent = (props: IBlogBodyTextComponentProps) => {
  const [renderSidebar, setRenderSidebar] = useState<boolean>(true);
  const [renderSpecialSeo, setRenderSpecialSeo] = useState<string | undefined | null>(null);
  const { currentMerchant } = useAppSelector(state => state.merchantOverview);
  const { selectedProduct } = useAppSelector(state => state.product);
  const rootRef = useRef<HTMLDivElement>(null);
  const intl = useIntl();

  const {
    bodyText,
    sidebarItems,
    dateTime,
    showSharingOptions,
    useMerchantSingleSeoData,
    useProductSingleSeoData,
    publishDate,
    author,
    showAuthorTop,
    showAuthorBottom
  } = props.blogBodyTextModule;

  const authorTop = showAuthorTop ? author : undefined;
  const authorBottom = showAuthorBottom ? author : undefined;

  const backgroundColorStyle = {
    ...(props.blogBodyTextModule.background?.backgroundColour && {
      backgroundColor: props.blogBodyTextModule.background.backgroundColour
    })
  };

  useEffect(() => {
    setRenderSidebar(checkIfSidebarContainsItems(sidebarItems));
  }, [props.blogBodyTextModule]);

  useEffect(() => {
    const seo =
      getMerchantSeoHtml(currentMerchant, useMerchantSingleSeoData) ||
      getProductSeoHtml(selectedProduct, useProductSingleSeoData);

    setRenderSpecialSeo(seo);
  }, [currentMerchant, selectedProduct, useMerchantSingleSeoData, useProductSingleSeoData]);

  useEffect(() => {
    // If there is no bodytext, hide the root component
    if (!bodyText?.html && !renderSidebar && renderSpecialSeo === undefined) {
      if (props.rootRef?.current) {
        props.rootRef.current.style.display = "none";
      }
    } else if (props.rootRef?.current) {
      props.rootRef.current.style.display = "block";
    }
  }, [bodyText?.html, renderSidebar, renderSpecialSeo]);

  return (
    <div className={clsx("uk-container", styles["blog-body-text"])} style={backgroundColorStyle} ref={rootRef}>
      <div className={styles["blog-body-text__container"]}>
        <div className={styles["blog-body-text__content"]}>
          {(publishDate || authorTop || dateTime) && (
            <div className={styles.meta}>
              {publishDate && (
                <div className={styles.metaItem}>
                  <IconComponent icon={CalendarIcon} size="14" />{" "}
                  {intl.formatDate(publishDate, {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                    hour: "2-digit",
                    minute: "2-digit"
                  })}
                </div>
              )}
              {authorTop && (
                <Link className={styles.metaItem} to={authorTop.url || ""}>
                  <IconComponent icon={PersonIcon} size="14" /> {authorTop.name}
                </Link>
              )}
              {dateTime && (
                <div className={styles.metaItem}>
                  <IconComponent icon={ClockIcon} size="14" /> {dateTime}
                </div>
              )}
            </div>
          )}
          <BodyTextComponent bodyTextModule={{ ...bodyText, html: renderSpecialSeo || bodyText.html }} />
          {authorBottom && <AuthorComponent {...authorBottom} />}
          {showSharingOptions && (
            <div className={styles["blog-body-text__content__footer"]}>
              <ShareSocialDropdownComponent
                buttonTitle={formatMessage({ id: "blogBodyText.shareSocialDropdown.buttonTitle" })}
              />
            </div>
          )}
        </div>
        {renderSidebar && sidebarItems && (
          <div className={styles["blog-body-text__sidebar"]}>
            <SidebarComponent
              bannerModule={sidebarItems.bannerModule}
              featuredMerchantsModule={sidebarItems.featuredMerchantsModule}
              featuredDealsModule={sidebarItems.featuredDealsModule}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const checkIfSidebarContainsItems = (sidebarItems?: ISidebarComponentProps): boolean => {
  if (!sidebarItems) return false;
  const { bannerModule, featuredMerchantsModule: featuredStoresModule, featuredDealsModule } = sidebarItems;
  const noBannerModule = !bannerModule;
  const noFeaturedStoresModule =
    !featuredStoresModule || !featuredStoresModule.featuredItems || featuredStoresModule.featuredItems.length === 0;
  const noFeaturedDealsModule =
    !featuredDealsModule || !featuredDealsModule.featuredItems || featuredDealsModule.featuredItems.length === 0;

  return !(noBannerModule && noFeaturedStoresModule && noFeaturedDealsModule);
};

const getMerchantSeoHtml = (merchant?: IOutSingleMerchantDTO, useData?: boolean) =>
  useData && merchant?.seo?.pageText && stripHtmlString(merchant?.seo?.pageText) ? merchant.seo.pageText : undefined;

const getProductSeoHtml = (product?: OutProductExtendedViewDTO, useData?: boolean) =>
  useData && product?.seo?.pageText && stripHtmlString(product?.seo?.pageText) ? product.seo.pageText : undefined;
