import React from "react";
import styles from "./infinite-line-loader-component.module.scss";

const InfiniteLineLoader = (): JSX.Element => (
  <div className={styles.loader}>
    <div className={styles.barPosition}>
      <div className={styles.barResize} />
    </div>
  </div>
);

export { InfiniteLineLoader };
