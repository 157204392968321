/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  GenericIdDTO,
  GenericIdDTOFromJSON,
  GenericIdDTOFromJSONTyped,
  GenericIdDTOToJSON,
  ImageDTO,
  ImageDTOFromJSON,
  ImageDTOFromJSONTyped,
  ImageDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface CreateBannerDTO
 */
export interface CreateBannerDTO {
  /**
   *
   * @type {PlatformDTO}
   * @memberof CreateBannerDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  cta?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  label?: string;
  /**
   *
   * @type {ImageDTO}
   * @memberof CreateBannerDTO
   */
  bannerImage?: ImageDTO;
  /**
   *
   * @type {ImageDTO}
   * @memberof CreateBannerDTO
   */
  logoImage?: ImageDTO;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  name: string;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof CreateBannerDTO
   */
  brands?: Array<GenericIdDTO>;
  /**
   *
   * @type {Array<GenericIdDTO>}
   * @memberof CreateBannerDTO
   */
  categories?: Array<GenericIdDTO>;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof CreateBannerDTO
   */
  endDate?: string;
}

export function CreateBannerDTOFromJSON(json: any): CreateBannerDTO {
  return CreateBannerDTOFromJSONTyped(json, false);
}

export function CreateBannerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateBannerDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    platform: PlatformDTOFromJSON(json["platform"]),
    description: !exists(json, "description") ? undefined : json["description"],
    cta: !exists(json, "cta") ? undefined : json["cta"],
    label: !exists(json, "label") ? undefined : json["label"],
    bannerImage: !exists(json, "bannerImage") ? undefined : ImageDTOFromJSON(json["bannerImage"]),
    logoImage: !exists(json, "logoImage") ? undefined : ImageDTOFromJSON(json["logoImage"]),
    url: !exists(json, "url") ? undefined : json["url"],
    name: json["name"],
    brands: !exists(json, "brands") ? undefined : (json["brands"] as Array<any>).map(GenericIdDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(GenericIdDTOFromJSON),
    startDate: !exists(json, "startDate") ? undefined : json["startDate"],
    endDate: !exists(json, "endDate") ? undefined : json["endDate"]
  };
}

export function CreateBannerDTOToJSON(value?: CreateBannerDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    platform: PlatformDTOToJSON(value.platform),
    description: value.description,
    cta: value.cta,
    label: value.label,
    bannerImage: ImageDTOToJSON(value.bannerImage),
    logoImage: ImageDTOToJSON(value.logoImage),
    url: value.url,
    name: value.name,
    brands: value.brands === undefined ? undefined : (value.brands as Array<any>).map(GenericIdDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(GenericIdDTOToJSON),
    startDate: value.startDate,
    endDate: value.endDate
  };
}
