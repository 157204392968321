import * as React from "react";
import { NewsletterFormComponent, INewsletterFormValues } from "./newsletter.forms.component";
import { useDispatch } from "react-redux";
import { subscribeToNewsletter } from "@app/redux/thunks/newsletter.thunk";
import { useIntl } from "react-intl";
import { ReCaptcha } from "../recaptcha/recaptcha.component";
import { formatMessage } from "@app/translations/intl";

export interface INewsletterProps {
  title?: string;
}

export const NewsletterComponent = (props: INewsletterProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [showConfirmation, setShowConfirmation] = React.useState(false);

  const onSubmit = (values: INewsletterFormValues) => {
    dispatch(subscribeToNewsletter(values, recaptchaToken));
    setShowConfirmation(true);
  };

  return (
    <div style={{ height: "100%", display: "flex" }}>
      <ReCaptcha action="newsletter" callBackToken={setRecaptchaToken} />
      {showConfirmation ? (
        <div>{formatMessage({ id: "newsLetterModalForm.confirmation.message" })}</div>
      ) : (
        <NewsletterFormComponent intl={intl} onSubmit={onSubmit} title={props.title} />
      )}
    </div>
  );
};
