import * as React from "react";
import { Link } from "react-router-dom";
import style from "./link-component.module.scss";
import clsx from "clsx";
import { IconComponent } from "../icon/icon.component";
import { checkExternalUrl } from "@app/api/wp-page-fetcher/utils/check-same-domain";

export interface ILinkComponentProps {
  animated?: boolean;
  children: any;
  icon?: string;
  target?: string;
  to?: string;
  variant?:
    | "primary"
    | "secondary"
    | "tertiary"
    | "quaternary"
    | "primary-new-colored-simple"
    | "primary-new-colored-black";
}

const LinkComponent = (props: ILinkComponentProps) => {
  const { icon, to, target, variant, animated } = props;
  const defaultVariant = variant || "primary";
  const linkClassName = clsx(style["link"], style[`link--${defaultVariant}`], { [style["link--animated"]]: animated });

  const linkBody = (
    <>
      {props.children}
      {icon && <IconComponent icon={icon} size="14px" />}
    </>
  );

  if (target === "_blank" || checkExternalUrl(to)) {
    return (
      <a href={to} className={linkClassName} target={target === "_blank" ? "_blank" : undefined}>
        {linkBody}
      </a>
    );
  }

  return (
    <Link target={target} className={linkClassName} to={to || ""}>
      {linkBody}
    </Link>
  );
};

export { LinkComponent };
