/* tslint:disable */
export const logCustomErrorMessage = (Sentry: any, e: any, message: string) => {
  Sentry.withScope(function (scope: any) {
    scope.setExtra("extra_message", message);
    Sentry.captureException(e);
  });
};

export const logOnlyMessage = (Sentry: any, message: string) => {
  Sentry.captureMessage(message);
};
