import { SearchApi } from "@app/bf-api";

import { ISearchPageDataCollection } from "@app/api/core/search-page-data";
import {
  mapDealsToFeaturedDeals,
  mapMerchantsToFeaturedMerchants
} from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { getFeaturedPagesMapper } from "@app/middleware/api/featured-pages-get.api";

import {
  clearResults,
  ISearchResults,
  setNavbarSearchItems,
  setNavbarSearchText,
  setIsLoading
} from "../reducers/search";
import { AppThunk } from "../store";

const SKIP = 0;
const DEFAULT_LIMIT = 8;

export type SearchItemsModules = "deals" | "merchants" | "products" | "pages";

interface SearchItemsProps {
  searchValue: string;
  platformId: number;
  modules?: SearchItemsModules[];
  limit:
    | number
    | {
        deals: number;
        merchants: number;
        products: number;
        pages: number;
      };
}

export const searchItems = (props: SearchItemsProps): AppThunk => async (dispatch, getState) => {
  try {
    dispatch(setIsLoading(true));
    dispatch(setNavbarSearchText(props.searchValue));

    const result = await getSearchItems(props);

    if (!result) {
      dispatch(clearResults());

      return;
    }

    dispatch(setNavbarSearchItems(result));
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const getSearchItems = async ({
  platformId,
  searchValue,
  ...rest
}: SearchItemsProps): Promise<ISearchResults | undefined> => {
  try {
    const modules = rest.modules ? rest.modules : ["deals", "merchants", "products", "pages"];
    const apiModules =
      modules.includes("merchants") || modules.includes("deals") || modules.includes("products")
        ? modules.filter(m => m !== "pages").join(";")
        : undefined;

    if (!searchValue) {
      return undefined;
    }

    const limit =
      typeof rest.limit === "number" ? rest.limit : Math.max(...Object.values(rest.limit || {})) || DEFAULT_LIMIT;

    const limits = {
      deals: typeof rest.limit === "number" ? limit : rest.limit.deals,
      merchants: typeof rest.limit === "number" ? limit : rest.limit.merchants,
      products: typeof rest.limit === "number" ? limit : rest.limit.products,
      pages: typeof rest.limit === "number" ? limit : rest.limit.pages
    };

    const api = new SearchApi();
    const resultFromApi = await api.searchGet(
      limit,
      SKIP,
      platformId,
      undefined,
      undefined,
      searchValue || undefined,
      apiModules
    );

    const pages = modules?.includes("pages")
      ? await searchPages(searchValue, platformId, limit)
      : {
          count: 0,
          data: []
        };

    const result: ISearchResults = {
      merchants: {
        count: resultFromApi.merchants?.count || 0,
        data: mapMerchantsToFeaturedMerchants(resultFromApi?.merchants?.data || []).slice(0, limits.merchants)
      },
      deals: {
        count: resultFromApi.deals?.count || 0,
        data: mapDealsToFeaturedDeals(resultFromApi?.deals?.data || []).slice(0, limits.deals)
      },
      products: {
        count: resultFromApi?.products?.count || 0,
        data: (resultFromApi?.products?.data || []).slice(0, limits.products)
      },
      pages: {
        count: pages.count,
        data: pages.data?.slice(0, limits.pages)
      }
    };

    return result;
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const searchPages = async (
  searchValue: string,
  platformId: number,
  limit: number
): Promise<ISearchPageDataCollection> => {
  try {
    if (!searchValue) {
      return { count: 0, data: [] };
    }

    const result = await getFeaturedPagesMapper({ platformId, searchValue, limit });

    if (result === 404 || result === 503) {
      return { count: 0, data: [] };
    }

    return { count: result.total, data: result.data };
  } catch (error) {
    return { count: 0, data: [] };
  }
};

export const navbarSearchThunks = {
  searchItems
};
