import { formatMessage } from "@app/translations/intl";

export const SEARCH_QUERY_PARAM = "q";

const searchPageLink = (searchQuery?: string) => {
  const rootUrl = formatMessage({ id: "global.searchPage.url" });

  if (!searchQuery) {
    return rootUrl;
  }

  return `${rootUrl.slice(0, -1)}?${SEARCH_QUERY_PARAM}=${searchQuery}`;
};

export { searchPageLink };
