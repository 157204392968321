/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { PutFilterDTO, PutFilterDTOFromJSON, PutFilterDTOFromJSONTyped, PutFilterDTOToJSON } from "./";

/**
 *
 * @export
 * @interface FilterListResponseDTO
 */
export interface FilterListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof FilterListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<PutFilterDTO>}
   * @memberof FilterListResponseDTO
   */
  filters?: Array<PutFilterDTO>;
}

export function FilterListResponseDTOFromJSON(json: any): FilterListResponseDTO {
  return FilterListResponseDTOFromJSONTyped(json, false);
}

export function FilterListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    filters: !exists(json, "filters") ? undefined : (json["filters"] as Array<any>).map(PutFilterDTOFromJSON)
  };
}

export function FilterListResponseDTOToJSON(value?: FilterListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    filters: value.filters === undefined ? undefined : (value.filters as Array<any>).map(PutFilterDTOToJSON)
  };
}
