import { AppThunk } from "@app/redux/store";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { FiltersApi, MerchantsApi, OutFilterDTO } from "@app/bf-api";
import { bannersGetApi } from "@app/middleware/api/banners-get.api";
import { mapMerchantsToFeaturedMerchants } from "@app/api/wp-page-fetcher/module-selector/module-mappers/featured-merchants-module";
import { getMerchantsApi } from "@app/middleware/api/merchants-get.api";
import { getAmountOfMerchantsApi } from "@app/middleware/api/total-amount-of-merchants-get.api";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

import {
  setBanners,
  setBrandFilters,
  setCategoryFilters,
  setCurrentMerchant,
  setCurrentTotalMerchants,
  setMerchants,
  setTotalAmountOfMerchants,
  updateMerchants
} from "../reducers/merchants";

export interface IMerchantResult {
  merchants: IOutFeaturedMerchantDTO[];
  totalResults: number;
}

export const getMerchants = (
  skip: number,
  take: number,
  categoryFilters: FilterItem[],
  brandFilters: FilterItem[],
  platformId: number,
  sortBy?: string
): AppThunk => async (dispatch, getState) => {
  try {
    const currentTotal = getState().merchantOverview.totalCurrentMerchants || 0;

    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const filtersIds = [...categoryFiltersIds, ...brandFiltersIds];
    const filters = filtersIds.join(";");

    const resultFromApi = await getMerchantsApi(take, skip, platformId, filters);

    const amountOfMerchants = resultFromApi?.merchants?.length || 0;

    dispatch(
      setCurrentTotalMerchants(
        amountOfMerchants !== resultFromApi?.total ? currentTotal + amountOfMerchants : resultFromApi.total
      )
    );
    const result: IMerchantResult = {
      merchants: mapMerchantsToFeaturedMerchants(resultFromApi?.merchants || []),
      totalResults: resultFromApi?.total || 0
    };

    if (skip === 0) {
      dispatch(setMerchants(result));
    } else {
      dispatch(updateMerchants(result));
    }
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

// TODO: API endpoint for filtertypes will be created at later time.
// Update: back-end apparently never implemented this endpoint...
const categoryFilterType = 1;
const brandFilterType = 3;
export const getAllFilters = (platformId: number): AppThunk => async (dispatch, getState) => {
  const api = new FiltersApi();

  const brandFilters = await api.filtersTopGet("merchants", 50, brandFilterType, platformId);
  const brandFilterItems: FilterItem[] = brandFilters.map(filter => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  const categoryFilters = await api.filtersTopGet("merchants", 50, categoryFilterType, platformId);
  const categoryFilterItems: FilterItem[] = categoryFilters.map(filter => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  dispatch(setBrandFilters(brandFilterItems));
  dispatch(setCategoryFilters(categoryFilterItems));
};

export const getBrandTopFilters = (categoryFilters: FilterItem[], platformId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const currentlySelectedBrandFilters = getState().merchantOverview.brandFilterItems.filter(item => item.isSelected);

  const filtersIds = [...categoryFiltersIds];
  const filters = filtersIds.join(";");

  const api = new FiltersApi();
  const brandFilters = await api.filtersTopGet("merchants", 50, brandFilterType, platformId, undefined, filters);
  const brandFilterItems: FilterItem[] = brandFilters.map(filter => {
    let isSelected = false;
    if (categoryFilters && categoryFilters.length > 0) {
      isSelected = !!currentlySelectedBrandFilters.find(item => filter.id.toString() === item.id);
    }

    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  dispatch(setBrandFilters(brandFilterItems));
};

export const getCategoryTopFilters = (brandFilters: FilterItem[], platformId: number): AppThunk => async (
  dispatch,
  getState
) => {
  const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const currentlySelectedCategoryFilters = getState().merchantOverview.categoryFilterItems.filter(
    item => item.isSelected
  );

  const filtersIds = [...brandFiltersIds];
  const filters = filtersIds.join(";");

  const api = new FiltersApi();
  const categoryFilters = await api.filtersTopGet("merchants", 50, categoryFilterType, platformId, undefined, filters);
  const categoryFilterItems: FilterItem[] = categoryFilters.map(filter => {
    let isSelected = false;
    if (brandFilters && brandFilters.length > 0) {
      isSelected = !!currentlySelectedCategoryFilters.find(item => filter.id.toString() === item.id);
    }

    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  dispatch(setCategoryFilters(categoryFilterItems));
};

export const onBrandFilterSearch = (value: string, categoryFilters?: FilterItem[]): AppThunk => async (
  dispatch,
  getState
) => {
  let filters;

  if (categoryFilters && categoryFilters.length > 0) {
    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const filtersIds = [...categoryFiltersIds];
    filters = filtersIds.join(";");
  }
  if (!categoryFilters || categoryFilters.length === 0) {
    filters = undefined;
  }
  const platformId = getState().settings.platformId;
  const api = new FiltersApi();
  let brandFilterResult: OutFilterDTO[];
  if (value) {
    // implement merchant filters
    const result: any = await api.filtersGet(
      value,
      50,
      brandFilterType.toString(),
      "merchants",
      undefined,
      filters,
      platformId
    );
    brandFilterResult = result.filters;
  } else {
    brandFilterResult = await api.filtersTopGet("merchants", 50, brandFilterType, platformId, undefined, filters);
  }

  const brandFilterItems: FilterItem[] = brandFilterResult.map((filter: OutFilterDTO) => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  dispatch(setBrandFilters(brandFilterItems));
};

export const onCategoryFilterSearch = (value: string, brandFilters?: FilterItem[]): AppThunk => async (
  dispatch,
  getState
) => {
  let filters;

  if (brandFilters && brandFilters.length > 0) {
    const categoryFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const filtersIds = [...categoryFiltersIds];
    filters = filtersIds.join(";");
  }
  if (!brandFilters || brandFilters.length === 0) {
    filters = undefined;
  }
  const platformId = getState().settings.platformId;
  const api = new FiltersApi();
  let categoryFilterResult: OutFilterDTO[];
  if (value) {
    // implement merchant filters
    const result: any = await api.filtersGet(
      value,
      50,
      categoryFilterType.toString(),
      "merchants",
      undefined,
      filters,
      platformId
    );
    categoryFilterResult = result.filters;
  } else {
    categoryFilterResult = await api.filtersTopGet("merchants", 50, categoryFilterType, platformId, undefined, filters);
  }

  const categoryFilterItems: FilterItem[] = categoryFilterResult.map((filter: OutFilterDTO) => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByMerchant
    };

    return filterItem;
  });

  dispatch(setCategoryFilters(categoryFilterItems));
};

export const getBanners = (
  platformId: number,
  categoryFilters?: FilterItem[],
  brandFilters?: FilterItem[]
): AppThunk => async (dispatch, getState) => {
  let filterIds: string[] = [];
  if (categoryFilters && Array.isArray(categoryFilters) && brandFilters && Array.isArray(brandFilters)) {
    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    filterIds = [...categoryFiltersIds, ...brandFiltersIds];
  }

  const filters = filterIds.join(";");

  const banners = (await bannersGetApi(platformId, filters)) || [];

  dispatch(setBanners(banners));
};

export const getBannersWithFilters = (platformId: number, filters: string): AppThunk => async (dispatch, getState) => {
  const banners = (await bannersGetApi(platformId, filters)) || [];

  dispatch(setBanners(banners));
};

export const getTotalAmountOfMerchants = (platformId: number): AppThunk => async (dispatch, getState) => {
  const totalAmountOfMerchants = (await getAmountOfMerchantsApi(platformId)) || 0;

  dispatch(setTotalAmountOfMerchants(totalAmountOfMerchants));
};

export const getMerchant = (slug: string): AppThunk => async (dispatch, getState) => {
  try {
    const api = new MerchantsApi();
    const platformId = getState().settings.platformId;
    const merchant = await api.merchantsSlugMerchantSlugGet(platformId, slug);
    dispatch(setCurrentMerchant(merchant));
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

export const merchantOverviewThunks = {
  getAllFilters,
  getBanners,
  getBannersWithFilters,
  getMerchants,
  onBrandFilterSearch,
  onCategoryFilterSearch,
  getTotalAmountOfMerchants,
  getBrandTopFilters,
  getCategoryTopFilters
};
