import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import { Icon } from "@app/components/icon/icon";
import React from "react";
import { Link } from "react-router-dom";
import styles from "../mobile-nav-bar-component.module.scss";
import ChevronRightIcon from "@assets/icons/chevron-right.svg";

interface IProps {
  subMenuItem?: IMenuItem;
  closeMenu: () => void;
}

export const SubMenuItem = (props: IProps) => {
  const hasChildren = props.subMenuItem?.children && props.subMenuItem?.children.length > 0;

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.stopPropagation();
    props.closeMenu();
  };

  return (
    <div className={styles.subMenuItem}>
      <Link onClick={onClick} to={props.subMenuItem?.url || ""} className={styles.title}>
        {props.subMenuItem?.title}
      </Link>
      <ul className={styles.items}>
        {props.subMenuItem?.children?.map(subMenuItem => (
          <li className={styles.link} key={subMenuItem.title}>
            {subMenuItem?.isExternalLink ? (
              <a onClick={onClick} href={subMenuItem.url || ""}>
                <span>{subMenuItem.title}</span>
                {hasChildren && <Icon icon={ChevronRightIcon} />}
              </a>
            ) : (
              <Link onClick={onClick} to={subMenuItem.url || ""}>
                <span>{subMenuItem.title}</span>
                {hasChildren && <Icon icon={ChevronRightIcon} />}
              </Link>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};
