import { LOCALES } from "@app/constants";

const nlTranslations = require("@app/translations/nl-nl.json");
const benlTranslations = require("@app/translations/be-nl.json");
const enTranslations = require("@app/translations/en-en.json");
const befrTranslations = require("@app/translations/be-fr.json");
const frTranslations = require("@app/translations/fr-fr.json");
const seTranslations = require("@app/translations/sv-se.json");
const esTranslations = require("@app/translations/es-es.json");
const deTranslations = require("@app/translations/de-de.json");
const itTranslations = require("@app/translations/it-it.json");

export const getMessages = (locale: string) => {
  switch (locale) {
    case LOCALES.NL:
      return nlTranslations;
    case LOCALES.BENL:
      return benlTranslations;
    case LOCALES.EN:
      return enTranslations;
    case LOCALES.BEFR:
      return befrTranslations;
    case LOCALES.FR:
      return frTranslations;
    case LOCALES.SE:
      return seTranslations;
    case LOCALES.DE:
      return deTranslations;
    case LOCALES.ES:
      return esTranslations;
    case LOCALES.IT:
      return itTranslations;
    default:
      return nlTranslations;
  }
};
