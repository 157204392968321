import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { BannerComponent } from "@app/core/banner/banner.component";
import { OutBannerDTO } from "@app/bf-api";
import { getBannersFromLocalStorage, IExtendedBannerDTO, saveBannerSeenInLocalStorage } from "@app/util";
import { BannerModule } from "@app/api/modules/banner/banner.module";
import { merchantsState as rdxMerchantsState } from "@app/redux/reducers/merchants";
import { settingsState as rdxSettingsState } from "@app/redux/reducers/settings";
import { getBannersWithFilters } from "@app/redux/thunks/merchant-overview.thunk";

export interface IBannerWrapperProps {
  bannerModule: BannerModule;
}

const component = (props: IBannerWrapperProps & RouteComponentProps) => {
  const dispatch = useDispatch();

  const merchantsState = useSelector(rdxMerchantsState);
  const { banners } = merchantsState;

  const settingsState = useSelector(rdxSettingsState);
  const { platformId } = settingsState;

  const [banner, setBanner] = useState<OutBannerDTO | undefined>(banners[0]);
  const { filters, showAlternativeBanner = false, variant } = props.bannerModule;

  useEffect(() => {
    dispatch(getBannersWithFilters(platformId, filters));
  }, []);

  useEffect(() => {
    if (banners.length === 1) {
      setBanner(banners[0]);
    } else if (banners.length > 1) {
      const extendedBanners: IExtendedBannerDTO[] = getBannersFromLocalStorage(banners);

      setBanner(extendedBanners[0]);
      saveBannerSeenInLocalStorage(extendedBanners[0].id);
    }
  }, [banners, props.location]);

  if (!banner) {
    return <React.Fragment />;
  }

  return <BannerComponent showAlternativeBanner={showAlternativeBanner} banner={banner} variant={variant} />;
};

const BannerWrapper = withRouter(component);
export { BannerWrapper };
