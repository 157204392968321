import { useAppSelector } from "@app/redux/store";

export const isOverviewPageHook = () => {
  const modules = useAppSelector(state => state.pages.currentPage?.wordPressPostModules);

  if (modules) {
    const storeOverview = modules.find(module => module.name === "StoreOverviewModule");
    const dealOverview = modules.find(module => module.name === "DealOverviewModule");

    if (storeOverview == null && dealOverview == null) {
      return false;
    }

    return true;
  }

  return false;
};
