import { FilterItem } from "@app/api/core/filter/filter-item";
import { OutBannerDTO } from "@app/bf-api";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card/deal-card.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IDealResult {
  deals: IOutFeaturedDealDTO[];
  total: number;
}
export interface IDealsState {
  banners: OutBannerDTO[];
  brandFilterItems: FilterItem[];
  categoryFilterItems: FilterItem[];
  deals: IOutFeaturedDealDTO[];
  sponsoredDeals: IOutFeaturedDealDTO[];
  merchantFilterItems: FilterItem[];
  orderByValues?: [string, string];
  sortBy: string;
  totalAmountOfDeals: number;
  totalCurrentDeals?: number;
  totalResults: number;
}

const initialState: IDealsState = {
  banners: [],
  brandFilterItems: [],
  categoryFilterItems: [],
  merchantFilterItems: [],
  deals: [],
  sponsoredDeals: [],
  orderByValues: undefined,
  sortBy: "",
  totalAmountOfDeals: 0,
  totalCurrentDeals: 0,
  totalResults: 0
};

const clearFilters = (filterItems: FilterItem[]) => filterItems.map(item => ({ ...item, isSelected: false }));

export const slice = createSlice({
  name: "dealOverview",
  initialState,
  reducers: {
    setBrandFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.brandFilterItems = [...action.payload];
    },
    setCategoryFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.categoryFilterItems = [...action.payload];
    },
    setMerchantFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.merchantFilterItems = [...action.payload];
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    clearAllFilters: state => {
      state.brandFilterItems = clearFilters(state.brandFilterItems);
      state.categoryFilterItems = clearFilters(state.categoryFilterItems);
      state.merchantFilterItems = clearFilters(state.merchantFilterItems);
    },
    setDeals: (state, action: PayloadAction<IDealResult>) => {
      state.deals = [...(action.payload.deals || [])];
      state.totalResults = action.payload.total;
    },
    setSponsoredDeals: (state, action: PayloadAction<IDealResult>) => {
      const newDeals = [...state.deals];

      // tslint:disable-next-line
      for (let i = 0; i < action.payload.deals.length; i++) {
        const deal = action.payload.deals[i];
        if (deal.sponsoredPosition) {
          newDeals.splice(deal.sponsoredPosition - 1, 0, deal);
        }
      }

      state.deals = newDeals;
      state.sponsoredDeals = [...action.payload.deals];
    },
    updateDeals: (state, action: PayloadAction<IDealResult>) => {
      state.deals = [...state.deals, ...action.payload.deals];
      state.totalResults = action.payload.total;
    },
    setBanners: (state, action: PayloadAction<OutBannerDTO[]>) => {
      state.banners = [...action.payload];
    },
    setTotalAmountOfDeals: (state, action: PayloadAction<number>) => {
      state.totalAmountOfDeals = action.payload;
    },
    setCurrentTotalDeals: (state, action: PayloadAction<number>) => {
      state.totalCurrentDeals = action.payload;
    },
    setOrderByValues: (state, action: PayloadAction<[string, string]>) => {
      state.orderByValues = action.payload;
    }
  }
});

export const dealOverviewState = (state: RootState) => state.dealOverview;

export const {
  clearAllFilters,
  setBanners,
  setBrandFilters,
  setCategoryFilters,
  setCurrentTotalDeals,
  setDeals,
  setMerchantFilters,
  setOrderByValues,
  setSortBy,
  setSponsoredDeals,
  setTotalAmountOfDeals,
  updateDeals
} = slice.actions;

export default slice.reducer;
