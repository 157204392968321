import { RouteComponentProps } from "react-router-dom";
import { useSSRSafeEffect } from "./use-isomorphic-layout-effect";

const useScrollToTop = (history?: RouteComponentProps["history"]) => {
  useSSRSafeEffect(() => {
    if (history) {
      scrollToTop();
    }
  }, [history?.location.pathname]);

  // tslint:disable-next-line:ban-ts-ignore
  // @ts-ignore for some reason behavior is not recognized
  const scrollToTop = () => document.documentElement.scrollTo({ top: 0, left: 0, behavior: "instant" });

  return {
    scrollToTop
  };
};

export { useScrollToTop };
