import { CampaignDurationStatusDTO } from "@app/bf-api";
import { IntlShape } from "react-intl";
import { getStatusColor, statusColorOptions } from "./get-status-bar-color";
import { getStoreStatusText } from "./merchant-time-left";

export const getCampaignStatus = (
  intl: IntlShape,
  durationStatus?: CampaignDurationStatusDTO
): { title?: string; color?: statusColorOptions; showStatus: boolean } => ({
  title: getStoreStatusText(intl, durationStatus?.name, durationStatus?.startInAmountOfDays),
  color: getStatusColor(durationStatus?.name),
  showStatus: durationStatus?.name === "ONGOING" || durationStatus?.name === "ABOUT_TO_EXPIRE"
});
