/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface InImageDuplicateDTO
 */
export interface InImageDuplicateDTO {
  /**
   *
   * @type {string}
   * @memberof InImageDuplicateDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InImageDuplicateDTO
   */
  alt?: string;
  /**
   *
   * @type {string}
   * @memberof InImageDuplicateDTO
   */
  url: string;
}

export function InImageDuplicateDTOFromJSON(json: any): InImageDuplicateDTO {
  return InImageDuplicateDTOFromJSONTyped(json, false);
}

export function InImageDuplicateDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InImageDuplicateDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    title: !exists(json, "title") ? undefined : json["title"],
    alt: !exists(json, "alt") ? undefined : json["alt"],
    url: json["url"]
  };
}

export function InImageDuplicateDTOToJSON(value?: InImageDuplicateDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    title: value.title,
    alt: value.alt,
    url: value.url
  };
}
