import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import bfGermanyLogo from "@assets/logos/bf-de.svg";
import FavIconGermany from "@assets/favicons/favicon-bf-de.png";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const germanyPlatform: IPlatformSpecificInfo = {
  id: 10,
  currency: "EUR",
  name: "Black Friday Expert",
  country: "Deutschland",
  domain: "blackfriday.expert",
  merchantOverviewLink: "/shops/",
  dealOverviewLink: "/deals/",
  urlAddition: "de",
  isLandingPage: false,
  defaultBackButtonText: "Zurück",
  breadcrumbHomeName: "Home",
  breadcrumbHomeUrl: "/de/",
  readingTimeText: {
    singular: "Minute Lesezeit",
    plural: "Minuten Lesezeit"
  },
  newsletterButtonTitle: "Registrieren",
  newsletterModalTitle: "Melden Sie sich für unseren Newsletter an",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114414/default-blog-image-nl.jpg",
  bottomFooter: {
    items: [
      {
        title: "Kontakt",
        url: "/de/kontakt/"
      },
      {
        title: "Privacy",
        url: "/de/privacy/"
      },
      {
        title: "Cookies",
        url: "/de/cookies/"
      },
      {
        title: "Sitemap",
        url: "/de/sitemap/"
      }
    ],
    logo: {
      src: bfGermanyLogo,
      alt: "Black Friday Expert Deutschland logo",
      title: "Black Friday Expert Deutschland logo"
    },
    logoLink: "/de/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Mehr über Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Am häufigsten gesehen",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Shops mit den besten Deals",
        links: []
      }
    ],
    newsletter: {
      title: "Melden Sie sich für den Newsletter an",
      text: "Und erhalten Sie die besten Angebote",
      buttonText: "Newsletter",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Folge uns",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/BlackFridayExpertDE"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridayexpert_de/"
        },
        {
          link: "mailto:info@blackfridaynederland.nl",
          image: {
            src: mailIcon,
            alt: "Mail Icon",
            title: "Mail Icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Header speichern",
    title: "Seitenkopf speichern"
  },
  logo: {
    src: bfGermanyLogo,
    alt: "Black Friday Expert Deutschland logo",
    title: "Black Friday Expert Deutschland logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/de/shops/",
          title: "Alle Angebote in einer Übersicht"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/de/deals/",
          title: "Erzielen Sie ganz einfach die besten Deals"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/de/wann-ist-black-friday/",
          title: "Verpassen Sie nicht den Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/de/shops/",
      title: "Shops",
      isSelected: true
    },
    {
      href: "/de/deals/",
      title: "Produktangebote"
    }
  ],
  dealTabs: [
    {
      href: "/de/shops/",
      title: "Shops"
    },
    {
      href: "/de/deals/",
      title: "Produktangebote",
      isSelected: true
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.DE_RED,
    "--colour-version-website-RGB": WEBSITE_COLORS.DE_RED_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.DE_RED_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.DE_RED_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.DE_RED_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.DE_RED_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.DE_LIGHT_ACCENT
  },
  locale: "de-DE",
  tagManagerScriptLocation: "/static/googletagmanager-de-de.js",
  weCanTrackScriptLocation: "/static/wecantrack-de-de.js",
  favIcon: FavIconGermany,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-de.png",
  gtm: "GTM-MQ3GX4B"
};
