import React from "react";
import { hydrate } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";

import { IntlProviderWrapper } from "@app/translations/intl-provider.container";
import { ColorSetter } from "@app/core/color-setter/color-setter";
import { TooltipProvider } from "@app/api/modules/tooltip-provider/tooltip-provider";

import "./index.scss"; // This must be imported before the Routes
import { Routes } from "@app/routes";

import { getStoreWithPreloadedState } from "../shared/redux/store";

// Create/use the store
// history MUST be passed here if you want syncing between server on initial route
const store = getStoreWithPreloadedState(window.__PRELOADED_STATE__);

hydrate(
  <Provider store={store}>
    <Router>
      <IntlProviderWrapper>
        <ColorSetter />
        <TooltipProvider />
        <React.Fragment>
          <Routes />
        </React.Fragment>
      </IntlProviderWrapper>
    </Router>
  </Provider>,
  document.getElementById("app")
);

if (process.env.NODE_ENV === "development") {
  if (module.hot) {
    module.hot.accept();
  }

  if (!window.store) {
    window.store = store;
  }
}
