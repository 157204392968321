/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FilterLabelDTO
 */
export interface FilterLabelDTO {
  /**
   *
   * @type {number}
   * @memberof FilterLabelDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FilterLabelDTO
   */
  colorHex: string;
  /**
   *
   * @type {string}
   * @memberof FilterLabelDTO
   */
  icon: string;
  /**
   *
   * @type {boolean}
   * @memberof FilterLabelDTO
   */
  showText: boolean;
}

export function FilterLabelDTOFromJSON(json: any): FilterLabelDTO {
  return FilterLabelDTOFromJSONTyped(json, false);
}

export function FilterLabelDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterLabelDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    colorHex: json["colorHex"],
    icon: json["icon"],
    showText: json["showText"]
  };
}

export function FilterLabelDTOToJSON(value?: FilterLabelDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    colorHex: value.colorHex,
    icon: value.icon,
    showText: value.showText
  };
}
