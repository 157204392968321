import * as express from "express";
import { IWPSidebarComponent } from "./sidebar-component.interface";
import { ISidebarComponentProps } from "@app/core/sidebar";
import { getSidebarModuleInfo } from "./get-sidebar-component-info";
import { desktopSpacing, mobileSpacing } from "@app/api/modules/wordpress-module/wordpress-module";
import { BannerModule } from "@app/api/modules/banner/banner.module";
import Logger from "@app/util/logger";

export interface ISidebarComponentMapper {
  platformId: number;
  sidebarData?: IWPSidebarComponent;
  res?: express.Response;
}

export const sidebarComponentMapper = async (
  props: ISidebarComponentMapper
): Promise<ISidebarComponentProps | undefined> => {
  try {
    const { platformId, sidebarData, res } = props;
    if (!sidebarData || !sidebarData.showSidebar) return undefined;

    const { bannerModuleFilters, featuredMerchants, featuredDeals } = await getSidebarModuleInfo(
      platformId,
      sidebarData,
      res
    );

    const bannerModule = mapBannerModule(bannerModuleFilters);

    if (!featuredMerchants && !featuredDeals && !bannerModule) return undefined;

    return {
      ...(bannerModule && { bannerModule }),
      ...(featuredMerchants && {
        featuredMerchantsModule: {
          title: sidebarData.featuredStores?.title || "",
          featuredItems: featuredMerchants
        }
      }),
      ...(featuredDeals && {
        featuredDealsModule: {
          title: sidebarData.featuredDeals?.title || "",
          featuredItems: featuredDeals
        }
      })
    };
  } catch (e) {
    Logger.logError(e, "Error in: sidebarComponentMapper");
    return undefined;
  }
};

const mapBannerModule = (bannerModuleFilters?: string): BannerModule | undefined => {
  if (typeof bannerModuleFilters === "undefined") return undefined;

  return {
    background: { backgroundColour: "#f7f7f7" },
    bottomPadding: { desktopSpacing: desktopSpacing.small, mobileSpacing: mobileSpacing.medium },
    id: "7",
    name: "BannerModule",
    filters: bannerModuleFilters,
    showAlternativeBanner: false,
    variant: "small"
  };
};
