import * as React from "react";
import styles from "./new-see-more-card-component.module.scss";
import { LinkComponent } from "../link";
import { getArrow } from "@app/constants/icons";
import { ClickableComponent } from "../clickable";

export interface ISeeMoreCardComponentProps {
  CTAButtonLink: string;
  CTAButtonText: string;
  UnderCTAButtonLink?: string;
  UnderCTAButtonText?: string;
  onClick?: () => void;
  title: string;
  variant?: string;
}

const NewSeeMoreCardComponent = (props: ISeeMoreCardComponentProps) => {
  const { title, CTAButtonLink, CTAButtonText, UnderCTAButtonLink, UnderCTAButtonText, variant } = props;

  return (
    <div className={`${styles["see-more-card"]} ${styles[`see-more-card--${variant ? variant : "default"}`]}`}>
      <div className={styles["see-more-card__content"]}>
        <div className={styles["see-more-card__title"]}>
          <h4>{title}</h4>
        </div>
      </div>

      <div className={styles["see-more-card__button"]}>
        <ClickableComponent
          fullWidth
          size={44}
          variant="primary-new-colored-simple"
          iconStyle="filled"
          buttonType="submit"
          title={CTAButtonText}
          href={CTAButtonLink}
        />
      </div>

      <div
        className={styles["see-more-card__CTA-under-text"]}
        onClick={() => (props.onClick ? props.onClick() : null)}
        role="button"
      >
        {UnderCTAButtonText && (
          <LinkComponent to={UnderCTAButtonLink} icon={getArrow(false)} animated variant="primary-new-colored-simple">
            {UnderCTAButtonText}
          </LinkComponent>
        )}
      </div>
    </div>
  );
};

export { NewSeeMoreCardComponent };
