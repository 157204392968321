import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { CtaSmallModule } from "@app/api/modules/cta-small/cta-small.module";
import { IWPCtaSmallModuleFull } from "./cta-small-module.interface";
import { CtaNewsletterModule } from "@app/api/modules/cta-newsletter/cta-newsletter.module";
import sendIcon from "@assets/icons/send.svg";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { formatMessage } from "@app/translations/intl";

export interface ICtaSmallModuleMapper extends IModuleFetcher {
  module: IWPCtaSmallModuleFull;
}

export const ctaSmallModuleMapper = async (
  props: ICtaSmallModuleMapper
): Promise<CtaSmallModule | CtaNewsletterModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in ctaSmallModuleMapper");

    const mainInfo = {
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      href: data.ctaLink?.url,
      buttonTitle: data.ctaLink?.title || formatMessage({ id: "default.newsletter.button.title" }),
      icon: data.icon?.url || sendIcon,
      ctaText: data.ctaText
    };

    if (data.newsletterModal) {
      return {
        id: "6",
        name: "CtaNewsletterModule",
        modalTitle: formatMessage({ id: "default.newsletter.modal.title" }),
        ...mainInfo
      };
    }

    return {
      id: "16",
      name: "CtaSmallModule",
      ...mainInfo
    };
  } catch (e) {
    Logger.logError(e, "Error in: ctaSmallModuleMapper");

    return undefined;
  }
};
