import * as express from "express";
import { IWPBannerModuleFull, IWPBannerModule } from "./banner-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { BannerModule } from "@app/api/modules/banner/banner.module";
import { combineFilters, getFilterIdsFromArray } from "@app/api/wp-page-fetcher/utils/combine-filters";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { BRAND_BANNER, CATEGORY_BANNER } from "@app/constants/banner";
import { Console } from "@sentry/node/dist/integrations";

export interface IBannerModuleMapper extends IModuleFetcher {
  module: IWPBannerModuleFull;
}

export const bannerModuleMapper = async (props: IBannerModuleMapper): Promise<BannerModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in bannerModuleMapper");

    const getBannerFilters = await getBannerFiltersAndSetInStore(data, platformId, res);

    return {
      id: "7",
      name: "BannerModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      filters: getBannerFilters,
      showAlternativeBanner: false,
      variant: "default"
    };
  } catch (e) {
    Logger.logError(e, "Error in: bannerModuleMapper");
    return undefined;
  }
};

const getBannerFiltersAndSetInStore = async (
  data: IWPBannerModule,
  platformId: number,
  res?: express.Response
): Promise<string> => {
  // Combined for banner modules that arent using the category or brand yet.
  let bannerModuleFilters = combineFilters([data.brandSelection, data.categorySelection]);
  if (data.bannerType === BRAND_BANNER) {
    bannerModuleFilters = getFilterIdsFromArray(data.brandSelection);
  }
  if (data.bannerType === CATEGORY_BANNER) {
    bannerModuleFilters = getFilterIdsFromArray(data.categorySelection);
  }
  if (res) {
    // Enable if we want the banners to be set from server. Banners are changed upon usage from Local storage.
    // So setting banners from server side is redundant.
    // await setBannersInStore(res, platformId, bannerModuleFilters);
  }

  return bannerModuleFilters;
};
