import React, { useEffect } from "react";
import styles from "./homepage-header-component.module.scss";
import Slider from "react-slick";
import { CHEVRON_RIGHT, PLAY_BUTTON } from "@app/constants/icons";
import { ArrowPanel, LabelComponent, IconComponent } from "@app/core";
import { getHomePageHeaderSetting } from "./slider-settings";
import { HomepageHeader } from "@app/api/modules/homepage-header/homepage-header.module";
import { ModalComponent } from "@app/core/modal";
import { ClickableComponent } from "@app/core/clickable";
import { getBlackFridayYear } from "@app/util/get-blackfriday-year";
import { HtmlRenderComponent } from "@app/core/html-render";
import { PlatformType } from "@app/constants/platform";
import { useAppSelector } from "@app/redux/store";
import { CountDownComponent } from "@app/core/count-down";
import { formatMessage } from "@app/translations/intl";

export interface IHomePageHeaderComponentProps {
  customNextArrow?: JSX.Element | undefined;
  customPrevArrow?: JSX.Element | undefined;
  homePageModule: HomepageHeader;
}

export const HomePageHeaderComponent = (props: IHomePageHeaderComponentProps) => {
  const { platform } = useAppSelector(state => state.platform);
  const { platformId } = useAppSelector(state => state.settings);

  useEffect(() => {
    if (typeof window === "object") window.scroll(0, 0);
  }, []);

  const settings = getHomePageHeaderSetting(
    1,
    props.customNextArrow,
    props.customPrevArrow,
    styles["homepage-header__slick-slider"]
  );

  const blackFridayYear = getBlackFridayYear();

  return (
    <div className={styles["homepage-header"]}>
      <div className={styles["homepage-header-top"]}>
        <div className="uk-container">
          <div className="uk-grid uk-child-width-1-2@m">
            <div>
              <div className={styles["homepage-header-wrap"]}>
                <LabelComponent onlyMobile={true}>
                  <React.Fragment>
                    {platform?.headerText ? (
                      <span>{platform.headerText}</span>
                    ) : (
                      <CountDownComponent location={"navbar"} prefix={`Black Friday ${blackFridayYear}:`} />
                    )}
                  </React.Fragment>
                </LabelComponent>
                <div className={styles["homepage-header__title"]}>
                  <h1>{props.homePageModule.title}</h1>
                </div>
                <ul className={`uk-list-check ${styles["homepage-header__list"]}`}>
                  {props.homePageModule.description.map((text, index) => (
                    <li key={index}>
                      <HtmlRenderComponent html={text} />
                    </li>
                  ))}
                </ul>
                <div className={styles["homepage-header__cards"]}>
                  {props.homePageModule.arrowPanelslinks.map((panel, key) => (
                    <ArrowPanel
                      key={key}
                      link={{
                        title: panel.link.title,
                        url: panel.link.url
                      }}
                      fallbackImgUrl={panel.fallbackImgUrl}
                      image={{
                        src: panel.image?.src || "",
                        alt: panel.image?.alt || "",
                        title: panel.image?.title || ""
                      }}
                    />
                  ))}
                </div>
              </div>
              <div className={`uk-invert ${styles["homepage-header-info"]}`}>
                <div className={styles["homepage-header-info__col"]}>
                  <div
                    className={
                      platformId === PlatformType.FRENCH
                        ? styles["homepage-header-info__labelFrench"]
                        : styles["homepage-header-info__label"]
                    }
                  >
                    {props.homePageModule.startingDateBlackFridayTitle}
                  </div>
                  <div
                    className={
                      platformId === PlatformType.FRENCH
                        ? styles["homepage-header-info__labelFrench"]
                        : styles["homepage-header-info__label"]
                    }
                  >
                    <h3>{`${formatMessage({
                      id: "homepageHeader.blackFridayDayMonth"
                    })} ${blackFridayYear}`}</h3>
                  </div>
                </div>
                <div className={styles["homepage-header-info__col"]}>
                  <div
                    className={
                      platformId === PlatformType.FRENCH
                        ? styles["homepage-header-info__labelFrench"]
                        : styles["homepage-header-info__label"]
                    }
                  >
                    {props.homePageModule.durationToBlackFridayTitle}
                  </div>
                  <div className={styles["homepage-header-info__item"]}>
                    <h3>
                      <CountDownComponent isFullVersion={true} />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Slider {...settings}>
                {props.homePageModule.slides.map((slide, index) => (
                  <div key={index} className={styles["homepage-header__slide-container"]}>
                    <span className={styles["slider-wrapper"]}>
                      <div
                        style={{ backgroundImage: `url(${slide.image})` }}
                        className={styles["homepage-header__slider-item"]}
                      />
                      {slide.video && (
                        <span className={styles["video-wrapper"]}>
                          <div className={styles["homepage-header__slider-item-video"]}>
                            <ModalComponent
                              isTriggerOnClick
                              variant={"full-screen"}
                              modalBackground={"transparent"}
                              trigger={<IconComponent icon={PLAY_BUTTON} size={"80px"} />}
                            >
                              <iframe
                                width="100%"
                                height="100%"
                                src={slide.video}
                                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                              />
                            </ModalComponent>
                          </div>
                        </span>
                      )}

                      {slide.title && (
                        <div className={styles["homepage-header__slider-control"]}>
                          <div className={styles["homepage-header__slider-title"]}>{slide.title}</div>
                          {slide.link && (
                            <ClickableComponent
                              title={slide.link.title}
                              href={slide.link.url}
                              iconRight={CHEVRON_RIGHT}
                              variant="link-quaternary"
                              iconStyle="outline"
                              iconSize="12px"
                            />
                          )}
                        </div>
                      )}
                    </span>
                  </div>
                ))}
              </Slider>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["homepage-header-bottom"]}>
        <div className={`uk-invert ${styles["homepage-header-bottom__wrap"]}`}>
          <div className="uk-container">
            <div className={styles["all-cards"]}>
              <div className={styles["homepage-header-bottom__cards-container"]}>
                <div className={styles["homepage-header-bottom__label"]}>
                  <h2>{props.homePageModule.popularProductsTitle}</h2>
                </div>
                <div className={styles["homepage-header-bottom__cards"]}>
                  {props.homePageModule.popularProducts.map((product, key) => (
                    <div key={key}>
                      <ArrowPanel
                        key={key}
                        link={{ title: product.link.title, url: product.link.url }}
                        fallbackImgUrl={product.fallbackImgUrl}
                        image={{
                          src: product.image?.src || "",
                          alt: product.image?.alt || "",
                          title: product.image?.title || ""
                        }}
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className={styles["homepage-header-bottom__cards-container"]}>
                <div className={styles["homepage-header-bottom__label"]}>
                  <h2>{props.homePageModule.popularStoresTitle}</h2>
                </div>
                <div className={styles["homepage-header-bottom__cards"]}>
                  {props.homePageModule.popularStores.map((store, key) => (
                    <div key={key}>
                      <ArrowPanel
                        key={key}
                        link={{ title: store.link.title, url: store.link.url }}
                        image={{
                          src: store.image?.src || "",
                          alt: store.image?.alt || "",
                          title: store.image?.title || ""
                        }}
                        fallbackImgUrl={store.fallbackImgUrl}
                      />
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
