/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface FilterTypeDTO
 */
export interface FilterTypeDTO {
  /**
   *
   * @type {number}
   * @memberof FilterTypeDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof FilterTypeDTO
   */
  name: string;
}

export function FilterTypeDTOFromJSON(json: any): FilterTypeDTO {
  return FilterTypeDTOFromJSONTyped(json, false);
}

export function FilterTypeDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): FilterTypeDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"]
  };
}

export function FilterTypeDTOToJSON(value?: FilterTypeDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name
  };
}
