import React from "react";

import IcnPage from "@assets/icons/page-big.svg";
import { ArrowPanel } from "@app/core/arrow-panel";
import { IFeaturedWPPage } from "@app/middleware/api/featured-pages-get.api";

interface IProps {
  page: IFeaturedWPPage;
}

const PageCard = ({ page }: IProps) => {
  return (
    <ArrowPanel
      fallbackImgUrl={IcnPage}
      image={page.icon}
      link={{ title: page.title || "", url: page.permalink || "" }}
    />
  );
};

export { PageCard };
