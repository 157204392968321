/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ImageDTO,
  ImageDTOFromJSON,
  ImageDTOFromJSONTyped,
  ImageDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOFromJSONTyped,
  OutFilterDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutBannerDTO
 */
export interface OutBannerDTO {
  /**
   *
   * @type {PlatformDTO}
   * @memberof OutBannerDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  cta?: string;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  label?: string;
  /**
   *
   * @type {ImageDTO}
   * @memberof OutBannerDTO
   */
  bannerImage?: ImageDTO;
  /**
   *
   * @type {ImageDTO}
   * @memberof OutBannerDTO
   */
  logoImage?: ImageDTO;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  name: string;
  /**
   *
   * @type {number}
   * @memberof OutBannerDTO
   */
  id: number;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof OutBannerDTO
   */
  brands?: Array<OutFilterDTO>;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof OutBannerDTO
   */
  categories?: Array<OutFilterDTO>;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  slug: string;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  status: OutBannerDTOStatusEnum;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof OutBannerDTO
   */
  endDate?: string;
}

export function OutBannerDTOFromJSON(json: any): OutBannerDTO {
  return OutBannerDTOFromJSONTyped(json, false);
}

export function OutBannerDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutBannerDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    platform: PlatformDTOFromJSON(json["platform"]),
    description: !exists(json, "description") ? undefined : json["description"],
    cta: !exists(json, "cta") ? undefined : json["cta"],
    label: !exists(json, "label") ? undefined : json["label"],
    bannerImage: !exists(json, "bannerImage") ? undefined : ImageDTOFromJSON(json["bannerImage"]),
    logoImage: !exists(json, "logoImage") ? undefined : ImageDTOFromJSON(json["logoImage"]),
    url: !exists(json, "url") ? undefined : json["url"],
    name: json["name"],
    id: json["id"],
    brands: !exists(json, "brands") ? undefined : (json["brands"] as Array<any>).map(OutFilterDTOFromJSON),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(OutFilterDTOFromJSON),
    slug: json["slug"],
    status: json["status"],
    startDate: !exists(json, "startDate") ? undefined : json["startDate"],
    endDate: !exists(json, "endDate") ? undefined : json["endDate"]
  };
}

export function OutBannerDTOToJSON(value?: OutBannerDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    platform: PlatformDTOToJSON(value.platform),
    description: value.description,
    cta: value.cta,
    label: value.label,
    bannerImage: ImageDTOToJSON(value.bannerImage),
    logoImage: ImageDTOToJSON(value.logoImage),
    url: value.url,
    name: value.name,
    id: value.id,
    brands: value.brands === undefined ? undefined : (value.brands as Array<any>).map(OutFilterDTOToJSON),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(OutFilterDTOToJSON),
    slug: value.slug,
    status: value.status,
    startDate: value.startDate,
    endDate: value.endDate
  };
}

/**
 * @export
 * @enum {string}
 */
export enum OutBannerDTOStatusEnum {
  VISIBLE = "VISIBLE",
  INVISIBLE = "INVISIBLE",
  ALMOSTVISIBLE = "ALMOST_VISIBLE"
}
