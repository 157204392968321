import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";
import { sanitizeJsonSchema } from "@app/util/sanitize-json-schema";
import { JsonSchemaModule } from "@app/api/modules/json-schema/json-schema.module";

import { IModuleFetcher } from "../../module-selector";
import { IWPJsonSchemaFull } from "./json-schema.interface";

export interface IJsonSchemaMapper extends IModuleFetcher {
  module: IWPJsonSchemaFull;
}

export const jsonSchemaModuleMapper = async (props: IJsonSchemaMapper): Promise<JsonSchemaModule | undefined> => {
  try {
    const {
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in jsonSchemaMapper");

    const jsonSchema = sanitizeJsonSchema(data.jsonSchema);

    return {
      id: "24",
      name: "jsonSchemaModule",
      jsonSchema
    } as JsonSchemaModule;
  } catch (e) {
    Logger.logError(e, "Error in: jsonSchemaModuleMapper");
    return undefined;
  }
};
