/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ImageDTO,
  ImageDTOFromJSON,
  ImageDTOFromJSONTyped,
  ImageDTOToJSON,
  OutCampaignDTO,
  OutCampaignDTOFromJSON,
  OutCampaignDTOFromJSONTyped,
  OutCampaignDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOFromJSONTyped,
  OutFilterDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  SeoDTO,
  SeoDTOFromJSON,
  SeoDTOFromJSONTyped,
  SeoDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutDetailedMerchantDTO
 */
export interface OutDetailedMerchantDTO {
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  id: number;
  /**
   *
   * @type {boolean}
   * @memberof OutDetailedMerchantDTO
   */
  live?: boolean;
  /**
   *
   * @type {PlatformDTO}
   * @memberof OutDetailedMerchantDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  features?: string;
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  buyInPosition?: number;
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  eCPC?: number;
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  approvalRate?: number;
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  score?: number;
  /**
   *
   * @type {number}
   * @memberof OutDetailedMerchantDTO
   */
  minimumDealScoreModifier?: number;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  trackingUrl?: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  adSenseScript?: string;
  /**
   *
   * @type {boolean}
   * @memberof OutDetailedMerchantDTO
   */
  inOverview?: boolean;
  /**
   *
   * @type {ImageDTO}
   * @memberof OutDetailedMerchantDTO
   */
  headerImage?: ImageDTO;
  /**
   *
   * @type {ImageDTO}
   * @memberof OutDetailedMerchantDTO
   */
  logoImage?: ImageDTO;
  /**
   *
   * @type {SeoDTO}
   * @memberof OutDetailedMerchantDTO
   */
  seo?: SeoDTO;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  lastEdit?: string;
  /**
   *
   * @type {boolean}
   * @memberof OutDetailedMerchantDTO
   */
  approved?: boolean;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof OutDetailedMerchantDTO
   */
  slug: string;
  /**
   *
   * @type {Date}
   * @memberof OutDetailedMerchantDTO
   */
  updatedAt?: Date;
  /**
   *
   * @type {Date}
   * @memberof OutDetailedMerchantDTO
   */
  updatedAtByUser?: Date;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof OutDetailedMerchantDTO
   */
  categories?: Array<OutFilterDTO>;
  /**
   *
   * @type {Array<OutCampaignDTO>}
   * @memberof OutDetailedMerchantDTO
   */
  campaigns?: Array<OutCampaignDTO>;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof OutDetailedMerchantDTO
   */
  filters?: Array<OutFilterDTO>;
}

export function OutDetailedMerchantDTOFromJSON(json: any): OutDetailedMerchantDTO {
  return OutDetailedMerchantDTOFromJSONTyped(json, false);
}

export function OutDetailedMerchantDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutDetailedMerchantDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    live: !exists(json, "live") ? undefined : json["live"],
    platform: PlatformDTOFromJSON(json["platform"]),
    features: !exists(json, "features") ? undefined : json["features"],
    buyInPosition: !exists(json, "buyInPosition") ? undefined : json["buyInPosition"],
    eCPC: !exists(json, "eCPC") ? undefined : json["eCPC"],
    approvalRate: !exists(json, "approvalRate") ? undefined : json["approvalRate"],
    score: !exists(json, "score") ? undefined : json["score"],
    minimumDealScoreModifier: !exists(json, "minimumDealScoreModifier") ? undefined : json["minimumDealScoreModifier"],
    url: !exists(json, "url") ? undefined : json["url"],
    trackingUrl: !exists(json, "trackingUrl") ? undefined : json["trackingUrl"],
    adSenseScript: !exists(json, "adSenseScript") ? undefined : json["adSenseScript"],
    inOverview: !exists(json, "inOverview") ? undefined : json["inOverview"],
    headerImage: !exists(json, "headerImage") ? undefined : ImageDTOFromJSON(json["headerImage"]),
    logoImage: !exists(json, "logoImage") ? undefined : ImageDTOFromJSON(json["logoImage"]),
    seo: !exists(json, "seo") ? undefined : SeoDTOFromJSON(json["seo"]),
    lastEdit: !exists(json, "lastEdit") ? undefined : json["lastEdit"],
    approved: !exists(json, "approved") ? undefined : json["approved"],
    name: json["name"],
    slug: json["slug"],
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    updatedAtByUser: !exists(json, "updatedAtByUser") ? undefined : new Date(json["updatedAtByUser"]),
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(OutFilterDTOFromJSON),
    campaigns: !exists(json, "campaigns") ? undefined : (json["campaigns"] as Array<any>).map(OutCampaignDTOFromJSON),
    filters: !exists(json, "filters") ? undefined : (json["filters"] as Array<any>).map(OutFilterDTOFromJSON)
  };
}

export function OutDetailedMerchantDTOToJSON(value?: OutDetailedMerchantDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    live: value.live,
    platform: PlatformDTOToJSON(value.platform),
    features: value.features,
    buyInPosition: value.buyInPosition,
    eCPC: value.eCPC,
    approvalRate: value.approvalRate,
    score: value.score,
    minimumDealScoreModifier: value.minimumDealScoreModifier,
    url: value.url,
    trackingUrl: value.trackingUrl,
    adSenseScript: value.adSenseScript,
    inOverview: value.inOverview,
    headerImage: ImageDTOToJSON(value.headerImage),
    logoImage: ImageDTOToJSON(value.logoImage),
    seo: SeoDTOToJSON(value.seo),
    lastEdit: value.lastEdit,
    approved: value.approved,
    name: value.name,
    slug: value.slug,
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    updatedAtByUser: value.updatedAtByUser === undefined ? undefined : value.updatedAtByUser.toISOString(),
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(OutFilterDTOToJSON),
    campaigns: value.campaigns === undefined ? undefined : (value.campaigns as Array<any>).map(OutCampaignDTOToJSON),
    filters: value.filters === undefined ? undefined : (value.filters as Array<any>).map(OutFilterDTOToJSON)
  };
}
