import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { PageModel } from "@app/api/pagebuilder/page.model";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
export interface IBaseModules {
  footerModule?: WordPressPostModule;
  navBarModule?: WordPressPostModule;
}
export interface IPageState {
  baseModules?: IBaseModules;
  currentPage: PageModel | undefined;
  isLoading?: boolean;
  pages: PageModel[];
}

const initialState: IPageState = {
  baseModules: undefined,
  currentPage: undefined,
  pages: [],
  isLoading: false
};

export const slice = createSlice({
  name: "pages",
  initialState,
  reducers: {
    setPages: (state, action: PayloadAction<PageModel[]>) => {
      state.pages = action.payload;
    },
    setCurrentPage: (state, action: PayloadAction<PageModel | undefined>) => {
      state.currentPage = action.payload;
    },
    addToPages: (state, action: PayloadAction<PageModel | undefined>) => {
      if (action.payload) {
        const newPages = addPageToCurrentPages(state.pages, action.payload);
        state.pages = newPages;
      }
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setModule: (state, action: PayloadAction<WordPressPostModule>) => {
      const foundIndex = state.currentPage?.wordPressPostModules.findIndex(item => item.name === action.payload.name);

      if (foundIndex !== undefined && state.currentPage) {
        const modules = [...state.currentPage.wordPressPostModules];
        modules[foundIndex] = action.payload;
        // tslint:disable-next-line: no-shadowed-variable
        const currentPage: PageModel = { ...state.currentPage, wordPressPostModules: modules };

        state.currentPage = currentPage;
      }
    }
  }
});

export const pagesState = (state: RootState) => state.pages;

export const { addToPages, setCurrentPage, setIsLoading, setModule, setPages } = slice.actions;

export default slice.reducer;

export const addPageToCurrentPages = (pages: PageModel[], page: PageModel): PageModel[] => {
  const newPages = [...pages];
  newPages.map(item => (item.id === page.id ? page : item));
  newPages.push(page);

  return [...Array.from(new Set(newPages))];
};
