import { BannersApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const bannersGetApi = async (platformId: number, filters?: string) => {
  try {
    const api = new BannersApi();
    const resultFromApi = await api.bannersGet(platformId, true, filters || undefined);

    if (!resultFromApi) return moduleMapperThrowMessage("Cannot get banners");

    return resultFromApi;
  } catch (e) {
    Logger.logError(e, "Error in: bannersGetApi");

    return undefined;
  }
};
