import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { OutProductDTO } from "@app/bf-api";
import { SimpleProductCard } from "@app/components/product-cards/simple-product-card/simple-product-card";
import { ClickableComponent } from "@app/core/clickable";
import IconChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { formatMessage } from "@app/translations/intl";

import styles from "../search-results.module.scss";

interface IProps {
  headerText: string;
  products: OutProductDTO[];
  count?: number;
  closeSearch: () => void;
}

const component = (props: IProps & RouteComponentProps) => {
  const handleClick = () => {
    props.closeSearch();
  };

  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.header}>
        {props.headerText} {(props.count || 0) > 0 ? `(${props.count})` : ""}
      </div>

      <div className={styles.pagePanel}>
        {props.products?.map((product, index) => (
          <div key={index}>
            <SimpleProductCard key={index} product={product} anchorProps={{ onClick: handleClick }} />
          </div>
        ))}
      </div>
      <div className={styles.button} onClick={handleClick}>
        <ClickableComponent
          title={formatMessage({ id: "navbar.search.result.button.products" })}
          iconStyle="filled"
          iconRight={IconChevronRight}
          iconFillColor="white"
          iconSize="14px"
          variant="primary-new-colored-simple"
          href={formatMessage({ id: "global.productOverviewLink" })}
        />
      </div>
    </div>
  );
};

export const NavBarResultProducts = withRouter(component);
