import * as React from "react";
import clsx from "clsx";

import arrowLongRightIcon from "@assets/icons/arrow-long-right.svg";
import { getTotalAmountOfMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { useDispatch, useSelector } from "react-redux";
import { merchantsState } from "@app/redux/reducers/merchants";
import { settingsState, ViewType } from "@app/redux/reducers/settings";
import { IWPMerchantOverviewSwitchButtons } from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { dealOverviewState } from "@app/redux/reducers/deals";
import { getTotalAmountOfDeals } from "@app/redux/thunks/deal-overview.thunk";
import { CountLabelComponent } from "@app/core/count-label/count-label.component";
import { ClickableComponent } from "@app/core/clickable";

import styles from "./new-tab-v2-component.module.scss";

export interface ITabLinkV2 {
  href: string;
  isSelected?: boolean;
  title: string;
  mobileTitle: string;
}

export interface ITabComponentV2Props {
  tabs: ITabLinkV2[];
  switchButtons: IWPMerchantOverviewSwitchButtons;
}

const NewTabComponentV2 = (props: ITabComponentV2Props) => {
  const dispatch = useDispatch();
  const settings = useSelector(settingsState);
  const { platformId, screenSize } = settings;
  const merchants = useSelector(merchantsState);
  const deals = useSelector(dealOverviewState);
  const { totalAmountOfMerchants } = merchants;
  const { totalAmountOfDeals } = deals;

  React.useEffect(() => {
    if (!totalAmountOfMerchants) {
      dispatch(getTotalAmountOfMerchants(platformId));
    }
    if (!totalAmountOfDeals) {
      dispatch(getTotalAmountOfDeals(platformId));
    }
  }, []);

  return (
    <div className={clsx(styles["tab-container"], "uk-container")}>
      <div className={styles["background"]} />
      {props.tabs.map((tab, key) => (
        <span className={styles["list"]} key={key}>
          {key === 0 ? (
            <ClickableComponent
              title={
                screenSize && screenSize.viewType > ViewType.Desktop
                  ? props.switchButtons.merchantsButtonText || tab.title
                  : tab.mobileTitle
              }
              href={tab.href}
              buttonType="button"
              variant={tab.isSelected ? "primary-new-overview-inverted" : "primary-new-overview"}
              iconLeft={props.switchButtons.merchantsOverviewMerchantsButtonArrow && arrowLongRightIcon}
              iconSize="32px"
              amountLabel={
                props.switchButtons.merchantsOverviewMerchantsButtonCounter ? (
                  <CountLabelComponent amount={totalAmountOfMerchants} />
                ) : undefined
              }
              fullWidth
              size={48}
            />
          ) : (
            <ClickableComponent
              title={
                screenSize && screenSize.viewType > ViewType.Desktop
                  ? props.switchButtons.dealsButtonText || tab.title
                  : tab.mobileTitle
              }
              href={tab.href}
              buttonType="button"
              iconLeft={props.switchButtons.merchantsOverviewDealsButtonArrow && arrowLongRightIcon}
              iconSize="32px"
              amountLabel={
                props.switchButtons.merchantsOverviewDealsButtonCounter ? (
                  <CountLabelComponent amount={totalAmountOfDeals} />
                ) : undefined
              }
              variant={tab.isSelected ? "primary-new-overview-inverted" : "primary-new-overview"}
              fullWidth
              size={48}
            />
          )}
        </span>
      ))}
    </div>
  );
};
export { NewTabComponentV2 };
