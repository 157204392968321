import * as express from "express";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import EmptyStateIcon from "@assets/icons/sign.svg";
import { FIRST_DEAL_TAKE } from "@app/constants/overviews-numbers";
import { DealOverviewModule, IOverviewMessages } from "@app/api/modules/deal-overview/deal-overview.module";
import { getDealsApi } from "@app/middleware/api/deals-get.api";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { IDealResult, setDeals, setSponsoredDeals } from "@app/redux/reducers/deals";
import { formatMessage } from "@app/translations/intl";

import {
  IWPDealOverviewModuleFull,
  IWPDealOverviewMessages,
  IWPDealOverviewSwitchButtons
} from "./deal-overview-module.interface";
import { mapDealsToFeaturedDeals } from "../featured-deals-module";
import { getPlatformInformation } from "@app/util/api/get-platform-info";

export interface IDealOverviewMapper extends IModuleFetcher {
  module: IWPDealOverviewModuleFull;
}

export const dealOverviewModuleMapper = async (props: IDealOverviewMapper): Promise<DealOverviewModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in dealOverviewModuleMapper");

    if (res) {
      await setDealsInPreloadedState(platformId, res);
    }

    const platform = await getPlatformInformation(props.platformId);
    const overviewMessages = mapDealOverviewMessages(data.messagesRegardingResultsDeals);
    const suffix = pageUrl.split(formatMessage({ id: "global.dealTabLink" }))[0];

    return {
      id: "1",
      name: "DealOverviewModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      emptyStateIcon: EmptyStateIcon,
      switchButtons: getSwitchButtons(data.dealOverviewSwitchButtons),
      tabs: [
        {
          href: `${suffix}${formatMessage({ id: "global.merchantTabLink" })}`,
          title: formatMessage({ id: "global.merchantOverviewLink.name" }),
          mobileTitle: formatMessage({ id: "global.merchantOverviewLink.mobileName" })
        },
        {
          href: `${suffix}${formatMessage({ id: "global.dealTabLink" })}`,
          title: formatMessage({ id: "global.dealOverviewLink.name" }),
          mobileTitle: formatMessage({ id: "global.dealOverviewLink.mobileName" }),
          isSelected: true
        }
      ],
      platform,
      overviewMessages
    };
  } catch (e) {
    Logger.logError(e, "Error in: dealOverviewModuleMapper");

    return undefined;
  }
};

const getSwitchButtons = (switchButtons?: IWPDealOverviewSwitchButtons): IWPDealOverviewSwitchButtons => ({
  merchantsButtonText: "",
  dealsButtonText: "",
  merchantsOverviewMerchantsButtonArrow: "",
  merchantsOverviewMerchantsButtonCounter: "",
  merchantsOverviewDealsButtonArrow: "",
  merchantsOverviewDealsButtonCounter: "",
  ...switchButtons
});

const mapDealOverviewMessages = (messages: IWPDealOverviewMessages): IOverviewMessages => ({
  emptyResults: {
    isActive: messages.activateMessageNoDealsActivated,
    message: messages.messageNoDealsActivated
  },
  noResultsWithFilter: {
    text: messages.messageNoFilterResultsDeals,
    buttonText: messages.buttonTextNoFilterResultsDeals
  },
  onTop: { isActive: messages.activateMessageAboveDeals, message: messages.messageAboveDeals }
});

const setDealsInPreloadedState = async (platformId: number, res?: express.Response): Promise<void> => {
  const result = await getDealsApi(FIRST_DEAL_TAKE, 0, platformId);

  const mappedResult: IDealResult = {
    deals: mapDealsToFeaturedDeals(result?.deals || []),
    total: result?.total || 0
  };

  if (result?.deals && res) {
    res.locals.store.dispatch(setDeals(mappedResult));
  }

  const sponsoredDealsFromApi = await getDealsApi(
    FIRST_DEAL_TAKE,
    0,
    platformId,
    undefined,
    undefined,
    undefined,
    undefined,
    true
  );

  const sponsoredResult: IDealResult = {
    deals: mapDealsToFeaturedDeals(sponsoredDealsFromApi?.deals || []),
    total: sponsoredDealsFromApi?.total || 0
  };

  if (sponsoredResult?.deals && res) {
    res.locals.store.dispatch(setSponsoredDeals(sponsoredResult));
  }
};
