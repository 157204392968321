import { MerchantsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getAmountOfMerchantsApi = async (platformId: number): Promise<number | undefined> => {
  try {
    const api = new MerchantsApi();
    const resultFromApi = await api.merchantsCountGet(platformId);

    if (!resultFromApi && resultFromApi !== 0) return moduleMapperThrowMessage("Cannot get amountOfMerchants");

    return resultFromApi;
  } catch (e) {
    Logger.logError(e, "Error in: getAmountOfMerchantsApi");

    return undefined;
  }
};
