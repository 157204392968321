import * as React from "react";
import styles from "./blog-single-header-component.module.scss";
import { BlogSingleHeaderModule } from "@app/api/modules/blog-single-header/blog-single-header";
import { ImageComponent } from "@app/core/image";
import { HeaderContentComponent } from "@app/core/header-content";
import clsx from "clsx";

export interface IBlogSingleHeaderComponentProps {
  blogSingleHeader: BlogSingleHeaderModule;
}

const BlogSingleHeaderComponent = (props: IBlogSingleHeaderComponentProps) => {
  const { breadcrumbProps, title, image } = props.blogSingleHeader;

  return (
    <div className={clsx(styles["blog-single-header"], image?.src && styles["has-image"])}>
      <div className={styles["header-content"]}>
        <div className="uk-container">
          <HeaderContentComponent breadcrumbProps={breadcrumbProps} title={title} headerMobilePosition="left" />
        </div>
      </div>
      <div className="uk-container">
        {image?.src && (
          <div className={styles["image"]}>
            <ImageComponent src={image.src} alt={image.alt} title={image.title} isBlocking />
          </div>
        )}
      </div>
    </div>
  );
};

export { BlogSingleHeaderComponent };
