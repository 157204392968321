/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import { PlatformDTO, PlatformDTOFromJSON, PlatformDTOToJSON } from "../models";

export interface PlatformsGetRequest {
  platformId: number;
}

export interface PlatformsPlatformIdPutRequest {
  platformId: number;
  platformDTO: PlatformDTO;
}

/**
 *
 */
export class PlatformApi extends runtime.BaseAPI {
  /**
   */
  async platformsGetRaw(requestParameters: PlatformsGetRequest): Promise<runtime.ApiResponse<PlatformDTO>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling platformsGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/platforms`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => PlatformDTOFromJSON(jsonValue));
  }

  /**
   */
  async platformsGet(platformId: number): Promise<PlatformDTO> {
    const response = await this.platformsGetRaw({ platformId: platformId });
    return await response.value();
  }

  /**
   */
  async platformsPlatformIdPutRaw(
    requestParameters: PlatformsPlatformIdPutRequest
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling platformsPlatformIdPut."
      );
    }

    if (requestParameters.platformDTO === null || requestParameters.platformDTO === undefined) {
      throw new runtime.RequiredError(
        "platformDTO",
        "Required parameter requestParameters.platformDTO was null or undefined when calling platformsPlatformIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/platforms/{platformId}`.replace(
        `{${"platformId"}}`,
        encodeURIComponent(String(requestParameters.platformId))
      ),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: PlatformDTOToJSON(requestParameters.platformDTO)
    });

    return new runtime.VoidApiResponse(response);
  }

  /**
   */
  async platformsPlatformIdPut(platformId: number, platformDTO: PlatformDTO): Promise<void> {
    await this.platformsPlatformIdPutRaw({ platformId: platformId, platformDTO: platformDTO });
  }
}
