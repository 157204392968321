import { Background } from "@app/api/core/background";
import { Curve } from "@app/api/core/curve";
import { Spacing } from "@app/api/core/padding";

export class IModuleSpacing {
  public bottomMargin?: Spacing;
  public bottomPadding?: Spacing;
  public topMargin?: Spacing;
  public topPadding?: Spacing;
}

export class WordPressPostModule extends IModuleSpacing {
  public background?: Background;
  public curve?: Curve;
  public id: string;
  public name: string;
  public status?: 200 | 503 | 302;
}

export const mobileSpacing = {
  large: 48,
  medium: 32,
  small: 24,
  xLarge: 64
};

export const desktopSpacing = {
  large: 96,
  medium: 72,
  small: 56,
  xLarge: 120
};
