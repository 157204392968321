import { base64encode } from "./base64";

interface IGetOptimizedImageUrlParams {
  url?: string;
  width?: number | string;
  height?: number | string;
  quality?: number;
}

export const getOptimizedImageUrl = (params: IGetOptimizedImageUrlParams) => {
  const { url, width, height } = params;

  if (url == null) {
    return url;
  }

  try {
    const cloudfrontUrl = "https://d1le7wxg1rulhk.cloudfront.net";
    const options = getOptions(url, width, height);
    const encodedOptions = base64encode(JSON.stringify(options));
    const finalUrl = `${cloudfrontUrl}/${encodedOptions}`;

    return finalUrl;
  } catch (error) {
    return url;
  }
};

const getBucket = (url?: string) => {
  if (url?.startsWith("https://static.maddoxmedia.eu")) {
    return "blackfriday-public-images";
  }

  if (url?.startsWith("https://media.blackfridaynederland.nl")) {
    return "blackfriday-media-bucket";
  }

  return undefined;
};

const getBucketKey = (url?: string) => {
  if (url) {
    try {
      const urlObject = new URL(url);
      const path = urlObject.pathname;
      const decodedPath = decodeURI(path);

      return decodedPath.substring(1);
    } catch (error) {
      return undefined;
    }
  }

  return undefined;
};

interface IOptions {
  bucket?: string;
  key?: string;
  edits?: {
    resize?: {
      width?: number;
      height?: number;
    };
    webp?: {
      quality: number;
    };
    jpeg?: {
      quality: number;
    };
    png?: {
      quality: number;
    };
  };
}

const DEFAULT_IMAGE_QUALITY = 80;

// Image options more can be found at https://sharp.pixelplumbing.com/
const getOptions = (
  url?: string,
  width?: number | string,
  height?: number | string,
  quality: number = DEFAULT_IMAGE_QUALITY
) => {
  const options: IOptions = {
    bucket: getBucket(url),
    key: getBucketKey(url),
    edits: {
      resize: { width: Number(width), height: Number(height) },
      webp: {
        quality
      }
    }
  };

  if (width == null && height == null) {
    delete options.edits?.resize;
  }

  if (width == null) {
    delete options.edits?.resize?.width;
  }

  if (height == null) {
    delete options.edits?.resize?.height;
  }

  return options;
};
