/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface CampaignStatusDTO
 */
export interface CampaignStatusDTO {
  /**
   *
   * @type {string}
   * @memberof CampaignStatusDTO
   */
  email: string;
  /**
   *
   * @type {string}
   * @memberof CampaignStatusDTO
   */
  firstName: string;
  /**
   *
   * @type {string}
   * @memberof CampaignStatusDTO
   */
  lastName: string;
}

export function CampaignStatusDTOFromJSON(json: any): CampaignStatusDTO {
  return CampaignStatusDTOFromJSONTyped(json, false);
}

export function CampaignStatusDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): CampaignStatusDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    email: json["email"],
    firstName: json["firstName"],
    lastName: json["lastName"]
  };
}

export function CampaignStatusDTOToJSON(value?: CampaignStatusDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName
  };
}
