import { FeaturedBlogModule } from "@app/api/modules/featured-blog/featured-blog";
import { getFeaturedBlogs } from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { setModule } from "../reducers/page";
import { AppThunk } from "../store";

export const getFeaturedBlogsExtraInformation = (page: string): AppThunk => async (dispatch, getState) => {
  const module: FeaturedBlogModule | undefined = getState().pages.currentPage?.wordPressPostModules.find(
    item => item.name === "FeaturedBlogModule"
  ) as FeaturedBlogModule;

  if (!module) {
    return;
  }

  const platformId = getState().settings.platformId;

  const result = await getFeaturedBlogs(platformId, page, true, module.amountOfItems, module.selectBlogs);

  if (result !== 404 && result !== 503) {
    const newModule: FeaturedBlogModule = {
      ...module,
      items: result
    };

    dispatch(setModule(newModule));
  }
};

export const moduleThunks = {
  getFeaturedBlogsExtraInformation
};
