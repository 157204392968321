import * as React from "react";
import { Route } from "react-router-dom";
import { PagebuilderContainer } from "./core/pagebuilder";

const Routes: React.FunctionComponent = () => (
  <React.Fragment>
    <Route path="*" component={PagebuilderContainer} />
  </React.Fragment>
);

export { Routes };
