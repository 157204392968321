import React from "react";
import clsx from "clsx";

import ChevronRightWhite from "@assets/icons/chevron-right-white.svg";
import { CouponButtonComponent } from "@app/core/discount-card";
import { IconComponent, ImageComponent } from "@app/core";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { ClickableComponent } from "@app/core/clickable";
import { ShadowCardComponent } from "@app/core/shadow-card";
import { HtmlRenderComponent } from "@app/core/html-render";
import { formatMessage } from "@app/translations/intl";
import LongArrow from "@assets/icons/arrow-long-right.svg";
import { CampaignStatusComponent } from "@app/core/campaign-status-label/campaign-status-label.component";
import { FeaturedFilterLabel } from "@app/core/featured-filter-label/featured-filter-label";
import { IOutSingleMerchantDTO } from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { RelAttributes } from "@app/constants";

import { formatMerchantFeatures } from "../sidebar/merchant-header-sidebar";
import styles from "./merchant-header-main.module.scss";

export interface IMerchantHeaderMainProps {
  merchant: IOutSingleMerchantDTO;
  shopSingleHeaderModule: ShopSingleHeaderModule;
  dealsButtonRef: React.Ref<HTMLDivElement>;
  className?: string;
}

export const MerchantHeaderMain = (props: IMerchantHeaderMainProps) => {
  const { toCampaignButtonText, campaignDescriptionTitle } = props.shopSingleHeaderModule;
  const { merchant, dealsButtonRef, className } = props;

  const discountSubtitle = formatMessage({ id: "discountCard.discountSubtitle" });
  const merchantOutLink = getMerchantOutLink(merchant);

  const campaignDescription = getCampaignDescription(props);

  return (
    <div className={clsx(styles.merchantHeaderMain, className)}>
      <ShadowCardComponent borderRadius={["2px"]} backgroundColor="white">
        {merchant.campaign?.durationStatus && (
          <div className={styles.campaignStatus}>
            <CampaignStatusComponent durationStatus={merchant.campaign.durationStatus} />
          </div>
        )}
        {merchant.campaign?.featuredFilter?.name && (
          <FeaturedFilterLabel
            className={styles.label}
            label={merchant.campaign.featuredFilter?.label}
            text={merchant.campaign.featuredFilter.name}
            corners="both"
          />
        )}
        <div className={styles.box}>
          <div className={styles.info}>
            {merchant?.logoImage?.url && (
              <a className={styles.logo} href={merchantOutLink} target="_blank" rel="sponsored">
                <ImageComponent
                  alt={merchant?.logoImage?.alt}
                  title={merchant?.logoImage?.title}
                  src={merchant?.logoImage?.url}
                  forceImageTag
                  isBlocking
                />
              </a>
            )}
            {merchant.campaign?.excerpt && <h2 className={styles.excerpt}>{merchant.campaign.excerpt}</h2>}

            <div className={styles.actionButtons}>
              {merchant?.campaign?.couponCode && (
                <>
                  <span className={styles.discountSubtitle}>{discountSubtitle}</span>
                  <CouponButtonComponent classNames={styles.coupon} coupon={merchant.campaign.couponCode} />
                </>
              )}
              <div className={styles.dealsButton} ref={dealsButtonRef}>
                <div className={styles.arrowIcon}>
                  <IconComponent icon={LongArrow} size="44px" />
                </div>

                <ClickableComponent
                  iconRight={ChevronRightWhite}
                  title={toCampaignButtonText}
                  href={merchantOutLink}
                  target="_blank"
                  variant="primary-new-big"
                  forceExternal
                  relAttribute={RelAttributes.SPONSORED}
                  fullWidth
                />
              </div>
            </div>
            {campaignDescription && (
              <>
                {campaignDescriptionTitle && merchant.campaign && (
                  <span className={styles.campaignDescriptionTitle}>{campaignDescriptionTitle}</span>
                )}
                <div className={styles.campaignDescription}>
                  <HtmlRenderComponent html={campaignDescription} />
                </div>
              </>
            )}
          </div>

          {(merchant.categories?.length || 0) > 0 && (
            <div className={styles.footer}>
              <span className={styles.categoriesContainer}>
                {merchant.categories?.map(category => (
                  <span key={category.id}>{category.name}</span>
                ))}
              </span>
            </div>
          )}
        </div>
      </ShadowCardComponent>
    </div>
  );
};

export const getMerchantOutLink = (merchant?: IOutSingleMerchantDTO): string => {
  const urlAdditionPlatform = formatMessage({ id: "global.urlAddition" });

  if (!merchant?.slug) {
    return "";
  }

  if (urlAdditionPlatform) {
    return `/out/store/${urlAdditionPlatform}/${merchant.slug}/`;
  }

  return `/out/store/${merchant.slug}/`;
};

const getCampaignDescription = (props: IMerchantHeaderMainProps): string => {
  const { merchant, shopSingleHeaderModule } = props;
  const formattedMerchantFeatures = formatMerchantFeatures(merchant?.features);

  if (merchant?.campaign?.description) {
    return merchant.campaign.description;
  }

  if (!merchant?.campaign && formattedMerchantFeatures) {
    return shopSingleHeaderModule.fallbackBodyText || "";
  }

  return "";
};
