export interface IRemaingingTime {
  days: number;
  hours: number;
  minutes: number;
  seconds: number;
}

export const calculateRemainingTimeUnits = (seconds: number): IRemaingingTime => {
  let countdown = Math.abs(seconds);

  const result: IRemaingingTime = {
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0
  };

  result.days = Math.floor(countdown / 86400);
  countdown -= result.days * 86400;

  result.hours = Math.floor(countdown / 3600);
  countdown -= result.hours * 3600;

  result.minutes = Math.floor(countdown / 60);
  countdown -= result.minutes * 60;

  result.seconds = Math.ceil(countdown % 60);

  return result;
};
