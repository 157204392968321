import React, { useEffect } from "react";
import { useAppSelector } from "@app/redux/store";
import { formatMessage } from "@app/translations/intl";
import GreenCheckmarkIcon from "@assets/icons/green-checkmark.svg";
import { Icon } from "@app/components/icon/icon";

import { NavBarResultMerchants } from "../navbar-result-merchants/navbar-result-merchants";
import { NavBarResultDeals } from "../navbar-result-deals/navbar-result-deals";
import { ISearchResultsProps } from "../search-results";

import styles from "../search-results.module.scss";

interface IProps extends ISearchResultsProps {}

export const NavbarNoResults = ({ searchValue, popularDeals, popularMerchants, closeSearch }: IProps) => {
  const { foundDeals, foundMerchants, isLoading, foundProducts, noResults } = useAppSelector(state => state.search);
  const [prevSearchValue, setPrevSearchValue] = React.useState<string>();
  const showPopular = foundMerchants.count === 0 && foundDeals.count === 0 && foundProducts.count === 0;
  const hasNoResults = !!searchValue && noResults;

  useEffect(() => {
    if (!isLoading) {
      setPrevSearchValue(searchValue);
    }
  }, [isLoading]);

  return (
    <>
      {hasNoResults && (
        <div className={styles.noResults}>
          <div className={styles.title}>
            {formatMessage(
              { id: "navbar.search.noResult.text" },
              {
                searchValue: prevSearchValue
              }
            )}
          </div>
          <ul className={styles.steps}>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipOne" })}</span>
            </li>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipTwo" })}</span>
            </li>
          </ul>
        </div>
      )}
      {showPopular && (
        <>
          <NavBarResultMerchants
            button={{
              title: formatMessage({ id: "navbar.search.result.button.merchants" })
            }}
            merchants={popularMerchants || []}
            headerText={formatMessage({ id: "navbar.search.noResult.merchants" })}
            closeSearch={closeSearch}
          />
          <NavBarResultDeals
            button={{
              title: formatMessage({ id: "navbar.search.result.button.deals" })
            }}
            deals={popularDeals || []}
            headerText={formatMessage({ id: "navbar.search.noResult.deals" })}
            closeSearch={closeSearch}
          />
        </>
      )}
    </>
  );
};
