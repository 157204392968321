import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { useSelector } from "react-redux";

import {
  mapMerchantsToFeaturedMerchants,
  getCorrectFeaturedMerchants
} from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { FeaturedMerchantsModule } from "@app/api/modules/featured-merchants/featured-merchants.module";
import Store from "@assets/icons/store.svg";
import { settingsState, ViewType } from "@app/redux/reducers/settings";
import { merchantsState } from "@app/redux/reducers/merchants";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { LinkComponent } from "@app/core/link";
import { getArrow } from "@app/constants/icons";
import Typography from "@app/components/typography";

import { MerchantCardWrapper } from "../merchant-card-wrapper/merchant-card-wrapper";
import { SeeMoreCardWrapper } from "../see-more-card-wrapper/see-more-card-wrapper";
import styles from "./featured-merchants-component.module.scss";

export interface IFeaturedMerchantsComponentProps {
  featuredMerchantsModule: FeaturedMerchantsModule;
}

const AMOUNT_OF_ITEMS_MOBILE = 8;

export const FeaturedMerchants = (props: IFeaturedMerchantsComponentProps) => {
  const settings = useSelector(settingsState);
  const { platformId } = settings;
  const merchantsFromState = useSelector(merchantsState);
  const { currentMerchant } = merchantsFromState;

  const {
    topCtaTitle,
    ctaButton,
    ctaUnderButton,
    ctaDescription,
    title,
    featuredMerchants,
    isMerchantSinglePage,
    selectionChoice,
    merchantSelection,
    amountOfStores,
    filterIds,
    labelFilter,
    pageUrl
  } = props.featuredMerchantsModule;

  const [merchants, setMerchants] = useState<IOutFeaturedMerchantDTO[]>(featuredMerchants || []);
  const [merchantSingleFilters, setMerchantSingleFilters] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (currentMerchant && isMerchantSinglePage) {
      const merchantFilterIds = currentMerchant.categories?.map(item => item.id);
      const merchantFilters = merchantFilterIds?.join(";");
      setMerchantSingleFilters(merchantFilters);
      getMerchants(merchantFilters, true);
    } else {
      getMerchants(undefined, false);
    }
    setMerchantSingleFilters(undefined);
  }, [pageUrl]);

  const getMerchants = async (merchantFilters?: string, merchantSinglePage?: boolean): Promise<void> => {
    const newMerchants = await getCorrectFeaturedMerchants({
      platformId,
      limit: amountOfStores,
      filterIds,
      selectionChoice,
      merchantSelection,
      isMerchantSinglePage: merchantSinglePage,
      merchantSingleFilters: merchantFilters || merchantSingleFilters,
      labelFilter
    });

    setMerchants(mapMerchantsToFeaturedMerchants(newMerchants || []));
  };

  const merchantsToUse = merchants && merchants.length > 0 ? merchants : featuredMerchants;

  if (!merchantsToUse || merchantsToUse.length === 0) {
    return <React.Fragment />;
  }
  const AMOUNT_OF_ITEMS_DESKTOP = 5;
  const amountOfRows = Math.ceil((merchantsToUse.length + 1) / AMOUNT_OF_ITEMS_DESKTOP);

  const maxAmountOfItems = {
    1200: amountOfRows * 4 - 1,
    959: amountOfRows * 3 - 1,
    768: 3,
    640: props.featuredMerchantsModule.amountOfMerchantsMobile || AMOUNT_OF_ITEMS_MOBILE
  };

  const hideItemOn1200 = (index: number) => index > maxAmountOfItems["1200"] && styles["hide-1200"];
  const hideItemOn959 = (index: number) => index > maxAmountOfItems["959"] && styles["hide-959"];
  const hideItemOn768 = (index: number) => index > maxAmountOfItems["768"] && styles["hide-768"];
  const hideItemOn640 = (index: number) => index > maxAmountOfItems["640"] && styles["hide-640"];

  if (!merchants || merchants.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(styles["featured-shops"], "uk-container")}>
      <div className={styles["top-container"]}>
        {title && (
          <Typography className={styles.title} tag="h2">
            {title}
          </Typography>
        )}
        <div className={styles["top-cta-link"]}>
          {topCtaTitle?.title && (
            <LinkComponent to={topCtaTitle.url} icon={getArrow(false)} animated variant="primary-new-colored-black">
              {topCtaTitle.title}
            </LinkComponent>
          )}
        </div>
      </div>
      <div className={styles.list}>
        {merchantsToUse.map((merchant, index) => (
          <div
            className={clsx(
              styles["item"],
              hideItemOn1200(index + 1),
              hideItemOn959(index + 1),
              hideItemOn768(index + 1),
              hideItemOn640(index + 1)
            )}
            key={merchant.id}
          >
            <MerchantCardWrapper merchant={merchant} />
          </div>
        ))}
        <div className={clsx(styles.seeMoreCard, styles.new)}>
          <SeeMoreCardWrapper
            title={ctaDescription || ""}
            CTAButtonText={ctaButton?.title || ""}
            CTAButtonLink={ctaButton?.url || ""}
            UnderCTAButtonText={ctaUnderButton?.title || ""}
            UnderCTAButtonLink={ctaUnderButton?.url || ""}
            icon={Store}
          />
        </div>
      </div>
    </div>
  );
};
