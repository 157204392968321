import React, { useEffect } from "react";
import { useAppSelector } from "@app/redux/store";
import { GET_CONFIG, usePrevious } from "@app/util";

export interface IGoogleAdSenseProps {
  slotId: string;
}

const GoogleAdSense = ({ slotId }: IGoogleAdSenseProps): JSX.Element => {
  const { currentPage } = useAppSelector(state => state.pages);
  const prevPageRoute = usePrevious(currentPage?.route);

  // Activate ad on page change
  useEffect(() => {
    activateAd();
  }, [currentPage?.route, slotId]);

  const activateAd = () => {
    if (prevPageRoute !== currentPage?.route && slotId) {
      const adsbygoogle = window.adsbygoogle || [];
      adsbygoogle.push({});
    }
  };

  if (!slotId) {
    return <></>;
  }

  return (
    <ins
      // By setting a different key on pageChange, we reset the ad and reload it in the useEffect.
      // If we don't do this, the ad won't change or won't activate.
      // Also, if we try to activate an ad that already is activated Google will throw an error.
      key={`${slotId}-${currentPage?.route}`}
      className="adsbygoogle"
      style={{ display: "block" }}
      data-ad-client={GET_CONFIG.GOOGLE_AD_SENSE_CLIENT}
      data-ad-slot={slotId}
      data-ad-format="auto"
      data-full-width-responsive="true"
      // Enable fake ads for testing on non-production environments
      {...(isDevMode && {
        "data-ad-test": "on"
      })}
    />
  );
};

export { GoogleAdSense };

const isDevMode =
  process.env.API_URL.startsWith("test") ||
  process.env.API_URL.startsWith("acceptatie") ||
  process.env.HOST?.includes("localhost");
