export type errorVariants = "emptyModule";

export const moduleMapperThrowMessage = (customMessage?: string, variant: errorVariants = "emptyModule") => {
  if (customMessage) throw new Error(customMessage);

  throw new Error(getErrorVariant(variant));
};

const getErrorVariant = (variant: errorVariants): string => {
  switch (variant) {
    case "emptyModule":
      return "No data found in module.";
    default:
      return "No data found in module";
  }
};
