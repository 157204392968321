import * as React from "react";
import styles from "./count-label-component.module.scss";

export interface ICountLabelProps {
  amount: number;
}

const CountLabelComponent = (props: ICountLabelProps) => <span className={styles.label}>{props.amount}</span>;

export { CountLabelComponent };
