import * as React from "react";
import { TextField as TextFieldCore, ITextFieldProps as ITextFieldPropsCore } from "@webbio/components";

import styles from "./search-input.module.scss";

const SearchInput = (props: ITextFieldPropsCore): JSX.Element => {
  return <TextFieldCore {...props} propStyles={styles} />;
};

export { SearchInput };
