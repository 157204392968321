import * as React from "react";
import { useSelector } from "react-redux";

import { settingsState } from "@app/redux/reducers/settings";
import { dealOverviewState } from "@app/redux/reducers/deals";
import { NewDealCardComponent } from "@app/core/new-deal-card/deal-card.component";
import { IOutFeaturedDealDTO } from "@app/core/old-deal-card";

export interface IDealCardWrapperProps {
  deal: IOutFeaturedDealDTO;
  responsive?: boolean;
  variant?: "normal" | "mobile-search";
}

const DealCardWrapper = (props: IDealCardWrapperProps) => {
  const settings = useSelector(settingsState);
  const { platformId } = settings;
  const dealState = useSelector(dealOverviewState);
  const { sponsoredDeals } = dealState;

  const isSponsored = sponsoredDeals.find(item => item.id === props.deal.id);

  return (
    <NewDealCardComponent
      deal={props.deal}
      platformId={platformId}
      responsive={props.responsive}
      isSponsored={!!isSponsored}
      variant={props.variant}
    />
  );
};

export { DealCardWrapper };
