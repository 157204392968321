import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { FeaturedBlogModule } from "@app/api/modules/featured-blog/featured-blog";
import { FeaturedBlogItemsComponent } from "@app/core/featured-blog-items";
import { getFeaturedBlogsExtraInformation } from "@app/redux/thunks/module.thunk";
import Typography from "@app/components/typography";

import styles from "./featured-blog-component.module.scss";

export interface IFeaturedBlogComponentProps {
  featuredBlog: FeaturedBlogModule;
}

const component = (props: IFeaturedBlogComponentProps & RouteComponentProps) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (!props.featuredBlog.items || props.featuredBlog.items.length === 0) {
      dispatch(getFeaturedBlogsExtraInformation(props.location.pathname.toLowerCase()));
    }
  }, []);

  return (
    <div className={styles["featured-blog"]}>
      <div className="uk-container">
        {props.featuredBlog.title && (
          <Typography tag="h3" variant="h2" className={styles.title}>
            {props.featuredBlog.title}
          </Typography>
        )}
        <div className={styles.body}>
          {props.featuredBlog.items &&
            props.featuredBlog.items.map((item, key) => (
              <div key={key} className={styles.card}>
                <FeaturedBlogItemsComponent item={item} />
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const FeaturedBlogComponent = withRouter(component);
export { FeaturedBlogComponent };
