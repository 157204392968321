import * as express from "express";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { StoreOverviewModule } from "@app/api/modules/store-overview/store-overview.module";
import EmptyStateIcon from "@assets/icons/sign.svg";
import { getMerchantsApi } from "@app/middleware/api/merchants-get.api";
import { FIRST_MERCHANT_TAKE } from "@app/constants/overviews-numbers";
import { IOverviewMessages } from "@app/api/modules/deal-overview/deal-overview.module";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { IMerchantResult, setMerchants } from "@app/redux/reducers/merchants";
import { formatMessage } from "@app/translations/intl";

import {
  IWPMerchantOverviewModuleFull,
  IWPMerchantOverviewMessages,
  IWPMerchantOverviewSwitchButtons
} from "./merchant-overview-module.interface";
import { mapMerchantsToFeaturedMerchants } from "../featured-merchants-module";
import { getPlatformInformation } from "@app/util/api/get-platform-info";

export interface IMerchantOverviewMapper extends IModuleFetcher {
  module: IWPMerchantOverviewModuleFull;
}

export const merchantOverviewModuleMapper = async (
  props: IMerchantOverviewMapper
): Promise<StoreOverviewModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in merchantOverviewModuleMapper");

    await setMerchantsInPreloadedState(platformId, res);
    const overviewMessages = mapMerchantOverviewMessages(data.messagesRegardingResultsMerchants);
    const platform = await getPlatformInformation(props.platformId);

    const suffix = pageUrl.split(formatMessage({ id: "global.merchantTabLink" }))[0];

    return {
      id: "1",
      name: "StoreOverviewModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      emptyStateIcon: EmptyStateIcon,
      switchButtons: getSwitchButtons(data.merchantOverviewSwitchButtons),
      tabs: [
        {
          href: `${suffix}${formatMessage({ id: "global.merchantTabLink" })}`,
          title: formatMessage({ id: "global.merchantOverviewLink.name" }),
          mobileTitle: formatMessage({ id: "global.merchantOverviewLink.mobileName" }),
          isSelected: true
        },
        {
          href: `${suffix}${formatMessage({ id: "global.dealTabLink" })}`,
          title: formatMessage({ id: "global.dealOverviewLink.name" }),
          mobileTitle: formatMessage({ id: "global.dealOverviewLink.mobileName" })
        }
      ],
      platform,
      overviewMessages
    };
  } catch (e) {
    Logger.logError(e, "Error in: merchantOverviewModuleMapper");

    return undefined;
  }
};

const getSwitchButtons = (switchButtons?: IWPMerchantOverviewSwitchButtons): IWPMerchantOverviewSwitchButtons => ({
  merchantsButtonText: "",
  dealsButtonText: "",
  merchantsOverviewMerchantsButtonArrow: "",
  merchantsOverviewMerchantsButtonCounter: "",
  merchantsOverviewDealsButtonArrow: "",
  merchantsOverviewDealsButtonCounter: "",
  ...switchButtons
});

const mapMerchantOverviewMessages = (messages: IWPMerchantOverviewMessages): IOverviewMessages => ({
  emptyResults: {
    isActive: messages.activateMessageNoMerchantsActivated,
    message: messages.messageNoMerchantsActivated
  },
  noResultsWithFilter: {
    text: messages.messageNoFilterResultsMerchants,
    buttonText: messages.buttonTextNoFilterResultsMerchants
  },
  onTop: { isActive: messages.activateMessageAboveMerchants, message: messages.messageAboveMerchants }
});

const setMerchantsInPreloadedState = async (platformId: number, res?: express.Response): Promise<void> => {
  const result = await getMerchantsApi(FIRST_MERCHANT_TAKE, 0, platformId);

  const mappedResult: IMerchantResult = {
    merchants: mapMerchantsToFeaturedMerchants(result?.merchants || []),
    totalResults: result?.total || 0
  };

  if (result?.merchants && res) {
    res.locals.store.dispatch(setMerchants(mappedResult));
  }
};
