import React from "react";
import styles from "./featured-filter-label.module.scss";
import IconHot from "@assets/icons/labels/hot.svg";
import IconAanrader from "@assets/icons/labels/aanrader.svg";
import IconBetrouwbaar from "@assets/icons/labels/betrouwbaar.svg";
import IconEco from "@assets/icons/labels/eco.svg";
import IconExclusief from "@assets/icons/labels/exclusief.svg";
import IconHoogsteKorting from "@assets/icons/labels/hoogste_korting.svg";
import IconMeestBekeken from "@assets/icons/labels/meest_bekeken.svg";
import IconMeestVerkocht from "@assets/icons/labels/meest_verkocht.svg";
import { IconComponent } from "../icon/icon.component";
import clsx from "clsx";

interface IFeaturedLabelProps {
  className?: string;
  label: IFeaturedLabel | undefined;
  text: string;
  corners?: "left" | "right" | "both";
}

interface IFeaturedLabel {
  colorHex: string;
  icon: string;
  showText: boolean;
}

const mapIconToImport = (iconName: string): string | undefined => {
  switch (iconName) {
    case "Geen icoon":
      return undefined;
    case "Aanrader":
      return IconAanrader;
    case "Betrouwbaar":
      return IconBetrouwbaar;
    case "Eco":
      return IconEco;
    case "Exclusief":
      return IconExclusief;
    case "Hoogste korting":
      return IconHoogsteKorting;
    case "Meest bekeken":
      return IconMeestBekeken;
    case "Meest verkocht":
      return IconMeestVerkocht;
    default:
      return IconHot;
  }
};

const component = (props: IFeaturedLabelProps) => {
  const backgroundColor = props.label ? props.label.colorHex : "var(--colour-version-website)";
  const icon = mapIconToImport(props.label?.icon || "hot");
  const hideText = props.label && !props.label.showText;
  const text = hideText ? null : props.text;

  return (
    <div className={clsx(styles.label, props.className, styles[props.corners || "both"])} style={{ backgroundColor }}>
      {!!icon && <IconComponent icon={icon} fillColor="white" size={"12px"} />}
      {!hideText && <span className={clsx(styles.labelText, !!icon && styles.withIcon)}>{text}</span>}
    </div>
  );
};

const FeaturedFilterLabel = component;
export { FeaturedFilterLabel };
