import { PlatformApi } from "@app/bf-api";
import Logger from "@app/util/logger";

export const getPlatformInformation = async (platformId: number) => {
  try {
    const platformApi = new PlatformApi();
    const result = await platformApi.platformsGet(platformId);
    return result;
  } catch (error) {
    Logger.logError("Error in get getPlatformInformation", error as any);
  }
};
