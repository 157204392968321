/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import * as runtime from "../runtime";
import {
  CreateFilterDTO,
  CreateFilterDTOFromJSON,
  CreateFilterDTOToJSON,
  FilterListResponseDTO,
  FilterListResponseDTOFromJSON,
  FilterListResponseDTOToJSON,
  FilterResponseDTO,
  FilterResponseDTOFromJSON,
  FilterResponseDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOToJSON,
  PutFilterDTO,
  PutFilterDTOFromJSON,
  PutFilterDTOToJSON
} from "../models";

export interface FiltersExtendedGetRequest {
  skip: number;
  take: number;
  exactSearch?: string;
  searchQuery?: string;
  typeIds?: string;
}

export interface FiltersFilterIdDeleteRequest {
  filterId: number;
}

export interface FiltersFilterIdGetRequest {
  filterId: number;
}

export interface FiltersFilterIdPutRequest {
  filterId: number;
  putFilterDTO: PutFilterDTO;
}

export interface FiltersGetRequest {
  searchQuery: string;
  take: number;
  typeIds: string;
  promotion?: string;
  merchantFilters?: string;
  filters?: string;
  platformId?: number;
}

export interface FiltersMerchantsGetRequest {
  take: number;
  filters?: string;
  platformId?: number;
  searchQuery?: string;
}

export interface FiltersMerchantsTopGetRequest {
  take: number;
  platformId: number;
  filters?: string;
}

export interface FiltersPostRequest {
  createFilterDTO: CreateFilterDTO;
}

export interface FiltersTopGetRequest {
  promotion: string;
  take: number;
  typeId: number;
  platformId: number;
  merchantFilters?: string;
  filters?: string;
}

/**
 *
 */
export class FiltersApi extends runtime.BaseAPI {
  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async filtersExtendedGetRaw(
    requestParameters: FiltersExtendedGetRequest
  ): Promise<runtime.ApiResponse<FilterListResponseDTO>> {
    if (requestParameters.skip === null || requestParameters.skip === undefined) {
      throw new runtime.RequiredError(
        "skip",
        "Required parameter requestParameters.skip was null or undefined when calling filtersExtendedGet."
      );
    }

    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling filtersExtendedGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.exactSearch !== undefined) {
      queryParameters["exactSearch"] = requestParameters.exactSearch;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.typeIds !== undefined) {
      queryParameters["typeIds"] = requestParameters.typeIds;
    }

    if (requestParameters.skip !== undefined) {
      queryParameters["skip"] = requestParameters.skip;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/extended`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterListResponseDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async filtersExtendedGet(
    skip: number,
    take: number,
    exactSearch?: string,
    searchQuery?: string,
    typeIds?: string
  ): Promise<FilterListResponseDTO> {
    const response = await this.filtersExtendedGetRaw({
      skip: skip,
      take: take,
      exactSearch: exactSearch,
      searchQuery: searchQuery,
      typeIds: typeIds
    });
    return await response.value();
  }

  /**
   */
  async filtersFilterIdDeleteRaw(
    requestParameters: FiltersFilterIdDeleteRequest
  ): Promise<runtime.ApiResponse<boolean>> {
    if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
      throw new runtime.RequiredError(
        "filterId",
        "Required parameter requestParameters.filterId was null or undefined when calling filtersFilterIdDelete."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
      method: "DELETE",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.TextApiResponse(response) as any;
  }

  /**
   */
  async filtersFilterIdDelete(filterId: number): Promise<boolean> {
    const response = await this.filtersFilterIdDeleteRaw({ filterId: filterId });
    return await response.value();
  }

  /**
   */
  async filtersFilterIdGetRaw(
    requestParameters: FiltersFilterIdGetRequest
  ): Promise<runtime.ApiResponse<OutFilterDTO>> {
    if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
      throw new runtime.RequiredError(
        "filterId",
        "Required parameter requestParameters.filterId was null or undefined when calling filtersFilterIdGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => OutFilterDTOFromJSON(jsonValue));
  }

  /**
   */
  async filtersFilterIdGet(filterId: number): Promise<OutFilterDTO> {
    const response = await this.filtersFilterIdGetRaw({ filterId: filterId });
    return await response.value();
  }

  /**
   */
  async filtersFilterIdPutRaw(
    requestParameters: FiltersFilterIdPutRequest
  ): Promise<runtime.ApiResponse<OutFilterDTO>> {
    if (requestParameters.filterId === null || requestParameters.filterId === undefined) {
      throw new runtime.RequiredError(
        "filterId",
        "Required parameter requestParameters.filterId was null or undefined when calling filtersFilterIdPut."
      );
    }

    if (requestParameters.putFilterDTO === null || requestParameters.putFilterDTO === undefined) {
      throw new runtime.RequiredError(
        "putFilterDTO",
        "Required parameter requestParameters.putFilterDTO was null or undefined when calling filtersFilterIdPut."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/filters/{filterId}`.replace(`{${"filterId"}}`, encodeURIComponent(String(requestParameters.filterId))),
      method: "PUT",
      headers: headerParameters,
      query: queryParameters,
      body: PutFilterDTOToJSON(requestParameters.putFilterDTO)
    });

    return new runtime.JSONApiResponse(response, jsonValue => OutFilterDTOFromJSON(jsonValue));
  }

  /**
   */
  async filtersFilterIdPut(filterId: number, putFilterDTO: PutFilterDTO): Promise<OutFilterDTO> {
    const response = await this.filtersFilterIdPutRaw({ filterId: filterId, putFilterDTO: putFilterDTO });
    return await response.value();
  }

  /**
   */
  async filtersGetRaw(requestParameters: FiltersGetRequest): Promise<runtime.ApiResponse<FilterResponseDTO>> {
    if (requestParameters.searchQuery === null || requestParameters.searchQuery === undefined) {
      throw new runtime.RequiredError(
        "searchQuery",
        "Required parameter requestParameters.searchQuery was null or undefined when calling filtersGet."
      );
    }

    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling filtersGet."
      );
    }

    if (requestParameters.typeIds === null || requestParameters.typeIds === undefined) {
      throw new runtime.RequiredError(
        "typeIds",
        "Required parameter requestParameters.typeIds was null or undefined when calling filtersGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.promotion !== undefined) {
      queryParameters["promotion"] = requestParameters.promotion;
    }

    if (requestParameters.merchantFilters !== undefined) {
      queryParameters["merchantFilters"] = requestParameters.merchantFilters;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.typeIds !== undefined) {
      queryParameters["typeIds"] = requestParameters.typeIds;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async filtersGet(
    searchQuery: string,
    take: number,
    typeIds: string,
    promotion?: string,
    merchantFilters?: string,
    filters?: string,
    platformId?: number
  ): Promise<FilterResponseDTO> {
    const response = await this.filtersGetRaw({
      searchQuery: searchQuery,
      take: take,
      typeIds: typeIds,
      promotion: promotion,
      merchantFilters: merchantFilters,
      filters: filters,
      platformId: platformId
    });
    return await response.value();
  }

  /**
   */
  async filtersMerchantsGetRaw(
    requestParameters: FiltersMerchantsGetRequest
  ): Promise<runtime.ApiResponse<FilterResponseDTO>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling filtersMerchantsGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    if (requestParameters.searchQuery !== undefined) {
      queryParameters["searchQuery"] = requestParameters.searchQuery;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/merchants`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => FilterResponseDTOFromJSON(jsonValue));
  }

  /**
   */
  async filtersMerchantsGet(
    take: number,
    filters?: string,
    platformId?: number,
    searchQuery?: string
  ): Promise<FilterResponseDTO> {
    const response = await this.filtersMerchantsGetRaw({
      take: take,
      filters: filters,
      platformId: platformId,
      searchQuery: searchQuery
    });
    return await response.value();
  }

  /**
   */
  async filtersMerchantsTopGetRaw(
    requestParameters: FiltersMerchantsTopGetRequest
  ): Promise<runtime.ApiResponse<Array<OutFilterDTO>>> {
    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling filtersMerchantsTopGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling filtersMerchantsTopGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/merchants/top`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(OutFilterDTOFromJSON));
  }

  /**
   */
  async filtersMerchantsTopGet(take: number, platformId: number, filters?: string): Promise<Array<OutFilterDTO>> {
    const response = await this.filtersMerchantsTopGetRaw({ take: take, platformId: platformId, filters: filters });
    return await response.value();
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async filtersPostRaw(requestParameters: FiltersPostRequest): Promise<runtime.ApiResponse<OutFilterDTO>> {
    if (requestParameters.createFilterDTO === null || requestParameters.createFilterDTO === undefined) {
      throw new runtime.RequiredError(
        "createFilterDTO",
        "Required parameter requestParameters.createFilterDTO was null or undefined when calling filtersPost."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters["Content-Type"] = "application/json";

    const response = await this.request({
      path: `/filters`,
      method: "POST",
      headers: headerParameters,
      query: queryParameters,
      body: CreateFilterDTOToJSON(requestParameters.createFilterDTO)
    });

    return new runtime.JSONApiResponse(response, jsonValue => OutFilterDTOFromJSON(jsonValue));
  }

  /**
   * This request can be accessed with an ApiKey.
   * ApiKey
   */
  async filtersPost(createFilterDTO: CreateFilterDTO): Promise<OutFilterDTO> {
    const response = await this.filtersPostRaw({ createFilterDTO: createFilterDTO });
    return await response.value();
  }

  /**
   */
  async filtersTopGetRaw(requestParameters: FiltersTopGetRequest): Promise<runtime.ApiResponse<Array<OutFilterDTO>>> {
    if (requestParameters.promotion === null || requestParameters.promotion === undefined) {
      throw new runtime.RequiredError(
        "promotion",
        "Required parameter requestParameters.promotion was null or undefined when calling filtersTopGet."
      );
    }

    if (requestParameters.take === null || requestParameters.take === undefined) {
      throw new runtime.RequiredError(
        "take",
        "Required parameter requestParameters.take was null or undefined when calling filtersTopGet."
      );
    }

    if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
      throw new runtime.RequiredError(
        "typeId",
        "Required parameter requestParameters.typeId was null or undefined when calling filtersTopGet."
      );
    }

    if (requestParameters.platformId === null || requestParameters.platformId === undefined) {
      throw new runtime.RequiredError(
        "platformId",
        "Required parameter requestParameters.platformId was null or undefined when calling filtersTopGet."
      );
    }

    const queryParameters: runtime.HTTPQuery = {};

    if (requestParameters.merchantFilters !== undefined) {
      queryParameters["merchantFilters"] = requestParameters.merchantFilters;
    }

    if (requestParameters.promotion !== undefined) {
      queryParameters["promotion"] = requestParameters.promotion;
    }

    if (requestParameters.take !== undefined) {
      queryParameters["take"] = requestParameters.take;
    }

    if (requestParameters.typeId !== undefined) {
      queryParameters["typeId"] = requestParameters.typeId;
    }

    if (requestParameters.filters !== undefined) {
      queryParameters["filters"] = requestParameters.filters;
    }

    if (requestParameters.platformId !== undefined) {
      queryParameters["platformId"] = requestParameters.platformId;
    }

    const headerParameters: runtime.HTTPHeaders = {};

    const response = await this.request({
      path: `/filters/top`,
      method: "GET",
      headers: headerParameters,
      query: queryParameters
    });

    return new runtime.JSONApiResponse(response, jsonValue => jsonValue.map(OutFilterDTOFromJSON));
  }

  /**
   */
  async filtersTopGet(
    promotion: string,
    take: number,
    typeId: number,
    platformId: number,
    merchantFilters?: string,
    filters?: string
  ): Promise<Array<OutFilterDTO>> {
    const response = await this.filtersTopGetRaw({
      promotion: promotion,
      take: take,
      typeId: typeId,
      platformId: platformId,
      merchantFilters: merchantFilters,
      filters: filters
    });
    return await response.value();
  }
}
