import * as React from "react";
import styles from "./select-component.module.scss";
import ArrowLongDown from "@assets/icons/chevron-down-select.svg";
import { IconComponent } from "../icon";
import clsx from "clsx";

export interface ISelectComponentProps {
  icon?: string;
  iconSize?: string;
  onSelect: (value: string) => void;
  options: string[];
  isRounded?: boolean;
  variant?: "white" | "default";
}

const SelectComponent = (props: ISelectComponentProps) => {
  const variant = props.variant ?? "default";
  const handelChange = (event: any) => {
    const newValue = event.target.value;
    props.onSelect(newValue);
  };

  return (
    <div className={clsx(styles.select, props.isRounded && styles.rounded, styles[`select--${variant}`])}>
      <select
        aria-label="Select"
        className={styles["select-title"]}
        onChange={handelChange}
        onSelect={() => props.onSelect}
      >
        {props.options.map((value, index) => (
          <option aria-selected value={value} key={index}>
            {value}
          </option>
        ))}
      </select>

      <span className={styles[`select--${variant}-icon`]}>
        <IconComponent icon={props.icon || ArrowLongDown} size={props.iconSize || "12px"} />
      </span>
    </div>
  );
};

export { SelectComponent };
