import { StoreStatus } from "@app/api/core/store/store";
import { formatMessage } from "@app/translations/intl";
import { IntlShape } from "react-intl";
import { percentageFromDates } from "./percentage-from-date";

export const getStoreStatusText = (intl: IntlShape, name?: string, startInAmountOfDays?: number) => {
  if (!name) return "";
  switch (name) {
    case "UNKNOWN":
      return formatMessage({ id: "global.status.UNKNOWN" });
    case "ABOUT_TO_START":
      return startInAmountOfDays
        ? startInAmountOfDays > 1
          ? formatMessage({ id: "global.status.ABOUT_TO_START_DAYS" }, { startInAmountOfDays })
          : formatMessage({ id: "global.status.ABOUT_TO_START_DAY" }, { startInAmountOfDays })
        : formatMessage({ id: "global.status.ABOUT_TO_START" });
    case "ONGOING":
      return formatMessage({ id: "global.status.ONGOING" });
    case "ABOUT_TO_EXPIRE":
      return formatMessage({ id: "global.status.ABOUT_TO_EXPIRE" });
    case "EXPIRED":
      return formatMessage({ id: "global.status.EXPIRED" });
    default:
      return formatMessage({ id: "global.status.UNKNOWN" });
  }
};

export const getStoreStatus = (currentDate: Date, startDate?: Date, endDate?: Date): StoreStatus => {
  if (!startDate || !endDate) {
    return StoreStatus.Unknown;
  }

  if (startDate > currentDate) {
    return StoreStatus.StartsSoon;
  }

  if (currentDate > endDate) {
    return StoreStatus.Expired;
  }

  const percentage = percentageFromDates(currentDate, startDate, endDate);

  if (percentage < 20) {
    return StoreStatus.Expired;
  }

  return StoreStatus.NowValid;
};
