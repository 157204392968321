/* tslint:disable */
import * as React from "react";
import style from "./text-field-component.module.scss";
import clsx from "clsx";

interface ITextFieldProps {
  autoFocus?: boolean;
  errorMessage?: string | false;
  fullWidth?: boolean;
  helperText?: string;
  id?: string;
  maxCharacters?: number;
  onBlur?<T = string | any>(fieldOrEvent: T): T extends string ? (e: any) => void : void;
  onChange?(e: React.ChangeEvent<any>): void;
  placeholder?: string;
  label: string;
  type?: "text" | "email" | "password";
  variant?: "primary" | "secondary" | "new";
  value?: any;
  isTextArea?: boolean;
  height?: number;
}

const TextFieldComponent = (props: ITextFieldProps) => {
  const [isFocused, setIsFocused] = React.useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const onBlur = (e: any) => {
    setIsFocused(false);
    if (props.onBlur) {
      props.onBlur(e);
    }
  };

  return (
    <div
      style={{ width: props.fullWidth ? "100%" : "auto" }}
      className={clsx(
        style["form-field"],
        isFocused && style["form-field__focused"],
        props.variant === "secondary" && isFocused && !props.errorMessage && style.secondary,
        props.errorMessage && style.error,
        props.value && props.value.length > 0 && style["not-empty"],
        isHovered && style["hovered"],
        props.variant === "new" && style.newTextField
      )}
    >
      {props.label && <div className={style["form-field__label"]}>{props.label}</div>}
      <div className={style["form-field__control"]}>
        {!props.isTextArea ? (
          <input
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={props.onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={onBlur}
            autoFocus={props.autoFocus}
            value={props.value}
            id={props.id}
            placeholder={props.placeholder}
            type={props.type}
            className={style["form-field__input"]}
          />
        ) : (
          <textarea
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onChange={props.onChange}
            onFocus={() => setIsFocused(true)}
            onBlur={onBlur}
            placeholder={props.placeholder}
            autoFocus={props.autoFocus}
            value={props.value}
            id={props.id}
            className={style["form-field__input"]}
            style={{ resize: "vertical", height: props.height ? props.height.toString().concat("px") : "100px" }}
          />
        )}
      </div>

      <div className={clsx(style["form-field__extra"], props.errorMessage && style.hasError)}>
        {props.maxCharacters && !props.errorMessage && !props.helperText && <div />}
        {!props.errorMessage && props.helperText && (
          <label className={style["form-field__extra__helper"]}>{props.helperText}</label>
        )}
        {props.errorMessage && <label className={style["form-field__extra__error"]}>{props.errorMessage}</label>}
        {props.maxCharacters && (
          <label
            className={style["form-field__extra__character-counter"]}
          >{`${props.value.length} / ${props.maxCharacters}`}</label>
        )}
      </div>
    </div>
  );
};

export { TextFieldComponent };
