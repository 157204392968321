export const combineFilters = (filters: (string | string[] | undefined)[]): string => {
  const allFilters = filters.map(filterArray => getFilterIdsFromArray(filterArray));
  const onlyValidFilters = allFilters.filter((filter: string) => filter);

  return onlyValidFilters.join(";");
};

export const getFilterIdsFromArray = (arr?: string[] | string): string => {
  if (!arr || !Array.isArray(arr)) {
    return "";
  }

  return arr.map(item => Object.keys(JSON.parse(item) as any)?.[0]).join(";");
};
