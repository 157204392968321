import * as React from "react";

import { NewSeeMoreCardComponent } from "@app/core/new-see-more-card";

export interface ISeeMoreComponentWrapperProps {
  CTAButtonLink: string;
  CTAButtonText: string;
  UnderCTAButtonLink?: string;
  UnderCTAButtonText?: string;
  icon?: string;
  onClick?: () => void;
  title: string;
  variant?: string;
}

const SeeMoreCardWrapper = (props: ISeeMoreComponentWrapperProps) => <NewSeeMoreCardComponent {...props} />;

export { SeeMoreCardWrapper };
