import React from "react";
import clsx from "clsx";
import { Link } from "react-router-dom";

import ChevronRight from "@assets/icons/chevron-right-sharp.svg";
import CheckIcon from "@assets/icons/check.svg";

import { IconComponent } from "@app/core";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { ShadowCardComponent } from "@app/core/shadow-card";
import { IOutSingleMerchantDTO } from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { formatMessage } from "@app/translations/intl";

import styles from "./merchant-header-sidebar.module.scss";
import { ClickableComponent } from "@app/core/clickable";
import { getMerchantOutLink } from "../main/merchant-header-main";
import { RelAttributes } from "@app/constants";

export interface IMerchantHeaderSidebarProps {
  merchant: IOutSingleMerchantDTO;
  shopSingleHeaderModule: ShopSingleHeaderModule;
  className?: string;
}

export const MerchantHeaderSidebar = (props: IMerchantHeaderSidebarProps) => {
  const { merchantFeaturesTitle, campaignUspTitle } = props.shopSingleHeaderModule;

  const { merchant, className } = props;

  const merchantOutLink = getMerchantOutLink(merchant);
  const merchantFeaturesText = getMerchantFeaturesFallbackText(props);
  const merchantUsps = merchant.campaign?.usps;

  return (
    <ShadowCardComponent borderRadius={["2px"]} backgroundColor="white">
      <div className={clsx(styles.sidebar, className)}>
        <div className={styles.top}>
          <div className={styles.merchantFeatures}>
            {merchantFeaturesTitle && <h4 className={styles.merchantFeaturesTitle}>{merchantFeaturesTitle}</h4>}
            <span className={styles.merchantFeatures}>{merchantFeaturesText}</span>
          </div>
          {(merchantUsps?.length || 0) > 0 && (
            <div className={styles.campaignUsp}>
              {campaignUspTitle && <h4 className={styles.campaignUspTitle}>{campaignUspTitle}</h4>}
              {merchantUsps?.map((usp, key) => (
                <div key={key} className={styles.campaignUspListItem}>
                  <IconComponent size="14px" icon={CheckIcon} />
                  <span className={styles.uspText}>{usp}</span>
                </div>
              ))}
            </div>
          )}
          {merchantOutLink && (
            <div className={styles.merchantOutLink}>
              <ClickableComponent
                variant="primary-new-colored-small"
                href={merchantOutLink}
                title={formatMessage(
                  {
                    id: "merchantSingleHeader.showDeals.buttonTitle"
                  },
                  { merchantName: merchant.name }
                )}
                iconRight={ChevronRight}
                target="_blank"
                forceExternal
                fullWidth
                relAttribute={RelAttributes.SPONSORED}
              />
            </div>
          )}
        </div>
        <div className={styles.moreBF}>
          {<h4>{formatMessage({ id: "merchantSingleHeader.moreBF.title" })}</h4>}
          <div className={styles.dealButtons}>
            <Link className={styles.dealButton} to={formatMessage({ id: "global.merchantOverviewLink" })}>
              {formatMessage({ id: "merchantSingleHeader.moreBF.buttons.merchants.title" })}
              <div className={styles.extra}>
                {formatMessage({ id: "merchantSingleHeader.moreBF.buttons.merchants.extra" })}
                <IconComponent size="12px" icon={ChevronRight} />
              </div>
            </Link>
            <Link className={styles.dealButton} to={formatMessage({ id: "global.dealOverviewLink" })}>
              {formatMessage({ id: "merchantSingleHeader.moreBF.buttons.deals.title" })}
              <div>
                <IconComponent size="12px" icon={ChevronRight} />
              </div>
            </Link>
          </div>
        </div>
      </div>
    </ShadowCardComponent>
  );
};

export const formatMerchantFeatures = (features = ""): string => {
  const regex = /;/gi;

  return features.replace(regex, " | ");
};

const getMerchantFeaturesFallbackText = (props: IMerchantHeaderSidebarProps): string => {
  const { merchant, shopSingleHeaderModule } = props;

  const formattedMerchantFeatures = formatMerchantFeatures(merchant?.features);

  if (formattedMerchantFeatures) {
    return formattedMerchantFeatures;
  }

  if (merchant?.campaign && shopSingleHeaderModule?.fallbackAboutMerchantText) {
    return shopSingleHeaderModule.fallbackAboutMerchantText;
  }

  return shopSingleHeaderModule?.fallbackBodyText || "";
};
