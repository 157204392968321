import * as React from "react";
import styles from "./error-page-component.module.scss";
import { ErrorPageModule } from "@app/api/modules/error-page/error-page";
import { IconComponent, ResourceTextComponent } from "@app/core";
import RoadSignRight from "@assets/icons/road-sign-right.svg";
import { ClickableComponent } from "@app/core/clickable";
import { formatMessage } from "@app/translations/intl";

export interface INotFoundComponentProps {
  notFoundModule: ErrorPageModule;
}

export const ErrorPageComponent = (props: INotFoundComponentProps) => {
  const { variant = "notFound" } = props.notFoundModule;

  return (
    <div className={styles["not-found"]}>
      <div className={styles["not-found-container"]}>
        <IconComponent icon={RoadSignRight} size={"45px"} />
        <span className={styles["not-found-title"]}>
          <ResourceTextComponent resourceKey={`${variant}.title`} />
        </span>
        <p>
          <ResourceTextComponent resourceKey={`${variant}.content`} />
        </p>
        <ClickableComponent
          title={formatMessage({ id: `${variant}.button.title` })}
          variant={"secondary"}
          href={formatMessage({ id: `${variant}.button.link` })}
        />
      </div>
    </div>
  );
};
