import React from "react";
import ReactSVG from "react-svg";
import clsx from "clsx";

import style from "./icon-component.module.scss";

export interface IIconComponentProps {
  fillColor?: string;
  icon: any;
  size: string;
  strokeColor?: string;
  strokeWidth?: string;
  className?: string;
}

const iconStyleClassName = (props: IIconComponentProps) => {
  const { fillColor, strokeColor, strokeWidth } = props;

  const defaultStyle = "icon-svg";
  const fillClassName = typeof fillColor !== "undefined" ? style[`${defaultStyle}--filled`] : "";
  const strokeWidthClassName = typeof strokeWidth !== "undefined" ? style[`${defaultStyle}--stroke-width`] : "";
  const outlineClassName = typeof strokeColor !== "undefined" ? style[`${defaultStyle}--outline`] : "";

  return clsx(style[defaultStyle], fillClassName, outlineClassName, strokeWidthClassName, props.className);
};

const component: React.FunctionComponent<IIconComponentProps> = props => {
  if (typeof window === "undefined") {
    return (
      <span
        className={iconStyleClassName(props)}
        style={{
          fill: props.fillColor,
          height: props.size,
          stroke: props.strokeColor,
          strokeWidth: props.strokeWidth,
          width: props.size
        }}
      >
        <img src={props.icon} alt="" role="presentation" />
      </span>
    );
  }

  return (
    <ReactSVG
      suppressHydrationWarning
      src={props.icon}
      renumerateIRIElements={true}
      wrapper="span"
      style={{
        fill: props.fillColor,
        height: props.size,
        stroke: props.strokeColor,
        strokeWidth: props.strokeWidth,
        width: props.size
      }}
      className={iconStyleClassName(props)}
    />
  );
};

const IconComponent = React.memo(component);

export { IconComponent };
