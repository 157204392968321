/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutMerchantDTO, OutMerchantDTOFromJSON, OutMerchantDTOFromJSONTyped, OutMerchantDTOToJSON } from "./";

/**
 *
 * @export
 * @interface MerchantListResponseDTO
 */
export interface MerchantListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof MerchantListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<OutMerchantDTO>}
   * @memberof MerchantListResponseDTO
   */
  merchants?: Array<OutMerchantDTO>;
}

export function MerchantListResponseDTOFromJSON(json: any): MerchantListResponseDTO {
  return MerchantListResponseDTOFromJSONTyped(json, false);
}

export function MerchantListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    merchants: !exists(json, "merchants") ? undefined : (json["merchants"] as Array<any>).map(OutMerchantDTOFromJSON)
  };
}

export function MerchantListResponseDTOToJSON(value?: MerchantListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    merchants: value.merchants === undefined ? undefined : (value.merchants as Array<any>).map(OutMerchantDTOToJSON)
  };
}
