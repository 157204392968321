import * as React from "react";
import styles from "./animated-ellipsis-loader-component.module.scss";

export interface IAnimatedEllipsisLoaderProps {
  color?: string;
}

const AnimatedEllipsisLoader = (props: IAnimatedEllipsisLoaderProps) => {
  const { color } = props;

  const divColor = color ? { backgroundColor: color } : {};

  return (
    <div className={styles.animatedLoader}>
      <div style={divColor} />
      <div style={divColor} />
      <div style={divColor} />
      <div style={divColor} />
    </div>
  );
};

export { AnimatedEllipsisLoader };
