import { BlogOverviewHeaderModule } from "@app/api/modules/blog-overview-header/blog-overview-header";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IHeaderBlogSingleModuleMapper, headerBlogSingleModuleMapper } from "../header-blog-single-module";
import { getFeaturedBlogs } from "../featured-blogs-module";
import { breadcrumbComponentMapper } from "../../component-mappers/breadcrumb-component/breadcrumb-component-mapper";
import Logger from "@app/util/logger";

export interface IHeaderBlogOverviewModuleMapper extends IHeaderBlogSingleModuleMapper {}

const BLOG_POSTS_TO_SHOW = 2;

export const headerBlogOverviewModuleMapper = async (
  props: IHeaderBlogOverviewModuleMapper
): Promise<BlogOverviewHeaderModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      pageUrl,
      pageData
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in headerBlogOverviewModuleMapper");

    if (!data.recentBlogPosts) {
      return headerBlogSingleModuleMapper(props);
    }

    const featuredBlogs = await getFeaturedBlogs(platformId, pageUrl, true, BLOG_POSTS_TO_SHOW);
    if (!featuredBlogs || featuredBlogs === 404 || featuredBlogs === 503)
      return moduleMapperThrowMessage("Cannot get featured blogs in headerBlogOverviewModuleMapper");

    const breadcrumbProps = await breadcrumbComponentMapper({
      platformId,
      pageUrl,
      variant: "light",
      title: pageData.meta?.breadcrumbTitle
    });

    return {
      id: "14",
      name: "BlogOverviewHeaderModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      title: data.title || "",
      breadcrumbProps,
      blogPosts: featuredBlogs
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerBlogOverviewModuleMapper");

    return undefined;
  }
};
