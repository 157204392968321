export const FIRST_MERCHANT_TAKE = 50;
export const MERCHANT_TAKE = 25;

export const FIRST_DEAL_TAKE = FIRST_MERCHANT_TAKE;
export const DEAL_TAKE = MERCHANT_TAKE;

export const SHOW_AD_EVERY_LINES = 4;
export const NAVIGATION_HEIGHT_DEFAULT = 127;
export const NAVIGATION_HEIGHT_MOBILE = 116;
export const NAVIGATION_HEIGHT_MOBILE_SEARCH = 58;
export const BOTTOM_SCROLL_DEBOUNCE = 50;
