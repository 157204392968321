import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import FavIconUk from "@assets/favicons/favicon-bf-uk.png";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import BfUkLogo from "@assets/logos/bf-uk.svg";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const unitedKingdomPlatform: IPlatformSpecificInfo = {
  id: 9,
  currency: "GBP",
  name: "Black Friday Expert",
  country: "United Kingdom",
  domain: "blackfriday.expert",
  merchantOverviewLink: "/stores/",
  dealOverviewLink: "/deals/",
  urlAddition: "uk",
  isLandingPage: false,
  defaultBackButtonText: "Back",
  breadcrumbHomeName: "Home",
  breadcrumbHomeUrl: "/uk/",
  readingTimeText: {
    singular: "minute readingtime",
    plural: "minutes readingtime"
  },
  newsletterButtonTitle: "Register",
  newsletterModalTitle: "Sign up for our newsletter",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114412/default-blog-image-be.jpg",
  bottomFooter: {
    items: [
      {
        title: "Contact",
        url: "/uk/contact/"
      },
      {
        title: "Privacy",
        url: "/uk/privacy/"
      },
      {
        title: "Cookies",
        url: "/uk/cookies-policy/"
      },
      {
        title: "Sitemap",
        url: "/uk/sitemap/"
      }
    ],
    logo: {
      src: BfUkLogo,
      alt: "Black Friday Expert UK logo",
      title: "Black Friday Expert UK logo"
    },
    logoLink: "/uk/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "More about Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Most viewed pages",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Stores with the best deals",
        links: []
      }
    ],
    newsletter: {
      title: "",
      text: "",
      buttonText: "",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Follow us",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/BlackFridayUnitedKingdom/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridayexpert_uk/"
        },
        {
          link: "info@blackfriday.expert",
          image: {
            src: mailIcon,
            alt: "Mail icon",
            title: "Mail icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Store header",
    title: "Header storepage"
  },
  logo: {
    src: BfUkLogo,
    alt: "Black Friday Expert UK logo footer",
    title: "Black Friday Expert UK logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/stores/",
          title: "All offers in one overview"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/deals/",
          title: "Easily score the best deals"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/uk/when-is-black-friday/",
          title: "Don't miss out on Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/uk/stores/",
      isSelected: true,
      title: "Stores"
    },
    {
      href: "/uk/deals/",
      title: "Deals"
    }
  ],
  dealTabs: [
    {
      href: "/uk/stores/",
      title: "Stores"
    },
    {
      href: "/uk/deals/",
      isSelected: true,
      title: "Deals"
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.UK_RED,
    "--colour-version-website-RGB": WEBSITE_COLORS.UK_RED_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.UK_RED_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.UK_RED_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.UK_RED_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.UK_RED_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.UK_LIGHT_ACCENT
  },
  locale: "en-GB",
  tagManagerScriptLocation: "/static/googletagmanager-uk-uk.js",
  weCanTrackScriptLocation: "/static/wecantrack-uk-uk.js",
  favIcon: FavIconUk,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-uk.png",
  gtm: "GTM-TQPJ3SL",
  customCtaButtonColor: {
    "--custom-colour-version-website": WEBSITE_COLORS.UK_BLUE,
    "--custom-colour-version-website-darken7": WEBSITE_COLORS.UK_BLUE_DARKEN_7,
    "--custom-colour-version-website-darken16": WEBSITE_COLORS.UK_BLUE_DARKEN_16
  }
};
