export const getCorrectUrl = (pageUrl: string): string => {
  let trimmedUrl = pageUrl.trim();
  const umtLinkIndex = trimmedUrl.indexOf("?");

  if (umtLinkIndex > -1) {
    trimmedUrl = `${trimmedUrl.substring(0, umtLinkIndex)}`;
  }

  if (trimmedUrl[trimmedUrl.length - 1] !== "/" && trimmedUrl !== "/") {
    return `${trimmedUrl}/`;
  }

  return trimmedUrl;
};
