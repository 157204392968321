import { IWPMerchantSingleHeaderModuleFull } from "./merchant-single-header-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { getLastArrayItem } from "@app/util/get-last-array-item";
import { OutMerchantDTO, OutFilterDTO } from "@app/bf-api";
import { checkSpecialSinglePage } from "@app/api/wp-page-fetcher/utils/check-special-single-page";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import {
  IOutSingleMerchantDTO,
  IFilteredFilterDTO
} from "@app/modules/merchant-single-header/merchant-single-header.interface";
import Logger from "@app/util/logger";
import { singleMerchantGetApi } from "@app/middleware/api/single-merchant-get.api";
import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { setCurrentMerchant } from "@app/redux/reducers/merchants";
import { formatMessage } from "@app/translations/intl";

import { breadcrumbComponentMapper } from "../../component-mappers/breadcrumb-component/breadcrumb-component-mapper";
import { findPlatformContent } from "@app/api/wp-page-fetcher/utils/platform-content-array";
import { GET_CONFIG } from "@app/util";

export interface IMerchantSingleHeaderMapper extends IModuleFetcher {
  module: IWPMerchantSingleHeaderModuleFull;
}

export const merchantSingleHeaderModuleMapper = async (
  props: IMerchantSingleHeaderMapper
): Promise<ShopSingleHeaderModule | WordPressPostModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      shopPageUrlName,
      splittedUrl,
      pageData,
      pageUrl
    } = props;
    if (!data || !checkSpecialSinglePage(shopPageUrlName, splittedUrl))
      return moduleMapperThrowMessage("No data found in merchantSingleHeaderModuleMapper");

    const singleMerchant = await setSingleMerchantInStore(props);

    if (singleMerchant === 302) {
      return { id: "1", name: "ShopSingleHeaderModule", status: 302 };
    }

    if (!singleMerchant)
      return moduleMapperThrowMessage("Cannot get single merchant in merchantSingleHeaderModuleMapper");

    const breadcrumbProps = await breadcrumbComponentMapper({
      platformId,
      pageUrl,
      variant: "dark",
      title: pageData.meta?.breadcrumbTitle
    });

    return {
      id: "1",
      name: "ShopSingleHeaderModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      fallbackHeaderImage: {
        src: formatMessage({ id: "fallbackHeader.src" }),
        alt: "Header image",
        title: "Header image"
      },
      breadcrumbProps,
      merchant: singleMerchant,
      fallbackBodyText: getTextWithWildCards(data.defaultBodyText, singleMerchant.name),
      toCampaignButtonText: data.discountCardOutButtonText || "",
      merchantFeaturesTitle: getTextWithWildCards(data.shopFeaturesTitle, singleMerchant.name),
      seo: {
        ...singleMerchant.seo,
        jsonSchema: singleMerchant.seo?.jsonSchema
      },
      campaignDescriptionTitle: getTextWithWildCards(data.campaignDescriptionTitle, singleMerchant.name),
      campaignUspTitle: getTextWithWildCards(data.campaignUspTitle, singleMerchant.name),
      fallbackAboutMerchantText: getTextWithWildCards(data.fallbackAboutMerchantText, singleMerchant.name),
      customJsonSchema: getStructuredData(singleMerchant, platformId, pageUrl)
    };
  } catch (e) {
    Logger.logError(e, "merchantSingleHeaderModuleMapper went wrong");

    return undefined;
  }
};

const getTextWithWildCards = (text?: string, merchantName?: string): string => {
  if (!text || !merchantName) return "";

  const currentYear = new Date().getFullYear();

  const replacedMerchantNames = text.replace(/{merchantName}/, merchantName);
  const replacedYear = replacedMerchantNames.replace(/{currentYear}/, `${currentYear}`);

  return replacedYear;
};

export const setSingleMerchantInStore = async (
  props: IMerchantSingleHeaderMapper
): Promise<IOutSingleMerchantDTO | undefined | 302> => {
  const { splittedUrl, platformId, res, dispatch } = props;

  if (!splittedUrl) return undefined;

  try {
    const slug = getLastArrayItem(splittedUrl);
    const merchant = await singleMerchantGetApi(platformId, slug);

    if (!merchant) return moduleMapperThrowMessage("Cannot get single merchant");

    if (merchant === 302) return 302;

    const mappedMerchant = mapMerchantToSingleMerchant(merchant);

    if (res) {
      res.locals.store.dispatch(setCurrentMerchant(mappedMerchant));
    }
    if (dispatch) {
      dispatch(setCurrentMerchant(mappedMerchant));
    }

    return mappedMerchant;
  } catch (e) {
    Logger.logError(e, "Error in: setSingleMerchantInStore");

    return undefined;
  }
};

export const mapMerchantToSingleMerchant = (merchant: OutMerchantDTO): IOutSingleMerchantDTO => {
  const campaign = merchant.campaigns?.[0];

  const mappedMerchant: IOutSingleMerchantDTO = {
    id: merchant.id,
    name: merchant.name,
    logoImage: {
      url: merchant.logoImage?.url || "",
      title: merchant.logoImage?.title,
      alt: merchant.logoImage?.alt
    },
    headerImage: merchant.headerImage
      ? {
          url: merchant.headerImage?.url || "",
          title: merchant.headerImage?.title,
          alt: merchant.headerImage?.alt
        }
      : undefined,
    slug: merchant.slug,
    campaign: campaign && {
      featuredFilter: {
        name: campaign?.featuredFilter?.name || "",
        label: {
          showText: campaign?.featuredFilter?.label?.showText || false,
          colorHex: campaign?.featuredFilter?.label?.colorHex || "",
          icon: campaign?.featuredFilter?.label?.icon || "",
          id: campaign?.featuredFilter?.label?.id || 0
        }
      },
      excerpt: campaign?.excerpt,
      subtitle: campaign?.subtitle,
      durationStatus: campaign?.durationStatus,
      couponCode: campaign?.couponCode,
      description: campaign?.description,
      usps: campaign?.usps,
      startDate: campaign?.startDate,
      endDate: campaign?.endDate
    },
    categories: mapFiltersToFilterNames(merchant.categories || []),
    features: merchant.features,
    seo: merchant.seo,
    adSenseScript: merchant?.adSenseScript
  };
  return mappedMerchant;
};

const mapFiltersToFilterNames = (filters: OutFilterDTO[]): IFilteredFilterDTO[] =>
  filters?.map(filter => ({ id: filter.id, name: filter.name }));

const getStructuredData = (merchant: IOutSingleMerchantDTO, platformId: number, pageUrl: string): string => {
  if (!merchant?.campaign) {
    return "";
  }

  const url = `${GET_CONFIG.CLIENT_URL.slice(0, -1)}${pageUrl}`;

  const platform = findPlatformContent(platformId);

  const structuredData = {
    "@context": "https://schema.org",
    "@type": "Offer",
    name: merchant.name,
    url,
    description: merchant.campaign.description,
    priceCurrency: platform.currency,
    price: "0.00",
    discount: merchant.campaign.excerpt,
    validFrom: merchant.campaign.startDate,
    validThrough: merchant.campaign.endDate,
    availability: "https://schema.org/InStock",
    image: platform.logo.src,
    seller: {
      "@type": "Organization",
      name: platform.name,
      url: platform.url
    },
    eligibleRegion: {
      "@type": "Place",
      name: platform.country
    }
  };

  return JSON.stringify(structuredData);
};
