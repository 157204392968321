import { MerchantsApi, MerchantListResponseDTO } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getMerchantsApi = async (
  take: number,
  skip: number,
  platformId: number,
  filters?: string
): Promise<MerchantListResponseDTO | undefined> => {
  try {
    const api = new MerchantsApi();
    const resultFromApi = await api.merchantsGet(take, skip, platformId, undefined, filters);

    if (!resultFromApi || !resultFromApi.merchants) return moduleMapperThrowMessage("Cannot get merchants");

    return resultFromApi;
  } catch (e) {
    Logger.logError(e, "Error in: getMerchantsApi");

    return undefined;
  }
};
