import React, { useState, useEffect } from "react";
import { createPortal } from "react-dom";

import Cross from "@assets/icons/cross.svg";
import sortIcon from "@assets/icons/filter-sort.svg";
import RoundCheckboxOffIcon from "@assets/icons/round-checkbox-off.svg";
import RoundCheckboxOnIcon from "@assets/icons/round-checkbox-on.svg";
import { setBodyFixed } from "@app/util/set-body-fixed";
import HandPointing from "@assets/icons/hand-pointing.svg";

import { IconComponent, ResourceTextComponent } from "..";
import styles from "./mobile-sort-component.module.scss";

export interface IMobileSortComponentProps {
  onMobileSortChanged: (sortBy: string) => void;
  onMobileSortOpen: (isOpen: boolean) => void;
  selectedSortOption: string;
  sortOptions: string[];
}

export const MobileSortComponent = (props: IMobileSortComponentProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  let savedScrollPosition = 0;
  useEffect(() => {
    // Mobile filters opened or closed.
    savedScrollPosition = window.scrollY;

    if (props.onMobileSortOpen) {
      props.onMobileSortOpen(isOpen);
    }

    if (isOpen) {
      setBodyFixed(true);
    } else {
      setBodyFixed(false);
    }

    if (savedScrollPosition) {
      window.scrollTo(0, savedScrollPosition);
    }
  }, [isOpen]);

  if (isOpen) {
    return createPortal(
      <div className={styles.mobileFilter}>
        <div className={styles.header}>
          <span
            role="button"
            className={styles.navButton}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <IconComponent strokeColor="#fff" icon={Cross} size={"12px"} />

            <span className={styles.middleTitle}>
              <ResourceTextComponent resourceKey={"mobileFilter.mobileSortTitle"} />
              <IconComponent icon={HandPointing} size="16px" />
            </span>
          </span>
        </div>
        <div className={styles.list}>
          {props.sortOptions.map((item, index) => (
            <div
              role="button"
              className={styles.subFilterItem}
              key={index}
              onClick={(event: any) => {
                props.onMobileSortChanged(item);
                props.onMobileSortOpen(false);
                setIsOpen(false);
              }}
            >
              <span className={styles.filterItemButton}>
                <IconComponent
                  icon={props.selectedSortOption === item ? RoundCheckboxOnIcon : RoundCheckboxOffIcon}
                  size="16px"
                />
                <span className={styles.filterItemText}>{item}</span>
              </span>
            </div>
          ))}
        </div>
      </div>,
      document.body
    );
  }

  // Render Filter button. (filter selection closed)
  return (
    <div
      role="button"
      onClick={() => {
        setIsOpen(true);
      }}
      className={styles.filterBar}
    >
      <IconComponent icon={sortIcon} size="20px" />
    </div>
  );
};
