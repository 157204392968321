import React from "react";
import clsx from "clsx";

import styles from "./typography.module.scss";

export type TypographyVariants = "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "p" | "small" | "screenreader";

export type AlignOptions = "left" | "center" | "right" | "justify";

export interface ITypographyProps {
  id?: string;
  tag?: keyof JSX.IntrinsicElements;
  variant?: TypographyVariants;
  align?: AlignOptions;
  children?: React.ReactChild | React.ReactChild[];
  className?: string;
}

export const Typography = ({
  variant = "p",
  tag,
  align,
  children,
  className,
  ...rest
}: ITypographyProps): JSX.Element => {
  const CustomTag = tag || (variant === "screenreader" ? "span" : variant);
  const customStyling = variant === "screenreader" || tag ? styles[variant] : undefined;

  return (
    <CustomTag className={clsx(customStyling, align && styles[align], className)} {...rest}>
      {children}
    </CustomTag>
  );
};

export default Typography;
