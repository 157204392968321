import * as express from "express";
import { IArrowPanelProps } from "@app/core";
import storeIcon from "@assets/icons/store.svg";
import dealIcon from "@assets/icons/medal.svg";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";
import { IWPSidebarComponent } from "./sidebar-component.interface";
import { IWPFeaturedMerchants, getFeaturedMerchantsMapper } from "../../module-mappers/featured-merchants-module";
import { IWPFeaturedDeals, getFeaturedDealsMapper } from "../../module-mappers/featured-deals-module";
import { formatMessage } from "@app/translations/intl";
import { FeaturedMerchantsSelectionChoiceType } from "@app/api/modules/featured-merchants/featured-merchants.module";
import { FeaturedDealsSelectionChoiceType } from "@app/api/modules/featured-deals/featured-deals.module";

const DEFAULT_AMOUNT_OF_MERCHANTS = 4;
const DEFAULT_AMOUNT_OF_DEALS = 4;

interface IGetSidebarComponentInfo {
  bannerModuleFilters?: string;
  featuredDeals?: IArrowPanelProps[];
  featuredMerchants?: IArrowPanelProps[];
}

export const getSidebarModuleInfo = async (
  platformId: number,
  sidebar: IWPSidebarComponent,
  res?: express.Response
): Promise<IGetSidebarComponentInfo> => {
  const { banner, featuredDeals, featuredStores } = sidebar;

  const bannerModuleFilters = banner?.showBanner
    ? combineFilters([banner?.categorySelection, banner?.brandSelection])
    : undefined;

  if (res) {
    // Enable if we want the banners to be set from server. Banners are changed upon usage from Local storage. So setting banners from server side is redundant.
    // await setBannersInStore(res, platformId, bannerModuleFilters);
  }

  const featuredMerchantsModuleFilters = combineFilters([
    featuredStores?.filters?.brandSelection,
    featuredStores?.filters?.categorySelection
  ]);

  const featuredMerchants = featuredStores?.showFeaturedStores
    ? await mapFeaturedMerchantsToItems(platformId, featuredMerchantsModuleFilters, featuredStores)
    : undefined;

  const featuredDealsModuleFilters = combineFilters([
    featuredDeals?.filters?.brandSelection,
    featuredDeals?.filters?.categorySelection
  ]);

  const allFeaturedDeals = featuredDeals?.showFeaturedDeals
    ? await mapFeaturedDealsToItems(platformId, featuredDealsModuleFilters, sidebar?.featuredDeals)
    : undefined;

  return { bannerModuleFilters, featuredMerchants, featuredDeals: allFeaturedDeals };
};

const mapFeaturedMerchantsToItems = async (
  platformId: number,
  filterIds: string,
  featuredModule?: IWPFeaturedMerchants
): Promise<IArrowPanelProps[] | undefined> => {
  const limit = Number(featuredModule?.filters?.merchantSelectionAmount) || DEFAULT_AMOUNT_OF_MERCHANTS;
  const merchantSelection = featuredModule?.merchants?.merchantSelection;
  const featuredMerchants = await getFeaturedMerchantsMapper({
    filterIds,
    platformId,
    limit,
    selectionChoice: featuredModule?.selectionChoice as FeaturedMerchantsSelectionChoiceType,
    merchantSelection
  });

  if (!featuredMerchants || featuredMerchants.length === 0) return undefined;

  const mappedMerchants: IArrowPanelProps[] = featuredMerchants.map(featuredMerchant => {
    const merchantOverviewLink = formatMessage({ id: "global.merchantOverviewLink" });

    return {
      image: {
        alt: featuredMerchant.logoImage?.alt || "",
        src: featuredMerchant.logoImage?.url || "",
        title: featuredMerchant.logoImage?.title || ""
      },
      link: {
        url: `${merchantOverviewLink}${featuredMerchant.slug}/`,
        title: featuredMerchant.name
      }
    };
  });

  const ctaItem: IArrowPanelProps = {
    fallbackImgUrl: storeIcon,
    link: {
      url: featuredModule?.cta?.url || "",
      title: featuredModule?.cta?.title || ""
    }
  };

  return [...mappedMerchants, ctaItem];
};

const mapFeaturedDealsToItems = async (
  platformId: number,
  filterIds: string,
  featuredDealsModule?: IWPFeaturedDeals
): Promise<IArrowPanelProps[] | undefined> => {
  const dealSelectionCount = Number(featuredDealsModule?.filters?.dealsSelectionAmount) || DEFAULT_AMOUNT_OF_DEALS;
  const dealSelection = featuredDealsModule?.deals?.dealSelection;
  const featuredDeals = await getFeaturedDealsMapper({
    filterIds,
    platformId,
    limit: dealSelectionCount,
    selectionChoice: featuredDealsModule?.selectionChoice as FeaturedDealsSelectionChoiceType,
    dealSelection
  });

  if (!featuredDeals || featuredDeals.length === 0) return undefined;

  const mappedDeals: IArrowPanelProps[] = featuredDeals.map(featuredDeal => ({
    image: {
      alt: featuredDeal.image?.alt || "",
      src: featuredDeal.image?.url || "",
      title: featuredDeal.image?.title || ""
    },
    link: {
      url: featuredDeal.merchant?.slug
        ? `/out/offer/${featuredDeal.merchant.slug}/${featuredDeal.id}/`
        : `/out/offer/${featuredDeal.id}/`,
      title: featuredDeal.name,
      newTab: true
    }
  }));

  const ctaItem: IArrowPanelProps = {
    fallbackImgUrl: dealIcon,
    link: {
      url: featuredDealsModule?.cta?.url || "",
      title: featuredDealsModule?.cta?.title || ""
    }
  };

  return [...mappedDeals, ctaItem];
};
