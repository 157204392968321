import * as React from "react";
import styles from "./cta-small-component.module.scss";
import { ShadowCardComponent } from "@app/core/shadow-card";
import { ImageComponent } from "@app/core";
import { ClickableComponent } from "@app/core/clickable";
export interface ICtaSmallComponentProps {
  buttonTitle?: string;
  ctaText?: string;
  href?: string;
  icon?: any;
  onClick?: () => void;
}

const CtaSmallComponent = (props: ICtaSmallComponentProps) => {
  const { buttonTitle, href, icon, onClick, ctaText } = props;

  return (
    <div className={styles["cta-small"]}>
      <div className="uk-container">
        <ShadowCardComponent borderRadius={["2px"]}>
          <div className={styles["cta-small__center"]}>
            <div className={styles["cta-small__wrapper"]}>
              {icon && (
                <div className={styles["icon"]}>
                  <ImageComponent src={icon} />
                </div>
              )}
              <div className={styles["cta-small__title"]}>{ctaText}</div>
              {buttonTitle && (
                <ClickableComponent href={href} onClick={onClick} title={buttonTitle} variant="primary-brand" />
              )}
            </div>
          </div>
        </ShadowCardComponent>
      </div>
    </div>
  );
};

export { CtaSmallComponent };
