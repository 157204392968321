import { IWPMerchantLinkListModuleFull } from "./merchant-link-list-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { MerchantLinkListModule } from "@app/api/modules/merchant-link-list/merchant-link-list";
import { getMerchantLinkList, IMerchantLinkList } from "@app/modules/merchant-link-list/get-merchant-link-list";

export interface IMerchantLinkListModuleMapper extends IModuleFetcher {
  module: IWPMerchantLinkListModuleFull;
}

export const merchantLinkListModuleMapper = async (
  props: IMerchantLinkListModuleMapper
): Promise<MerchantLinkListModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      res,
      platformId
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in merchantLinkListModuleMapper");

    let merchantList: IMerchantLinkList[] = [];

    if (res) {
      const merchants = await getMerchantLinkList(platformId);
      merchantList = merchants || [];
    }

    return {
      id: "21",
      name: "MerchantLinkListModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      title: data.title,
      merchants: merchantList
    };
  } catch (e) {
    Logger.logError(e, "Error in: merchantLinkListModuleMapper");

    return undefined;
  }
};
