import React, { useEffect, useRef } from "react";
import clsx from "clsx";
import ReactTooltip from "react-tooltip";

import ScissorsIcon from "@assets/icons/scissors.svg";
import { formatMessage } from "@app/translations/intl";

import { IconComponent } from "../icon";
import styles from "./coupon-button-component.module.scss";

interface ICouponButtonProps {
  coupon: string;
  onCouponCopied?: () => void;
  classNames?: string;
  hideIcon?: boolean;
}

const CouponButtonComponent = (props: ICouponButtonProps) => {
  const [couponCopied, setCouponCopied] = React.useState<boolean>(false);
  const targetTooltip = useRef<HTMLButtonElement>(null);

  const copyToClipboard = (text: string) => {
    createAnElementWithTextAndSelectCopy(text);
    setCouponCopied(true);
    props.onCouponCopied?.();
  };

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  return (
    <button
      onClick={() => copyToClipboard(props.coupon)}
      className={clsx(styles.coupon, props.classNames, props.hideIcon && styles.noIcon, couponCopied && styles.copied)}
      ref={targetTooltip}
      data-event="click"
      data-tip={formatMessage({ id: "discountCard.discountCodeCopied" })}
    >
      <span className={styles.text}>{props.coupon}</span>
      {!props.hideIcon && (
        <div className={styles.icon}>
          <IconComponent icon={ScissorsIcon} size="16px" />
        </div>
      )}
    </button>
  );
};

export { CouponButtonComponent };

const createAnElementWithTextAndSelectCopy = (value: string) => {
  const tempInput = document.createElement("input");
  tempInput.style.position = "absolute";
  tempInput.style.left = "-1000px";
  tempInput.style.top = "-1000px";
  tempInput.value = value;
  document.body.appendChild(tempInput);
  tempInput.select();
  /* tslint:disable-next-line deprecation */
  document.execCommand("copy");
  document.body.removeChild(tempInput);
};
