/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ImageDTO
 */
export interface ImageDTO {
  /**
   *
   * @type {number}
   * @memberof ImageDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof ImageDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ImageDTO
   */
  alt?: string;
  /**
   *
   * @type {string}
   * @memberof ImageDTO
   */
  url: string;
  /**
   *
   * @type {string}
   * @memberof ImageDTO
   */
  hash?: string;
}

export function ImageDTOFromJSON(json: any): ImageDTO {
  return ImageDTOFromJSONTyped(json, false);
}

export function ImageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImageDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: !exists(json, "title") ? undefined : json["title"],
    alt: !exists(json, "alt") ? undefined : json["alt"],
    url: json["url"],
    hash: !exists(json, "hash") ? undefined : json["hash"]
  };
}

export function ImageDTOToJSON(value?: ImageDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    alt: value.alt,
    url: value.url,
    hash: value.hash
  };
}
