import { IUsp, IUspsModule } from "@app/api/modules/usps-module/usps.module";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";
import { IModuleFetcher } from "../../module-selector";
import { IWPUsp, IWPUspModuleFull } from "./usp-module.interface";

export interface IUspModuleMapper extends IModuleFetcher {
  module: IWPUspModuleFull;
}

export const UspModuleMapper = async (props: IUspModuleMapper): Promise<IUspsModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in uspsModuleMapper");

    return {
      id: "23",
      name: "uspModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      usps: mapUsps(data.usp)
    };
  } catch (e) {
    Logger.logError(e, "Error in: uspsModuleMapper");
    return undefined;
  }
};

const mapUsps = (usps?: IWPUsp[]): IUsp[] =>
  usps?.map(usp => ({
    icon: { src: usp.icon?.url || "", alt: usp.icon?.alt || "", title: usp.icon?.title || "" },
    text: usp.text || ""
  })) || [];
