import React from "react";

import { OutBannerDTO } from "@app/bf-api";
import { formatMessage } from "@app/translations/intl";

import { BannerV3Component } from "./design-v3/banner-v3.component";

export type BannerVariant = "default" | "small";

export interface IBannerComponentProps {
  banner?: OutBannerDTO;
  design?: "new" | "old";
  showAlternativeBanner?: boolean;
  variant?: BannerVariant;
}

export const BannerComponent = (props: IBannerComponentProps) => {
  const { banner, showAlternativeBanner, variant = "default" } = props;
  const bannerLink = `/out/banner/${banner?.slug}/`;
  const buttonText = banner?.cta || formatMessage({ id: "banner.button.toCampaignPageOut" });

  return (
    <div className="uk-container">
      <BannerV3Component
        banner={banner}
        bannerLink={bannerLink}
        buttonText={buttonText}
        showAlternativeBanner={showAlternativeBanner}
        variant={variant}
      />
    </div>
  );
};
