import * as React from "react";
import { NotYetImplementedComponent } from "@app/modules/not-yet-implemented";
import { CtaSmallModuleComponent } from "@app/modules/cta-small";
import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { FeaturedMerchantsModule } from "@app/api/modules/featured-merchants/featured-merchants.module";
import { NotYetImplementedModule } from "@app/api/modules/not-implemented/not-yet-implemented-module";
import { BannerModule } from "@app/api/modules/banner/banner.module";
import { HomepageHeader } from "@app/api/modules/homepage-header/homepage-header.module";
import { CtaSmallModule } from "@app/api/modules/cta-small/cta-small.module";
import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { MediumHeroComponent } from "@app/modules/medium-hero";
import { MediumHeroModule } from "@app/api/modules/medium-hero/medium-hero";
import { ShopSingleHeaderModule } from "@app/api/modules/shop-single-header/shop-single-header.module";
import { CtaNewsletterModule } from "@app/api/modules/cta-newsletter/cta-newsletter.module";
import { BlogSingleHeaderComponent } from "@app/modules/blog-single-header";
import { BlogOverviewComponent } from "@app/modules/blog-overview";
import { BlogOverviewModule } from "@app/api/modules/blog-overview/blog-overview";
import { BlogOverviewHeaderComponent } from "@app/modules/blog-overview-header";
import { BlogOverviewHeaderModule } from "@app/api/modules/blog-overview-header/blog-overview-header";
import { BlogSingleHeaderModule } from "@app/api/modules/blog-single-header/blog-single-header";
import { FeaturedBlogModule } from "@app/api/modules/featured-blog/featured-blog";
import { BlogBodyTextComponent } from "@app/modules/blog-body-text";
import { BlogBodyTextModule } from "@app/api/core/blog-bodytext/blog-bodytext";
import { StoreOverviewModule } from "@app/api/modules/store-overview/store-overview.module";
import { DealOverviewModule } from "@app/api/modules/deal-overview/deal-overview.module";
import { HeaderInfoComponent } from "@app/modules/header-info";
import { HeaderInfoModule } from "@app/api/modules/header-info/header-info.module";
import { ErrorPageComponent } from "@app/modules/error-page";
import { ErrorPageModule } from "@app/api/modules/error-page/error-page";
import { BannerWrapper } from "@app/core/banner-wrapper";
import { FeaturedCategoriesModule } from "@app/api/modules/featured-categories/featured-categories.module";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { FeaturedDealsModule } from "@app/api/modules/featured-deals/featured-deals.module";
import { LandingPageComponent } from "@app/modules/landing-page";
import { LandingPageModule } from "@app/api/modules/landing-page/landing-page";
import { LinkBoxesComponent } from "@app/modules/link-boxes";
import { ILinkBoxesModule } from "@app/api/modules/link-boxes/link-boxes.module";
import { CtaHeaderModule } from "@app/api/modules/cta-header/cta-header.module";
import { MerchantLinkListModule } from "@app/api/modules/merchant-link-list/merchant-link-list";
import { CtaNewsletterComponent } from "@app/modules/cta-newsletter/cta-newsletter.component";
import { UspsComponent } from "@app/core";
import { IUspsModule } from "@app/api/modules/usps-module/usps.module";
import { AuthorityComponent } from "@app/modules/authority/authority.component";
import { AuthorityModule } from "@app/api/modules/authority/authority.module";
import { CtaHeader } from "@app/modules/cta-header/cta-header.component";
import { NavBarComponent } from "@app/modules/nav-bar/nav-bar.component";
import { FeaturedCategories } from "@app/modules/featured-categories";
import { FooterComponent } from "@app/modules/footer";
import { FeaturedMerchants } from "@app/modules/featured-merchants";
import { MerchantLinkListComponent } from "@app/modules/merchant-link-list";
import { DealOverview } from "@app/modules/deal-overview";
import { FeaturedBlogComponent } from "@app/modules/featured-blog";
import { FeaturedDeals } from "@app/modules/featured-deals";
import { MerchantOverview } from "@app/modules/merchant-overview";
import { HomePageHeaderComponent } from "@app/modules/homepage-header";
import { FeaturedDealsSearch } from "@app/modules/featured-deals-search";
import { FeaturedDealsSearchModule } from "@app/api/modules/featured-deals-search/featured-deals-search.module";
import { MerchantSingleHeaderWrapper } from "@app/modules/merchant-single-header-wrapper/merchant-single-header-wrapper.component";
import { WhenIs } from "@app/modules/when-is/when-is.component";
import { IWhenIsModule } from "@app/api/modules/when-is/when-is.module";
import { ICategoryBlocksModule } from "@app/api/modules/category-blocks/category-blocks.modules";
import { CategoryBlocks } from "@app/modules/category-blocks/category-blocks.component";
import { ProductSingle } from "@app/modules/product-single/product-single.component";
import { ProductSingleModule } from "@app/api/modules/product-single/product-single.module";
import { SearchResults } from "@app/modules/search-results";
import { SearchResultsModule } from "@app/api/modules/search-results/search-results.module";

export const getComponent = (wordPressModule: WordPressPostModule) => {
  switch (wordPressModule.name) {
    case "BannerModule":
      return <BannerWrapper bannerModule={wordPressModule as BannerModule} />;
    case "CtaSmallModule":
      return <CtaSmallModuleComponent ctaSmallModule={wordPressModule as CtaSmallModule} />;
    case "CtaNewsletterModule":
      return <CtaNewsletterComponent ctaNewsLetterModule={wordPressModule as CtaNewsletterModule} />;
    case "FeaturedMerchantsModule":
      return <FeaturedMerchants featuredMerchantsModule={wordPressModule as FeaturedMerchantsModule} />;
    case "FooterModule":
      return <FooterComponent footerModule={wordPressModule as FooterModule} />;
    case "HomepageHeader":
      return <HomePageHeaderComponent homePageModule={wordPressModule as HomepageHeader} />;
    case "CtaHeaderModule":
      return <CtaHeader module={wordPressModule as CtaHeaderModule} />;
    case "MediumHeroModule":
      return <MediumHeroComponent mediumHeroModule={wordPressModule as MediumHeroModule} />;
    case "NavBarModule":
      return <NavBarComponent navBarModule={wordPressModule as INavBarModule} />;
    case "notYetImplemented":
      return <NotYetImplementedComponent notYetImplemented={wordPressModule as NotYetImplementedModule} />;
    case "ShopSingleHeaderModule":
      return <MerchantSingleHeaderWrapper shopSingleHeaderModule={wordPressModule as ShopSingleHeaderModule} />;
    case "StoreOverviewModule":
      return <MerchantOverview storeOverviewModule={wordPressModule as StoreOverviewModule} />;
    case "DealOverviewModule":
      return <DealOverview dealOverviewModule={wordPressModule as DealOverviewModule} />;
    case "BlogSingleHeaderModule":
      return <BlogSingleHeaderComponent blogSingleHeader={wordPressModule as BlogSingleHeaderModule} />;
    case "BlogOverviewModule":
      return <BlogOverviewComponent blog={wordPressModule as BlogOverviewModule} />;
    case "BlogOverviewHeaderModule":
      return <BlogOverviewHeaderComponent blogOverviewHeader={wordPressModule as BlogOverviewHeaderModule} />;
    case "BlogBodyTextModule":
      return <BlogBodyTextComponent blogBodyTextModule={wordPressModule as BlogBodyTextModule} />;
    case "FeaturedBlogModule":
      return <FeaturedBlogComponent featuredBlog={wordPressModule as FeaturedBlogModule} />;
    case "FeaturedDealsModule":
      return <FeaturedDeals featuredDealsModule={wordPressModule as FeaturedDealsModule} />;
    case "FeaturedCategoriesModule":
      return <FeaturedCategories featuredCategoriesModule={wordPressModule as FeaturedCategoriesModule} />;
    case "HeaderInfo":
      return <HeaderInfoComponent headerInfo={wordPressModule as HeaderInfoModule} />;
    case "LandingPageModule":
      return <LandingPageComponent landingPageModule={wordPressModule as LandingPageModule} />;
    case "MerchantLinkListModule":
      return <MerchantLinkListComponent merchantLinkList={wordPressModule as MerchantLinkListModule} />;
    case "LinkBoxesModule":
      return <LinkBoxesComponent linkBoxes={wordPressModule as ILinkBoxesModule} />;
    case "uspModule":
      return <UspsComponent usps={wordPressModule as IUspsModule} />;
    case "authorityModule":
      return <AuthorityComponent authority={wordPressModule as AuthorityModule} />;
    case "FeaturedDealsSearchModule":
      return <FeaturedDealsSearch featuredDealsModule={wordPressModule as FeaturedDealsSearchModule} />;
    case "WhenIsModule":
      return <WhenIs whenIsModule={wordPressModule as IWhenIsModule} />;
    case "CategoryBlocksModule":
      return <CategoryBlocks categoryBlocksModule={wordPressModule as ICategoryBlocksModule} />;
    case "ProductSingleModule":
      return <ProductSingle module={wordPressModule as ProductSingleModule} />;
    case "SearchResultsModule":
      return <SearchResults module={wordPressModule as SearchResultsModule} />;
    case "NotFoundModule":
      return <ErrorPageComponent notFoundModule={wordPressModule as ErrorPageModule} />;
    default:
  }
};
