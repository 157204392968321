/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutMerchantDTO, OutMerchantDTOFromJSON, OutMerchantDTOFromJSONTyped, OutMerchantDTOToJSON } from "./";

/**
 *
 * @export
 * @interface MerchantSearchDTO
 */
export interface MerchantSearchDTO {
  /**
   *
   * @type {Array<OutMerchantDTO>}
   * @memberof MerchantSearchDTO
   */
  data?: Array<OutMerchantDTO>;
  /**
   *
   * @type {number}
   * @memberof MerchantSearchDTO
   */
  count: number;
}

export function MerchantSearchDTOFromJSON(json: any): MerchantSearchDTO {
  return MerchantSearchDTOFromJSONTyped(json, false);
}

export function MerchantSearchDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): MerchantSearchDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    data: !exists(json, "data") ? undefined : (json["data"] as Array<any>).map(OutMerchantDTOFromJSON),
    count: json["count"]
  };
}

export function MerchantSearchDTOToJSON(value?: MerchantSearchDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    data: value.data === undefined ? undefined : (value.data as Array<any>).map(OutMerchantDTOToJSON),
    count: value.count
  };
}
