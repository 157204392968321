export const checkExternalUrl = (url?: string): boolean => {
  if (!url) return false;
  if (url.indexOf("https://") > -1 || url.indexOf("http://") > -1 || url.indexOf("www.") > -1) return true;

  return false;
};

export const checkSameDomain = (url?: string): boolean => {
  if (typeof window === "undefined") return false;

  const currentPageUrl = window.location.href;

  if (url && url.indexOf(".") === -1) return true;

  return checkSameDomainFromStrings(currentPageUrl, url);
};

export const checkSameDomainFromStrings = (firstLink?: string, secondLink?: string): boolean =>
  firstLink && secondLink && getDomainFromString(firstLink) === getDomainFromString(secondLink) ? true : false;

export const getDomainFromString = (url: string) =>
  url.replace("https://", "").replace("http://", "").replace("www.", "").split("/")[0];
