export const getBlackFridayYear = (): number => {
  const blackFridayMonth = 12;
  const blackFridayEndDay = 1;
  const today = new Date();
  const blackFridayHasPassed =
    today.getMonth() + 1 >= blackFridayMonth && today.getDate() >= blackFridayEndDay ? true : false;
  const blackFridayYear = blackFridayHasPassed ? today.getFullYear() + 1 : today.getFullYear();

  return blackFridayYear;
};
