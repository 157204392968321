import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";

import { SearchInput } from "@app/components/search-input/search-input";
import SearchIcon from "@assets/icons/search.svg";
import { IconComponent } from "@app/core";
import { searchItems } from "@app/redux/thunks/navbar-search.thunk";
import { formatMessage } from "@app/translations/intl";
import { useOnClickOutside } from "@app/util/hooks/use-on-click-outside";
import CrossIcon from "@assets/icons/search-cross.svg";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { useDebouncedValue } from "@app/util/hooks/use-debounced-value";
import { InfiniteLineLoader } from "@app/core/infinite-line-loader/infinite-line-loader.component";
import { useAppSelector } from "@app/redux/store";
import { getHotkeyHandler, useHotkeys } from "@app/util/hooks/use-hotkeys/use-hotkeys";
import { searchPageLink } from "@app/util/search-page-link";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";

import { SearchResults } from "../../search-results/search-results";
import styles from "../new-nav-bar-component.module.scss";

interface IProps {
  popularDeals?: IOutFeaturedDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
}

const component = (props: IProps & RouteComponentProps) => {
  const searchResultsRef = React.useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const [isSearchOpen, setIsSearchOpen] = useState(false);
  const { isLoading } = useAppSelector(state => state.search);
  const platformId = Number(formatMessage({ id: "global.platformId" }));
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    if (typeof debouncedSearchValue === "string") {
      search(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  const search = (s: string) => {
    dispatch(
      searchItems({
        searchValue: s,
        platformId,
        limit: {
          deals: 6,
          merchants: 6,
          products: 8,
          pages: 8
        }
      })
    );
  };

  const openSearch = () => {
    setIsSearchOpen(true);
  };

  const closeSearch = () => {
    setSearchValue("");
    setIsSearchOpen(false);
  };

  const handleDelete = () => {
    setSearchValue("");
  };

  useEffect(() => {
    if (isSearchOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isSearchOpen]);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, navigateToSearchPage?: boolean) => {
    if (navigateToSearchPage) {
      props.history.push(searchPageLink(searchValue));
    }
    (e.target as HTMLInputElement)?.blur();
    closeSearch();
  };

  useOnClickOutside(searchResultsRef, closeSearch);
  useHotkeys([["escape", closeSearch]]);

  return (
    <>
      <div ref={searchResultsRef} className={styles.search}>
        <SearchInput
          id="searchInputDesktop"
          icon={<IconComponent fillColor="white" size="14px" icon={SearchIcon} />}
          iconPosition="right"
          onChange={handleChange}
          placeholder={formatMessage({ id: "navBar.search.placeholder" })}
          type="text"
          value={searchValue || ""}
          onFocus={openSearch}
          deleteIcon={<IconComponent fillColor="white" size="14px" icon={CrossIcon} />}
          onDelete={handleDelete}
          onKeyDown={getHotkeyHandler([
            ["enter", e => handleInputKeyDown(e, true)],
            ["escape", handleInputKeyDown]
          ])}
        />
        {isSearchOpen && (
          <div className={styles.searchResultsContainer}>
            <div className={styles.searchResultsContent}>
              {isLoading && <InfiniteLineLoader />}
              <SearchResults
                searchValue={debouncedSearchValue}
                popularDeals={props.popularDeals}
                popularMerchants={props.popularMerchants}
                closeSearch={closeSearch}
              />
            </div>
          </div>
        )}
      </div>
      {isSearchOpen && <div className={styles.overlay} />}
    </>
  );
};

const NavbarSearch = withRouter(component);

export { NavbarSearch };
