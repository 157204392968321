import React from "react";
import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { MobileNavBar } from "./mobile/mobile-nav-bar.component";
import { DesktopNavBar } from "./dekstop/dekstop-nav-bar.component";
import styles from "./nav-bar-component.module.scss";

export interface INavBarComponentProps {
  navBarModule: INavBarModule;
}

export const NavBarComponent = (props: INavBarComponentProps) => (
  <>
    <div className={styles.showForTabletAndBelow}>
      <MobileNavBar navBarModule={props.navBarModule} />
    </div>

    <div className={styles.showForTabletAndAbove}>
      <DesktopNavBar navBarModule={props.navBarModule} />
    </div>
  </>
);
