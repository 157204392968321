export const WEBSITE_COLORS = {
  RED: "#e10a14",
  RED_DARKEN_7: "#85050b",
  RED_DARKEN_16: "#C10A12",
  RED_DARKEN_35: "#360205",
  RED_LIGHTEN_51: "#fef1f1",
  RED_RGB: "225,10,20",
  ORANGE: "#ff6a04",
  ORANGE_DARKEN_7: "#df5b00",
  ORANGE_DARKEN_16: "#EA5F00",
  ORANGE_DARKEN_35: "#512100",
  ORANGE_LIGHTEN_40: "#ffe3d0",
  ORANGE_RGB: "255,106,4",
  BLUE: "#003AA5",
  BLUE_DARKEN_7: "#013086",
  BLUE_DARKEN_16: "#00288C",
  BLUE_DARKEN_35: "#011945",
  BLUE_LIGHTEN_51: "#ADCAFF",
  BLUE_RGB: "0,58,165",
  UK_RED: "#D10D24",
  UK_RED_DARKEN_7: "#B20B1B",
  UK_RED_DARKEN_16: "#990A17",
  UK_RED_DARKEN_35: "#730711",
  UK_RED_LIGHTEN_51: "#FFB6BF",
  UK_RED_RGB: "209,13,36",
  UK_BLUE: "#014991",
  UK_BLUE_DARKEN_7: "#013F7E",
  UK_BLUE_DARKEN_16: "#023F7C",
  SE_BLUE: "#0A5189",
  SE_BLUE_DARKEN_7: "#094778",
  SE_BLUE_DARKEN_16: "#073C66",
  SE_BLUE_DARKEN_35: "#052D4D",
  SE_BLUE_LIGHTEN_51: "#CCE9FF",
  SE_BLUE_RGB: "10,81,137",
  DE_RED: "#EE0000",
  DE_RED_DARKEN_7: "#D40000",
  DE_RED_DARKEN_16: "#B80000",
  DE_RED_DARKEN_35: "#870000",
  DE_RED_LIGHTEN_51: "#FFB4B4",
  DE_RED_RGB: "0,100,93",
  ES_RED: "#C60B1E",
  ES_RED_DARKEN_7: "#AD0A1A",
  ES_RED_DARKEN_16: "#940816",
  ES_RED_DARKEN_35: "#7A0712",
  ES_RED_LIGHTEN_51: "#F5ADB4",
  ES_RED_RGB: "354,94,78",
  IT_GREEN: "#009345",
  IT_GREEN_DARKEN_7: "#007A39",
  IT_GREEN_DARKEN_16: "#00612D",
  IT_GREEN_DARKEN_35: "#005226",
  IT_GREEN_LIGHTEN_51: "#B6EFD1",
  IT_GREEN_RGB: "148,100,58",
  BE_LIGHT_ACCENT: "#FFEFF1",
  NL_LIGHT_ACCENT: "#EFEEFA",
  FR_LIGHT_ACCENT: "#EFEEFA",
  DE_LIGHT_ACCENT: "#FFFAE9",
  IT_LIGHT_ACCENT: "#F8E9E6",
  SE_LIGHT_ACCENT: "#FFFAE9",
  ES_LIGHT_ACCENT: "#FFF6E9",
  UK_LIGHT_ACCENT: "#EFEEFA"
};
