import React, { useEffect, useState } from "react";
import clsx from "clsx";

import { formatMessage } from "@app/translations/intl";
import GreenCheckmarkIcon from "@assets/icons/green-checkmark.svg";
import { Icon } from "@app/components/icon/icon";
import { MerchantCardWrapper } from "@app/modules/merchant-card-wrapper/merchant-card-wrapper";
import { SimpleProductCard } from "@app/components/product-cards/simple-product-card/simple-product-card";
import { DealCardWrapper } from "@app/modules/deal-card-wrapper/deal-card-wrapper";
import { PageCard } from "@app/components/page-card/page-card";
import { SearchResultsModule } from "@app/api/modules/search-results/search-results.module";
import { IUseRefListScrollIntoView } from "@app/util/hooks/use-ref-list-scroll-into-view";

import { CardResults } from "../card-results/card-results";
import { getModulesToShow } from "../search-results.component";
import styles from "./no-results.module.scss";

type ResultState = "empty" | "no-results" | "results";

interface IProps extends SearchResultsModule {
  searchValue?: string;
  isLoading?: boolean;
  className?: string;
  setRef: IUseRefListScrollIntoView<HTMLDivElement>["setRef"];
  sections: ReturnType<typeof getModulesToShow>;
}

export const NoResults = (props: IProps) => {
  const { defaults, modules, translations, isLoading, searchValue, className, setRef } = props;
  const resultState = getResultState(props);
  const [results, setResults] = useState<ResultState>(resultState);
  const [prevSearchValue, setPrevSearchValue] = useState<string>();

  useEffect(() => {
    if (!isLoading) {
      setResults(resultState);
      setPrevSearchValue(searchValue);
    }
  }, [isLoading]);

  if (resultState === "results") {
    return null;
  }

  return (
    <div className={clsx(styles.wrapper, className)}>
      {results === "no-results" && (
        <div className={styles.noResults}>
          <h2 className={styles.title}>
            {formatMessage(
              { id: "navbar.search.noResult.text" },
              {
                searchValue: prevSearchValue
              }
            )}
          </h2>
          <ul className={styles.steps}>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipOne" })}</span>
            </li>
            <li>
              <span className={styles.icon}>
                <Icon icon={GreenCheckmarkIcon} />
              </span>
              <span>{formatMessage({ id: "navbar.search.noResult.tipTwo" })}</span>
            </li>
          </ul>
        </div>
      )}
      {(resultState === "empty" || resultState === "no-results") && (
        <div className={styles.defaults}>
          {modules?.includes("merchants") && defaults?.merchants && defaults?.merchants.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-merchants")}
              anchorId="results-merchants"
              translations={translations?.merchants}
              cards={defaults.merchants.map(merchant => (
                <MerchantCardWrapper key={merchant.id} merchant={merchant} variant="mobile-search" responsive />
              ))}
            />
          )}
          {modules?.includes("products") && defaults?.products && defaults.products.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-products")}
              anchorId="results-products"
              maxColumns={4}
              translations={translations?.products}
              cards={defaults.products.map(product => (
                <SimpleProductCard key={product.id} product={product} />
              ))}
            />
          )}
          {modules?.includes("deals") && defaults?.deals && defaults.deals.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-deals")}
              anchorId="results-deals"
              translations={translations?.deals}
              cards={defaults.deals.map(deal => (
                <DealCardWrapper key={deal.id} deal={deal} responsive variant="mobile-search" />
              ))}
            />
          )}
          {modules?.includes("pages") && defaults?.pages && defaults?.pages.length > 0 && (
            <CardResults
              ref={node => setRef(node, "results-pages")}
              anchorId="results-pages"
              maxColumns={4}
              translations={translations?.pages}
              cards={defaults.pages.map(page => (
                <PageCard key={page.id} page={page} />
              ))}
            />
          )}
        </div>
      )}
    </div>
  );
};

const getResultState = (props: IProps): ResultState => {
  const emptyResults = checkIfNoResults(props.sections);
  const noResults = !!props.searchValue && emptyResults;

  if (noResults) {
    return "no-results";
  }

  if (emptyResults) {
    return "empty";
  }

  return "results";
};

const checkIfNoResults = (sections: ReturnType<typeof getModulesToShow>): boolean => {
  const { showMerchants, showProducts, showDeals, showPages } = sections;

  return !showMerchants && !showProducts && !showDeals && !showPages;
};
