import { IWPPost, IWordPressPage } from "@app/api/wp-page-fetcher/module-selector/main-wp.interface";
import { BlogPost } from "@app/api/core/blog-post/blog-post";
import { IWPHeaderBlogSingleModule } from "../header-blog-single-module";
import { IWPBlogBodyTextModule } from "../blog-body-text-module";
import { stripHtmlString } from "@app/api/wp-page-fetcher/utils/strip-html-string";
import Logger from "@app/util/logger";
import { formatMessage } from "@app/translations/intl";

export interface IGetWPPostBlogItems {
  basicHeaderModule?: IWPHeaderBlogSingleModule;
  bodytextModule?: IWPBlogBodyTextModule;
  headerModule?: IWPHeaderBlogSingleModule;
  permalink: string;
  postInfo: IWPPost;
}

export const mapWPPostToBlogs = (blogs: IWordPressPage[], platformId: number): BlogPost[] | undefined => {
  const mappedBlogs = blogs.map(blog => {
    const blogItems = getWPPostBlogItems(blog);
    if (!blogItems) return undefined;

    const { postInfo, permalink, headerModule, bodytextModule, basicHeaderModule } = blogItems;

    return {
      title: headerModule?.title || basicHeaderModule?.title || postInfo.post_title,
      image: {
        src: headerModule?.headerImage?.url || formatMessage({ id: "default.fallBackHeaderImage" }),
        alt: headerModule?.headerImage?.alt || headerModule?.title,
        title: headerModule?.headerImage?.title || headerModule?.title
      },
      excerpt: postInfo.post_excerpt || stripHtmlString(bodytextModule?.bodyText || ""),
      link: { title: "Lees meer", url: permalink },
      publishDate: postInfo.post_modified
    };
  });

  const emptyBlogs = mappedBlogs.filter(blog => !blog).length > 0;
  if (emptyBlogs) {
    Logger.logMessage("Error in: mapWPPostToBlogs");
  }

  const allBlogs = mappedBlogs.filter(blog => blog);
  if (!allBlogs || allBlogs.length === 0) return undefined;

  return allBlogs as BlogPost[];
};

export const getWPPostBlogItems = (blog: IWordPressPage): IGetWPPostBlogItems | undefined => {
  const modules = blog.data?.modules;
  const postInfo = blog.data?.post;
  const permalink = blog.data?.permalink;
  const headerModule: IWPHeaderBlogSingleModule = modules?.find(module => module.acf_fc_layout === "headerBlogSingle")
    ?.headerBlogSingle;
  const basicHeaderModule: IWPHeaderBlogSingleModule = modules?.find(module => module.acf_fc_layout === "headerBasic")
    ?.headerBasic;
  const bodytextModule: IWPBlogBodyTextModule = modules?.find(module => module.acf_fc_layout === "bodytext")?.bodyText;

  if (!modules || modules.length === 0 || !postInfo || !permalink) return undefined;

  return { postInfo, permalink, headerModule, bodytextModule, basicHeaderModule };
};
