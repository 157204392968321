import React, { useEffect, useState, useRef } from "react";
import clsx from "clsx";
import { useDispatch } from "react-redux";

import { BannerComponent } from "@app/core/banner";
import { PageProgressBarComponent } from "@app/core/page-progress-bar";
import { IExtendedBannerDTO, getBannersFromLocalStorage, getOffset, usePrevious, addFiltersToGTM } from "@app/util";
import { CtaSmallComponent } from "@app/core/cta-small/cta-small.component";
import { DealOverviewModule } from "@app/api/modules/deal-overview/deal-overview.module";
import { FIRST_DEAL_TAKE, DEAL_TAKE } from "@app/constants/overviews-numbers";
import { AnimatedEllipsisLoader } from "@app/core/animated-ellipsis-loader/animated-ellipsis-loader.component";
import { FilterBarDeals } from "@app/core/filter-bar-deals";
import { DealCardWrapper } from "@app/modules/deal-card-wrapper/deal-card-wrapper";
import { useAppSelector } from "@app/redux/store";
import { getAllFilters, getBanners, getDeals } from "@app/redux/thunks/deal-overview.thunk";
import { clearAllFilters, setCurrentTotalDeals } from "@app/redux/reducers/deals";
import { ViewType } from "@app/redux/reducers/settings";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import { OverviewTabsWrapper } from "@app/components/overview-tabs/overview-tabs-wrapper";
import { PlatformAlert } from "@app/core/platform-alert";
import { useResizeObserver } from "@app/util/hooks/use-resize-observer";
import { useIntersection } from "@app/util/hooks/use-intersection";
import {
  allFiltersAndItemsAreEmpty,
  getIfSticky,
  getOverviewItems,
  IOverviewItem,
  singleFiltersAreDifferent,
  useGetTotalHeight
} from "@app/util/overview-helpers";

import styles from "./deal-overview-component.module.scss";

export interface IDealOverviewComponentProps {
  dealOverviewModule: DealOverviewModule;
}

let previousSkip: undefined | number;

const DealOverview = (props: IDealOverviewComponentProps) => {
  const dispatch = useDispatch();
  const { screenSize, platformId } = useAppSelector(state => state.settings);
  const {
    categoryFilterItems,
    brandFilterItems,
    merchantFilterItems,
    deals,
    totalResults,
    orderByValues,
    sortBy,
    banners: rdxBanners
  } = useAppSelector(state => state.dealOverview);

  const { overviewMessages } = props.dealOverviewModule;
  const mainDivRef = useRef<HTMLDivElement>(null);
  const isFirstRender = useRef(true);
  const prevOrderBy = usePrevious(orderByValues);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [progressPage, setProgressPage] = useState<number>(1);
  const [overviewItems, setOverviewItems] = useState<IOverviewItem<IOutFeaturedDealDTO>[] | undefined>(undefined);
  const [banners, setBanners] = useState<IExtendedBannerDTO[]>([]);
  const [listRef, { height: listRefHeight }] = useResizeObserver<HTMLDivElement>();
  const { ref: bottomRef, entry: bottomEntry } = useIntersection({ threshold: 1 });
  const { ref: filterBarRef, entry: filterBarEntry } = useIntersection({ threshold: 1 });
  const isSticky = getIfSticky(filterBarEntry, bottomEntry, totalResults, deals.length);
  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;
  const overviewItemsToUse = overviewItems || getOverviewItems(viewType, deals, []);
  const totalHeight = useGetTotalHeight(overviewItemsToUse.length, totalResults, listRefHeight);

  useEffect(() => {
    window.scroll(0, 0);
    setInitialValues();

    if (!deals || deals.length === 0) {
      dispatch(
        getDeals(
          0,
          FIRST_DEAL_TAKE,
          categoryFilterItems,
          brandFilterItems,
          merchantFilterItems,
          platformId,
          orderByValues ? orderByValues[1] : undefined,
          orderByValues ? orderByValues[0] : undefined
        )
      );
    }

    isFirstRender.current = false;
  }, []);

  useEffect(() => {
    if (bottomEntry?.isIntersecting && !isFetching) {
      bottomPageCallback();
    }
  }, [bottomEntry]);

  useEffect(() => {
    if (
      filtersAreDifferent() ||
      allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems, merchantFilterItems], deals)
    ) {
      setProgressPage(1);
      previousSkip = 0;
      const allFiltersCombined = [
        ...categoryFilterItems.filter(item => item.isSelected),
        ...brandFilterItems.filter(item => item.isSelected),
        ...merchantFilterItems.filter(item => item.isSelected)
      ];
      addFiltersToGTM(allFiltersCombined);
      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));

      if (!isFirstRender) dispatch(setCurrentTotalDeals(0));

      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef?.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }
    }
  }, [brandFilterItems, categoryFilterItems, merchantFilterItems, sortBy]);

  useEffect(() => {
    if (prevOrderBy !== null) {
      setProgressPage(1);
      previousSkip = 0;

      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef?.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }

      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));
    }
  }, [orderByValues]);

  useEffect(() => {
    if (deals.length > 0) {
      const overviewItemsResult = getOverviewItems(viewType, deals, banners);
      setOverviewItems(overviewItemsResult);
    } else {
      setIsLoading(false);
    }
  }, [deals, screenSize, banners]);

  useEffect(() => {
    const extendedBanners: IExtendedBannerDTO[] = getBannersFromLocalStorage(rdxBanners);

    setBanners(extendedBanners);
  }, [rdxBanners]);

  const bottomPageCallback = async () => {
    const newSkip = FIRST_DEAL_TAKE + (progressPage - 1) * DEAL_TAKE;
    if (deals.length < totalResults && !isFirstRender.current && previousSkip !== newSkip) {
      // totalcurrentmerchants is async and will sometimes give the wrong amount;
      // const skip = props.totalCurrentDeals || 0;
      previousSkip = newSkip;
      setIsLoading(true);
      setProgressPage(progressPage + 1);
      // Type is wrong, we can await this.
      await dispatch(
        getDeals(
          newSkip,
          DEAL_TAKE,
          categoryFilterItems,
          brandFilterItems,
          merchantFilterItems,
          platformId,
          orderByValues ? orderByValues[1] : undefined,
          orderByValues ? orderByValues[0] : undefined
        )
      );
      setIsFetching(false);
    } else {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  const prevCategoryFilterItems = usePrevious(categoryFilterItems);
  const prevBrandFilterItems = usePrevious(brandFilterItems);
  const prevMerchantFilterItems = usePrevious(merchantFilterItems);
  const filtersAreDifferent = (): boolean => {
    if (!prevCategoryFilterItems && !prevBrandFilterItems && !prevMerchantFilterItems) {
      return false;
    }

    const categoryFiltersAreDifferent = singleFiltersAreDifferent(prevCategoryFilterItems || [], categoryFilterItems);
    const brandFiltersAreDifferent = singleFiltersAreDifferent(prevBrandFilterItems || [], brandFilterItems);
    const merchantFiltersAreDifferent = singleFiltersAreDifferent(prevMerchantFilterItems || [], merchantFilterItems);

    return categoryFiltersAreDifferent || brandFiltersAreDifferent || merchantFiltersAreDifferent;
  };

  const getActualScrollPosition = () => {
    const position = getOffset(mainDivRef.current);
    const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const actualScrollPosition = currentScrollPosition - position;

    return actualScrollPosition;
  };

  const setInitialValues = () => {
    if (allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems, merchantFilterItems], deals, true)) {
      dispatch(getAllFilters(platformId));
    }

    dispatch(getBanners(platformId));
  };

  return (
    <div>
      <div className={styles["deals-overview__tab"]}>
        <OverviewTabsWrapper
          tabs={props.dealOverviewModule.tabs}
          switchButtons={props.dealOverviewModule.switchButtons}
        />
      </div>
      <div ref={mainDivRef}>
        <div ref={filterBarRef} className={styles.filterBarWrapper}>
          <div className={clsx(styles.filterBar, isSticky && styles.sticky)}>
            <FilterBarDeals />
            {isSticky && <PageProgressBarComponent height={totalHeight} mainDivRef={mainDivRef} />}
          </div>
        </div>
        <PlatformAlert platform={props.dealOverviewModule.platform} />
      </div>
      <div className={styles["deal-overview"]}>
        <div className="uk-container">
          {overviewMessages.onTop.isActive && (
            <div className={styles["no-black-friday"]}>
              <h2>{overviewMessages.onTop.message}</h2>
            </div>
          )}
          {deals && deals.length > 0 ? (
            <div ref={listRef} className={styles["deals-overview__body__list"]}>
              {overviewItemsToUse.map((overviewItem, index) => {
                const { item, banner } = overviewItem;

                return (
                  <React.Fragment key={index}>
                    <div
                      className={clsx(
                        styles[`deals-overview__body__cards`],
                        styles["deals-overview__body__cards__new"]
                      )}
                    >
                      <DealCardWrapper deal={item} />
                    </div>
                    {banner && (
                      <div
                        className={clsx(
                          styles["deals-overview__body__banner"],
                          styles["deals-overview__body__banner__new"]
                        )}
                      >
                        <BannerComponent
                          banner={banner}
                          showAlternativeBanner={banner.showAlternativeBanner}
                          variant="default"
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ) : !allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems], deals) && !isLoading ? (
            <div>
              <CtaSmallComponent
                buttonTitle={overviewMessages.noResultsWithFilter.buttonText}
                onClick={() => dispatch(clearAllFilters())}
                ctaText={overviewMessages.noResultsWithFilter.text}
                icon={props.dealOverviewModule.emptyStateIcon}
              />
            </div>
          ) : (
            !isLoading &&
            overviewMessages.emptyResults.isActive && (
              <div className={styles["no-black-friday"]}>
                <h2>{overviewMessages.emptyResults.message}</h2>
              </div>
            )
          )}
          {isLoading && (
            <div className={styles["loading-spinner"]}>
              <AnimatedEllipsisLoader />
            </div>
          )}
        </div>
      </div>
      <div ref={bottomRef} className={styles.bottomRef} />
    </div>
  );
};

export { DealOverview };
