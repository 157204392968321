import { MessageDescriptor, IntlShape } from "react-intl";
import { PrimitiveType } from "intl-messageformat";

export const intlFormatMessage = (descriptor: MessageDescriptor, values?: Record<string, PrimitiveType>): string => {
  const intl = IntlFormatMessage.getInstance().INTL_OBJECT;

  if (!intl || !descriptor.id) return "";

  const message = intl.formatMessage(descriptor, values);
  const keyIsAvailable = (descriptor.id && intl.messages[descriptor.id]) !== undefined;

  if ((keyIsAvailable && message === descriptor.id) || message === " ") {
    return "";
  }

  return message;
};

export default class IntlFormatMessage {
  private static instance: IntlFormatMessage;

  public INTL_OBJECT: IntlShape | undefined;

  public static getInstance(): IntlFormatMessage {
    if (!IntlFormatMessage.instance) {
      IntlFormatMessage.instance = new IntlFormatMessage();
    }

    return IntlFormatMessage.instance;
  }

  public setIntl(intl: IntlShape): void {
    this.INTL_OBJECT = intl;
  }
}
