import React from "react";

import { ArrowPanel } from "@app/core/arrow-panel";
import { OutProductDTO } from "@app/bf-api";
import { formatMessage } from "@app/translations/intl";

interface IProps {
  product: OutProductDTO;
  anchorProps?: React.HTMLAttributes<HTMLAnchorElement>;
}

const SimpleProductCard = ({ product, anchorProps }: IProps) => {
  const internalProductLink = `${formatMessage({ id: "global.productOverviewLink" })}${product?.slug}/`;

  return (
    <ArrowPanel
      image={{
        src: product.image?.url || "",
        alt: product.image?.alt || "",
        title: product.image?.title || ""
      }}
      link={{ title: product.title || "", url: internalProductLink }}
      {...anchorProps}
    />
  );
};

export { SimpleProductCard };
