import {
  IWPHeaderHomepageModuleFull,
  IWPHeaderHomepageItems,
  IWPHeaderHomepageSlides
} from "./header-homepage-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { HomepageHeader } from "@app/api/modules/homepage-header/homepage-header.module";
import { IArrowPanelProps } from "@app/core/arrow-panel";
import { Slide } from "@app/api/core/slide";
import { getAmountOfMerchantsApi } from "@app/middleware/api/total-amount-of-merchants-get.api";
import { getAmountOfDealsApi } from "@app/middleware/api/total-amount-of-deals-get.api";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";

export interface IHeaderHomepageModuleMapper extends IModuleFetcher {
  module: IWPHeaderHomepageModuleFull;
}

export const headerHomepageModuleMapper = async (
  props: IHeaderHomepageModuleMapper
): Promise<HomepageHeader | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in headerHomepageModuleMapper");

    return {
      id: "1",
      name: "HomepageHeader",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      title: data.title || "",
      description: mapParagraphStringsToArray(data.bodyTextUnderneathTitle),
      arrowPanelslinks: await mapItemsToArrowPanel(platformId, data.rightSide?.linksUnderneathBodyText),
      popularProducts: await mapItemsToArrowPanel(platformId, data.populairSubjects),
      popularStores: await mapItemsToArrowPanel(platformId, data.populairStores),
      slides: mapSlides(data.rightSide?.sliderRightSide),
      popularProductsTitle: data.popularProductsTitle || "",
      popularStoresTitle: data.popularStoresTitle || "",
      durationToBlackFridayTitle: data.durationToBlackFridayTitle || "",
      startingDateBlackFridayTitle: data.startingDateBlackFridayTitle || ""
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerHomepageModuleMapper");

    return undefined;
  }
};

export const mapParagraphStringsToArray = (text?: string): string[] | [] => {
  if (!text) return [];

  const removedLineBreaks = text.replace(/\r?\n|\r/g, "");

  // Instead of regex replace, performance wise this seems to be better.
  return removedLineBreaks
    .split("</p>")
    .join("")
    .split("<p>")
    .filter(item => item);
};

const mapItemsToArrowPanel = async (
  platformId: number,
  items?: IWPHeaderHomepageItems[]
): Promise<IArrowPanelProps[] | []> => {
  if (!items || items.length === 0) return [];

  const mappedItems = items.map(async item => {
    return {
      icon: item.icon?.url || "",
      link: {
        title: await checkAmountWildCards(item.urlAndButtonText?.title || "", platformId),
        url: item.urlAndButtonText?.url || ""
      }
    };
  });

  return Promise.all(mappedItems);
};

const mapSlides = (slides?: IWPHeaderHomepageSlides[]): Slide[] | [] => {
  if (!slides || slides.length === 0) return [];

  return slides.map(slide => ({
    image: slide.image?.url || "",
    link: slide.readMoreLink && {
      title: slide.readMoreLink?.title || "",
      url: slide.readMoreLink?.url || ""
    },
    title: slide.title || "",
    video: slide.videoUrl || ""
  }));
};

const checkAmountWildCards = async (text: string, platformId: number) => {
  const amountOfMerchantsWildCard = "{amountOfMerchants}";
  const amountOfDealsWildCard = "{amountOfDeals}";

  if (text.includes(amountOfMerchantsWildCard)) {
    const totalAmountOfMerchants = (await getAmountOfMerchantsApi(platformId)) || 0;
    return text.replace(amountOfMerchantsWildCard, `${totalAmountOfMerchants}`);
  }
  if (text.includes(amountOfDealsWildCard)) {
    const totalAmountOfDeals = (await getAmountOfDealsApi(platformId)) || 0;
    return text.replace(amountOfDealsWildCard, `${totalAmountOfDeals}`);
  }

  return text;
};
