import { PlatformDTO } from "@app/bf-api";
export interface IPlatformAlertInfo {
  alertStartDate: Date;
  alertEndDate: Date;
  alertMessage: string;
}

export const getPlatformAlertInfo = (platform?: PlatformDTO): IPlatformAlertInfo | undefined => {
  if (!platform?.showAlert || !platform.alertStartDate || !platform.alertEndDate) {
    return undefined;
  }

  return {
    alertEndDate: new Date(platform.alertEndDate),
    alertStartDate: new Date(platform.alertStartDate),
    alertMessage: platform.alertMessage ?? ""
  };
};
