import React, { RefObject, useEffect, useState } from "react";
import { getOffset } from "@app/util";
import useIsMounted from "@app/util/is-mounted-check";
import { useWindowScroll } from "@app/util/hooks/use-scroll-position";

import styles from "./page-progress-bar-component.module.scss";

export interface IPageProgressBarComponentProps {
  mainDivRef: RefObject<HTMLDivElement>;
  height: number;
}

const PageProgressBarComponent = ({ height, mainDivRef }: IPageProgressBarComponentProps) => {
  const [positionPercentage, setPositionPercentage] = useState<number>(0);

  const isMounted = useIsMounted();
  const [scrollPos] = useWindowScroll();

  useEffect(() => {
    if (isMounted.current) {
      const actualScrollPosition = getActualScrollPosition();
      const percentage = (actualScrollPosition * 100) / height;
      setPositionPercentage(percentage);
    }
  }, [scrollPos.y, height]);

  const getActualScrollPosition = () => {
    const position = getOffset(mainDivRef.current);
    const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const actualScrollPosition = currentScrollPosition - position;

    return actualScrollPosition;
  };

  return (
    <div className={styles["page-progress-bar"]}>
      <div style={{ width: `${positionPercentage}%` }} className={styles["page-progress-bar__progress"]} />
    </div>
  );
};

export { PageProgressBarComponent };
