import { MerchantsApi, OutMerchantDTO } from "@app/bf-api";
import { OutMerchantLinkDTO } from "@app/api/modules/merchant-link-list/merchant-link-list";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getBulkMerchantsApi = async (
  platformId: number,
  labelFilter?: string,
  merchandIds?: string
): Promise<OutMerchantDTO[] | undefined> => {
  try {
    const api = new MerchantsApi();
    const resultFromApi = await api.merchantsBulkGet(platformId, labelFilter, merchandIds);

    if (!resultFromApi || !resultFromApi.merchants) return moduleMapperThrowMessage("Cannot bulk get merchants");

    return resultFromApi.merchants;
  } catch (e) {
    Logger.logError(e, "Error in: getBulkMerchantsApi");

    return undefined;
  }
};

export const getBulkMerchantLinksApi = async (platformId: number): Promise<OutMerchantLinkDTO[] | undefined> => {
  try {
    const api = new MerchantsApi();
    const resultFromApi = await api.merchantsBulkGet(platformId);

    if (!resultFromApi || !resultFromApi.merchants) return moduleMapperThrowMessage("Cannot bulk get merchant links");

    return mapOutMerchantDTOToMerchantLinkList(resultFromApi.merchants);
  } catch (e) {
    Logger.logError(e, "Error in: getBulkMerchantLinksApi");

    return undefined;
  }
};

const mapOutMerchantDTOToMerchantLinkList = (merchantList: OutMerchantDTO[]): OutMerchantLinkDTO[] =>
  merchantList.map(merchant => ({ id: merchant.id, title: merchant.name, url: merchant.slug }));
