import React, { ComponentType, useEffect, useMemo, useState } from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import clsx from "clsx";

import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { ImageComponent } from "@app/core";
import HamburgerIcon from "@assets/icons/hamburger.svg";

import { NAVIGATION_HEIGHT_MOBILE, NAVIGATION_HEIGHT_MOBILE_SEARCH } from "@app/constants/overviews-numbers";
import { isOverviewPageHook } from "@app/util/is-overview-page";
import useScrollDirection, { Direction } from "@app/util/hooks/use-scroll-direction";
import { useScrollToTop } from "@app/util/hooks/use-scroll-to-top";
import { formatMessage } from "@app/translations/intl";

import { Search } from "./search/search";
import { NewMenuItems } from "./new-menu-items/new-menu-items.component";
import styles from "./mobile-nav-bar-component.module.scss";

export interface INavBarModuleProps {
  navBarModule: INavBarModule;
}

export interface IMobileNavBarComponentProps extends INavBarModuleProps {}

const NavBar = (props: IMobileNavBarComponentProps & RouteComponentProps) => {
  const navBarItemsRef = React.useRef<HTMLDivElement>(null);
  const [searchIsOpen, setSearchIsOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const { scrollToTop } = useScrollToTop();

  const closeSearch = () => {
    setSearchIsOpen(false);
  };

  const openSearch = () => {
    setSearchIsOpen(true);
  };

  const closeMenu = () => {
    setMenuIsOpen(false);
  };

  const toggleMenu = () => {
    setMenuIsOpen(!menuIsOpen);
  };

  useEffect(() => {
    if (searchIsOpen || menuIsOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [searchIsOpen, menuIsOpen]);

  return (
    <div className={styles.mobileNavBar}>
      {!searchIsOpen && (
        <div className={styles.top}>
          <div className={styles.wrapper}>
            <button type="button" className={styles.hamburger} onClick={toggleMenu}>
              <img src={HamburgerIcon} alt="menu" />
            </button>

            <Link to={formatMessage({ id: "global.homepageLink" })} className={styles.logo} onClick={scrollToTop}>
              <ImageComponent
                src={props.navBarModule.logo.src}
                alt={props.navBarModule.logo.alt}
                title={props.navBarModule.logo.title}
                isBlocking
              />
            </Link>
          </div>
          <Link to={props.navBarModule.navBarLink || ""} className={styles.rightText}>
            <span className={styles.title}>{props.navBarModule.navBarTitle}</span>
            <span className={styles.subtitle}>{props.navBarModule.navBarSubTitle}</span>
          </Link>
        </div>
      )}

      <Search
        popularMerchants={props.navBarModule.popularMerchants}
        popularDeals={props.navBarModule.popularDeals}
        isSearchOpen={searchIsOpen}
        openSearch={openSearch}
        closeSearch={closeSearch}
      />

      {menuIsOpen && (
        <>
          <div ref={navBarItemsRef} className={clsx(styles.menu)}>
            <NewMenuItems
              menuItems={props.navBarModule.menuItems}
              logo={props.navBarModule.logo}
              closeMenu={closeMenu}
            />
          </div>
          <div role="button" className={clsx(styles.overlay)} onClick={closeMenu} />
        </>
      )}
    </div>
  );
};

const component = (props: INavBarModuleProps & RouteComponentProps) => {
  const { scrollDir, scrollPosition } = useScrollDirection({ thr: 100 });
  const isOverviewPage = isOverviewPageHook();
  const navigationHeight = isOverviewPage ? NAVIGATION_HEIGHT_MOBILE_SEARCH : NAVIGATION_HEIGHT_MOBILE;

  const navBar = useMemo(
    () => (
      <NavBar navBarModule={props.navBarModule} history={props.history} location={props.location} match={props.match} />
    ),
    []
  );

  return (
    <div
      style={{
        marginBottom: `${NAVIGATION_HEIGHT_MOBILE}px` // Height of navbar
      }}
    >
      <div
        className={clsx(
          styles.navBarWrapper,
          (scrollDir === Direction.Up || scrollPosition.top <= navigationHeight) && styles.sticky
        )}
      >
        {navBar}
      </div>
    </div>
  );
};

export const MobileNavBar: ComponentType<INavBarModuleProps> = withRouter(component);
