import { useEffect, useState } from "react";

import { ViewType } from "@app/redux/reducers/settings";
import { SHOW_AD_EVERY_LINES } from "@app/constants/overviews-numbers";
import { FilterItem } from "@app/api/core/filter/filter-item";

import { IExtendedBannerDTO, saveBannerSeenInLocalStorage } from "./local-storage-banners";

interface IOverviewItem<T> {
  banner: IExtendedBannerDTO | undefined;
  item: T;
}

const getOverviewItems = <T extends Record<string, any>>(
  viewType: ViewType,
  items: T[],
  banners: IExtendedBannerDTO[]
): IOverviewItem<T>[] => {
  bannerIndex = 0;
  const overviewItemsResult: IOverviewItem<T>[] = [];
  const showAdEvery = getAmountOfAdsPerItems(viewType);

  let storeIndex = 1;
  let showAlternativeBanner = false;

  items.forEach(item => {
    if (storeIndex === showAdEvery && banners.length > 0) {
      overviewItemsResult.push({
        banner: { ...getNextBanner(banners), showAlternativeBanner },
        item
      });
      storeIndex = 1;
      showAlternativeBanner = !showAlternativeBanner;
    } else {
      overviewItemsResult.push({
        banner:
          items.length < showAdEvery && banners.length > 0 && storeIndex === items.length
            ? { ...getNextBanner(banners), showAlternativeBanner }
            : undefined,
        item
      });
      storeIndex += 1;
    }
  });

  return overviewItemsResult;
};

let bannerIndex = 0;
const getNextBanner = (banners: IExtendedBannerDTO[]): IExtendedBannerDTO => {
  if (bannerIndex >= banners.length) {
    bannerIndex = 0;
  }
  const result = banners[bannerIndex];

  saveBannerSeenInLocalStorage(result.id);

  bannerIndex += 1;

  return result;
};

const getAmountOfAdsPerItems = (viewType: ViewType) => {
  switch (viewType) {
    case ViewType.DesktopFull:
      return SHOW_AD_EVERY_LINES * 5;

    case ViewType.DesktopLarge:
      return SHOW_AD_EVERY_LINES * 4;

    case ViewType.Desktop:
      return SHOW_AD_EVERY_LINES * 3;

    case ViewType.Mobile:
    case ViewType.MobileBig:
    case ViewType.Tablet:
      return SHOW_AD_EVERY_LINES * 2;
    default:
      return 0;
  }
};

const useGetTotalHeight = (results: number, totalResults: number, overviewHeight?: number) => {
  const [totalHeight, setTotalHeight] = useState(getTotalHeight(results, totalResults, overviewHeight));

  useEffect(() => {
    if (overviewHeight) {
      const newHeight = getTotalHeight(results, totalResults, overviewHeight);

      if (newHeight > totalHeight) {
        setTotalHeight(newHeight);
      }
    }
  }, [results, totalResults, overviewHeight]);

  return totalHeight;
};

const getTotalHeight = (results: number, totalResults: number, overviewHeight?: number) => {
  if (!overviewHeight) {
    return 0;
  }

  const heightPerResult = overviewHeight / results;

  return heightPerResult * totalResults;
};

const allFiltersAndItemsAreEmpty = <T extends Record<string, any>>(
  allFilterItems: FilterItem[][],
  items: T[],
  excludedItems?: boolean
) => {
  const totalSelecteditems = allFilterItems.reduce(
    (count, filterItems) => count + filterItems.filter(item => item.isSelected).length,
    0
  );

  if (totalSelecteditems > 0) {
    return false;
  }

  if (excludedItems || items.length === 0) {
    return true;
  }

  return false;
};

const singleFiltersAreDifferent = (oldFilters: FilterItem[], newFilters: FilterItem[]) => {
  for (let i = 0; i < newFilters.length; i += 1) {
    if (!oldFilters[i]) {
      return false;
    }
    if (oldFilters[i].isSelected !== newFilters[i].isSelected) {
      return true;
    }
  }

  return false;
};

const getIfSticky = (
  filterBarEntry?: IntersectionObserverEntry | null,
  bottomEntry?: IntersectionObserverEntry | null,
  totalResults?: number,
  totalItems?: number
) => {
  if (
    bottomEntry?.boundingClientRect.bottom &&
    bottomEntry.boundingClientRect.bottom <= 0 &&
    totalResults === totalItems
  ) {
    return false;
  }

  if (filterBarEntry?.boundingClientRect.top && filterBarEntry.boundingClientRect.top <= 0) {
    return true;
  }

  return false;
};

export {
  IOverviewItem,
  getOverviewItems,
  getTotalHeight,
  allFiltersAndItemsAreEmpty,
  singleFiltersAreDifferent,
  getIfSticky,
  useGetTotalHeight
};
