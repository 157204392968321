import { settingsState } from "@app/redux/reducers/settings";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";

export interface IRecaptchaProps {
  callBackToken: (token: string) => void;
  action: string;
}

export const ReCaptcha = (props: IRecaptchaProps) => {
  const settings = useSelector(settingsState);
  const { recaptchaSiteKey, recaptchaIsLoaded } = settings;

  const handleLoaded = () => {
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(recaptchaSiteKey, { action: props.action })
        .then((token: string) => props.callBackToken(token));
    });
  };

  useEffect(() => {
    if (typeof window !== "undefined" && recaptchaIsLoaded) {
      handleLoaded();
    }
  }, [recaptchaIsLoaded]);

  return <></>;
};
