import { FilterItem } from "@app/api/core/filter/filter-item";
import { OutBannerDTO } from "@app/bf-api";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { IOutSingleMerchantDTO } from "@app/modules/merchant-single-header/merchant-single-header.interface";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IMerchantResult {
  merchants: IOutFeaturedMerchantDTO[];
  totalResults: number;
}

export interface IMerchantsState {
  currentMerchant?: IOutSingleMerchantDTO;
  banners: OutBannerDTO[];
  brandFilterItems: FilterItem[];
  categoryFilterItems: FilterItem[];
  merchants: IOutFeaturedMerchantDTO[];
  sortBy: string;
  totalAmountOfMerchants: number;
  totalCurrentMerchants?: number;
  totalResults: number;
}

const initialState: IMerchantsState = {
  currentMerchant: undefined,
  banners: [],
  brandFilterItems: [],
  categoryFilterItems: [],
  merchants: [],
  sortBy: "",
  totalAmountOfMerchants: 0,
  totalCurrentMerchants: 0,
  totalResults: 0
};

const clearFilters = (filterItems: FilterItem[]) => filterItems.map(item => ({ ...item, isSelected: false }));

export const slice = createSlice({
  name: "merchantOverview",
  initialState,
  reducers: {
    setCurrentMerchant: (state, action: PayloadAction<IOutSingleMerchantDTO | undefined>) => {
      state.currentMerchant = action.payload;
    },
    setBrandFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.brandFilterItems = action.payload;
    },
    setCategoryFilters: (state, action: PayloadAction<FilterItem[]>) => {
      state.categoryFilterItems = action.payload;
    },
    setSortBy: (state, action: PayloadAction<string>) => {
      state.sortBy = action.payload;
    },
    clearAllFilters: state => {
      state.brandFilterItems = clearFilters(state.brandFilterItems);
      state.categoryFilterItems = clearFilters(state.categoryFilterItems);
    },
    setMerchants: (state, action: PayloadAction<IMerchantResult>) => {
      state.merchants = action.payload.merchants;
      state.totalResults = action.payload.totalResults;
    },
    updateMerchants: (state, action: PayloadAction<IMerchantResult>) => {
      state.merchants = [...state.merchants, ...action.payload.merchants];
      state.totalResults = action.payload.totalResults;
    },
    setBanners: (state, action: PayloadAction<OutBannerDTO[]>) => {
      state.banners = action.payload;
    },
    setTotalAmountOfMerchants: (state, action: PayloadAction<number>) => {
      state.totalAmountOfMerchants = action.payload;
    },
    setCurrentTotalMerchants: (state, action: PayloadAction<number>) => {
      state.totalCurrentMerchants = action.payload;
    }
  }
});

export const merchantsState = (state: RootState) => state.merchantOverview;

export const {
  setCurrentMerchant,
  setBanners,
  setBrandFilters,
  setCategoryFilters,
  setCurrentTotalMerchants,
  setMerchants,
  setSortBy,
  setTotalAmountOfMerchants,
  clearAllFilters,
  updateMerchants
} = slice.actions;

export default slice.reducer;
