import { OutDealDTO } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { getDealsApi } from "./deals-get.api";
import Logger from "@app/util/logger";

export const getFeaturedDealsApi = async (
  take: number,
  filterIds: string | undefined,
  platformId: number,
  merchantId?: string | undefined
): Promise<OutDealDTO[] | undefined> => {
  try {
    const resultFromApi = await getDealsApi(take, 0, platformId, merchantId, filterIds);
    if (!resultFromApi || !resultFromApi.deals) return moduleMapperThrowMessage("Cannot get deals");

    return resultFromApi.deals;
  } catch (e) {
    Logger.logError(e, "Error in: getFeaturedDealsApi");

    return undefined;
  }
};
