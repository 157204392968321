import React, { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { StoreOverviewModule } from "@app/api/modules/store-overview/store-overview.module";
import { BannerComponent } from "@app/core/banner";
import { PageProgressBarComponent } from "@app/core/page-progress-bar";
import { IExtendedBannerDTO, getBannersFromLocalStorage, getOffset, usePrevious, addFiltersToGTM } from "@app/util";
import { CtaSmallComponent } from "@app/core/cta-small/cta-small.component";
import { FIRST_MERCHANT_TAKE, MERCHANT_TAKE } from "@app/constants/overviews-numbers";
import { AnimatedEllipsisLoader } from "@app/core/animated-ellipsis-loader/animated-ellipsis-loader.component";
import { MerchantCardWrapper } from "@app/modules/merchant-card-wrapper/merchant-card-wrapper";
import { FilterBar } from "@app/core/filter-bar";
import { useAppSelector } from "@app/redux/store";
import { getAllFilters, getBanners, getMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { clearAllFilters, setCurrentTotalMerchants } from "@app/redux/reducers/merchants";
import { ViewType } from "@app/redux/reducers/settings";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { OverviewTabsWrapper } from "@app/components/overview-tabs/overview-tabs-wrapper";
import { PlatformAlert } from "@app/core/platform-alert";
import { useIntersection } from "@app/util/hooks/use-intersection";
import { useResizeObserver } from "@app/util/hooks/use-resize-observer";
import {
  allFiltersAndItemsAreEmpty,
  getIfSticky,
  getOverviewItems,
  IOverviewItem,
  singleFiltersAreDifferent,
  useGetTotalHeight
} from "@app/util/overview-helpers";

import styles from "./merchant-overview-component.module.scss";

export interface IMerchantOverviewComponentProps {
  storeOverviewModule: StoreOverviewModule;
}

let previousSkip: undefined | number;

const component = (props: IMerchantOverviewComponentProps) => {
  const dispatch = useDispatch();
  const {
    banners: rdxBanners,
    brandFilterItems,
    categoryFilterItems,
    merchants,
    sortBy,
    totalResults
  } = useAppSelector(state => state.merchantOverview);
  const { screenSize, platformId } = useAppSelector(state => state.settings);
  const { overviewMessages } = props.storeOverviewModule;
  const mainDivRef = useRef<HTMLDivElement>(null);

  const isFirstRender = useRef(true);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [progressPage, setProgressPage] = useState<number>(1);
  const [overviewItems, setOverviewItems] = useState<IOverviewItem<IOutFeaturedMerchantDTO>[] | undefined>(undefined);

  const [banners, setBanners] = useState<IExtendedBannerDTO[]>([]);

  const [listRef, { height: listRefHeight }] = useResizeObserver<HTMLDivElement>();
  const { ref: bottomRef, entry: bottomEntry } = useIntersection({ threshold: 1 });
  const { ref: filterBarRef, entry: filterBarEntry } = useIntersection({ threshold: 1 });
  const isSticky = getIfSticky(filterBarEntry, bottomEntry, totalResults, merchants.length);
  const viewType = screenSize ? screenSize.viewType : ViewType.Desktop;

  const overviewItemsToUse = overviewItems || getOverviewItems(viewType, merchants, []);
  const totalHeight = useGetTotalHeight(overviewItemsToUse.length, totalResults, listRefHeight);

  useEffect(() => {
    window.scroll(0, 0);
    setInitialValues();

    isFirstRender.current = false;

    if (merchants.length === 0) {
      dispatch(getMerchants(0, FIRST_MERCHANT_TAKE, categoryFilterItems, brandFilterItems, platformId, sortBy));
    }
  }, []);

  useEffect(() => {
    if (bottomEntry?.isIntersecting && !isFetching) {
      bottomPageCallback();
    }
  }, [bottomEntry]);

  useEffect(() => {
    if (filtersAreDifferent() || allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems], merchants)) {
      setProgressPage(1);
      previousSkip = 0;
      const allFiltersCombined = [
        ...categoryFilterItems.filter(item => item.isSelected),
        ...brandFilterItems.filter(item => item.isSelected)
      ];
      addFiltersToGTM(allFiltersCombined);
      dispatch(getBanners(platformId, categoryFilterItems, brandFilterItems));

      if (!isFirstRender) dispatch(setCurrentTotalMerchants(0));

      const actualScrollPosition = getActualScrollPosition();
      if (mainDivRef?.current && actualScrollPosition > 0) {
        window.scroll(0, 0);
      }
    }
  }, [brandFilterItems, categoryFilterItems, sortBy]);

  useEffect(() => {
    if (merchants.length > 0) {
      const overviewItemsResult = getOverviewItems(viewType, merchants, banners);
      setOverviewItems(overviewItemsResult);
    } else {
      setIsLoading(false);
    }
  }, [merchants, screenSize, banners]);

  useEffect(() => {
    const extendedBanners: IExtendedBannerDTO[] = getBannersFromLocalStorage(rdxBanners);

    setBanners(extendedBanners);
  }, [rdxBanners]);

  const bottomPageCallback = async () => {
    const newSkip = FIRST_MERCHANT_TAKE + (progressPage - 1) * MERCHANT_TAKE;
    if (merchants.length < totalResults && !isFirstRender.current && previousSkip !== newSkip) {
      // totalcurrentmerchants is async and will sometimes give the wrong amount;
      // const skip = props.totalCurrentMerchants || 0;
      previousSkip = newSkip;
      setIsLoading(true);
      setIsFetching(true);
      setProgressPage(progressPage + 1);
      // Type is wrong, we can await this.
      await dispatch(getMerchants(newSkip, MERCHANT_TAKE, categoryFilterItems, brandFilterItems, platformId, sortBy));
      setIsFetching(false);
    } else {
      setIsLoading(false);
      setIsFetching(false);
    }
  };

  const getActualScrollPosition = () => {
    const position = getOffset(mainDivRef.current);
    const currentScrollPosition = document.body.scrollTop || document.documentElement.scrollTop;
    const actualScrollPosition = currentScrollPosition - position;

    return actualScrollPosition;
  };

  const prevCategoryFilterItems = usePrevious(categoryFilterItems);
  const prevBrandFilterItems = usePrevious(brandFilterItems);
  const prevSortBy = usePrevious(sortBy);
  const filtersAreDifferent = (): boolean => {
    if (!prevCategoryFilterItems && !prevBrandFilterItems) {
      return false;
    }

    const categoryFiltersAreDifferent = singleFiltersAreDifferent(prevCategoryFilterItems || [], categoryFilterItems);
    const brandFiltersAreDifferent = singleFiltersAreDifferent(prevBrandFilterItems || [], brandFilterItems);
    const sortByIsDifferent = prevSortBy !== sortBy;

    return categoryFiltersAreDifferent || brandFiltersAreDifferent || sortByIsDifferent;
  };

  const setInitialValues = () => {
    if (allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems], merchants, true)) {
      dispatch(getAllFilters(platformId));
    }

    dispatch(getBanners(platformId));
  };

  return (
    <div>
      <div className={styles["merchants-overview__tab"]}>
        <OverviewTabsWrapper
          tabs={props.storeOverviewModule.tabs}
          switchButtons={props.storeOverviewModule.switchButtons}
        />
      </div>

      <div ref={mainDivRef}>
        <div ref={filterBarRef} className={styles.filterBarWrapper}>
          <div className={clsx(styles.filterBar, isSticky && styles.sticky)}>
            <FilterBar />
            {isSticky && <PageProgressBarComponent height={totalHeight} mainDivRef={mainDivRef} />}
          </div>
        </div>
        <PlatformAlert platform={props.storeOverviewModule.platform} />
      </div>

      <div className={styles["store-overview"]}>
        <div className="uk-container">
          {overviewMessages.onTop.isActive && (
            <div className={styles["no-black-friday"]}>
              <h2>{overviewMessages.onTop.message}</h2>
            </div>
          )}

          {merchants && merchants.length > 0 ? (
            <div ref={listRef} data-cy="merchant-overview" className={styles["stores-overview__body__list"]}>
              {overviewItemsToUse.map((overviewItem, index) => {
                const { item, banner } = overviewItem;

                return (
                  <React.Fragment key={item.id}>
                    <div
                      data-cy="merchant-card"
                      className={clsx(
                        styles["stores-overview__body__cards"],
                        styles["stores-overview__body__cards__new"]
                      )}
                    >
                      <MerchantCardWrapper merchant={item} />
                    </div>
                    {banner && (
                      <div
                        data-bannername={banner.slug}
                        className={clsx(
                          styles[`stores-overview__body__banner`],
                          styles["stores-overview__body__banner__new"]
                        )}
                      >
                        <BannerComponent
                          banner={banner}
                          showAlternativeBanner={banner.showAlternativeBanner}
                          variant="default"
                        />
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          ) : !allFiltersAndItemsAreEmpty([categoryFilterItems, brandFilterItems], merchants) && !isLoading ? (
            <div>
              <CtaSmallComponent
                buttonTitle={overviewMessages.noResultsWithFilter.buttonText}
                onClick={() => {
                  // Clear filters in case a selection has been made and a subset is selected
                  dispatch(getAllFilters(platformId));

                  dispatch(clearAllFilters());
                }}
                ctaText={overviewMessages.noResultsWithFilter.text}
                icon={props.storeOverviewModule.emptyStateIcon}
              />
            </div>
          ) : (
            !isLoading &&
            overviewMessages.emptyResults.isActive && (
              <div className={styles["no-black-friday"]}>
                <h2>{overviewMessages.emptyResults.message}</h2>
              </div>
            )
          )}
          {isLoading && (
            <div data-cy="merchant-overview_loading-spinner" className={styles["loading-spinner"]}>
              <AnimatedEllipsisLoader />
            </div>
          )}
        </div>
      </div>
      <div ref={bottomRef} className={styles.bottomRef} />
    </div>
  );
};

const MerchantOverview = React.memo(component);
export { MerchantOverview };
