import * as React from "react";
import { useIntl } from "react-intl";
import { Link } from "react-router-dom";
import clsx from "clsx";

import StoreIcon from "@assets/icons/store.svg";
import DealIcon from "@assets/icons/medal.svg";
import ThuiswinkelIcon from "@assets/icons/logo-thuiswinkel.svg";

import { ImageComponent, IconComponent, USP, ResourceTextComponent } from "@app/core/";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { StarsRatingComponent } from "@app/core/stars-rating";
import { formatMessage } from "@app/translations/intl";

import { FooterAction } from "../footer-action/footer-action.component";
import { ISocialItem } from "../footer.component";
import { FooterLanguages } from "../footer-languages/footer-languages.component";
import styles from "./footer-desktop-component.module.scss";

export interface IFooterDesktopComponentProps {
  footerModule: FooterModule;
  socialItems: ISocialItem[];
  totalAmountOfDeals: number;
  totalAmountOfMerchants: number;
  className?: string;
}

const numberStars = 5;

export const FooterDesktopComponent = (props: IFooterDesktopComponentProps) => {
  const intl = useIntl();

  return (
    <div className={clsx(styles.footerDesktop, props.className)}>
      <div className={styles["footerDesktop__top"]}>
        <div className="uk-container">
          <div className={styles["footerDesktop__top-holder"]}>
            <div className={styles["footerDesktop__top-left"]}>
              <ul className={styles["footerDesktop__top-list"]}>
                <li>
                  <Link to={formatMessage({ id: "global.merchantOverviewLink" })}>
                    <span className={styles["shop-image"]}>
                      <ImageComponent
                        src={StoreIcon}
                        alt={formatMessage({ id: "footer.image.shopIconImageAlt" })}
                        title={formatMessage({ id: "footer.image.shopIconImageTitle" })}
                      />
                    </span>
                    <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                      <ResourceTextComponent
                        resourceKey={"footer.top.shopLink"}
                        values={{ totalAmountOfMerchants: props.totalAmountOfMerchants }}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={formatMessage({ id: "global.dealOverviewLink" })}>
                    <span className={styles["shop-image"]}>
                      <ImageComponent
                        src={DealIcon}
                        alt={formatMessage({ id: "footer.image.dealIconImageAlt" })}
                        title={formatMessage({ id: "footer.image.dealIconImageTitle" })}
                      />
                    </span>
                    <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                      <ResourceTextComponent
                        resourceKey={"footer.top.dealLink"}
                        values={{ totalAmountOfDeals: props.totalAmountOfDeals }}
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={`${styles["footerDesktop__top-right"]} uk-visible@m`}>
              <USP uspModule={props.footerModule.usp} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footerDesktop__holder"]}>
        <div className="uk-container">
          <div className={styles["footerDesktop__columns"]}>
            <div className={styles["footerDesktop__socialSection"]}>
              <FooterAction />
              <span className={styles.infoText}>
                <ResourceTextComponent
                  resourceKey="newsletterModalForm.infoText"
                  values={{
                    conditions: (
                      <Link rel="nofollow" to={formatMessage({ id: "global.conditionsLink" })}>
                        {formatMessage({ id: "newsletterModalForm.conditions" })}
                      </Link>
                    )
                  }}
                />
              </span>
            </div>

            {/* footer links -> */}
            {props.footerModule.middleFooter.links.map((item, index) => (
              <div key={index}>
                <div className={styles["footerDesktop__column-desktop__container"]}>
                  <div className={styles["footerDesktop__column-title"]}>{item.title}</div>
                  <div>
                    <ul className={styles["footerDesktop__column-nav"]}>
                      {item.links.map(
                        (link, i) =>
                          link.url && (
                            <li key={i}>
                              <Link to={link.url}>{link.title}</Link>
                            </li>
                          )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            ))}
          </div>
          <div className={styles["footerDesktop__socialFeedbackSection"]}>
            <div className={styles.followContainer}>
              <div className={styles.share}>
                <div className={styles["footerDesktop__column-title"]}>
                  {props.footerModule.middleFooter.socialMedia.title}
                </div>

                <div className={styles["footerDesktop__social"]}>
                  {props.socialItems.map((item, index) => (
                    <div className={styles["footerDesktop__social-items"]} key={index}>
                      <a
                        aria-label="Logo link"
                        href={item.link}
                        className={styles["footerDesktop__social-link"]}
                        target="_blank"
                        rel="noopener"
                      >
                        <div className={styles["footerDesktop__social-icon"]}>
                          <IconComponent icon={item.image.src} size="24px" />
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>

              <FooterLanguages />
            </div>

            <div className={styles.bottomSection}>
              {props.footerModule.middleFooter.googleReviews?.link.url && (
                <div className={styles["rating"]}>
                  <div className={styles["starsNumberContainer"]}>
                    <span className={styles["ratingScore"]}>{formatMessage({ id: "footer.ratingScore" })}</span>
                    <StarsRatingComponent
                      rating={props.footerModule.middleFooter.googleReviews.rating}
                      voteTrigger={() =>
                        typeof window !== "undefined"
                          ? window.open(props.footerModule.middleFooter.googleReviews?.link.url)
                          : ""
                      }
                      numberOfStars={numberStars}
                    />
                  </div>
                  <div className={styles["rating-text"]}>
                    <a href={props.footerModule.middleFooter.googleReviews.link.url} target="_blank">
                      {props.footerModule.middleFooter.googleReviews.link.title}
                    </a>
                  </div>
                </div>
              )}
              <div className={styles.thuiswinkelIcon}>
                {intl.messages["footer.thuiswinkelLink"] ? (
                  <a href={formatMessage({ id: "footer.thuiswinkelLink" })} target="_blank">
                    <ImageComponent src={ThuiswinkelIcon} alt={"thuiswinkelIcon"} />
                  </a>
                ) : null}
              </div>
            </div>
          </div>
          {intl.messages["footer.ukDisclosure"] && (
            <div className={styles.ukDisclosure}>{formatMessage({ id: "footer.ukDisclosure" })}</div>
          )}
        </div>
      </div>
    </div>
  );
};
