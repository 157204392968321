/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutDealDTO, OutDealDTOFromJSON, OutDealDTOFromJSONTyped, OutDealDTOToJSON } from "./";

/**
 *
 * @export
 * @interface DealsListResponseDTO
 */
export interface DealsListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof DealsListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<OutDealDTO>}
   * @memberof DealsListResponseDTO
   */
  deals?: Array<OutDealDTO>;
}

export function DealsListResponseDTOFromJSON(json: any): DealsListResponseDTO {
  return DealsListResponseDTOFromJSONTyped(json, false);
}

export function DealsListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealsListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    deals: !exists(json, "deals") ? undefined : (json["deals"] as Array<any>).map(OutDealDTOFromJSON)
  };
}

export function DealsListResponseDTOToJSON(value?: DealsListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    deals: value.deals === undefined ? undefined : (value.deals as Array<any>).map(OutDealDTOToJSON)
  };
}
