import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";
import { getBulkDealsApi } from "@app/middleware/api/bulk-deals-get.api";
import { OutDealDTO } from "@app/bf-api";
import { getFeaturedDealsApi } from "@app/middleware/api/featured-deals-get.api";
import { FeaturedDealsSelectionChoiceType } from "@app/api/modules/featured-deals/featured-deals.module";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";
import { mapDealsToFeaturedDeals } from "./featured-deals-module-mapper";

const DEFAULT_AMOUNT_OF_DEALS = 9;

export interface IGetFeaturedDealsMapper {
  filterIds?: string;
  selectionChoice?: FeaturedDealsSelectionChoiceType;
  dealSelection?: string[];
  limit?: number;
  platformId: number;
  merchantId?: string;
  labelFilter?: string;
}

export const getFeaturedDealsMapper = async (props: IGetFeaturedDealsMapper): Promise<OutDealDTO[] | undefined> =>
  await getDeals(props);

export const getFeaturedDealsMapperWithDto = async (
  props: IGetFeaturedDealsMapper
): Promise<IOutFeaturedDealDTO[] | undefined> => {
  const deals = await getDeals(props);

  return mapDealsToFeaturedDeals(deals || []);
};

const getDeals = async (props: IGetFeaturedDealsMapper): Promise<OutDealDTO[] | undefined> => {
  const {
    platformId,
    limit = DEFAULT_AMOUNT_OF_DEALS,
    filterIds,
    selectionChoice,
    dealSelection,
    merchantId,
    labelFilter
  } = props;

  if (selectionChoice === "deals") {
    const dealIds = combineFilters([dealSelection]);

    if (!dealIds) return undefined;

    return await getBulkDealsApi(dealIds, platformId, undefined);
  }

  if (labelFilter && selectionChoice === "label") {
    return await getBulkDealsApi("", platformId, labelFilter);
  }

  return await getFeaturedDealsApi(limit, filterIds, platformId, merchantId);
};
