import * as React from "react";
import styles from "./header-info-component.module.scss";
import { ImageComponent } from "@app/core/image";
import { HeaderInfoModule } from "@app/api/modules/header-info/header-info.module";
import { HtmlRenderComponent } from "@app/core/html-render";
import { ResourceTextComponent } from "@app/core/resource-text";
import clsx from "clsx";
import { BreadcrumbComponent } from "@app/core/breadcrumb";
import { getOptimizedImageUrl } from "@app/util/image-optimization";
import { PlatformAlert } from "@app/core/platform-alert";

export interface IHeaderInfoComponentProps {
  headerInfo: HeaderInfoModule;
}

export const HeaderInfoComponent = (props: IHeaderInfoComponentProps) => {
  const { icon, image, title, content, breadcrumbProps } = props.headerInfo;

  const onSeeMoreClick = () => {
    // TODO: this needs to be better, a ref should be used to a different module.
    const element = document.getElementById("bodytext");
    const elementSeoText = document.getElementById("seobodytext");
    if (element) {
      const rect = element.getBoundingClientRect();

      window.scroll(0, rect.top);
    }
    if (elementSeoText) {
      const rect = elementSeoText.getBoundingClientRect();

      window.scroll(0, rect.top);
    }
  };

  return (
    <div className={styles["header-info"]}>
      {image?.src && (
        <div className={styles["header-info__image"]}>
          <PlatformAlert platform={props.headerInfo.platform} absolute />

          <ImageComponent
            alt={image.alt || ""}
            src={
              getOptimizedImageUrl({
                url: image.src,
                height: 424
              }) || ""
            }
            title={image.title || ""}
            isBlocking
          />
        </div>
      )}
      <div className="uk-container">
        <div className={clsx(styles["header-info__body"], icon?.src && styles["icon"])}>
          <div className={styles["header-info__body__content"]}>
            {icon?.src && (
              <div className={styles["header-info__icon"]}>
                <ImageComponent
                  forceImageTag
                  alt={icon.alt || ""}
                  src={icon.src || ""}
                  title={icon.title || ""}
                  className={styles["header-info__icon-image"]}
                  isBlocking
                />
              </div>
            )}
            <div className={styles["header-info__content"]}>
              <div className={styles["top"]}>
                <div className={styles["left"]}>
                  <div className={styles["left-top"]}>
                    {breadcrumbProps && (
                      <div className={styles["breadcrumbs"]}>
                        <BreadcrumbComponent
                          variant={breadcrumbProps.variant}
                          breadcrumbs={breadcrumbProps.breadcrumbs}
                          backButton={breadcrumbProps.backButton}
                        />
                      </div>
                    )}
                  </div>
                  <h1 className={styles["header-info__title"]}>{title}</h1>
                </div>
              </div>
              {content && (
                <>
                  <span className={styles["bodyText"]}>
                    <HtmlRenderComponent html={content} />
                  </span>{" "}
                  <a role="button" onClick={onSeeMoreClick} className={styles["seeMoreLink"]}>
                    <ResourceTextComponent resourceKey={"headerInfo.link.seeMore"} />
                  </a>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
