import * as React from "react";
import { withFormik, FormikProps, FormikErrors, Form } from "formik";
import { TextFieldComponent } from "@app/core";
import { validateEmail } from "@app/util";
import styles from "./new-newsletter-form-component.module.scss";
import { ClickableComponent } from "@app/core/clickable";
import clsx from "clsx";
import { IntlShape } from "react-intl";
import { formatMessage } from "@app/translations/intl";
import { setRecaptchaIsLoaded } from "@app/redux/reducers/settings";
import { useAppSelector } from "@app/redux/store";
import { useDispatch } from "react-redux";

interface INewNewsletterFormErrorMessages {
  emailAddress: string;
}

export interface INewNewsletterFormValues {
  emailAddress: string;
}

interface IOtherProps {
  intl: IntlShape;
  title?: string;
  isSubmitted?: boolean;
  footerVersion?: boolean;
  singlePageVersion?: boolean;
  whatIsVersion?: boolean;
}

const InnerForm = (props: IOtherProps & FormikProps<INewNewsletterFormValues>) => {
  const dispatch = useDispatch();
  const { touched, errors } = props;

  const { recaptchaSiteKey, recaptchaIsLoaded } = useAppSelector(state => state.settings);

  const handleLoaded = () => {
    dispatch(setRecaptchaIsLoaded(true));
  };

  const onFocus = () => {
    if (!recaptchaIsLoaded) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${recaptchaSiteKey}`;
      script.addEventListener("load", handleLoaded);
      document.body.appendChild(script);
    }
  };

  return props.isSubmitted ? (
    <span
      className={clsx(
        styles.successBox,
        props.footerVersion && styles.footerVersion,
        props.singlePageVersion && styles.singlePageVersion,
        props.whatIsVersion && styles.whatIsVersion
      )}
    >
      {formatMessage({ id: "newsLetterModalForm.success.message" })}
    </span>
  ) : (
    <Form onFocus={onFocus}>
      <div className={clsx(styles.formWrapper, props.singlePageVersion && styles.singlePageVersion)}>
        <div className={clsx(styles.input, props.singlePageVersion && styles.singlePageVersion)}>
          <TextFieldComponent
            label=""
            variant="new"
            height={44}
            placeholder={formatMessage({ id: "newsLetterModalForm.input.name.placeholder" })}
            value={props.values.emailAddress}
            onChange={props.handleChange}
            onBlur={props.handleBlur}
            type="email"
            id="emailAddress"
            errorMessage={touched.emailAddress === true && errors.emailAddress}
          />
        </div>
        <div className={clsx(styles.button, props.singlePageVersion && styles.singlePageVersion)}>
          <ClickableComponent
            fullWidth
            size={44}
            variant="primary-new-colored-simple"
            iconStyle="filled"
            buttonType="submit"
            title={formatMessage({ id: "newsletterModalForm.button.applyTitle" })}
          />
        </div>
      </div>
    </Form>
  );
};

interface IFormProps {
  intl: IntlShape;
  onSubmit: (values: INewNewsletterFormValues) => void;
  title?: string;
  isSubmitted?: boolean;
  footerVersion?: boolean;
  singlePageVersion?: boolean;
  whatIsVersion?: boolean;
}

export const NewNewsletterFormComponent = withFormik<IFormProps, INewNewsletterFormValues>({
  mapPropsToValues: () => ({
    emailAddress: ""
  }),

  validate: (values: INewNewsletterFormValues, bag) => {
    const errors: FormikErrors<INewNewsletterFormErrorMessages> = {};

    if (values.emailAddress && !validateEmail(values.emailAddress)) {
      errors.emailAddress = formatMessage({ id: "newsLetterModalForm.input.email.errorMessageWrong" });
    }

    return errors;
  },

  handleSubmit: (values, bag) => {
    bag.props.onSubmit(values);
  }
})(InnerForm);
