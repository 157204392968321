/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  CampaignDurationStatusDTO,
  CampaignDurationStatusDTOFromJSON,
  CampaignDurationStatusDTOFromJSONTyped,
  CampaignDurationStatusDTOToJSON,
  CampaignStatusDTO,
  CampaignStatusDTOFromJSON,
  CampaignStatusDTOFromJSONTyped,
  CampaignStatusDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOFromJSONTyped,
  OutFilterDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutCampaignDTO
 */
export interface OutCampaignDTO {
  /**
   *
   * @type {number}
   * @memberof OutCampaignDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof OutCampaignDTO
   */
  isFeatured: boolean;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  excerpt?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  subtitle?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  couponCode?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  startDate?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  endDate?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  startDateStatusShow?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  startDatePublish?: string;
  /**
   *
   * @type {CampaignDurationStatusDTO}
   * @memberof OutCampaignDTO
   */
  durationStatus?: CampaignDurationStatusDTO;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  url?: string;
  /**
   *
   * @type {string}
   * @memberof OutCampaignDTO
   */
  trackingUrl?: string;
  /**
   *
   * @type {Array<string>}
   * @memberof OutCampaignDTO
   */
  usps?: Array<string>;
  /**
   *
   * @type {OutFilterDTO}
   * @memberof OutCampaignDTO
   */
  featuredFilter?: OutFilterDTO;
  /**
   *
   * @type {boolean}
   * @memberof OutCampaignDTO
   */
  isFeaturedFilterActive?: boolean;
  /**
   *
   * @type {CampaignStatusDTO}
   * @memberof OutCampaignDTO
   */
  editedBy?: CampaignStatusDTO;
}

export function OutCampaignDTOFromJSON(json: any): OutCampaignDTO {
  return OutCampaignDTOFromJSONTyped(json, false);
}

export function OutCampaignDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutCampaignDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    name: json["name"],
    isFeatured: json["isFeatured"],
    excerpt: !exists(json, "excerpt") ? undefined : json["excerpt"],
    subtitle: !exists(json, "subtitle") ? undefined : json["subtitle"],
    description: !exists(json, "description") ? undefined : json["description"],
    couponCode: !exists(json, "couponCode") ? undefined : json["couponCode"],
    startDate: !exists(json, "startDate") ? undefined : json["startDate"],
    endDate: !exists(json, "endDate") ? undefined : json["endDate"],
    startDateStatusShow: !exists(json, "startDateStatusShow") ? undefined : json["startDateStatusShow"],
    startDatePublish: !exists(json, "startDatePublish") ? undefined : json["startDatePublish"],
    durationStatus: !exists(json, "durationStatus")
      ? undefined
      : CampaignDurationStatusDTOFromJSON(json["durationStatus"]),
    url: !exists(json, "url") ? undefined : json["url"],
    trackingUrl: !exists(json, "trackingUrl") ? undefined : json["trackingUrl"],
    usps: !exists(json, "usps") ? undefined : json["usps"],
    featuredFilter: !exists(json, "featuredFilter") ? undefined : OutFilterDTOFromJSON(json["featuredFilter"]),
    isFeaturedFilterActive: !exists(json, "isFeaturedFilterActive") ? undefined : json["isFeaturedFilterActive"],
    editedBy: !exists(json, "editedBy") ? undefined : CampaignStatusDTOFromJSON(json["editedBy"])
  };
}

export function OutCampaignDTOToJSON(value?: OutCampaignDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    name: value.name,
    isFeatured: value.isFeatured,
    excerpt: value.excerpt,
    subtitle: value.subtitle,
    description: value.description,
    couponCode: value.couponCode,
    startDate: value.startDate,
    endDate: value.endDate,
    startDateStatusShow: value.startDateStatusShow,
    startDatePublish: value.startDatePublish,
    durationStatus: CampaignDurationStatusDTOToJSON(value.durationStatus),
    url: value.url,
    trackingUrl: value.trackingUrl,
    usps: value.usps,
    featuredFilter: OutFilterDTOToJSON(value.featuredFilter),
    isFeaturedFilterActive: value.isFeaturedFilterActive,
    editedBy: CampaignStatusDTOToJSON(value.editedBy)
  };
}
