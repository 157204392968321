import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useIntl } from "react-intl";
import clsx from "clsx";

import { ImageComponent, IconComponent, ResourceTextComponent, USP } from "@app/core/";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { LinkComponent } from "@app/core/link";
import { ExpandablePanelComponent } from "@app/core/expandable-panel";
import ArrowUp from "@assets/icons/chevron-up.svg";
import ArrowDown from "@assets/icons/chevron-down.svg";
import ThuiswinkelIcon from "@assets/icons/logo-thuiswinkel.svg";
import StoreIcon from "@assets/icons/store.svg";
import DealIcon from "@assets/icons/medal.svg";
import { StarsRatingComponent } from "@app/core/stars-rating";
import { formatMessage } from "@app/translations/intl";

import { FooterLanguages } from "../footer-languages/footer-languages.component";
import { FooterAction } from "../footer-action/footer-action.component";
import { ISocialItem } from "../footer.component";
import styles from "./footer-mobile-component.module.scss";

export interface IFooterMobileComponentProps {
  footerModule: FooterModule;
  socialItems: ISocialItem[];
  totalAmountOfDeals: number;
  totalAmountOfMerchants: number;
  className?: string;
}

const CLOSE_PANEL = "close";

const numberStars = 5;

export const FooterMobileComponent = (props: IFooterMobileComponentProps) => {
  const intl = useIntl();
  const [openedPanel, setOpenedPanel] = useState<string | undefined>(undefined);

  const panelClicked = (key?: string) => {
    if (openedPanel === key) {
      setOpenedPanel(CLOSE_PANEL);
    } else {
      setOpenedPanel(key);
    }
  };

  return (
    <div className={clsx(styles.footerMobile, props.className)}>
      <div className={styles["footerMobile__top"]}>
        <div className="uk-container">
          <div className={styles["footerMobile__top-holder"]}>
            <div className={styles["footerMobile__top-left"]}>
              <ul className={styles["footerMobile__top-list"]}>
                <li>
                  <Link to={formatMessage({ id: "global.merchantOverviewLink" })}>
                    <span className={styles["shop-image"]}>
                      <ImageComponent
                        src={StoreIcon}
                        alt={formatMessage({ id: "footer.image.shopIconImageAlt" })}
                        title={formatMessage({ id: "footer.image.shopIconImageTitle" })}
                      />
                    </span>
                    <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                      <ResourceTextComponent
                        resourceKey={"footer.top.shopLink"}
                        values={{ totalAmountOfMerchants: props.totalAmountOfMerchants }}
                      />
                    </span>
                  </Link>
                </li>
                <li>
                  <Link to={formatMessage({ id: "global.dealOverviewLink" })}>
                    <span className={styles["shop-image"]}>
                      <ImageComponent
                        src={DealIcon}
                        alt={formatMessage({ id: "footer.image.dealIconImageAlt" })}
                        title={formatMessage({ id: "footer.image.dealIconImageTitle" })}
                      />
                    </span>
                    <span style={{ marginLeft: "10px", fontSize: "15px" }}>
                      <ResourceTextComponent
                        resourceKey={"footer.top.dealLink"}
                        values={{ totalAmountOfDeals: props.totalAmountOfDeals }}
                      />
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
            <div className={`${styles["footerMobile__top-right"]} uk-visible@m`}>
              <USP uspModule={props.footerModule.usp} />
            </div>
          </div>
        </div>
      </div>
      <div className={styles["footerMobile__holder"]}>
        <div className="uk-container">
          <FooterAction />
          <p className={styles["footerMobile__infoText"]}>
            <ResourceTextComponent
              resourceKey={"newsletterModalForm.infoText"}
              values={{
                conditions: (
                  <Link rel="nofollow" to={formatMessage({ id: "global.conditionsLink" })}>
                    {formatMessage({ id: "newsletterModalForm.conditions" })}
                  </Link>
                )
              }}
            />
          </p>
          <div>
            <div className={styles.followContainer}>
              <div className={styles.share}>
                <div className={styles["footerMobile__column-top-title"]}>
                  {props.footerModule.middleFooter.socialMedia.title}
                </div>
                <div className={styles["footerMobile__social"]}>
                  {props.socialItems.map((item, index) => (
                    <div className={styles["footerMobile__social-items"]} key={index}>
                      <a
                        aria-label="Logo link"
                        href={item.link}
                        className={styles["footerMobile__social-link"]}
                        target="_blank"
                        rel="noopener"
                      >
                        <div className={styles["footerMobile__social-icon"]}>
                          <IconComponent icon={item.image.src} size="24px" />
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
              <FooterLanguages />
            </div>

            <div className={styles.bottomSection}>
              {props.footerModule.middleFooter.googleReviews?.link.url && (
                <div className={styles["footer__column-footer"]}>
                  <div className={styles["rating"]}>
                    <div className={styles["starsNumberContainer"]}>
                      <StarsRatingComponent
                        rating={props.footerModule.middleFooter.googleReviews.rating}
                        voteTrigger={() =>
                          typeof window !== "undefined"
                            ? window.open(props.footerModule.middleFooter.googleReviews?.link.url)
                            : ""
                        }
                        numberOfStars={numberStars}
                      />
                      <span>{formatMessage({ id: "footer.ratingScore" })}</span>
                    </div>
                    <div className={styles["rating-text"]}>
                      <a href={props.footerModule.middleFooter.googleReviews.link.url} target="_blank">
                        {props.footerModule.middleFooter.googleReviews.link.title}
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {intl.messages["footer.ukDisclosure"] && (
                <div className={styles.ukDisclosure}>{formatMessage({ id: "footer.ukDisclosure" })}</div>
              )}
              <div className={styles.thuiswinkelIcon}>
                {intl.messages["footer.thuiswinkelLink"] ? (
                  <a href={formatMessage({ id: "footer.thuiswinkelLink" })} target="_blank">
                    <ImageComponent src={ThuiswinkelIcon} alt={"thuiswinkelIcon"} />
                  </a>
                ) : null}
              </div>
            </div>

            <div className={styles["footerMobile__column-collapse"]} />
          </div>

          {/* other stuff -> */}
          <div className={styles["footerMobile__columns"]}>
            {props.footerModule.middleFooter.links.map((item, index) => (
              <div key={index} className={styles["footerMobile__column-collapse"]}>
                <div className={styles["footerMobile__column-mobile__container"]}>
                  <ExpandablePanelComponent
                    callback={panelClicked}
                    identifier={item.id}
                    opened={openedPanel === item.id}
                    key={index}
                    mainContent={
                      <div
                        className={`${styles["footerMobile__column-title"]} ${
                          openedPanel === `${item.id}` ? styles["footerMobile__column-title__clicked"] : ""
                        }`}
                      >
                        {item.title}
                        <div className={styles["footerMobile__column-title__icon"]}>
                          <IconComponent icon={openedPanel === `${item.id}` ? ArrowUp : ArrowDown} size="10px" />
                        </div>
                      </div>
                    }
                  >
                    <div>
                      <ul className={styles["footerMobile__column-nav"]}>
                        {item.links.map(
                          (link, i) =>
                            link.url && (
                              <li key={i}>
                                <LinkComponent to={link.url}>{link.title}</LinkComponent>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </ExpandablePanelComponent>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
