import React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { IFeaturedWPPage } from "@app/middleware/api/featured-pages-get.api";
import { PageCard } from "@app/components/page-card/page-card";

import styles from "../search-results.module.scss";

interface IProps {
  headerText: string;
  pages: IFeaturedWPPage[];
  count?: number;
  closeSearch: () => void;
}

const component = (props: IProps & RouteComponentProps) => {
  const handleClick = () => {
    props.closeSearch();
  };

  return (
    <div className={styles.searchResultContainer}>
      <div className={styles.header}>
        {props.headerText} {(props.count || 0) > 0 ? `(${props.count})` : ""}
      </div>

      <div className={styles.pagePanel}>
        {props.pages?.map((page, index) => (
          <div key={index} role="button" onClick={handleClick}>
            <PageCard page={page} />
          </div>
        ))}
      </div>
    </div>
  );
};

export const NavBarResultPages = withRouter(component);
