import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import FavIconBelgium from "@assets/favicons/favicon-bf-be.png";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";
import bfBeLogo from "@assets/logos/bf-be.svg";
import Paper from "@assets/icons/paper.svg";
import mailIcon from "@assets/icons/mail.svg";
import instagramIcon from "@assets/icons/instagram.svg";
import facebookIcon from "@assets/icons/facebook.svg";
import youtubeIcon from "@assets/icons/new-youtube.svg";

export const belgiumNLPlatform: IPlatformSpecificInfo = {
  id: 2,
  currency: "EUR",
  name: "Black Friday",
  country: "België",
  domain: "blackfridayexpert.be",
  merchantOverviewLink: "/winkels/",
  dealOverviewLink: "/deals/",
  urlAddition: "",
  isLandingPage: false,
  defaultBackButtonText: "Terug",
  breadcrumbHomeName: "Home",
  breadcrumbHomeUrl: "/",
  readingTimeText: {
    singular: "minuut leestijd",
    plural: "minuten leestijd"
  },
  newsletterButtonTitle: "Aanmelden",
  newsletterModalTitle: "Inschrijven nieuwsbrief",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114412/default-blog-image-be.jpg",
  bottomFooter: {
    items: [
      {
        title: "Partner worden?",
        url: "/contact/"
      },
      {
        title: "Privacy",
        url: "/privacy-statement/"
      },
      {
        title: "Cookiebeleid",
        url: "/cookiebeleid/"
      },
      {
        title: "Sitemap",
        url: "/sitemap/"
      }
    ],
    logo: {
      src: bfBeLogo,
      alt: "",
      title: ""
    },
    logoLink: "/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Meer over Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Meest bekeken pagina's",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Winkels met de beste deals",
        links: []
      }
    ],
    newsletter: {
      title: "Meld je aan voor de nieuwsbrief",
      text: "En ontvang de allerbeste deals",
      buttonText: "Nieuwsbrief",
      buttonIcon: Paper
    },
    googleReviews: {
      link: {
        url: "https://www.feedbackcompany.com/nl-nl/reviews/black-friday-expert",
        title: "Feedback Company Reviews"
      },
      rating: 5
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Wil je ons volgen?",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/BlackFridayNederland/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridaynl/"
        },
        {
          link: "mailto:info@blackfridaynederland.nl",
          image: {
            src: mailIcon,
            alt: "Mail Icon",
            title: "Mail Icon"
          }
        },
        {
          link: "https://www.youtube.com/channel/UC049pjL85wCGYFXCEkp1lbg",
          image: {
            src: youtubeIcon,
            alt: "Youtube Icon",
            title: "Youtube Icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Winkel header",
    title: "Header winkelpagina"
  },
  logo: {
    src: bfBeLogo,
    alt: "Black Friday Logo",
    title: "Black Friday Logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/winkels/",
          title: "Alle acties in één overzicht"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/deals/",
          title: "Scoor gemakkelijk de beste deals"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/nieuwsbrief/",
          title: "Mis niets van Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/winkels/",
      title: "Winkels",
      isSelected: true
    },
    {
      href: "/deals/",
      title: "Deals"
    }
  ],
  dealTabs: [
    {
      href: "/winkels/",
      title: "Winkels"
    },
    {
      href: "/deals/",
      title: "Deals",
      isSelected: true
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.RED,
    "--colour-version-website-RGB": WEBSITE_COLORS.RED_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.RED_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.RED_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.RED_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.RED_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.BE_LIGHT_ACCENT
  },
  locale: "nl-BE",
  tagManagerScriptLocation: "/static/googletagmanager-nl-be.js",
  weCanTrackScriptLocation: "/static/wecantrack-nl-be.js",
  favIcon: FavIconBelgium,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-expert-be.png",
  gtm: "GTM-5JMPWC4"
};
