import { OutMerchantDTO } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { getMerchantsApi } from "./merchants-get.api";
import Logger from "@app/util/logger";

export const getFeaturedMerchantsApi = async (
  take: number,
  filterIds: string | undefined,
  platformId: number
): Promise<OutMerchantDTO[] | undefined> => {
  try {
    const resultFromApi = await getMerchantsApi(take, 0, platformId, undefined || filterIds);
    if (!resultFromApi || !resultFromApi.merchants) return moduleMapperThrowMessage("Cannot get merchants");

    return resultFromApi.merchants;
  } catch (e) {
    Logger.logError(e, "Error in: getFeaturedMerchantsApi");

    return undefined;
  }
};
