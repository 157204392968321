import { getMenuItems } from "@app/api/wp-page-fetcher/utils/get-wp-menu-items";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { MiddleFooter } from "@app/api/modules/footer/models/middle-footer";
import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { getAmountOfMerchantsApi } from "@app/middleware/api/total-amount-of-merchants-get.api";
import { getAmountOfDealsApi } from "@app/middleware/api/total-amount-of-deals-get.api";
import { formatMessage } from "@app/translations/intl";

import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import youtubeIcon from "@assets/icons/new-youtube.svg";
import pinterestIcon from "@assets/icons/new-pinterest.svg";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const footerModuleMapper = async (platformId: number): Promise<FooterModule | undefined> => {
  try {
    const allMappedMenus = await getMenuItems(platformId);

    if (!allMappedMenus) return moduleMapperThrowMessage("No menu items found in footerModuleMapper");

    const footerMenu = allMappedMenus.footer;
    const middleFooter: MiddleFooter = {
      links: [
        {
          id: "TRENDING_BLOCK",
          title: formatMessage({ id: "footer.middle.link.one.title" }),
          links: []
        },
        {
          id: "SHOPS_BLOCK",
          title: formatMessage({ id: "footer.middle.link.two.title" }),
          links: []
        },
        {
          id: "THIRD_BLOCK",
          title: formatMessage({ id: "footer.middle.link.three.title" }),
          links: []
        }
      ],
      newsletter: {
        title: formatMessage({ id: "footer.newsLetterModal.title" }),
        text: formatMessage({ id: "footer.middle.newsletter.text" }),
        buttonText: formatMessage({ id: "footer.middle.newsletter.buttonText" }),
        buttonIcon: Paper
      },
      googleReviews: {
        link: {
          url: formatMessage({ id: "footer.middle.googleReviews.link.url" }),
          title: formatMessage({ id: "footer.middle.googleReviews.link.title" })
        },
        rating: Number(formatMessage({ id: "footer.middle.googleReviews.link.rating" }))
      },
      socialMedia: {
        id: "SOCIAL_BLOCK",
        title: formatMessage({ id: "footer.middle.social.title" }),
        socialItem: [
          {
            image: {
              src: facebookIcon,
              alt: "Facebook Icon",
              title: "Facebook Icon"
            },
            link: formatMessage({ id: "footer.middle.social.link.facebook" })
          },
          {
            image: {
              src: instagramIcon,
              alt: "Instagram Icon",
              title: "Instagram Icon"
            },
            link: formatMessage({ id: "footer.middle.social.link.instagram" })
          },
          {
            link: formatMessage({ id: "footer.middle.social.link.mail" }),
            image: {
              src: mailIcon,
              alt: "Mail Icon",
              title: "Mail Icon"
            }
          },
          {
            link: formatMessage({ id: "footer.middle.social.link.youtube" }),
            image: {
              src: youtubeIcon,
              alt: "Youtube Icon",
              title: "Youtube Icon"
            }
          },
          {
            link: formatMessage({ id: "footer.middle.social.link.pinterest" }),
            image: {
              src: pinterestIcon,
              alt: "Pinterest icon",
              title: "Pinterest Icon"
            }
          }
        ]
      }
    };

    return {
      id: "12",
      name: "FooterModule",
      background: { backgroundColour: "#f7f7f7" },
      usp: {
        uniqueSellingPoints: [
          {
            icon: CheckmarkIcon,
            link: {
              url: formatMessage({ id: "global.merchantOverviewLink" }),
              title: formatMessage({ id: "footer.usp.one.link.title" })
            }
          },
          {
            icon: CheckmarkIcon,
            link: {
              url: formatMessage({ id: "global.dealOverviewLink" }),
              title: formatMessage({ id: "footer.usp.two.link.title" })
            }
          },
          {
            icon: CheckmarkIcon,
            link: {
              url: formatMessage({ id: "footer.usp.three.link.url" }),
              title: formatMessage({ id: "footer.usp.three.link.title" })
            }
          }
        ]
      },
      bottomFooter: {
        items: [
          {
            title: formatMessage({ id: "footer.bottom.link.one.title" }),
            url: formatMessage({ id: "footer.bottom.link.one.url" })
          },
          {
            title: formatMessage({ id: "footer.bottom.link.two.title" }),
            url: formatMessage({ id: "global.conditionsLink" })
          },
          {
            title: formatMessage({ id: "footer.bottom.link.three.title" }),
            url: formatMessage({ id: "global.cookieLink" })
          },
          {
            title: formatMessage({ id: "footer.bottom.link.four.title" }),
            url: formatMessage({ id: "footer.bottom.link.four.url" })
          }
        ],
        logo: {
          src: formatMessage({ id: "global.logo.src" }),
          alt: formatMessage({ id: "footer.bottom.logo.alt" }),
          title: formatMessage({ id: "footer.bottom.logo.title" })
        },
        logoLink: formatMessage({ id: "global.homepageLink" })
      },
      middleFooter: mapFooterMenus(
        middleFooter,
        footerMenu.footerColumnOne || [],
        footerMenu.footerColumnTwo || [],
        footerMenu.footerColumnThree || []
      ),
      totalAmountOfDeals: await getAmountOfDealsApi(platformId),
      totalAmountOfMerchants: await getAmountOfMerchantsApi(platformId)
    };
  } catch (e) {
    return undefined;
  }
};

const mapFooterMenus = (
  middleFooter: MiddleFooter,
  footerColumnOne: MenuItem[],
  footerColumnTwo: MenuItem[],
  footerColumnThree: MenuItem[]
) => {
  let mappedMiddleFooter = { ...middleFooter };

  mappedMiddleFooter.links = mappedMiddleFooter.links.map((item, idx) => {
    switch (idx) {
      case 0:
        return { ...item, links: footerColumnOne };
      case 1:
        return { ...item, links: footerColumnTwo };
      case 2:
        return { ...item, links: footerColumnThree };
      default:
        return { ...item };
    }
  });

  return mappedMiddleFooter;
};
