/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  ImageDTO,
  ImageDTOFromJSON,
  ImageDTOFromJSONTyped,
  ImageDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON,
  SeoDTO,
  SeoDTOFromJSON,
  SeoDTOFromJSONTyped,
  SeoDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutProductDTO
 */
export interface OutProductDTO {
  /**
   *
   * @type {number}
   * @memberof OutProductDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  ean?: string;
  /**
   *
   * @type {PlatformDTO}
   * @memberof OutProductDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {boolean}
   * @memberof OutProductDTO
   */
  live: boolean;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  slug: string;
  /**
   *
   * @type {ImageDTO}
   * @memberof OutProductDTO
   */
  image?: ImageDTO;
  /**
   *
   * @type {SeoDTO}
   * @memberof OutProductDTO
   */
  seo?: SeoDTO;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  description?: string;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  specification?: string;
  /**
   *
   * @type {Date}
   * @memberof OutProductDTO
   */
  updatedAt?: Date;
  /**
   *
   * @type {string}
   * @memberof OutProductDTO
   */
  createdAt?: string;
}

export function OutProductDTOFromJSON(json: any): OutProductDTO {
  return OutProductDTOFromJSONTyped(json, false);
}

export function OutProductDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutProductDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: json["title"],
    ean: !exists(json, "ean") ? undefined : json["ean"],
    platform: PlatformDTOFromJSON(json["platform"]),
    live: json["live"],
    slug: json["slug"],
    image: !exists(json, "image") ? undefined : ImageDTOFromJSON(json["image"]),
    seo: !exists(json, "seo") ? undefined : SeoDTOFromJSON(json["seo"]),
    description: !exists(json, "description") ? undefined : json["description"],
    specification: !exists(json, "specification") ? undefined : json["specification"],
    updatedAt: !exists(json, "updatedAt") ? undefined : new Date(json["updatedAt"]),
    createdAt: !exists(json, "createdAt") ? undefined : json["createdAt"]
  };
}

export function OutProductDTOToJSON(value?: OutProductDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    ean: value.ean,
    platform: PlatformDTOToJSON(value.platform),
    live: value.live,
    slug: value.slug,
    image: ImageDTOToJSON(value.image),
    seo: SeoDTOToJSON(value.seo),
    description: value.description,
    specification: value.specification,
    updatedAt: value.updatedAt === undefined ? undefined : value.updatedAt.toISOString(),
    createdAt: value.createdAt
  };
}
