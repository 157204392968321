import React, { useEffect } from "react";
import styles from "./clickable-component.module.scss";
import { Link } from "react-router-dom";
import { IconComponent } from "@app/core/icon";
import { RelAttributes } from "@app/constants";
import clsx from "clsx";

export type ButtonVariant =
  | "primary-default"
  | "primary-default-gradient"
  | "primary-default-inverted-gradient"
  | "primary-new-overview"
  | "primary-new-overview-inverted"
  | "primary-brand"
  | "primary-inverted"
  | "primary-inverted-border"
  | "secondary"
  | "secondary-inverted"
  | "tertiary"
  | "tertiary-inverted"
  | "link-primary"
  | "link-secondary"
  | "link-tertiary"
  | "link-quaternary"
  | "primary-new"
  | "primary-new-big"
  | "primary-new-colored"
  | "primary-new-colored-uk-custom"
  | "primary-new-colored-simple"
  | "primary-new-colored-small"
  | "primary-new-colored-medium"
  | "primary-generic"
  | "white";

export type IconStyle = "outline" | "filled" | "outline-fill";

export interface IClickableComponentProps {
  animated?: boolean;
  buttonType?: "submit" | "reset" | "button";
  dataCy?: string;
  disabled?: boolean;
  disableSelect?: boolean;
  dynamicSize?: boolean;
  forceExternal?: boolean;
  fullWidth?: boolean;
  href?: string;
  iconFillColor?: string;
  iconLeft?: string;
  iconRight?: string;
  iconSize?: string;
  iconStyle?: IconStyle;
  onClick?: () => void;
  size?: number;
  target?: "_blank" | "_self";
  title?: string;
  titleSize?: string;
  textRight?: string;
  variant?: ButtonVariant;
  zIndex?: number;
  isNonClickable?: boolean;
  relAttribute?: RelAttributes;
  amountLabel?: JSX.Element;
  className?: string;
  isRounded?: boolean;
  isFilterDropDown?: boolean;
}

const renderIconMargin = (
  margin: string,
  title: string | undefined,
  icon: string | undefined,
  iconFillColor: string | undefined,
  iconSize?: string
) => {
  let style;
  if (title) {
    style = `${styles[`icon-${margin}`]} `;
  }

  return (
    <span className={style}>
      <IconComponent icon={icon} size={iconSize ? iconSize : "14px"} fillColor={iconFillColor} />
    </span>
  );
};

const renderIconText = (
  iconLeft: string | undefined,
  iconRight: string | undefined,
  title: string | undefined,
  titleSize: string | undefined,
  iconFillColor: string | undefined,
  iconSize?: string,
  amountLabel?: JSX.Element,
  textRight?: string | undefined
) => {
  const showTextOnTheCenter = iconRight && iconLeft;

  return (
    <span className={styles["icon-svg"]}>
      {iconLeft && renderIconMargin(showTextOnTheCenter ? "center" : "left", title, iconLeft, iconFillColor, iconSize)}
      <span className={styles.title} style={{ fontSize: titleSize }}>
        {title}
      </span>
      <span className={styles.label}>{amountLabel}</span>
      {textRight && <span className={styles.textRight}>{textRight}</span>}
      {iconRight &&
        renderIconMargin(showTextOnTheCenter ? "center" : "right", title, iconRight, iconFillColor, iconSize)}
    </span>
  );
};

const ClickableComponent = (props: IClickableComponentProps) => {
  const [iconText, setIconText] = React.useState<JSX.Element>(
    renderIconText(
      props.iconLeft,
      props.iconRight,
      props.title,
      props.titleSize,
      props.iconFillColor,
      props.iconSize,
      props.amountLabel,
      props.textRight
    )
  );
  useEffect(
    () =>
      setIconText(
        renderIconText(
          props.iconLeft,
          props.iconRight,
          props.title,
          props.titleSize,
          props.iconFillColor,
          props.iconSize,
          props.amountLabel,
          props.textRight
        )
      ),
    [props.iconLeft, props.iconRight, props.title, props.amountLabel]
  );
  const {
    buttonType,
    disableSelect,
    onClick,
    href,
    variant,
    title,
    disabled,
    iconStyle,
    target,
    zIndex,
    className,
    isRounded,
    isFilterDropDown
  } = props;

  const classModify = variant || "primary-default";
  const buttonFAB = !title && styles["button--FAB"];
  const animated = props.animated && styles["animated"];
  const noSelect = disableSelect && styles[`no-select`];
  const rounded = isRounded && styles["rounded"];
  const filterDropDown = isFilterDropDown && styles["filterDropDown"];
  const iconOutline = iconStyle && styles[`button--${classModify}--icon-${iconStyle}`];
  const buttonClassName = clsx(
    styles["button"],
    styles[`button--${classModify}`],
    buttonFAB,
    animated,
    filterDropDown,
    rounded,
    iconOutline,
    noSelect,
    {
      "button--fullWidth": props.fullWidth
    },
    {
      "button__dynamic-size": props.dynamicSize
    },
    className
  );

  const buttonStyle = {
    width: props.fullWidth ? "100%" : props.size,
    height: props.size,
    zIndex
  };

  if (href && !props.isNonClickable) {
    const regex = "https://|http://";
    const isExternalLink = href.match(regex) !== null ? true : false;

    if (isExternalLink || props.forceExternal) {
      return (
        <a
          data-cy={props.dataCy}
          target={target || "_blank"}
          rel={
            props.relAttribute === RelAttributes.NO_FOLLOW
              ? RelAttributes.NO_FOLLOW
              : props.relAttribute === RelAttributes.SPONSORED
              ? RelAttributes.SPONSORED
              : ""
          }
          className={buttonClassName}
          style={buttonStyle}
          href={href}
        >
          {iconText}
        </a>
      );
    }

    return (
      <Link data-cy={props.dataCy} target={target || "_self"} className={buttonClassName} style={buttonStyle} to={href}>
        {iconText}
      </Link>
    );
  }

  if (props.isNonClickable) {
    return (
      <div data-cy={props.dataCy} style={buttonStyle} className={buttonClassName}>
        {iconText}
      </div>
    );
  }

  return (
    <button
      data-cy={props.dataCy}
      disabled={disabled}
      style={buttonStyle}
      type={buttonType}
      name={title}
      className={buttonClassName}
      onClick={onClick}
    >
      {iconText}
    </button>
  );
};

export { ClickableComponent };
