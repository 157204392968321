import React, { useEffect, useMemo, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IntlShape, useIntl } from "react-intl";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import youtubeIcon from "@assets/icons/new-youtube.svg";
import pinterestIcon from "@assets/icons/new-pinterest.svg";

import { ImageComponent, ResourceTextComponent } from "@app/core/";
import { FooterModule } from "@app/api/modules/footer/footer.module";
import { LinkComponent } from "@app/core/link";
import { useAppSelector } from "@app/redux/store";
import { getTotalAmountOfMerchants } from "@app/redux/thunks/merchant-overview.thunk";
import { getTotalAmountOfDeals } from "@app/redux/thunks/deal-overview.thunk";
import { formatMessage } from "@app/translations/intl";
import { IImage } from "@app/api/core/image";

import { FooterMobileComponent } from "./footer-mobile/footer-mobile.component";
import { FooterDesktopComponent } from "./footer-desktop/footer-desktop.component";
import styles from "./footer-component.module.scss";

export interface IFooterComponentProps {
  footerModule: FooterModule;
}

const component = (props: IFooterComponentProps & RouteComponentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { totalAmountOfMerchants: rdxTotalAmountOfMerchants } = useAppSelector(state => state.merchantOverview);
  const { platformId } = useAppSelector(state => state.settings);
  const { totalAmountOfDeals: rdxTotalAmountOfDeals } = useAppSelector(state => state.dealOverview);

  const [totalAmountOfMerchants, setTotalAmountOfMerchants] = useState<number>(
    props.footerModule.totalAmountOfMerchants || 0
  );
  const [totalAmountOfDeals, setTotalAmountOfDeals] = useState<number>(props.footerModule.totalAmountOfDeals || 0);

  const socialItems = useMemo(() => getSocialItems(intl), []);

  useEffect(() => {
    if (!totalAmountOfMerchants) {
      dispatch(getTotalAmountOfMerchants(platformId));
      setTotalAmountOfMerchants(rdxTotalAmountOfMerchants);
    }
    if (!totalAmountOfDeals) {
      dispatch(getTotalAmountOfDeals(platformId));
      setTotalAmountOfDeals(rdxTotalAmountOfDeals);
    }
  }, [rdxTotalAmountOfDeals, rdxTotalAmountOfMerchants]);

  const onHomeClick = () => {
    props.history.push(props.footerModule.bottomFooter.logoLink);
    window.scroll(0, 0);
  };

  return (
    <div className={styles["footer"]}>
      <FooterMobileComponent
        className="uk-hidden@s"
        footerModule={props.footerModule}
        socialItems={socialItems}
        totalAmountOfMerchants={totalAmountOfMerchants}
        totalAmountOfDeals={totalAmountOfDeals}
      />

      <FooterDesktopComponent
        className="uk-visible@s"
        footerModule={props.footerModule}
        socialItems={socialItems}
        totalAmountOfMerchants={totalAmountOfMerchants}
        totalAmountOfDeals={totalAmountOfDeals}
      />

      <div className={styles["footer__bottom"]}>
        <div className="uk-container">
          <div className={styles["footer__bottom-holder"]}>
            <div className={styles["footer__logo-box"]}>
              <a
                role="button"
                aria-label="Logo link"
                onClick={onHomeClick}
                className={clsx("uk-visible@m", styles["footer__logo"])}
              >
                <ImageComponent
                  src={props.footerModule.bottomFooter.logo.src}
                  alt={props.footerModule.bottomFooter.logo.alt}
                  title={props.footerModule.bottomFooter.logo.title}
                />
              </a>
              <span>
                <ResourceTextComponent
                  resourceKey={"footer.bottom.copyright"}
                  values={{ currentYear: new Date().getFullYear() }}
                />
              </span>
            </div>
            <ul className={styles["footer__nav"]}>
              {props.footerModule.bottomFooter.items.map((link, key) => (
                <li key={key}>
                  <LinkComponent to={link.url}>{link.title}</LinkComponent>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export const FooterComponent = withRouter(component);

export interface ISocialItem {
  image: IImage;
  link: string;
}

const getSocialItems = (intl: IntlShape): ISocialItem[] =>
  [
    {
      image: {
        src: facebookIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.facebook"]
        ? formatMessage({ id: "footer.middle.social.link.facebook" })
        : ""
    },
    {
      image: {
        src: instagramIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.instagram"]
        ? formatMessage({ id: "footer.middle.social.link.instagram" })
        : ""
    },
    {
      image: {
        src: mailIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.mail"]
        ? formatMessage({ id: "footer.middle.social.link.mail" })
        : ""
    },
    {
      image: {
        src: youtubeIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.youtube"]
        ? formatMessage({ id: "footer.middle.social.link.youtube" })
        : ""
    },
    {
      image: {
        src: pinterestIcon,
        alt: "",
        title: ""
      },
      link: intl.messages["footer.middle.social.link.pinterest"]
        ? formatMessage({ id: "footer.middle.social.link.pinterest" })
        : ""
    }
  ].filter(socialItem => socialItem.link);
