/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface InImageDTO
 */
export interface InImageDTO {
  /**
   *
   * @type {number}
   * @memberof InImageDTO
   */
  id: number;
  /**
   *
   * @type {string}
   * @memberof InImageDTO
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof InImageDTO
   */
  alt?: string;
}

export function InImageDTOFromJSON(json: any): InImageDTO {
  return InImageDTOFromJSONTyped(json, false);
}

export function InImageDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InImageDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"],
    title: !exists(json, "title") ? undefined : json["title"],
    alt: !exists(json, "alt") ? undefined : json["alt"]
  };
}

export function InImageDTOToJSON(value?: InImageDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id,
    title: value.title,
    alt: value.alt
  };
}
