import { DealsApi, DealsListResponseDTO } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getDealsApi = async (
  take: number,
  skip: number,
  platformId: number,
  merchantId?: string,
  filters?: string,
  orderByDirection?: string,
  orderBy?: string,
  onlySponsored?: boolean
): Promise<DealsListResponseDTO | undefined> => {
  try {
    const api = new DealsApi();
    const resultFromApi = await api.dealsGet(
      take,
      skip,
      orderByDirection,
      orderBy,
      merchantId,
      "",
      filters,
      onlySponsored ? 1 : 0,
      platformId
    );

    if (!resultFromApi || !resultFromApi.deals) return moduleMapperThrowMessage("Cannot get deals");

    return resultFromApi;
  } catch (e) {
    Logger.logError(e, "Error in: getDealsApi");

    return undefined;
  }
};
