import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { ProductSearchDTO } from "@app/bf-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { ISearchPageDataCollection } from "@app/api/core/search-page-data";
import { IOutFeaturedMerchantCollection } from "@app/core/new-merchant-card/featured-merchants.interface";
import { IOutFeaturedDealCollection } from "@app/core/new-deal-card";

export interface ISearchResults {
  deals?: IOutFeaturedDealCollection;
  merchants?: IOutFeaturedMerchantCollection;
  products?: ProductSearchDTO;
  pages?: ISearchPageDataCollection;
}

export interface INavbarSearchState {
  searchValue: string;
  foundDeals: IOutFeaturedDealCollection;
  foundMerchants: IOutFeaturedMerchantCollection;
  foundProducts: ProductSearchDTO;
  foundPages: ISearchPageDataCollection;
  menuIsOpen: boolean;
  searchIsOpen: boolean;
  selectedMenuItem?: MenuItem;
  isLoading: boolean;
  noResults: boolean;
}

const emptyData = { count: 0, data: [] };

const initialState: INavbarSearchState = {
  searchValue: "",
  foundDeals: emptyData,
  foundMerchants: emptyData,
  foundProducts: emptyData,
  foundPages: emptyData,
  menuIsOpen: false,
  searchIsOpen: false,
  isLoading: false,
  noResults: false
};

export const slice = createSlice({
  name: "search",
  initialState,
  reducers: {
    clearResults: state => {
      state.foundDeals = emptyData;
      state.foundMerchants = emptyData;
      state.foundProducts = emptyData;
      state.foundPages = emptyData;
      state.noResults = false;
      state.isLoading = false;
    },
    setNavbarSearchItems: (state, action: PayloadAction<ISearchResults>) => {
      state.foundMerchants = action.payload.merchants || emptyData;
      state.foundDeals = action.payload.deals || emptyData;
      state.foundProducts = action.payload.products || emptyData;
      state.foundPages = action.payload.pages || emptyData;

      const noResults =
        state.foundMerchants.count === 0 &&
        state.foundDeals.count === 0 &&
        state.foundProducts.count === 0 &&
        state.foundPages.count === 0;

      state.noResults = noResults;

      state.isLoading = false;
    },
    setNavbarSearchText: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    }
  }
});

export const navbarSearchState = (state: RootState) => state.search;

export const { setNavbarSearchItems, setNavbarSearchText, setIsLoading, clearResults } = slice.actions;

export default slice.reducer;
