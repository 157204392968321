import { IWordPressPage, IWordPressPageCollection, IWPMenus } from "../module-selector/main-wp.interface";
import { moduleMapperThrowMessage } from "../utils/module-mapper-error-handling";

import Logger from "@app/util/logger";
import { GET_CONFIG } from "@app/util";

export const baseWordPressApiGet = async (url: string, supressError?: boolean): Promise<any> => {
  try {
    const options: RequestInit = {
      method: "GET"
    };

    const UrlWithoutTrailingSlash = GET_CONFIG.API_URL;
    const urlWithoutApi = UrlWithoutTrailingSlash.replace("/api", "");
    const BASE_PATH = urlWithoutApi;
    const fetchUrl = `${BASE_PATH}/wp-api/${url}`;

    const response = await fetch(fetchUrl, options);

    if (!response.ok) {
      return moduleMapperThrowMessage(`${response.status}`);
    }

    return await response.json();
  } catch (e) {
    if (!supressError) {
      Logger.logError(e, "Error in: baseWordPressApiGet, URL:" + url);
    }
    return { error: e };
  }
};

export const menuWordPressApiGet = async (platformId: number): Promise<IWPMenus | (404 | 503)> => {
  const apiUrl = `menu?platformId=${platformId}`;
  const result = await baseWordPressApiGet(apiUrl);

  if (result.error) {
    Logger.logError(result.error, "Error in: MenuWordPressApiGet");

    return returnErrorType(`${result.error}`);
  }

  return result;
};

export const postsWordPressApiGet = async (
  platformId: number,
  url?: string
): Promise<IWordPressPage[] | (404 | 503)> => {
  const apiUrl = `posts?platformId=${platformId}&permalink=${url}`;

  const result = await baseWordPressApiGet(apiUrl);

  if (result.error) {
    Logger.logError(result.error, "Error in: PostsWordPressApiGet");

    return returnErrorType(`${result.error}`);
  }

  return result.data;
};

export const pagesWordPressApiGet = async (
  platformId: number,
  search?: string,
  take?: number,
  skip?: number
): Promise<IWordPressPageCollection | (404 | 503)> => {
  const parameterTake = take ? `&take=${take}` : "";
  const parameterSkip = skip ? `&skip=${skip}` : "";
  const searchParameter = search ? `&search=${search}` : "";

  const apiUrl = `posts?platformId=${platformId}${searchParameter}${parameterTake}${parameterSkip}`;

  const result = await baseWordPressApiGet(apiUrl, true);

  if (result.error) {
    if (result.error?.message !== "404") {
      Logger.logError(result.error, "Error in: PagesWordPressApiGet");
    }

    return returnErrorType(`${result.error}`);
  }

  return result;
};

export const taxonomyWordPressApiGet = async (
  platformId: number,
  taxonomy: string,
  take?: number,
  skip?: number
): Promise<IWordPressPage[] | (404 | 503)> => {
  const parameterTake = take ? `&take=${take}` : "";
  const parameterSkip = skip ? `&skip=${skip}` : "";

  const apiUrl = `posts?platformId=${platformId}&taxonomies=${taxonomy}${parameterTake}${parameterSkip}`;
  const result = await baseWordPressApiGet(apiUrl, true);

  if (result.error) {
    if (result.error?.message !== "404") {
      Logger.logError(result.error, `Error in: TaxonomyWordPressApiGet (${taxonomy})`);
    }

    return returnErrorType(`${result.error}`);
  }

  return result.data;
};

const returnErrorType = (errorMessage: string): 404 | 503 => {
  if (errorMessage.includes("404")) return 404;

  return 503;
};
