import React from "react";
import clsx from "clsx";

import { IconComponent, ImageComponent } from "@app/core/";
import IconDefault from "@assets/icons/link.svg";
import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import { OutBannerDTO } from "@app/bf-api";
import { getOptimizedImageUrl } from "@app/util/image-optimization";
import { ClickableComponent } from "@app/core/clickable";
import IconHot from "@assets/icons/labels/hot.svg";

import { BannerVariant } from "../banner.component";
import styles from "./banner-v3-component.module.scss";

export interface IBannerNewComponentProps {
  banner?: OutBannerDTO;
  bannerLink?: string;
  buttonText: string;
  showAlternativeBanner?: boolean;
  variant: BannerVariant;
}

export const BannerV3Component = (props: IBannerNewComponentProps) => {
  const { banner, bannerLink, variant, buttonText, showAlternativeBanner } = props;

  return (
    <a rel="sponsored" href={bannerLink} target="_blank" className={clsx(styles.banner, styles[variant])}>
      <div className={clsx(styles.holder, showAlternativeBanner && styles.rowReverse)}>
        <div className={styles.image}>
          <div className={styles.imageWrapper}>
            <ImageComponent
              alt={banner?.bannerImage?.alt || ""}
              src={
                getOptimizedImageUrl({
                  url: banner?.bannerImage?.url,
                  height: 280
                }) || ""
              }
              title={banner?.bannerImage?.title || ""}
              errorImage={IconDefault}
              isBlocking
              forceImageTag
            />
          </div>
        </div>
        <div className={styles.body}>
          <div className={styles.content}>
            <div className={styles.label}>
              <IconComponent icon={IconHot} fillColor="white" size="12px" />
              {banner?.label}
            </div>
            <h2 className={styles.title}>{banner?.name}</h2>
            <span>{banner?.description}</span>
          </div>
          <div className={styles.footer}>
            {banner?.slug && (
              <div className={styles.button}>
                <ClickableComponent
                  title={buttonText}
                  variant="primary-new-colored"
                  iconStyle="filled"
                  iconRight={IcnChevronRight}
                  isNonClickable
                  iconSize="12px"
                  titleSize="15px"
                />
              </div>
            )}
            {banner?.logoImage && (
              <div className={styles.logo}>
                <ImageComponent
                  src={banner.logoImage.url}
                  alt={banner.logoImage.alt}
                  title={banner.logoImage.title}
                  isBlocking
                  forceImageTag
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </a>
  );
};
