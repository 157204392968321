import bfFranceLogo from "@assets/logos/bf-fr.svg";
import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import FavIconFrance from "@assets/favicons/favicon-bf-fr.png";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const francePlatform: IPlatformSpecificInfo = {
  id: 4,
  currency: "EUR",
  name: "Black Friday Expert",
  country: "France",
  domain: "blackfriday.expert",
  merchantOverviewLink: "/magasins/",
  dealOverviewLink: "/offres/",
  urlAddition: "fr",
  isLandingPage: false,
  defaultBackButtonText: "Retourner",
  breadcrumbHomeName: "Accueil",
  breadcrumbHomeUrl: "/fr/",
  readingTimeText: {
    singular: "minute de lecture",
    plural: "minutes de lecture"
  },
  newsletterButtonTitle: "Inscrivez-vous!",
  newsletterModalTitle: "Inscrivez-vous à la newsletter",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114412/default-blog-image-be.jpg",
  bottomFooter: {
    items: [
      {
        title: "Contacter",
        url: "/fr/contacter/"
      },
      {
        title: "Privacy",
        url: "/fr/privacy/"
      },
      {
        title: "Cookies",
        url: "/fr/cookies/"
      },
      {
        title: "Sitemap",
        url: "/fr/sitemap/"
      }
    ],
    logo: {
      src: bfFranceLogo,
      alt: "Logo Black Friday Expert",
      title: "Logo Black Friday Expert"
    },
    logoLink: "/fr/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Tout sur le Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Boutiques les plus vues",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Offres Black Friday",
        links: []
      }
    ],
    newsletter: {
      title: "Meld je aan voor de nieuwsbrief",
      text: "En ontvang de allerbeste deals",
      buttonText: "Newsletter",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Entrer en contact",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/BlackFridayNederland/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridaynl/"
        },
        {
          link: "mailto:info@blackfridaynederland.nl",
          image: {
            src: mailIcon,
            alt: "Mail Icon",
            title: "Mail Icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/sites/3/20200424102322/Black-Friday-Magasins.jpg",
    alt: "Page de magasins",
    title: "Header page de magasins"
  },
  logo: {
    src: bfFranceLogo,
    alt: "Black Friday Logo",
    title: "Black Friday Logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/fr/magasins/",
          title: "Toutes les offres en un seul coup d'œil"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/fr/offres/",
          title: "Obtenez les meilleures offres !"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/fr/black-friday/",
          title: "Ne manquez rien du Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/fr/magasins/",
      title: "Magasins",
      isSelected: true
    },
    {
      href: "/fr/offres/",
      title: "Offres"
    }
  ],
  dealTabs: [
    {
      href: "/fr/magasins/",
      title: "Magasins"
    },
    {
      href: "/fr/offres/",
      isSelected: true,
      title: "Offres"
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.BLUE,
    "--colour-version-website-RGB": WEBSITE_COLORS.BLUE_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.BLUE_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.BLUE_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.BLUE_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.BLUE_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.FR_LIGHT_ACCENT
  },
  locale: "fr-FR",
  tagManagerScriptLocation: "/static/googletagmanager-fr-fr.js",
  weCanTrackScriptLocation: "/static/wecantrack-fr-fr.js",
  favIcon: FavIconFrance,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-expert-fr.png",
  gtm: "GTM-5KG6LNX"
};
