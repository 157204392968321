import React, { useState } from "react";
import { useIntl } from "react-intl";
import { useDispatch } from "react-redux";

import NewsletterArrow from "@assets/icons/newsletter-arrow-down.svg";
import CheckIcon from "@assets/icons/new-green-checkmark.svg";

import { IconComponent, ResourceTextComponent } from "@app/core/";

import { INewNewsletterFormValues, NewNewsletterFormComponent } from "@app/core/newsletter";
import { subscribeToNewsletter } from "@app/redux/thunks/newsletter.thunk";
import { ReCaptcha } from "@app/core/recaptcha/recaptcha.component";

import styles from "./footer-action-component.module.scss";

const FooterAction = () => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit = (values: INewNewsletterFormValues) => {
    if (values.emailAddress) {
      dispatch(subscribeToNewsletter(values, recaptchaToken));
      setIsSubmitted(true);
    }
  };

  return (
    <div className={styles.footerAction}>
      <h3 className={styles.columnTitle}>
        <ResourceTextComponent resourceKey={"footer.newsLetter.title"} />
      </h3>
      <div className={styles.content}>
        <div className={styles.list}>
          <span>
            <IconComponent size="12px" icon={CheckIcon} />

            <ResourceTextComponent resourceKey="footer.newsLetter.description.item.one" />
          </span>
          <span>
            <IconComponent size="12px" icon={CheckIcon} />

            <ResourceTextComponent resourceKey="footer.newsLetter.description.item.two" />
          </span>
          <span>
            <IconComponent size="12px" icon={CheckIcon} />

            <ResourceTextComponent resourceKey="footer.newsLetter.description.item.three" />
          </span>
        </div>
        <div className={styles.arrowIcon}>
          <IconComponent size="" icon={NewsletterArrow} />
        </div>
      </div>

      <div className={styles.applyForm}>
        <ReCaptcha action="newsletter" callBackToken={setRecaptchaToken} />
        <NewNewsletterFormComponent intl={intl} onSubmit={onSubmit} isSubmitted={isSubmitted} footerVersion />
      </div>
    </div>
  );
};

export { FooterAction };
