import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import bfItalyLogo from "@assets/logos/bf-it.svg";
import FavIconItaly from "@assets/favicons/favicon-bf-it.png";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const italyPlatform: IPlatformSpecificInfo = {
  id: 13,
  currency: "EUR",
  name: "Black Friday Expert",
  country: "Italia",
  domain: "blackfriday.expert",
  merchantOverviewLink: "/negozi/",
  dealOverviewLink: "/accordi/",
  urlAddition: "it",
  isLandingPage: false,
  defaultBackButtonText: "Indietro",
  breadcrumbHomeName: "Inizio",
  breadcrumbHomeUrl: "/it/",
  readingTimeText: {
    singular: "Tempo di lettura minuto",
    plural: "Tempo di lettura dei minuti"
  },
  newsletterButtonTitle: "Registrazione",
  newsletterModalTitle: "Iscriviti alla newsletter",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114414/default-blog-image-nl.jpg",
  bottomFooter: {
    items: [
      {
        title: "Contatto",
        url: "/it/contatto/"
      },
      {
        title: "Privacy",
        url: "/it/privacy/"
      },
      {
        title: "Cookies",
        url: "/it/cookies/"
      },
      {
        title: "Sitemap",
        url: "/it/sitemap/"
      }
    ],
    logo: {
      src: bfItalyLogo,
      alt: "BlackFridayExpert Italy Logo",
      title: "BlackFridayExpert Italy Logo"
    },
    logoLink: "/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Maggiori informazioni sul Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Pagine più viste",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Negozi con le migliori offerte",
        links: []
      }
    ],
    newsletter: {
      title: "Iscriviti alla newsletter",
      text: "E ottieni le migliori offerte",
      buttonText: "Newsletter",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Seguici",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook Icon",
            title: "Facebook Icon"
          },
          link: "https://www.facebook.com/Black-Friday-Italia-108317548021976/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram Icon",
            title: "Instagram Icon"
          },
          link: "https://www.instagram.com/blackfridayexpert_it/"
        },
        {
          link: "mailto:info@blackfriday.expert",
          image: {
            src: mailIcon,
            alt: "Mail Icon",
            title: "Mail Icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Salva intestazione",
    title: "Salva intestazione di pagina"
  },
  logo: {
    src: bfItalyLogo,
    alt: "Black Friday Logo",
    title: "Black Friday Logo"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/negozi/",
          title: "Tutte le azioni in un'unica panoramica"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/accordi/",
          title: "Ottieni le migliori offerte con facilità"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/newsletter/",
          title: "Non perderti il ​​Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/negozi/",
      title: "Negozi",
      isSelected: true
    },
    {
      href: "/accordi/",
      title: "Accordi"
    }
  ],
  dealTabs: [
    {
      href: "/negozi/",
      title: "Negozi"
    },
    {
      href: "/accordi/",
      title: "Accordi",
      isSelected: true
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.IT_GREEN,
    "--colour-version-website-RGB": WEBSITE_COLORS.IT_GREEN_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.IT_GREEN_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.IT_GREEN_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.IT_GREEN_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.IT_GREEN_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.IT_LIGHT_ACCENT
  },
  locale: "it-IT",
  tagManagerScriptLocation: "/static/googletagmanager-it-it.js",
  weCanTrackScriptLocation: "/static/wecantrack-it-it.js",
  favIcon: FavIconItaly,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-it.png",
  gtm: "GTM-5MK9BF7"
};
