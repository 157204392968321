import * as React from "react";
import { RouteComponentProps, withRouter } from "react-router";

import { getViewType } from "@app/util/detect-view";
import { InfiniteLineLoader } from "@app/core/infinite-line-loader/infinite-line-loader.component";
import { findPlatformContent } from "@app/api/wp-page-fetcher/utils/platform-content-array";

import { HelmetComponent } from "./helmet.component";
import { ModuleComponent } from "./module.component";
import { PagebuilderContainerProps } from "../index";

export interface IPagebuilderComponentProps {}

type IState = {};

// tslint:disable-next-line: class-name
class component extends React.Component<
  IPagebuilderComponentProps & PagebuilderContainerProps & RouteComponentProps,
  IState
> {
  public constructor(props: IPagebuilderComponentProps & PagebuilderContainerProps & RouteComponentProps) {
    super(props);

    this.state = {
      metaData: undefined
    };

    if (typeof window === "object") {
      window.scroll(0, 0);
      window.addEventListener("resize", this.handleResize.bind(this));
      this.setScreenSize();
      this.changeIcon(props.platformId);
    }
  }

  private intervalId: any;

  public componentDidMount() {
    if (this.props.platform?.googleExperimentId) {
      this.setUpGoogleOptimize();
    }
  }

  public async componentDidUpdate(
    prevProps: IPagebuilderComponentProps & PagebuilderContainerProps & RouteComponentProps
  ) {
    if (this.props.location && this.props.location !== prevProps.location) {
      await this.props.getPage(this.props.location.pathname.toLowerCase(), this.props.platformId);
    }
  }

  public componentWillUnmount() {
    if (typeof window === "object") {
      window.removeEventListener("resize", this.handleResize.bind(this));
    }
  }

  // 1 = nederland, 2 = belgie (nl), 3 = belgie (fr), 4 = frans
  private changeIcon = (platformId: number) => {
    const platform = findPlatformContent(platformId);
    const defaultPlatformFavicon = "https://media.blackfridaynederland.nl/static/FavIcons/favicon.png";

    const link: any = document.querySelector("link[rel*='icon']") || document.createElement("link");
    link.type = "image/x-icon";
    link.rel = "shortcut icon";
    link.href = platform?.favIconPublic || defaultPlatformFavicon;
    document.getElementsByTagName("head")[0].appendChild(link);
  };

  private handleResize() {
    this.setScreenSize();
  }

  private setScreenSize() {
    const screenSize = getViewType(window.innerWidth);

    if (this.props.screenSize === undefined || this.props.screenSize.viewType !== screenSize.viewType) {
      this.props.setScreenSize(screenSize);
    }
  }

  private async setUpGoogleOptimize() {
    if (window.dataLayer) {
      await window.dataLayer.push({ event: "optimize.activate" });
    }
    this.intervalId = setInterval(() => {
      if (window.google_optimize !== undefined) {
        const variant = Number(window.google_optimize.get(this.props.platform?.googleExperimentId));
        this.props.setOptimizeVariant(variant || 0);
        clearInterval(this.intervalId);
      }
    }, 100);
  }

  public render() {
    const pageUrl = `${this.props.blackFridayRootUrl}${this.props.location.pathname}`;

    return (
      <React.Fragment>
        <React.Fragment>
          <HelmetComponent
            metaData={this.props.currentPage?.metaData}
            platformId={this.props.platformId}
            rootUrl={this.props.blackFridayRootUrl}
            pageUrl={pageUrl}
            pageRes={this.props.currentPage?.pageRes}
          />
          {this.props.isLoading && <InfiniteLineLoader />}

          {this.props.baseModules?.navBarModule && (
            <ModuleComponent
              wordPressModule={this.props.baseModules?.navBarModule}
              screenSize={this.props.screenSize}
            />
          )}
          {this.props.currentPage?.wordPressPostModules.map((wordPressModule, index) => (
            <ModuleComponent wordPressModule={wordPressModule} screenSize={this.props.screenSize} key={index} />
          ))}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

const PageBuilderComponent = withRouter(component);
export { PageBuilderComponent };
