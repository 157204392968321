import { SearchResponseDTO } from "./../../bf-api/models/SearchResponseDTO";
import { OutMerchantDTO } from "@app/bf-api";
import { OutDealDTO } from "./../../bf-api/models/OutDealDTO";
import { MenuItem } from "@app/api/core/menu-item/menu-item";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface INavBarState {
  isLoading: boolean;
  menuIsOpen: boolean;
  searchIsOpen: boolean;
  selectedMenuItem?: MenuItem;
  searchValue: string;
  foundMerchants: OutMerchantDTO[];
  foundDeals: OutDealDTO[];
}

const initialState: INavBarState = {
  isLoading: false,
  menuIsOpen: false,
  searchIsOpen: false,
  searchValue: "",
  selectedMenuItem: undefined,
  foundDeals: [],
  foundMerchants: []
};

export const slice = createSlice({
  name: "navbar",
  initialState,
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setSearchValue: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setMenuIsOpen: (state, action: PayloadAction<boolean>) => {
      state.menuIsOpen = action.payload;
    },
    setSearchIsOpen: (state, action: PayloadAction<boolean>) => {
      state.searchIsOpen = action.payload;
    },
    setSelectedMenuItem: (state, action: PayloadAction<string>) => {
      state.searchValue = action.payload;
    },
    setSearchResult: (state, action: PayloadAction<SearchResponseDTO>) => {
      state.foundMerchants = [];
      state.foundDeals = [];
    }
  }
});

export const navbarState = (state: RootState) => state.navbar;

export const {
  setSearchValue,
  setMenuIsOpen,
  setSearchIsOpen,
  setSearchResult,
  setSelectedMenuItem,
  setIsLoading
} = slice.actions;

export default slice.reducer;
