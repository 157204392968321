import React from "react";
import { useIntl } from "react-intl";

import nlFlag from "@assets/flags/nl.png";
import beFlag from "@assets/flags/be.png";
import frFlag from "@assets/flags/fr.png";
import deFlag from "@assets/flags/de.png";
import ukFlag from "@assets/flags/uk.png";
import seFlag from "@assets/flags/se.png";
import itFlag from "@assets/flags/it.png";
import esFlag from "@assets/flags/es.png";

import { useAppSelector } from "@app/redux/store";
import { PlatformType } from "@app/constants/platform";
import { getEnvironmentBaseUrl } from "@app/api/wp-page-fetcher/utils/get-environment";
import { IImage } from "@app/api/core/image";

import styles from "./footer-languages-component.module.scss";

const FooterLanguages = () => {
  const intl = useIntl();

  const { platformId } = useAppSelector(state => state.settings);

  const languages = getLanguages(platformId).map(l => languageProps()[l as keyof LanguagePropsMap]);

  if (!languages.length) {
    return <></>;
  }

  return (
    <div className={styles.footerLanguages}>
      <span className={styles.title}>{intl.formatMessage({ id: "footer.languages.title" })}</span>

      <div className={styles.list}>
        {languages.map((l, i) => (
          <a key={i} href={l.link} title={l.image.title}>
            <img src={l.image.src} alt={l.image.alt} title={l.image.title} />
          </a>
        ))}
      </div>
    </div>
  );
};

export { FooterLanguages };

type LanguageProps = {
  link: string;
  image: IImage;
};

type LanguagePropsMap = { [key in Exclude<PlatformType, PlatformType.LANDINGPAGE>]: LanguageProps };

const languageProps = (): LanguagePropsMap => ({
  [PlatformType.NETHERLANDS]: {
    link: `${getEnvironmentBaseUrl()}blackfridaynederland.nl/`,
    image: { src: nlFlag, alt: "Flag of Nederland", title: "Black Friday Nederland" }
  },
  [PlatformType.BELGIANDUTCH]: {
    link: `${getEnvironmentBaseUrl()}blackfridayexpert.be/`,
    image: { src: beFlag, alt: "Flag of België", title: "Black Friday Expert België" }
  },
  [PlatformType.BELGIANFRENCH]: {
    link: `${getEnvironmentBaseUrl()}blackfridayexpert.be/fr/`,
    image: { src: beFlag, alt: "Flag of France", title: "Black Friday Expert Belgique" }
  },
  [PlatformType.FRENCH]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/fr/`,
    image: { src: frFlag, alt: "Flag of France", title: "Black Friday Expert France" }
  },
  [PlatformType.GERMANY]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/de/`,
    image: { src: deFlag, alt: "Flag of Deutschland", title: "Black Friday Expert Deutschland" }
  },
  [PlatformType.UNITED_KINGDOM]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/uk/`,
    image: { src: ukFlag, alt: "Flag of UK", title: "Black Friday Expert UK" }
  },
  [PlatformType.SWEDEN]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/se/`,
    image: { src: seFlag, alt: "Flag of Sverige", title: "Black Friday Expert Sverige" }
  },
  [PlatformType.ITALY]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/it/`,
    image: { src: itFlag, alt: "Flag of Italie", title: "Black Friday Expert Italia" }
  },
  [PlatformType.SPAIN]: {
    link: `${getEnvironmentBaseUrl()}blackfriday.expert/es/`,
    image: { src: esFlag, alt: "Flag of España", title: "Black Friday Expert España" }
  }
});

const getLanguages = (platformId: PlatformType) => {
  switch (platformId) {
    case PlatformType.NETHERLANDS:
      return [
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.BELGIANDUTCH:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.BELGIANFRENCH:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.FRENCH:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.ITALY
      ];
    case PlatformType.GERMANY:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.UNITED_KINGDOM:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.SWEDEN:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SPAIN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];
    case PlatformType.ITALY:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.SPAIN,
        PlatformType.FRENCH
      ];
    case PlatformType.SPAIN:
      return [
        PlatformType.NETHERLANDS,
        PlatformType.GERMANY,
        PlatformType.BELGIANDUTCH,
        PlatformType.UNITED_KINGDOM,
        PlatformType.SWEDEN,
        PlatformType.FRENCH,
        PlatformType.ITALY
      ];

    default:
      return [];
  }
};
