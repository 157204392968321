/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
/**
 *
 * @export
 * @interface ContainsIdDTO
 */
export interface ContainsIdDTO {
  /**
   *
   * @type {number}
   * @memberof ContainsIdDTO
   */
  id: number;
}

export function ContainsIdDTOFromJSON(json: any): ContainsIdDTO {
  return ContainsIdDTOFromJSONTyped(json, false);
}

export function ContainsIdDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ContainsIdDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    id: json["id"]
  };
}

export function ContainsIdDTOToJSON(value?: ContainsIdDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    id: value.id
  };
}
