import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IntlShape, useIntl } from "react-intl";
import clsx from "clsx";

import HandPointing from "@assets/icons/hand-pointing.svg";
import DealIcon from "@assets/icons/medal.svg";
import { IconComponent, ResourceTextComponent } from "@app/core";
import { FilterItem } from "@app/api/core/filter/filter-item";
import {
  replaceOrAdd,
  filtersAreDifferent,
  getDealCount,
  getSelectedIds,
  getSelectedFilters,
  usePrevious,
  addSortToGTM
} from "@app/util";
import { FIRST_DEAL_TAKE } from "@app/constants/overviews-numbers";

import {
  clearAllFilters,
  dealOverviewState,
  setBrandFilters,
  setCategoryFilters,
  setMerchantFilters,
  setOrderByValues
} from "@app/redux/reducers/deals";
import { settingsState } from "@app/redux/reducers/settings";
import {
  getBrandTopFilters,
  getCategoryTopFilters,
  getDeals,
  getMerchantTopFilters,
  onBrandFilterSearch as rdxOnBrandFilterSearch,
  onCategoryFilterSearch as rdxOnCategoryFilterSearch,
  onMerchantFilterSearch as rdxOnMerchantFilterSearch
} from "@app/redux/thunks/deal-overview.thunk";
import { formatMessage } from "@app/translations/intl";

import { SearchableCheckboxDropdown } from "../searchable-checkbox-dropdown/searchable-checkbox-dropdown.component";
import { SelectComponent } from "../select";
import { MobileSortComponent } from "../mobile-sort";
import { SingleMobileFilter } from "../mobile-filter/single-mobile-filter.component";
import styles from "./filter-bar-deals-component.module.scss";

export interface IFilterBarProps {
  onMobileFilterOpen?: (isOpen: boolean) => void;
}

export const FilterBarDeals = (props: IFilterBarProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const settings = useSelector(settingsState);
  const { platformId } = settings;

  const dealState = useSelector(dealOverviewState);
  const {
    brandFilterItems: rdxBrandFilterItems,
    categoryFilterItems: rdxCategoryFilterItems,
    merchantFilterItems: rdxMerchantFilterItems,
    totalAmountOfDeals: rdxTotalAmountOfDeals,
    totalResults
  } = dealState;

  const [categoryFilterItems, setCategoryFilterItems] = useState<FilterItem[]>([]);
  const [selectedCategoryFilterItems, setSelectedCategoryFilterItems] = useState<FilterItem[]>([]);
  const [brandFilterItems, setBrandFilterItems] = useState<FilterItem[]>([]);
  const [selectedBrandFilterItems, setSelectedBrandFilterItems] = useState<FilterItem[]>([]);
  const [merchantFilterItems, setMerchantFilterItems] = useState<FilterItem[]>([]);
  const [selectedMerchantFilterItems, setSelectedMerchantFilterItems] = useState<FilterItem[]>([]);
  const [totalNumberOfFilters, setTotalNumberOfFilters] = useState<number>(0);
  const [totalAmountOfDeals, setTotalAmountOfDeals] = useState<number | undefined>(undefined);
  const [orderBy, setOrderBy] = useState<string>("Meest populair");
  const prevOrderBy = usePrevious(orderBy);

  // Initial setup
  useEffect(() => {
    const totalSelectedFilters =
      rdxCategoryFilterItems.filter(item => item.isSelected).length +
      rdxBrandFilterItems.filter(item => item.isSelected).length +
      rdxMerchantFilterItems.filter(item => item.isSelected).length;

    setTotalNumberOfFilters(totalSelectedFilters);

    const internalSelectedCategoryItems = rdxCategoryFilterItems.filter(item => item.isSelected);
    const internalSelectedBrandItems = rdxBrandFilterItems.filter(item => item.isSelected);
    const internalSelectedMerchantFilterItems = rdxMerchantFilterItems.filter(item => item.isSelected);

    setSelectedCategoryFilterItems(internalSelectedCategoryItems);
    setSelectedBrandFilterItems(internalSelectedBrandItems);
    setSelectedMerchantFilterItems(internalSelectedMerchantFilterItems);

    const externalSelectedCategoryFilterItems = getSelectedFilters(
      rdxCategoryFilterItems,
      internalSelectedCategoryItems
    );
    setCategoryFilterItems(externalSelectedCategoryFilterItems);

    const externalSelectedBrandFilterItems = getSelectedFilters(rdxBrandFilterItems, internalSelectedBrandItems);
    setBrandFilterItems(externalSelectedBrandFilterItems);

    const externalSelectedMerchantFilterItems = getSelectedFilters(
      rdxMerchantFilterItems,
      internalSelectedMerchantFilterItems
    );
    setMerchantFilterItems(externalSelectedMerchantFilterItems);
  }, []);

  // New Category filter items received, set the selected items and add them to internal state.
  useEffect(() => {
    const newCategoryFilterItems = getSelectedFilters(rdxCategoryFilterItems, selectedCategoryFilterItems);
    setCategoryFilterItems(newCategoryFilterItems);
  }, [rdxCategoryFilterItems]);

  // New Brand filter items received, set the selected items and add them to internal state.
  useEffect(() => {
    const newBrandFilterItems = getSelectedFilters(rdxBrandFilterItems, selectedBrandFilterItems);
    setBrandFilterItems(newBrandFilterItems);
  }, [rdxBrandFilterItems]);

  // New Merchant filter items received, set the selected items and add them to internal state.
  useEffect(() => {
    const newMerchantFilterItems = getSelectedFilters(rdxMerchantFilterItems, selectedMerchantFilterItems);
    setMerchantFilterItems(newMerchantFilterItems);
  }, [rdxMerchantFilterItems]);

  useEffect(() => {
    if (typeof prevOrderBy !== "undefined") {
      const orderByApiValues = getOrderByApiValues(orderBy, intl);
      dispatch(setOrderByValues(orderByApiValues));
      dispatch(
        getDeals(
          0,
          FIRST_DEAL_TAKE,
          selectedCategoryFilterItems,
          selectedBrandFilterItems,
          selectedMerchantFilterItems,
          platformId,
          orderByApiValues[1],
          orderByApiValues[0]
        )
      );
    }
  }, [orderBy]);

  const onOrderBySelect = (value: string) => {
    setOrderBy(value);
    addSortToGTM(value);
  };

  const onCategorySelection = (item: FilterItem) => {
    let newSelectedCategories: FilterItem[] = [];
    selectedCategoryFilterItems.find(selectedItem => selectedItem.id === item.id)
      ? (newSelectedCategories = selectedCategoryFilterItems.filter(selectedItem => selectedItem.id !== item.id))
      : (newSelectedCategories = [...selectedCategoryFilterItems, { ...item }]);

    setSelectedCategoryFilterItems(newSelectedCategories);
    setAmountOfDeals(newSelectedCategories, selectedBrandFilterItems, selectedMerchantFilterItems);
  };

  const onBrandSelection = (item: FilterItem) => {
    let newSelectedBrands: FilterItem[] = [];
    selectedBrandFilterItems.find(selectedItem => selectedItem.id === item.id)
      ? (newSelectedBrands = selectedBrandFilterItems.filter(selectedItem => selectedItem.id !== item.id))
      : (newSelectedBrands = [...selectedBrandFilterItems, { ...item }]);

    setAmountOfDeals(selectedCategoryFilterItems, newSelectedBrands, selectedMerchantFilterItems);
    setSelectedBrandFilterItems(newSelectedBrands);
  };

  const onMerchantSelection = (item: FilterItem) => {
    let newSelectedMerchants: FilterItem[] = [];
    selectedMerchantFilterItems.find(selectedItem => selectedItem.id === item.id)
      ? (newSelectedMerchants = selectedMerchantFilterItems.filter(selectedItem => selectedItem.id !== item.id))
      : (newSelectedMerchants = [...selectedMerchantFilterItems, { ...item }]);

    setAmountOfDeals(selectedCategoryFilterItems, selectedBrandFilterItems, newSelectedMerchants);
    setSelectedMerchantFilterItems(newSelectedMerchants);
  };

  const onCategorySelectionCleared = () => {
    setSelectedCategoryFilterItems([]);
    dispatch(getDeals(0, FIRST_DEAL_TAKE, [], selectedBrandFilterItems, selectedMerchantFilterItems, platformId));
    setNewTotalAmountOfSelectedFilters([], selectedBrandFilterItems, selectedMerchantFilterItems);
    setAmountOfDeals([], selectedBrandFilterItems, selectedMerchantFilterItems);
  };

  const onBrandSelectionCleared = () => {
    setSelectedBrandFilterItems([]);
    dispatch(getDeals(0, FIRST_DEAL_TAKE, selectedCategoryFilterItems, [], selectedMerchantFilterItems, platformId));
    setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, [], selectedMerchantFilterItems);
    setAmountOfDeals(selectedCategoryFilterItems, [], selectedMerchantFilterItems);
  };

  const onMerchantSelectionCleared = () => {
    setSelectedMerchantFilterItems([]);
    dispatch(getDeals(0, FIRST_DEAL_TAKE, selectedCategoryFilterItems, selectedBrandFilterItems, [], platformId));
    setNewTotalAmountOfSelectedFilters(selectedCategoryFilterItems, selectedBrandFilterItems, []);
    setAmountOfDeals(selectedCategoryFilterItems, selectedBrandFilterItems, []);
  };

  const onCategoryFilterItemsChanged = (items: FilterItem[]) => {
    if (items && items.length === 0) {
      return;
    }

    const newFilters = replaceOrAdd(items, selectedCategoryFilterItems);

    if (filtersAreDifferent(newFilters, categoryFilterItems)) {
      dispatch(setCategoryFilters([...newFilters]));
      dispatch(getBrandTopFilters(selectedCategoryFilterItems, merchantFilterItems, platformId));
      dispatch(getMerchantTopFilters(selectedBrandFilterItems, selectedCategoryFilterItems));
      dispatch(
        getDeals(
          0,
          FIRST_DEAL_TAKE,
          selectedCategoryFilterItems,
          selectedBrandFilterItems,
          selectedMerchantFilterItems,
          platformId
        )
      );
      setNewTotalAmountOfSelectedFilters(
        selectedCategoryFilterItems,
        selectedBrandFilterItems,
        selectedMerchantFilterItems
      );
    }
  };

  const onBrandFilterItemsChanged = (items: FilterItem[]) => {
    if (items && items.length === 0) {
      return;
    }
    const newFilters = replaceOrAdd(items, selectedBrandFilterItems);

    if (filtersAreDifferent(newFilters, brandFilterItems)) {
      dispatch(setBrandFilters([...newFilters]));
      dispatch(getCategoryTopFilters(newFilters, merchantFilterItems, platformId));
      dispatch(
        getMerchantTopFilters(
          newFilters,
          categoryFilterItems.filter(item => item.isSelected)
        )
      );

      dispatch(
        getDeals(
          0,
          FIRST_DEAL_TAKE,
          selectedCategoryFilterItems,
          selectedBrandFilterItems,
          selectedMerchantFilterItems,
          platformId
        )
      );
      setNewTotalAmountOfSelectedFilters(
        selectedCategoryFilterItems,
        selectedBrandFilterItems,
        selectedMerchantFilterItems
      );
    }
  };

  const onMerchantFilterItemsChanged = (items: FilterItem[]) => {
    if (items && items.length === 0) {
      return;
    }
    const newFilters = replaceOrAdd(items, selectedMerchantFilterItems);

    if (filtersAreDifferent(newFilters, rdxMerchantFilterItems)) {
      dispatch(setMerchantFilters(newFilters));
      dispatch(getBrandTopFilters(selectedCategoryFilterItems, newFilters, platformId));
      dispatch(getCategoryTopFilters(selectedBrandFilterItems, newFilters, platformId));

      dispatch(
        getDeals(0, FIRST_DEAL_TAKE, selectedCategoryFilterItems, selectedBrandFilterItems, newFilters, platformId)
      );
      setNewTotalAmountOfSelectedFilters(
        selectedCategoryFilterItems,
        selectedBrandFilterItems,
        selectedMerchantFilterItems
      );
    }
  };

  const onBrandFilterSearch = (value: string) => {
    if (value) {
      dispatch(rdxOnBrandFilterSearch(value, categoryFilterItems, merchantFilterItems));
    } else {
      dispatch(getBrandTopFilters(categoryFilterItems, merchantFilterItems, platformId));
    }
  };

  const onMerchantFilterSearch = (value: string) => {
    dispatch(rdxOnMerchantFilterSearch(value, brandFilterItems, categoryFilterItems));
  };

  const onCategoryFilterSearch = (value: string) => {
    if (value) {
      dispatch(rdxOnCategoryFilterSearch(value, brandFilterItems, merchantFilterItems));
    } else {
      dispatch(getCategoryTopFilters(brandFilterItems, merchantFilterItems, platformId));
    }
  };

  const onMobileBrandFilterChanged = (filters: FilterItem[]) => {
    let brandFilters: FilterItem[] = [];
    brandFilters = filters.filter(item => item.isSelected);

    const selectedCurrentBrandIds = selectedBrandFilterItems.map(item => item.id).join(";");
    const selectedNewBrandIds = brandFilters.map(item => item.id).join(";");

    setSelectedBrandFilterItems(brandFilters);
    dispatch(getBrandTopFilters(categoryFilterItems, merchantFilterItems, platformId));

    const brandsChanged = selectedCurrentBrandIds !== selectedNewBrandIds;

    if (brandsChanged) {
      setAmountOfDeals(categoryFilterItems, brandFilters, merchantFilterItems);
      setNewTotalAmountOfSelectedFilters(categoryFilterItems, brandFilters, merchantFilterItems);
    }
  };

  const onMobileCategoryFilterChanged = (filters: FilterItem[]) => {
    let categoryFilters: FilterItem[] = [];
    categoryFilters = filters.filter(item => item.isSelected);

    const selectedCurrentCategoryIds = selectedCategoryFilterItems.map(item => item.id).join(";");
    const selectedNewCategoryIds = categoryFilters.map(item => item.id).join(";");

    setSelectedCategoryFilterItems(categoryFilters);
    dispatch(getCategoryTopFilters(brandFilterItems, merchantFilterItems, platformId));

    const categoryChanged = selectedCurrentCategoryIds !== selectedNewCategoryIds;

    if (categoryChanged) {
      setAmountOfDeals(categoryFilters, brandFilterItems, merchantFilterItems);
      setNewTotalAmountOfSelectedFilters(categoryFilters, brandFilterItems, merchantFilterItems);
    }
  };

  const onMobileFilterOpenChanged = (isOpen: boolean) => {
    if (!isOpen) {
      const newBrandFilterItems = getSelectedFilters(brandFilterItems, selectedBrandFilterItems);
      dispatch(setBrandFilters([...newBrandFilterItems]));
      const newCategoryFilterItems = getSelectedFilters(categoryFilterItems, selectedCategoryFilterItems);
      dispatch(setCategoryFilters([...newCategoryFilterItems]));
      const newMerchantFilterItems = getSelectedFilters(merchantFilterItems, selectedMerchantFilterItems);
      dispatch(setMerchantFilters([...newMerchantFilterItems]));

      dispatch(
        getDeals(
          0,
          FIRST_DEAL_TAKE,
          selectedCategoryFilterItems,
          selectedBrandFilterItems,
          selectedMerchantFilterItems,
          platformId
        )
      );
    }
    setNewTotalAmountOfSelectedFilters(
      selectedCategoryFilterItems,
      selectedBrandFilterItems,
      selectedMerchantFilterItems
    );

    props.onMobileFilterOpen?.(isOpen);
  };

  const setAmountOfDeals = async (
    categoryFilters: FilterItem[],
    brandFilters: FilterItem[],
    merchantFilters: FilterItem[]
  ) => {
    const total = await getDealCount(platformId, categoryFilters, brandFilters, merchantFilters);
    rdxTotalAmountOfDeals === total ? setTotalAmountOfDeals(undefined) : setTotalAmountOfDeals(total);
  };

  const setNewTotalAmountOfSelectedFilters = (
    selectedCategoryFilters: FilterItem[],
    selectedBrandFilters: FilterItem[],
    selectedMerchantFilters: FilterItem[]
  ) => {
    const totalSelectedFilters =
      selectedCategoryFilters.length + selectedBrandFilters.length + selectedMerchantFilters.length;

    setTotalNumberOfFilters(totalSelectedFilters);
  };

  const sortOptions = [
    formatMessage({
      id: "filterBarDeals.sortByMostPopular"
    }),
    formatMessage({
      id: "filterBarDeals.sortByHighestDiscount"
    }),
    formatMessage({
      id: "filterBarDeals.sortByPriceLowHigh"
    }),
    formatMessage({
      id: "filterBarDeals.sortByPriceHighLow"
    }),
    formatMessage({
      id: "filterBarDeals.sortByNewestDeals"
    }),
    formatMessage({
      id: "filterBarDeals.sortByLastChanged"
    })
  ];

  return (
    <div className={clsx(styles["filter-bar"], "uk-container")}>
      <div className={clsx(styles.filterContainer, styles.desktop)}>
        <div className={`${styles["filter-bar__filter-list"]}  `}>
          <div className={`${styles["filter-label"]}`}>
            <span>
              <ResourceTextComponent resourceKey={"filterBar.filterTitle"} />
            </span>
            <span>
              <IconComponent icon={HandPointing} size={"20px"} />
            </span>
          </div>
          <div>
            <SearchableCheckboxDropdown
              searchPlaceholder={formatMessage({
                id: "filterBarDeals.checkboxDropdown.categorySearchPlaceholder"
              })}
              deleteFilterLabel={formatMessage({
                id: "filterBarDeals.checkboxDropdown.categoryDeleteFilters"
              })}
              title={formatMessage({
                id: "filterBarDeals.checkboxDropdown.categoryTitle"
              })}
              showFilterName={formatMessage({
                id: "filterBarDeals.checkboxDropdown.categoryFilterName"
              })}
              items={categoryFilterItems}
              onChange={onCategoryFilterItemsChanged}
              onSearch={onCategoryFilterSearch}
              overviewType="deals"
              onSelection={onCategorySelection}
              onSelectionCleared={onCategorySelectionCleared}
              selectedItems={selectedCategoryFilterItems}
              totalSelectedItems={totalAmountOfDeals}
              isFilterDropDown
              isRounded
            />
          </div>
          <div>
            <SearchableCheckboxDropdown
              searchPlaceholder={formatMessage({
                id: "filterBarDeals.checkboxDropdown.brandSearchPlaceholder"
              })}
              deleteFilterLabel={formatMessage({
                id: "filterBarDeals.checkboxDropdown.brandDeleteFilters"
              })}
              title={formatMessage({
                id: "filterBarDeals.checkboxDropdown.brandTitle"
              })}
              showFilterName={formatMessage({
                id: "filterBarDeals.checkboxDropdown.brandFilterName"
              })}
              items={brandFilterItems}
              onChange={onBrandFilterItemsChanged}
              onSearch={onBrandFilterSearch}
              onSelection={onBrandSelection}
              onSelectionCleared={onBrandSelectionCleared}
              overviewType="deals"
              selectedItems={selectedBrandFilterItems}
              totalSelectedItems={totalAmountOfDeals}
              isFilterDropDown
              isRounded
            />
          </div>
          <div>
            <SearchableCheckboxDropdown
              searchPlaceholder={formatMessage({
                id: "filterBarDeals.checkboxDropdown.merchantSearchPlaceholder"
              })}
              deleteFilterLabel={formatMessage({
                id: "filterBarDeals.checkboxDropdown.merchantDeleteFilters"
              })}
              title={formatMessage({
                id: "filterBarDeals.checkboxDropdown.merchantFilterName"
              })}
              showFilterName={formatMessage({
                id: "filterBarDeals.checkboxDropdown.merchantFilterName"
              })}
              items={merchantFilterItems}
              onChange={onMerchantFilterItemsChanged}
              onSearch={onMerchantFilterSearch}
              onSelection={onMerchantSelection}
              onSelectionCleared={onMerchantSelectionCleared}
              overviewType="deals"
              selectedItems={selectedMerchantFilterItems}
              totalSelectedItems={totalAmountOfDeals}
              isFilterDropDown
              isRounded
            />
          </div>
          {totalNumberOfFilters > 0 && (
            <div data-cy="filter-bar-deals_clear-filter" className={styles["filter-bar__clear-filter"]}>
              <span
                role="link"
                onClick={() => {
                  const selectedCategoryIds = getSelectedIds(categoryFilterItems);
                  const selectedBrandIds = getSelectedIds(brandFilterItems);
                  const selectedMerchantIds = getSelectedIds(merchantFilterItems);

                  setSelectedBrandFilterItems([]);
                  setSelectedCategoryFilterItems([]);
                  setSelectedMerchantFilterItems([]);

                  // Clear filters in case a selection has been made and a subset is selected
                  if (selectedCategoryIds || selectedMerchantIds) {
                    dispatch(getBrandTopFilters([], [], platformId));
                  }
                  if (selectedBrandIds || selectedMerchantIds) {
                    dispatch(getCategoryTopFilters([], [], platformId));
                  }
                  if (selectedCategoryIds || selectedBrandIds) {
                    dispatch(getMerchantTopFilters([], []));
                  }

                  dispatch(clearAllFilters());
                  dispatch(getDeals(0, FIRST_DEAL_TAKE, [], [], [], platformId));
                  setNewTotalAmountOfSelectedFilters([], [], []);
                  setAmountOfDeals([], [], []);
                }}
              >
                <ResourceTextComponent
                  resourceKey={"filterBarDeals.clearFilters"}
                  values={{
                    amountOfActiveFilters: totalNumberOfFilters
                  }}
                />
              </span>
            </div>
          )}
        </div>

        <div className={styles["filter-bar__sort"]}>
          <div className={styles["filter__sort-filter"]}>
            <span className={styles["amount-of-shops"]}>
              <IconComponent icon={DealIcon} size={"20px"} />
            </span>
            <ResourceTextComponent
              resourceKey={"filterBarDeals.amountOfDeals"}
              values={{
                amountOfDeals: totalResults
              }}
            />
            <div className={styles["filter__sort-filter-item"]}>
              <SelectComponent
                onSelect={(value: string) => {
                  onOrderBySelect(value);
                }}
                options={sortOptions}
                variant="white"
                isRounded
              />
            </div>
          </div>
        </div>
      </div>

      <div className={clsx(styles.filterContainer, styles.mobile)}>
        <SingleMobileFilter
          filters={categoryFilterItems}
          filterType="categories"
          onMobileFilterOpen={onMobileFilterOpenChanged}
          onMobileFilterSearch={onCategoryFilterSearch}
          onMobileFiltersChanged={onMobileCategoryFilterChanged}
          typeOfFilters="deals"
          totalAmountOfResults={totalAmountOfDeals}
        />
        <SingleMobileFilter
          filters={brandFilterItems}
          filterType="brands"
          onMobileFilterOpen={onMobileFilterOpenChanged}
          onMobileFilterSearch={onBrandFilterSearch}
          onMobileFiltersChanged={onMobileBrandFilterChanged}
          typeOfFilters="deals"
          totalAmountOfResults={totalAmountOfDeals}
        />

        <MobileSortComponent
          onMobileSortChanged={(value: string) => {
            onOrderBySelect(value);
          }}
          onMobileSortOpen={(isOpen: boolean) => {
            props.onMobileFilterOpen?.(isOpen);
          }}
          sortOptions={sortOptions}
          selectedSortOption={orderBy}
        />
      </div>
    </div>
  );
};

const getOrderByApiValues = (sortBy: string | undefined, intl: IntlShape): [string, string] => {
  switch (sortBy) {
    case formatMessage({
      id: "filterBarDeals.sortByMostPopular"
    }):
      return ["popular", "DESC"];
    case formatMessage({
      id: "filterBarDeals.sortByHighestDiscount"
    }):
      return ["discount", "DESC"];
    case formatMessage({
      id: "filterBarDeals.sortByPriceLowHigh"
    }):
      return ["price", "ASC"];
    case formatMessage({
      id: "filterBarDeals.sortByPriceHighLow"
    }):
      return ["price", "DESC"];
    case formatMessage({
      id: "filterBarDeals.sortByNewestDeals"
    }):
      return ["newlyAdded", "DESC"];
    case formatMessage({
      id: "filterBarDeals.sortByLastChanged"
    }):
      return ["lastUpdated", "DESC"];
    default:
      return ["popular", "DESC"];
  }
};
