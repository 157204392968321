export const setBodyFixed = (setFixed: boolean) => {
  const scrollPosition = window.scrollY;

  if (setFixed) {
    document.body.style.overflow = "hidden";
    document.body.style.position = "fixed";
    document.body.style.top = `-${scrollPosition}px`;
    document.body.style.bottom = "0";
    document.body.style.left = "0";
    document.body.style.right = "0";
  } else {
    document.body.style.overflow = "";
    document.body.style.position = "";
    document.body.style.bottom = "";
    document.body.style.left = "";
    document.body.style.right = "";
    window.scrollTo(0, Math.abs(parseInt(document.body.style.top || "0", 0)));
    document.body.style.top = "";
  }
};
