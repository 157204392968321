/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutProductDTO, OutProductDTOFromJSON, OutProductDTOFromJSONTyped, OutProductDTOToJSON } from "./";

/**
 *
 * @export
 * @interface ProductListResponseDTO
 */
export interface ProductListResponseDTO {
  /**
   *
   * @type {number}
   * @memberof ProductListResponseDTO
   */
  total: number;
  /**
   *
   * @type {Array<OutProductDTO>}
   * @memberof ProductListResponseDTO
   */
  products?: Array<OutProductDTO>;
}

export function ProductListResponseDTOFromJSON(json: any): ProductListResponseDTO {
  return ProductListResponseDTOFromJSONTyped(json, false);
}

export function ProductListResponseDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductListResponseDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    total: json["total"],
    products: !exists(json, "products") ? undefined : (json["products"] as Array<any>).map(OutProductDTOFromJSON)
  };
}

export function ProductListResponseDTOToJSON(value?: ProductListResponseDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    total: value.total,
    products: value.products === undefined ? undefined : (value.products as Array<any>).map(OutProductDTOToJSON)
  };
}
