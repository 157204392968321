import { BlogBodyTextModule } from "@app/api/core/blog-bodytext/blog-bodytext";
import { IWPBlogBodyTextModuleFull } from "./blog-body-text-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { sidebarComponentMapper } from "@app/api/wp-page-fetcher/module-selector/component-mappers/sidebar-component";
import { SeoDTO } from "@app/bf-api";

import { stripHtmlString } from "@app/api/wp-page-fetcher/utils/strip-html-string";
import { checkSpecialSinglePage } from "@app/api/wp-page-fetcher/utils/check-special-single-page";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { formatMessage } from "@app/translations/intl";

import { setSingleMerchantInStore } from "../merchant-single-module";
import { authorBlockComponentMapper } from "../../component-mappers/author-component";
import { setSingleProductInStore } from "../product-single-module";

export interface IBlogBodyTextModuleMapper extends IModuleFetcher {
  module: IWPBlogBodyTextModuleFull;
}

export const blogBodyTextModuleMapper = async (
  props: IBlogBodyTextModuleMapper
): Promise<BlogBodyTextModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res,
      shopPageUrlName,
      productPageUrlName,
      splittedUrl,
      pageData
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in blogBodyTextModuleMapper");

    const sidebar = await sidebarComponentMapper({ platformId, sidebarData: data.bodyTextSidebar, res });
    const isMerchantSinglePage = checkSpecialSinglePage(shopPageUrlName, splittedUrl);
    const isProductSinglePage = checkSpecialSinglePage(productPageUrlName, splittedUrl);
    const author = await authorBlockComponentMapper({ platformId, authorData: data.author });
    const html =
      (await checkAndGetSpecialHtml(props, shopPageUrlName, productPageUrlName, splittedUrl)) || data.bodyText || "";

    if (!sidebar && !stripHtmlString(html) && !isMerchantSinglePage && !isProductSinglePage) return undefined;

    return {
      id: "1",
      name: "BlogBodyTextModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      bodyText: {
        id: "1",
        name: "BlogBodyText",
        html
      },
      useMerchantSingleSeoData: isMerchantSinglePage,
      useProductSingleSeoData: isProductSinglePage,
      showSharingOptions: data.sharingOptions,
      dateTime: getReadingTimeText(Number(data.readingTime)),
      publishDate: data.showPublishedAtDate ? pageData.data?.post?.post_date : undefined,
      author,
      showAuthorTop: data.showAuthorTop,
      showAuthorBottom: data.showAuthorBottom,
      ...(sidebar && { sidebarItems: sidebar })
    };
  } catch (e) {
    Logger.logError(e, "Error in: blogBodyTextModuleMapper");

    return undefined;
  }
};

const checkAndGetSpecialHtml = async (
  props: IBlogBodyTextModuleMapper,
  shopPageUrlName?: string,
  productPageUrlName?: string,
  splittedUrl?: string[]
): Promise<string | undefined> => {
  const isMerchantSinglePage = checkSpecialSinglePage(shopPageUrlName, splittedUrl);
  const isProductSinglePage = checkSpecialSinglePage(productPageUrlName, splittedUrl);

  if (!isMerchantSinglePage && !isProductSinglePage) return undefined;

  try {
    if (isMerchantSinglePage) {
      const merchantSeo = await getMerchantSeo(props);

      return merchantSeo?.pageText || "";
    }
    if (isProductSinglePage) {
      const productSeo = await getProductSeo(props);

      return productSeo?.pageText || "";
    }
  } catch (e) {
    Logger.logError(e, "Error in: checkAndGetSpecialBodyText");
    return undefined;
  }
};

const getProductSeo = async (props: IBlogBodyTextModuleMapper): Promise<SeoDTO | undefined> => {
  if (!props.res) return undefined;

  const productStateSeo = props.res.locals.store.getState()?.product?.seo;

  if (!productStateSeo) {
    const product = await setSingleProductInStore(props);
    if (product !== 302 && product?.seo) {
      return product?.seo;
    }
  }

  return productStateSeo;
};

const getMerchantSeo = async (props: IBlogBodyTextModuleMapper): Promise<SeoDTO | undefined> => {
  if (!props.res) return undefined;

  const merchantStateSeo = props.res.locals.store.getState()?.merchantOverview?.currentMerchant?.seo;

  if (!merchantStateSeo) {
    const merchant = await setSingleMerchantInStore(props);
    if (merchant !== 302 && merchant?.seo) {
      return merchant?.seo;
    }
  }

  return merchantStateSeo;
};

const getReadingTimeText = (minutes?: number): string => {
  if (!minutes) return "";

  return minutes === 1
    ? `${minutes} ${formatMessage({ id: "readingTimeText.singular" })}`
    : `${minutes} ${formatMessage({ id: "readingTimeText.plural" })}`;
};
