import React from "react";
import { IUspsModule } from "@app/api/modules/usps-module/usps.module";
import styles from "./usps-component.module.scss";
import clsx from "clsx";
import { ImageComponent } from "../image";

export interface IUsps {
  usps: IUspsModule;
}

const UspsComponent = (props: IUsps) => (
  <div className={clsx(styles.uspContainer, "uk-container")}>
    <div className={styles.outerWrapper}>
      <div className={styles.innerWrapper}>
        {props.usps.usps.map((usp, index) => (
          <div key={index} className={styles.usp}>
            <div className={styles.icon}>
              <ImageComponent src={usp.icon.src} />
            </div>
            <div className={styles.text}>{usp.text}</div>
          </div>
        ))}
      </div>
    </div>
  </div>
);

export { UspsComponent };
