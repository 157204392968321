import React from "react";
import styles from "../mobile-nav-bar-component.module.scss";
import { MenuItem as IMenuItem } from "@app/api/core/menu-item/menu-item";
import { Icon } from "@app/components/icon/icon";
import IconChevronRight from "@assets/icons/chevron-right.svg";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

interface IProps {
  menuItem: IMenuItem;
  expandMenuItem: (menuItem: IMenuItem) => void;
  closeMenu: () => void;
}

export const component = (props: IProps & RouteComponentProps) => {
  const hasChildren = props.menuItem.children && props.menuItem.children.length > 0;

  const expandMenuItem = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (hasChildren) {
      props.expandMenuItem(props.menuItem);

      return;
    }

    props.history.push(props.menuItem.url || "#");
    props.closeMenu();
  };

  const onClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    props.closeMenu();
  };

  return (
    <button className={styles.menuItem} onClick={expandMenuItem}>
      <Link onClick={onClick} className={styles.title} to={props.menuItem.url || ""}>
        {props.menuItem.title}
      </Link>
      <span className={styles.chevron}>
        <Icon icon={IconChevronRight} />
      </span>
    </button>
  );
};

const MenuItem = withRouter(component);
export { MenuItem };
