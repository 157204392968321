import { OutDealDTO, DealsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getBulkDealsApi = async (
  dealIds: string,
  platformId: number,
  labelFilter?: string
): Promise<OutDealDTO[] | undefined> => {
  try {
    const api = new DealsApi();

    const resultFromApi = await api.dealsBulkGet(platformId, dealIds, labelFilter);

    if (!resultFromApi || !resultFromApi.deals) return moduleMapperThrowMessage("Cannot bulk get deals");

    return resultFromApi.deals;
  } catch (e) {
    Logger.logError(e, "Error in: getBulkDealsApi");

    return undefined;
  }
};
