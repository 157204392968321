import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router";
import { useDispatch } from "react-redux";
import clsx from "clsx";

import { SearchInput } from "@app/components/search-input/search-input";
import { IconComponent } from "@app/core";
import SearchIcon from "@assets/icons/search.svg";
import ChevronLeftIcon from "@assets/icons/chevron-left.svg";
import { Icon } from "@app/components/icon/icon";
import { formatMessage } from "@app/translations/intl";
import { searchItems } from "@app/redux/thunks/navbar-search.thunk";
import CrossIcon from "@assets/icons/search-cross.svg";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";
import { useDebouncedValue } from "@app/util/hooks/use-debounced-value";
import { getHotkeyHandler } from "@app/util/hooks/use-hotkeys/parse-hotkey";
import { useHotkeys } from "@app/util/hooks/use-hotkeys/use-hotkeys";
import { searchPageLink } from "@app/util/search-page-link";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card";

import { SearchResults } from "../../search-results/search-results";
import styles from "../mobile-nav-bar-component.module.scss";

interface IProps {
  popularDeals?: IOutFeaturedDealDTO[];
  popularMerchants?: IOutFeaturedMerchantDTO[];
  isSearchOpen: boolean;
  closeSearch: () => void;
  openSearch: () => void;
}

export const component = (props: IProps & RouteComponentProps) => {
  const dispatch = useDispatch();
  const platformId = Number(formatMessage({ id: "global.platformId" }));
  const [searchValue, setSearchValue] = useState<string>();
  const [debouncedSearchValue] = useDebouncedValue(searchValue, 200);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(e.target.value);
  };

  const closeSearch = () => {
    setSearchValue("");
    props.closeSearch();
  };

  const handleDelete = () => {
    setSearchValue("");
  };

  useEffect(() => {
    if (typeof debouncedSearchValue === "string") {
      search(debouncedSearchValue);
    }
  }, [debouncedSearchValue]);

  const search = (s: string) => {
    dispatch(
      searchItems({
        searchValue: s,
        platformId,
        limit: {
          deals: 3,
          merchants: 3,
          products: 5,
          pages: 5
        }
      })
    );
  };

  useEffect(() => {
    if (props.isSearchOpen) {
      document.body.style.overflow = "hidden";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [props.isSearchOpen]);

  const handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>, navigateToSearchPage?: boolean) => {
    if (navigateToSearchPage) {
      props.history.push(searchPageLink(searchValue));
    }
    (e.target as HTMLInputElement)?.blur();
    closeSearch();
  };

  useHotkeys([["escape", closeSearch]]);

  return (
    <div className={clsx(styles.search, props.isSearchOpen && styles.searchIsOpen)}>
      <div className={styles.top}>
        {props.isSearchOpen && (
          <button type="button" onClick={closeSearch} className={styles.icon}>
            <Icon icon={ChevronLeftIcon} />
          </button>
        )}
        <div className={styles.input}>
          <SearchInput
            id="searchInputMobile"
            icon={<IconComponent fillColor="white" size="14px" icon={SearchIcon} />}
            iconPosition="right"
            onChange={handleChange}
            placeholder={formatMessage({ id: "navBar.search.placeholder" })}
            type="text"
            value={searchValue || ""}
            onFocus={props.openSearch}
            deleteIcon={<IconComponent fillColor="white" size="14px" icon={CrossIcon} />}
            onDelete={handleDelete}
            onKeyDown={getHotkeyHandler([
              ["enter", e => handleInputKeyDown(e, true)],
              ["escape", handleInputKeyDown]
            ])}
          />
        </div>
      </div>
      {props.isSearchOpen && (
        <SearchResults
          searchValue={searchValue || ""}
          popularDeals={props.popularDeals}
          popularMerchants={props.popularMerchants}
          closeSearch={closeSearch}
        />
      )}
    </div>
  );
};

const Search = withRouter(component);

export { Search };
