/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  InImageDuplicateDTO,
  InImageDuplicateDTOFromJSON,
  InImageDuplicateDTOFromJSONTyped,
  InImageDuplicateDTOToJSON,
  OutFilterDTO,
  OutFilterDTOFromJSON,
  OutFilterDTOFromJSONTyped,
  OutFilterDTOToJSON,
  PlatformDTO,
  PlatformDTOFromJSON,
  PlatformDTOFromJSONTyped,
  PlatformDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface InCreateMerchantDTO
 */
export interface InCreateMerchantDTO {
  /**
   *
   * @type {string}
   * @memberof InCreateMerchantDTO
   */
  name: string;
  /**
   *
   * @type {PlatformDTO}
   * @memberof InCreateMerchantDTO
   */
  platform: PlatformDTO;
  /**
   *
   * @type {InImageDuplicateDTO}
   * @memberof InCreateMerchantDTO
   */
  logoImage?: InImageDuplicateDTO;
  /**
   *
   * @type {number}
   * @memberof InCreateMerchantDTO
   */
  eCPC?: number;
  /**
   *
   * @type {boolean}
   * @memberof InCreateMerchantDTO
   */
  inOverview?: boolean;
  /**
   *
   * @type {Array<OutFilterDTO>}
   * @memberof InCreateMerchantDTO
   */
  categories?: Array<OutFilterDTO>;
  /**
   *
   * @type {number}
   * @memberof InCreateMerchantDTO
   */
  buyInPosition?: number;
  /**
   *
   * @type {number}
   * @memberof InCreateMerchantDTO
   */
  approvalRate?: number;
}

export function InCreateMerchantDTOFromJSON(json: any): InCreateMerchantDTO {
  return InCreateMerchantDTOFromJSONTyped(json, false);
}

export function InCreateMerchantDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): InCreateMerchantDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    platform: PlatformDTOFromJSON(json["platform"]),
    logoImage: !exists(json, "logoImage") ? undefined : InImageDuplicateDTOFromJSON(json["logoImage"]),
    eCPC: !exists(json, "eCPC") ? undefined : json["eCPC"],
    inOverview: !exists(json, "inOverview") ? undefined : json["inOverview"],
    categories: !exists(json, "categories") ? undefined : (json["categories"] as Array<any>).map(OutFilterDTOFromJSON),
    buyInPosition: !exists(json, "buyInPosition") ? undefined : json["buyInPosition"],
    approvalRate: !exists(json, "approvalRate") ? undefined : json["approvalRate"]
  };
}

export function InCreateMerchantDTOToJSON(value?: InCreateMerchantDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    platform: PlatformDTOToJSON(value.platform),
    logoImage: InImageDuplicateDTOToJSON(value.logoImage),
    eCPC: value.eCPC,
    inOverview: value.inOverview,
    categories: value.categories === undefined ? undefined : (value.categories as Array<any>).map(OutFilterDTOToJSON),
    buyInPosition: value.buyInPosition,
    approvalRate: value.approvalRate
  };
}
