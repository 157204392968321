import React, { useEffect } from "react";
import styles from "./breadcrumb-component.module.scss";
import { withRouter, RouteComponentProps, Link } from "react-router-dom";
import clsx from "clsx";

export interface IBreadcrumbComponentProps {
  backButton?: IBreadcrumbLink;
  breadcrumbs?: IBreadcrumbLink[];
  variant?: "dark" | "light";
}

export interface IBreadcrumbLink {
  name: string;
  url?: string;
}

export const component = (props: IBreadcrumbComponentProps & RouteComponentProps) => {
  const { breadcrumbs } = props;
  const breadcrumbClassNames = clsx(
    styles.breadcrumbs,
    props.variant === "dark" ? styles["breadcrumbs__dark"] : styles["breadcrumbs__light"]
  );

  useEffect(() => {
    window.scroll(0, 0);
  }, [props.breadcrumbs]);

  return (
    <div className={breadcrumbClassNames}>
      {breadcrumbs && (
        <ul className={styles.breadcrumbs}>
          {breadcrumbs.map((link, index) => (
            <li key={index}>
              {breadcrumbs.length - 1 === index ? (
                <span className={styles.truncate}>{link.name}</span>
              ) : (
                <Link to={link.url || ""}>{link.name}</Link>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export const BreadcrumbComponent = withRouter(component);
