import { OutProductExtendedViewDTO } from "@app/bf-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../store";

export interface IProductState {
  selectedProduct?: OutProductExtendedViewDTO;
}

const initialState: IProductState = {
  selectedProduct: undefined
};

export const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    setSelectedProduct: (state, action: PayloadAction<OutProductExtendedViewDTO | undefined>) => {
      state.selectedProduct = action.payload;
    }
  }
});

export const productState = (state: RootState) => state.platform;

export const { setSelectedProduct } = slice.actions;

export default slice.reducer;
