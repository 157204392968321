import * as React from "react";
import styles from "./featured-blog-items-component.module.scss";
import { ImageComponent } from "../image";
import { BlogPost } from "@app/api/core/blog-post/blog-post";
import { Link } from "react-router-dom";
import { getOptimizedImageUrl } from "@app/util/image-optimization";

export interface IFeaturedBlogItemsComponentProps {
  item: BlogPost;
}

const FeaturedBlogItemsComponent = (props: IFeaturedBlogItemsComponentProps) => (
  <div className={styles["featured-blog-items"]}>
    <div className={styles["featured-blog-items__card"]}>
      <Link to={props.item.link.url}>
        <div className={styles["featured-blog-items__card-image"]}>
          <ImageComponent
            alt={props.item.image.alt}
            src={
              getOptimizedImageUrl({
                url: props.item.image.src,
                height: 240
              }) || ""
            }
            title={props.item.image.title}
          />
        </div>
        <h2 className={styles["featured-blog-items__card-title"]}>{props.item.title}</h2>
      </Link>
    </div>
  </div>
);

export { FeaturedBlogItemsComponent };
