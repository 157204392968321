import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { IconComponent } from "@app/core";
import { MerchantLinkListModule } from "@app/api/modules/merchant-link-list/merchant-link-list";
import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";
import styles from "./merchant-link-list-component.module.scss";
import { getMerchantLinkList, IMerchantLinkList } from "./get-merchant-link-list";
import { useSelector } from "react-redux";
import { settingsState } from "@app/redux/reducers/settings";
import { formatMessage } from "@app/translations/intl";

export interface IMerchantLinkListComponentProps {
  merchantLinkList: MerchantLinkListModule;
}

export const MerchantLinkListComponent = (props: IMerchantLinkListComponentProps) => {
  const settings = useSelector(settingsState);
  const { platformId } = settings;

  const { title, merchants } = props.merchantLinkList;
  const [merchantList, setMerchantList] = useState<IMerchantLinkList[]>(merchants || []);

  useEffect(() => {
    if (!merchantList || merchantList.length === 0) {
      getMerchants(platformId);
    }
  }, [merchantList]);

  const getMerchants = async (platform: number) => {
    const newMerchantList = await getMerchantLinkList(platform);
    setMerchantList(newMerchantList);
  };

  return (
    <div className={clsx(styles.merchantLinkList, "uk-container")}>
      {title && <h2>{title}</h2>}
      <div className={styles.listWrapper}>
        <ul>
          {merchantList.map(listItem => (
            <li key={listItem.id} className={clsx(listItem.type === "title" && styles.titleItem)}>
              {listItem.type === "title" ? (
                <h3>{listItem.title}</h3>
              ) : (
                <Link
                  to={
                    listItem.url ? `${formatMessage({ id: "global.merchantOverviewLink" })}${listItem.url}/` : ""
                    // tslint:disable-next-line: jsx-curly-spacing
                  }
                  className={styles.link}
                >
                  <span className={styles.icon}>
                    <IconComponent icon={IcnChevronRight} size="100%" />
                  </span>
                  <span className={styles.title}>{listItem.title}</span>
                </Link>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};
