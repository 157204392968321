import { IWPFeaturedBlogsModuleFull } from "./featured-blogs-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { FeaturedBlogModule } from "@app/api/modules/featured-blog/featured-blog";
import { BlogPost } from "@app/api/core/blog-post/blog-post";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { taxonomyWordPressApiGet } from "@app/api/wp-page-fetcher/wordpress-api/base-wp-api";
import { mapWPPostToBlogs } from "./map-featured-blogs";
import { IWPPost, IWordPressPage } from "@app/api/wp-page-fetcher/module-selector/main-wp.interface";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";

export interface IFeaturedBlogsModuleMapper extends IModuleFetcher {
  module: IWPFeaturedBlogsModuleFull;
}

export const featuredBlogsModuleMapper = async (
  props: IFeaturedBlogsModuleMapper
): Promise<FeaturedBlogModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      pageUrl,
      res
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in featuredBlogsModuleMapper");

    let featuredBlogs: BlogPost[] = [];
    if (res) {
      const result = await getFeaturedBlogs(
        platformId,
        pageUrl,
        data.showMostRecent,
        Number(data.amountOfItems),
        data.selectBlogs
      );

      if (!result || result === 404 || result === 503) {
        return moduleMapperThrowMessage("Cannot get featured blogs in featuredBlogsModuleMapper");
      }

      featuredBlogs = result;
    }

    return {
      id: "8",
      name: "FeaturedBlogModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      items: featuredBlogs,
      title: data.title || "",
      showMostRecent: data.showMostRecent,
      amountOfItems: Number(data.amountOfItems),
      selectBlogs: data.selectBlogs
    };
  } catch (e) {
    Logger.logError(e, "Error in: featuredBlogsModuleMapper");

    return undefined;
  }
};

export const getFeaturedBlogs = async (
  platformId: number,
  pageUrl?: string,
  showMostRecent?: boolean,
  take?: number,
  selectedBlogs?: IWPPost[]
): Promise<BlogPost[] | undefined | (404 | 503)> => {
  if (showMostRecent) {
    const defaultTake = take || 2;
    const allBlogs = await taxonomyWordPressApiGet(platformId, "blog", defaultTake + 1); // +1 so that we have one extra if the blog is removed from the array when it's on the same page
    if (!allBlogs || allBlogs === 404 || allBlogs === 503) return allBlogs;

    const removeCurrentBlog = pageUrl ? allBlogs.filter(blog => blog.data?.permalink !== pageUrl) : allBlogs;
    const takeBlogs = removeCurrentBlog.slice(0, defaultTake);

    return mapWPPostToBlogs(takeBlogs, platformId);
  }

  if (selectedBlogs) {
    const allBlogs = await taxonomyWordPressApiGet(platformId, "blog");
    if (!allBlogs || allBlogs === 404 || allBlogs === 503) return allBlogs;

    const blogs = getSelectedBlogs(allBlogs, selectedBlogs);
    if (blogs.length === 0) return undefined;

    return mapWPPostToBlogs(blogs, platformId);
  }

  return undefined;
};

const getSelectedBlogs = (allBlogs: IWordPressPage[], selectedBlogs: IWPPost[]): IWordPressPage[] | [] => {
  let newBlogs: IWordPressPage[] = [];

  selectedBlogs.forEach(selectedBlog => {
    const foundBlog = allBlogs.find(blog => selectedBlog.ID === blog.data?.post?.ID);

    if (foundBlog) newBlogs.push(foundBlog);
  });

  return newBlogs;
};
