import { IModuleFetcher } from "../../module-selector";
import { IWPLandingPageeModuleFull, ILandingPageModule } from "./landing-page-module.interface";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { LandingPageModule } from "@app/api/modules/landing-page/landing-page";
import Logger from "@app/util/logger";
import { mapParagraphStringsToArray } from "..";

export interface ILandingPageModuleMapper extends IModuleFetcher {
  module: IWPLandingPageeModuleFull;
}

export const landingPageModuleMapper = async (
  props: ILandingPageModuleMapper
): Promise<LandingPageModule | undefined> => {
  try {
    const {
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in landingPageModuleMapper");

    return {
      name: "LandingPageModule",
      id: "19",
      bgColor: data.bgColor,
      countries: data.countries?.map(item => ({
        country: item.country,
        link: item.link.url,
        icon: { alt: item.icon.alt || "", src: item.icon.url || "", title: item.icon.title }
      })),
      perks: mapParagraphStringsToArray(data.undertitle),
      topLogo: { alt: data.logo?.alt || "", src: data.logo?.url || "", title: data.logo?.title },
      rightImage: { alt: data.rightImage?.alt || "", src: data.rightImage?.url || "", title: data.rightImage?.title },
      title: data.title
    };
  } catch (e) {
    Logger.logError(e, "Error in: landingPageModuleMapper");

    return undefined;
  }
};
