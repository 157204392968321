import { FilterItem } from "@app/api/core/filter/filter-item";
import { OutFilterDTO, FiltersApi } from "@app/bf-api";
import { IOutFeaturedDealDTO } from "@app/core/new-deal-card/deal-card.interface";
import { mapDealsToFeaturedDeals } from "@app/api/wp-page-fetcher/module-selector/module-mappers/featured-deals-module";
import { AppThunk } from "@app/redux/store";
import { bannersGetApi } from "@app/middleware/api/banners-get.api";
import { getDealsApi } from "@app/middleware/api/deals-get.api";
import { getAmountOfDealsApi } from "@app/middleware/api/total-amount-of-deals-get.api";
import {
  setBanners,
  setBrandFilters,
  setCategoryFilters,
  setCurrentTotalDeals,
  setDeals,
  setMerchantFilters,
  setSponsoredDeals,
  setTotalAmountOfDeals,
  updateDeals
} from "../reducers/deals";

export interface IDealResult {
  deals: IOutFeaturedDealDTO[];
  total: number;
}

export const getDeals = (
  skip: number,
  take: number,
  categoryFilters: FilterItem[],
  brandFilters: FilterItem[],
  merchantFilters: FilterItem[],
  platformId: number,
  orderByDirection?: string,
  orderBy?: string
): AppThunk => async (dispatch, getState) => {
  try {
    const currentTotal: number = getState().dealOverview.totalCurrentDeals || 0;
    dispatch(setCurrentTotalDeals(currentTotal + take));

    // Combine all filters into a string
    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    const filtersIds = [...categoryFiltersIds, ...brandFiltersIds];
    const filters = filtersIds.join(";");

    const merchantFilterIds = merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    const filtersMerchantIds = [...merchantFilterIds];
    const joinedMerchantFilters = filtersMerchantIds.join(";");

    const resultFromApi = await getDealsApi(
      take,
      skip,
      platformId,
      joinedMerchantFilters || undefined,
      filters,
      orderByDirection,
      orderBy
    );

    const result: IDealResult = {
      deals: mapDealsToFeaturedDeals(resultFromApi?.deals || []),
      total: resultFromApi?.total || 0
    };

    if (skip === 0) {
      dispatch(setDeals(result));
    } else {
      dispatch(updateDeals(result));
    }

    // dont get sponsored deals when paginating
    if (skip === 0) {
      const sponsoredDealsFromApi = await getDealsApi(
        take,
        skip,
        platformId,
        joinedMerchantFilters || undefined,
        filters,
        orderByDirection,
        orderBy,
        true
      );

      const orderedDeals = sponsoredDealsFromApi?.deals?.sort((a, b) => {
        if ((b.sponsoredPosition || 0) > (a?.sponsoredPosition || 0)) {
          return -1;
        }

        return 0;
      });

      const sponsoredResult: IDealResult = {
        deals: mapDealsToFeaturedDeals(orderedDeals || []),
        total: sponsoredDealsFromApi?.total || 0
      };

      dispatch(setSponsoredDeals(sponsoredResult));
    }
  } catch (error) {
    // tslint:disable-next-line: no-unused-expression no-unsafe-any
    // new FatalError(error.name, error.message, error.stack);
  }
};

// TODO: API endpoint for filtertypes will be created at later time.
const categoryFilterType = 1;
const brandFilterType = 3;
export const getAllFilters = (platformId: number): AppThunk => async (dispatch, getState) => {
  const api = new FiltersApi();

  const brandFilters = await api.filtersTopGet("deals", 50, brandFilterType, platformId);
  const brandFilterItems: FilterItem[] = brandFilters.map(filter => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  const categoryFilters = await api.filtersTopGet("deals", 50, categoryFilterType, platformId);
  const categoryFilterItems: FilterItem[] = categoryFilters.map(filter => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  const merchantFilters = await api.filtersMerchantsTopGet(50, platformId, "");
  if (merchantFilters) {
    const merchantFilterItems: FilterItem[] = merchantFilters.map(filter => {
      const filterItem: FilterItem = {
        displayName: filter.name,
        id: filter.id.toString(),
        isSelected: false,
        totalAmount: filter.usageByDeals
      };

      return filterItem;
    });

    dispatch(setMerchantFilters(merchantFilterItems));
  }

  dispatch(setBrandFilters(brandFilterItems));
  dispatch(setCategoryFilters(categoryFilterItems));
};

export const getBrandTopFilters = (
  categoryFilters: FilterItem[],
  merchantFilters: FilterItem[],
  platformId: number
): AppThunk => async (dispatch, getState) => {
  const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const currentlySelectedBrandFilters = getState().dealOverview.brandFilterItems.filter(item => item.isSelected);
  const merchantFilterIds = merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);

  const filtersIds = [...categoryFiltersIds];
  const filters = filtersIds.join(";");
  const merchantFiltersString = merchantFilterIds && merchantFilterIds.length > 0 ? merchantFilterIds.join(";") : "";

  const api = new FiltersApi();
  const brandFilters = await api.filtersTopGet(
    "deals",
    50,
    brandFilterType,
    platformId,
    merchantFiltersString,
    filters
  );
  const brandFilterItems: FilterItem[] = brandFilters.map(filter => {
    let isSelected = false;
    if ((categoryFilters && categoryFilters.length > 0) || (merchantFilters && merchantFilters.length)) {
      isSelected = !!currentlySelectedBrandFilters.find(item => filter.id.toString() === item.id);
    }

    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  dispatch(setBrandFilters(brandFilterItems));
};

export const getCategoryTopFilters = (
  brandFilters: FilterItem[],
  merchantFilters: FilterItem[],
  platformId: number
): AppThunk => async (dispatch, getState) => {
  const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const currentlySelectedCategoryFilters = getState().dealOverview.categoryFilterItems.filter(item => item.isSelected);
  const merchantFilterIds = merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);

  const filtersIds = [...brandFiltersIds];
  const filters = filtersIds.join(";");
  const merchantFiltersString = merchantFilterIds && merchantFilterIds.length > 0 ? merchantFilterIds.join(";") : "";

  const api = new FiltersApi();
  const categoryFilters = await api.filtersTopGet(
    "deals",
    50,
    categoryFilterType,
    platformId,
    merchantFiltersString,
    filters
  );
  const categoryFilterItems: FilterItem[] = categoryFilters.map(filter => {
    let isSelected = false;
    if ((brandFilters && brandFilters.length > 0) || (merchantFilters && merchantFilters.length > 0)) {
      isSelected = !!currentlySelectedCategoryFilters.find(item => filter.id.toString() === item.id);
    }

    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  dispatch(setCategoryFilters(categoryFilterItems));
};

export const getMerchantTopFilters = (brandFilters: FilterItem[], categoryFilters: FilterItem[]): AppThunk => async (
  dispatch,
  getState
) => {
  const currentlySelectedMerchantFilters = getState().dealOverview.merchantFilterItems.filter(item => item.isSelected);

  // Combine all filters into a string
  const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const filtersIds = [...categoryFiltersIds, ...brandFiltersIds];
  const filters = filtersIds.join(";");

  const api = new FiltersApi();
  const platformId = getState().settings.platformId;
  const merchantFilters = await api.filtersMerchantsTopGet(50, platformId, filters);
  if (merchantFilters) {
    const merchantFilterItems: FilterItem[] = merchantFilters.map(filter => {
      let isSelected = false;
      if ((brandFilters && brandFilters.length > 0) || (categoryFilters && categoryFilters.length > 0)) {
        isSelected = !!currentlySelectedMerchantFilters.find(item => filter.id.toString() === item.id);
      }

      const filterItem: FilterItem = {
        displayName: filter.name,
        id: filter.id.toString(),
        isSelected,
        totalAmount: filter.usageByDeals
      };

      return filterItem;
    });

    dispatch(setMerchantFilters(merchantFilterItems));
  } else {
    dispatch(setMerchantFilters([]));
  }
};

export const onMerchantFilterSearch = (
  value: string,
  brandFilters: FilterItem[],
  categoryFilters: FilterItem[]
): AppThunk => async (dispatch, getState) => {
  const currentlySelectedMerchantFilters = getState().dealOverview.merchantFilterItems.filter(item => item.isSelected);

  // Combine all filters into a string
  const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const filtersIds = [...categoryFiltersIds, ...brandFiltersIds];
  const filters = filtersIds.join(";");

  const api = new FiltersApi();
  const platformId = getState().settings.platformId;
  const merchantFilterResult = await api.filtersMerchantsGet(50, filters, platformId, value);

  if (merchantFilterResult.filters) {
    const merchantFilterItems: FilterItem[] = merchantFilterResult.filters.map(filter => {
      const isSelected = !!currentlySelectedMerchantFilters.find(item => filter.id.toString() === item.id);

      const filterItem: FilterItem = {
        displayName: filter.name,
        id: filter.id.toString(),
        isSelected,
        totalAmount: filter.usageByDeals
      };

      return filterItem;
    });

    dispatch(setMerchantFilters(merchantFilterItems));
  } else {
    dispatch(setMerchantFilters([]));
  }
};

export const onBrandFilterSearch = (
  value: string,
  categoryFilters?: FilterItem[],
  merchantFilters?: FilterItem[]
): AppThunk => async (dispatch, getState) => {
  let filters;
  let merchantFiltersString;

  if (categoryFilters && categoryFilters.length > 0) {
    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const filtersIds = [...categoryFiltersIds];
    filters = filtersIds.join(";");
  }
  if (merchantFilters && merchantFilters.length > 0) {
    const merchantFilterIds = merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    const filtersIds = [...merchantFilterIds];
    merchantFiltersString = filtersIds.join(";");
  }

  if (!categoryFilters || categoryFilters.length === 0) {
    filters = undefined;
  }
  const platformId = getState().settings.platformId;
  const api = new FiltersApi();
  let brandFilterResult: OutFilterDTO[];
  if (value) {
    // implement merchant filters
    const result: any = await api.filtersGet(
      value,
      50,
      brandFilterType.toString(),
      "deals",
      merchantFiltersString,
      filters,
      platformId
    );
    brandFilterResult = result.filters;
  } else {
    brandFilterResult = await api.filtersTopGet(
      "deals",
      50,
      brandFilterType,
      platformId,
      merchantFiltersString,
      filters
    );
  }

  const brandFilterItems: FilterItem[] = brandFilterResult.map((filter: OutFilterDTO) => {
    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected: false,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  dispatch(setBrandFilters(brandFilterItems));
};

export const onCategoryFilterSearch = (
  value: string,
  brandFilters?: FilterItem[],
  merchantFilters?: FilterItem[]
): AppThunk => async (dispatch, getState) => {
  const currentlySelectedCategoryFilters = getState().dealOverview.categoryFilterItems.filter(item => item.isSelected);

  let filters;
  let merchantFiltersString;

  if (brandFilters && brandFilters.length > 0) {
    const categoryFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const filtersIds = [...categoryFiltersIds];
    filters = filtersIds.join(";");
  }
  if (merchantFilters && merchantFilters.length > 0) {
    const merchantFilterIds = merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    const filtersIds = [...merchantFilterIds];
    merchantFiltersString = filtersIds.join(";");
  }

  if (!brandFilters || brandFilters.length === 0) {
    filters = undefined;
  }
  const platformId = getState().settings.platformId;
  const api = new FiltersApi();
  let categoryFilterResult: OutFilterDTO[];
  if (value) {
    // implement merchant filters
    const result: any = await api.filtersGet(
      value,
      50,
      categoryFilterType.toString(),
      "deals",
      merchantFiltersString,
      filters,
      platformId
    );
    categoryFilterResult = result.filters;
  } else {
    categoryFilterResult = await api.filtersTopGet(
      "deals",
      50,
      categoryFilterType,
      platformId,
      merchantFiltersString,
      filters
    );
  }

  const categoryFilterItems: FilterItem[] = categoryFilterResult.map((filter: OutFilterDTO) => {
    const isSelected = !!currentlySelectedCategoryFilters.find(item => filter.id.toString() === item.id);

    const filterItem: FilterItem = {
      displayName: filter.name,
      id: filter.id.toString(),
      isSelected,
      totalAmount: filter.usageByDeals
    };

    return filterItem;
  });

  dispatch(setCategoryFilters(categoryFilterItems));
};

export const getBanners = (
  platformId: number,
  categoryFilters?: FilterItem[],
  brandFilters?: FilterItem[]
): AppThunk => async (dispatch, getState) => {
  let filterIds: string[] = [];
  if (categoryFilters && Array.isArray(categoryFilters) && brandFilters && Array.isArray(brandFilters)) {
    const categoryFiltersIds = categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);

    const brandFiltersIds = brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
    filterIds = [...categoryFiltersIds, ...brandFiltersIds];
  }

  const filters = filterIds.join(";");

  const banners = (await bannersGetApi(platformId, filters)) || [];

  dispatch(setBanners(banners));
};

export const getBannersWithFilters = (platformId: number, filters: string): AppThunk => async (dispatch, getState) => {
  const banners = (await bannersGetApi(platformId, filters)) || [];

  dispatch(setBanners(banners));
};

export const getTotalAmountOfDeals = (platformId: number): AppThunk => async (dispatch, getState) => {
  const totalAmountOfDeals = (await getAmountOfDealsApi(platformId)) || 0;

  dispatch(setTotalAmountOfDeals(totalAmountOfDeals));
};

export const dealOverviewThunks = {
  getAllFilters,
  getBanners,
  getBannersWithFilters,
  getBrandTopFilters,
  getCategoryTopFilters,
  getDeals,
  getMerchantTopFilters,
  getTotalAmountOfDeals,
  onBrandFilterSearch,
  onCategoryFilterSearch,
  onMerchantFilterSearch
};
