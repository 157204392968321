import React, { useState } from "react";
import { CtaNewsletterModule } from "@app/api/modules/cta-newsletter/cta-newsletter.module";
import styles from "./cta-newsletter-component.module.scss";
import { ShadowCardComponent } from "@app/core/shadow-card";
import { IconComponent, ImageComponent, ResourceTextComponent } from "@app/core";
import NewsletterArrow from "../../../assets/icons/newsletter-arrow.svg";
import { Link } from "react-router-dom";
import {
  NewNewsletterFormComponent,
  INewNewsletterFormValues
} from "@app/core/newsletter/new-newsletter-form/new-newsletter-form.component";
import { useDispatch } from "react-redux";
import { subscribeToNewsletter } from "@app/redux/thunks/newsletter.thunk";
import { ReCaptcha } from "@app/core/recaptcha/recaptcha.component";
import { useIntl } from "react-intl";
import { formatMessage } from "@app/translations/intl";

export interface ICtaNewsletterComponentProps {
  ctaNewsLetterModule: CtaNewsletterModule;
}

export const CtaNewsletterComponent = (props: ICtaNewsletterComponentProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [recaptchaToken, setRecaptchaToken] = React.useState("");
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const onSubmit = (values: INewNewsletterFormValues) => {
    if (values.emailAddress) {
      dispatch(subscribeToNewsletter(values, recaptchaToken));
      setIsSubmitted(true);
    }
  };

  return (
    <div className={styles.ctaSmallNewsLetter}>
      <div className="uk-container">
        <ShadowCardComponent borderRadius={["2px"]}>
          <div className={styles.center}>
            <div className={styles.wrapper}>
              {props.ctaNewsLetterModule.icon && (
                <div className={styles.paperIcon}>
                  <ImageComponent src={props.ctaNewsLetterModule.icon} />
                </div>
              )}
              {props.ctaNewsLetterModule.ctaText && (
                <h4 className={styles.ctaText}>{props.ctaNewsLetterModule.ctaText}</h4>
              )}

              {props.ctaNewsLetterModule.icon && (
                <div className={styles.arrowIcon}>
                  <IconComponent size="" icon={NewsletterArrow} />
                </div>
              )}

              <div className={styles.applyForm}>
                <ReCaptcha action="newsletter" callBackToken={setRecaptchaToken} />
                <NewNewsletterFormComponent intl={intl} onSubmit={onSubmit} isSubmitted={isSubmitted} />
              </div>
            </div>
            <p className={styles.infoText}>
              <ResourceTextComponent
                resourceKey="newsletterModalForm.infoText"
                values={{
                  conditions: (
                    <Link rel="nofollow" to={formatMessage({ id: "global.conditionsLink" })}>
                      {formatMessage({ id: "newsletterModalForm.conditions" })}
                    </Link>
                  )
                }}
              />
            </p>
          </div>
        </ShadowCardComponent>
      </div>
    </div>
  );
};
