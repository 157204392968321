/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import {
  FilterLabelDTO,
  FilterLabelDTOFromJSON,
  FilterLabelDTOFromJSONTyped,
  FilterLabelDTOToJSON,
  FilterTypeDTO,
  FilterTypeDTOFromJSON,
  FilterTypeDTOFromJSONTyped,
  FilterTypeDTOToJSON
} from "./";

/**
 *
 * @export
 * @interface OutFilterDTO
 */
export interface OutFilterDTO {
  /**
   *
   * @type {string}
   * @memberof OutFilterDTO
   */
  name: string;
  /**
   *
   * @type {FilterTypeDTO}
   * @memberof OutFilterDTO
   */
  type: FilterTypeDTO;
  /**
   *
   * @type {FilterLabelDTO}
   * @memberof OutFilterDTO
   */
  label?: FilterLabelDTO;
  /**
   *
   * @type {number}
   * @memberof OutFilterDTO
   */
  usageByMerchant?: number;
  /**
   *
   * @type {number}
   * @memberof OutFilterDTO
   */
  usageByDeals?: number;
  /**
   *
   * @type {number}
   * @memberof OutFilterDTO
   */
  id: number;
}

export function OutFilterDTOFromJSON(json: any): OutFilterDTO {
  return OutFilterDTOFromJSONTyped(json, false);
}

export function OutFilterDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): OutFilterDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    name: json["name"],
    type: FilterTypeDTOFromJSON(json["type"]),
    label: !exists(json, "label") ? undefined : FilterLabelDTOFromJSON(json["label"]),
    usageByMerchant: !exists(json, "usageByMerchant") ? undefined : json["usageByMerchant"],
    usageByDeals: !exists(json, "usageByDeals") ? undefined : json["usageByDeals"],
    id: json["id"]
  };
}

export function OutFilterDTOToJSON(value?: OutFilterDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    name: value.name,
    type: FilterTypeDTOToJSON(value.type),
    label: FilterLabelDTOToJSON(value.label),
    usageByMerchant: value.usageByMerchant,
    usageByDeals: value.usageByDeals,
    id: value.id
  };
}
