import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

export interface IBlackFridayDate {
  day: number;
  month: number;
  year: number;
}

export enum ViewType {
  Mobile,
  MobileBig,
  Tablet,
  Desktop,
  DesktopLarge,
  DesktopFull
}

export interface IScreenSize {
  breakpointPixels: number;
  viewType: ViewType;
}

export interface ISettingsState {
  blackFridayDate?: IBlackFridayDate;
  blackFridayRootUrl?: string;
  incomingUrl: string;
  optimizeVariant?: number;
  platformId: number;
  recaptchaSiteKey: string;
  recaptchaIsLoaded: boolean;
  screenSize?: IScreenSize;
  urlAddition: string;
}

const initialState: ISettingsState = {
  blackFridayDate: undefined,
  blackFridayRootUrl: undefined,
  incomingUrl: "",
  platformId: 1,
  recaptchaSiteKey: "6LcMHNgZAAAAAI3IXOrxZMzvpKawia9Gw_GDDmqp",
  screenSize: undefined,
  optimizeVariant: undefined,
  recaptchaIsLoaded: false,
  urlAddition: ""
};

export const slice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setScreenSize: (state, action: PayloadAction<IScreenSize>) => {
      state.screenSize = action.payload;
    },
    setBlackFridayDate: (state, action: PayloadAction<IBlackFridayDate>) => {
      state.blackFridayDate = action.payload;
    },
    setBlackFridayRootURL: (state, action: PayloadAction<string>) => {
      state.blackFridayRootUrl = action.payload;
    },
    setPlatformId: (state, action: PayloadAction<number>) => {
      state.platformId = action.payload;
    },
    setIncomingUrl: (state, action: PayloadAction<string>) => {
      state.incomingUrl = action.payload;
    },
    setOptimizeVariant: (state, action: PayloadAction<number>) => {
      state.optimizeVariant = action.payload;
    },
    setRecaptchaIsLoaded: (state, action: PayloadAction<boolean>) => {
      state.recaptchaIsLoaded = action.payload;
    },
    setUrlAddition: (state, action: PayloadAction<string>) => {
      state.urlAddition = action.payload;
    }
  }
});

export const settingsState = (state: RootState) => state.settings;

export const {
  setBlackFridayDate,
  setBlackFridayRootURL,
  setIncomingUrl,
  setOptimizeVariant,
  setPlatformId,
  setRecaptchaIsLoaded,
  setScreenSize,
  setUrlAddition
} = slice.actions;

export default slice.reducer;
