import * as React from "react";
import clsx from "clsx";

import { useAppSelector } from "@app/redux/store";
import { PlatformType } from "@app/constants/platform";

import styles from "./header-content-component.module.scss";
import { BreadcrumbComponent, IBreadcrumbComponentProps } from "../breadcrumb";

export interface IHeaderContentComponentProps {
  breadcrumbProps?: IBreadcrumbComponentProps;
  headerMobilePosition?: "left" | "center";
  subtitle?: string;
  text?: string;
  title?: string;
  useDarkText?: boolean;
}

const HeaderContentComponent = (props: IHeaderContentComponentProps) => {
  const { breadcrumbProps, title, subtitle, text, headerMobilePosition, useDarkText } = props;
  const mobilePositionClass = headerMobilePosition ? headerMobilePosition : "center";
  const { platformId } = useAppSelector(state => state.settings);
  const whiteHeaderColor = getUseWhiteTextColor(platformId, useDarkText);

  return (
    <div className={clsx(styles["header-content"], styles[mobilePositionClass], whiteHeaderColor && styles.white)}>
      {breadcrumbProps && (
        <BreadcrumbComponent
          variant={breadcrumbProps.variant}
          breadcrumbs={breadcrumbProps.breadcrumbs}
          backButton={breadcrumbProps.backButton}
        />
      )}

      {title && <h1>{title}</h1>}
      {subtitle && <h2 className={styles.subtitle}>{subtitle}</h2>}
      {text && <span className={styles.text}>{text}</span>}
    </div>
  );
};

export { HeaderContentComponent };

const getUseWhiteTextColor = (platformId: number, useDarkText?: boolean): boolean => {
  const platformsWithDarkText = [PlatformType.NETHERLANDS];

  if (useDarkText && platformsWithDarkText.includes(platformId)) {
    return false;
  }

  return true;
};
