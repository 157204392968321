import { DealsApi } from "@app/bf-api";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import Logger from "@app/util/logger";

export const getAmountOfDealsApi = async (platformId: number): Promise<number | undefined> => {
  try {
    const api = new DealsApi();
    const resultFromApi = await api.dealsCountGet(platformId);

    if (!resultFromApi && resultFromApi !== 0) return moduleMapperThrowMessage("Cannot get amountOfDeals");

    return resultFromApi;
  } catch (e) {
    Logger.logError(e, "Error in: amountOfDeals");

    return undefined;
  }
};
