import { formatMessage } from "@app/translations/intl";
import { PageModel } from "../page.model";
import uuid from "uuid";
import { navBarModuleMapper, errorPageModuleMapper } from "@app/api/wp-page-fetcher/module-selector/module-mappers";
import { PlatformType } from "@app/constants/platform";

export const notFoundPage = async () => {
  return {
    id: uuid(),
    route: formatMessage({ id: "notFound.route" }),
    pageRes: 404,
    metaData: {
      title: formatMessage({ id: "notFound.meta" }),
      content: formatMessage({ id: "notFound.meta" }),
      description: formatMessage({ id: "notFound.meta" }),
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(Number(formatMessage({ id: "global.platformId" }))),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel;
};

export const notAvailablePage = async () => {
  return {
    id: uuid(),
    route: formatMessage({ id: "unavailable.route" }),
    pageRes: 503,
    metaData: {
      title: formatMessage({ id: "unavailable.meta" }),
      content: formatMessage({ id: "unavailable.meta" }),
      description: formatMessage({ id: "unavailable.meta" }),
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(Number(formatMessage({ id: "global.platformId" }))),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel;
};

export const notFoundPageBENL = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Pagina niet gevonden",
      content: "Pagina niet gevonden",
      description: "Pagina niet gevonden",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.BELGIANDUTCH),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageBENL = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Pagina niet beschikbaar",
      content: "Pagina niet beschikbaar",
      description: "Pagina niet beschikbaar",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.BELGIANDUTCH),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageBEFR = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page non trouvée",
      content: "Page non trouvée",
      description: "Page non trouvée",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.BELGIANFRENCH),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageBEFR = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page indisponible",
      content: "Page indisponible",
      description: "Page indisponible",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.BELGIANFRENCH),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageFR = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page non trouvée",
      content: "Page non trouvée",
      description: "Page non trouvée",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.FRENCH),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageFR = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page indisponible",
      content: "Page indisponible",
      description: "Page indisponible",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.FRENCH),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageUK = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page not found",
      content: "Page not found",
      description: "Page not found",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.UNITED_KINGDOM),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageUK = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page not available",
      content: "Page not available",
      description: "Page not available",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.UNITED_KINGDOM),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageDE = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page not found",
      content: "Page not found",
      description: "Page not found",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.GERMANY),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageDE = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page not available",
      content: "Page not available",
      description: "Page not available",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.GERMANY),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageSE = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Seite nicht gefunden",
      content: "Seite nicht gefunden",
      description: "Seite nicht gefunden",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.SWEDEN),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageSE = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Seite ist nicht verfügbar",
      content: "Seite ist nicht verfügbar",
      description: "Seite ist nicht verfügbar",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.SWEDEN),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageES = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page not found",
      content: "Page not found",
      description: "Page not found",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.SPAIN),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageES = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page not available",
      content: "Page not available",
      description: "Page not available",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.SPAIN),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);

export const notFoundPageIT = async () =>
  ({
    id: uuid(),
    route: "/not-found/",
    pageRes: 404,
    metaData: {
      title: "Page not found",
      content: "Page not found",
      description: "Page not found",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.ITALY),
      await errorPageModuleMapper({ variant: "notFound" })
    ]
  } as PageModel);

export const notAvailablePageIT = async () =>
  ({
    id: uuid(),
    route: "/not-available/",
    pageRes: 503,
    metaData: {
      title: "Page not available",
      content: "Page not available",
      description: "Page not available",
      robots: { follow: 1, index: 1 }
    },
    wordPressPostModules: [
      await navBarModuleMapper(PlatformType.ITALY),
      await errorPageModuleMapper({ variant: "unavailable" })
    ]
  } as PageModel);
