import { IWPHeaderCTAModuleFull } from "./header-cta-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { CtaHeaderModule } from "@app/api/modules/cta-header/cta-header.module";

export interface IHeaderCTAModuleMapper extends IModuleFetcher {
  module: IWPHeaderCTAModuleFull;
}

export const headerCTAModuleMapper = async (props: IHeaderCTAModuleMapper): Promise<CtaHeaderModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in headerCTAModuleMapper");

    return {
      id: "22",
      name: "CtaHeaderModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      payOff: module.data?.payOff,
      title: module.data?.title,
      ctaMainUrl: module.data?.ctaMain?.url,
      ctaMainContent: module.data?.ctaMain?.title,
      ctaSecondaryUrl: module.data?.ctaSecondary?.url,
      ctaSecondaryContent: module.data?.ctaSecondary.title,
      imageDesktop: {
        alt: data.imageDesktop?.alt || "",
        src: data.imageDesktop?.url || "",
        title: data.imageDesktop?.title
      },
      imageMobile: {
        alt: data.imageMobile?.alt || "",
        src: data.imageMobile?.url || "",
        title: data.imageMobile?.title
      },
      usps: module.data?.usps || []
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerCTAModuleMapper");

    return undefined;
  }
};
