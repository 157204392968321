import React, { useEffect } from "react";
import ReactTooltip from "react-tooltip";

import styles from "./tooltip-provider.module.scss";

export const TooltipProvider = () => {
  const [renderTooltip, setRenderTooltip] = React.useState(false);

  useEffect(() => {
    setRenderTooltip(true);
  }, []);

  if (!renderTooltip) {
    return null;
  }

  return (
    <ReactTooltip
      isCapture
      effect="solid"
      globalEventOff="click"
      type="dark"
      className={styles.tooltip}
      backgroundColor="black"
    />
  );
};
