import React from "react";

import { formatMessage } from "@app/translations/intl";
import IcnChevronRight from "@assets/icons/chevron-right-sharp.svg";

import { ImageComponent } from "../image";
import styles from "./author-component.module.scss";
import { HtmlRenderComponent } from "../html-render";
import { ClickableComponent } from "../clickable";
import { Link } from "react-router-dom";

export interface IAuthorComponentProps {
  name?: string;
  image?: string;
  description?: string;
  url?: string;
}

export const AuthorComponent = ({ name, image, description, url }: IAuthorComponentProps) => {
  return (
    <div className={styles.author}>
      <Link className={styles.meta} to={url || ""}>
        {image && (
          <ImageComponent
            className={styles.image}
            src={image}
            alt={formatMessage({ id: "blogBodyText.author.image.alt" }, { authorName: name })}
            title={formatMessage({ id: "blogBodyText.author.image.alt" }, { authorName: name })}
            forceImageTag
          />
        )}
        {name && <span className={styles.name}>{name}</span>}
      </Link>
      <div className={styles.about}>
        {description && (
          <div className={styles.description}>
            <HtmlRenderComponent html={description} />
          </div>
        )}
        {url && (
          <ClickableComponent
            href={url}
            title={formatMessage({ id: "blogBodyText.author.more" }, { authorName: name })}
            variant="link-primary"
            iconRight={IcnChevronRight}
            iconStyle="filled"
            iconSize="12px"
          />
        )}
      </div>
    </div>
  );
};
