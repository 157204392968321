import { IWPLinkBoxes, IWPLinkBoxesModuleFull } from "./link-boxes-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { ILinkBoxesModule } from "@app/api/modules/link-boxes/link-boxes.module";
import { ILinkBox } from "@app/core/link-box/link-box.component";

export interface ILinkBoxesModuleMapper extends IModuleFetcher {
  module: IWPLinkBoxesModuleFull;
}

export const linkBoxesModuleMapper = async (props: ILinkBoxesModuleMapper): Promise<ILinkBoxesModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in linkBoxesModuleMapper");

    return {
      id: "20",
      name: "LinkBoxesModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      title: data.title,
      linkBoxes: mapLinkBoxes(data.linkBoxes)
    };
  } catch (e) {
    Logger.logError(e, "Error in: linkBoxesModuleMapper");

    return undefined;
  }
};

const mapLinkBoxes = (linkBoxes?: IWPLinkBoxes[]): ILinkBox[] =>
  linkBoxes?.map(linkBox => ({
    title: linkBox.title,
    image: { src: linkBox.image?.url || "", alt: linkBox.image?.alt || "", title: linkBox.image?.title || "" },
    links: linkBox.links
      .map(link => ({ url: link.link.url || "", title: link.link.title || "" }))
      .filter(newLink => newLink.title)
  })) || [];
