import * as React from "react";

import { IArrowPanelProps } from "@app/core";
import { ArrowPanel } from "@app/core/arrow-panel";

import styles from "./featured-sidebar-items.module.scss";

export interface IFeaturedSidebarItemsProps {
  featuredItems?: IArrowPanelProps[];
  title?: string;
}

const FeaturedSidebarItems = (props: IFeaturedSidebarItemsProps) => (
  <div className={styles["featured-sidebar-items"]}>
    <div>
      <h3 className={styles.title}>{props.title}</h3>
      {props.featuredItems && (
        <div className={styles.list}>
          {props.featuredItems.map((item, key) => (
            <ArrowPanel key={key} fallbackImgUrl={item.fallbackImgUrl} image={item.image} link={{ ...item.link }} />
          ))}
        </div>
      )}
    </div>
  </div>
);

export { FeaturedSidebarItems };
