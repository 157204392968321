import { IWPFeaturedMerchantsModuleFull } from "./featured-merchants-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import {
  FeaturedMerchantsModule,
  FeaturedMerchantsSelectionChoiceType
} from "@app/api/modules/featured-merchants/featured-merchants.module";
import { combineFilters } from "@app/api/wp-page-fetcher/utils/combine-filters";
import {
  getMerchantSingleFilters,
  checkIfMerchantSinglePage
} from "@app/api/wp-page-fetcher/utils/get-merchant-single-filters";
import { getFeaturedMerchantsMapper, IGetFeaturedMerchantsMapper } from "./get-featured-merchants-mapper";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import { OutMerchantDTO } from "@app/bf-api";
import Logger from "@app/util/logger";
import { getUniqueFromArray } from "@app/util/get-unique-from-array";
import { IOutFeaturedMerchantDTO } from "@app/core/new-merchant-card/featured-merchants.interface";

const DEFAULT_AMOUNT_OF_MERCHANTS = 9;

export interface IFeaturedMerchantsModuleMapper extends IModuleFetcher {
  module: IWPFeaturedMerchantsModuleFull;
}

interface IGetCorrectFeaturedMerchants extends IGetFeaturedMerchantsMapper {
  filterIds?: string;
  limit?: number;
  isMerchantSinglePage?: boolean;
  merchantSingleFilters?: string;
  labelFilter?: string;
}

export const featuredMerchantsModuleMapper = async (
  props: IFeaturedMerchantsModuleMapper
): Promise<FeaturedMerchantsModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      shopPageUrlName,
      splittedUrl,
      res,
      platformId,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in featuredMerchantsModuleMapper");

    const filterIds = combineFilters([data.filters?.brandSelection, data.filters?.categorySelection]);
    const merchantSelectionCount = Number(data.filters?.merchantSelectionAmount) || DEFAULT_AMOUNT_OF_MERCHANTS;
    const isMerchantSinglePage = checkIfMerchantSinglePage(splittedUrl, shopPageUrlName);
    let featuredMerchants: OutMerchantDTO[] | undefined = [];
    const selectionChoice = data.selectionChoice as FeaturedMerchantsSelectionChoiceType;

    // Check if server
    if (res) {
      const merchantSingleFilters = isMerchantSinglePage
        ? await getMerchantSingleFilters(platformId, splittedUrl, shopPageUrlName)
        : undefined;
      featuredMerchants = await getCorrectFeaturedMerchants({
        ...props,
        filterIds,
        limit: merchantSelectionCount,
        selectionChoice,
        merchantSelection: data.merchants?.merchantSelection,
        merchantSingleFilters: merchantSingleFilters?.filters
      });
      if (!featuredMerchants || featuredMerchants.length === 0)
        moduleMapperThrowMessage("No featured merchants found in featuredMerchantsModuleMapper");
    }

    return {
      id: "10",
      name: "FeaturedMerchantsModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      topCtaTitle: {
        title: data.topCtaTitle?.title || "",
        url: data.topCtaTitle?.url || ""
      },
      ctaButton: {
        title: data.cta?.title || "",
        url: data.cta?.url || ""
      },
      ctaUnderButton: {
        title: data.ctaUnderButton?.title || "",
        url: data.ctaUnderButton?.url || ""
      },
      ctaDescription: data.ctaDescription || "",
      title: data.title || "",
      filterIds: filterIds,
      amountOfStores: merchantSelectionCount,
      selectionChoice,
      merchantSelectionCount,
      merchantSelection: data.merchants?.merchantSelection,
      featuredMerchants: mapMerchantsToFeaturedMerchants(featuredMerchants || []),
      isMerchantSinglePage: isMerchantSinglePage,
      amountOfMerchantsMobile: data.amountOfMerchantsMobile,
      labelFilter: data.label?.labelFilter,
      pageUrl
    };
  } catch (e) {
    Logger.logError(e, "Error in: featuredMerchantsModuleMapper");

    return undefined;
  }
};

export const getCorrectFeaturedMerchants = async (props: IGetCorrectFeaturedMerchants) => {
  const {
    platformId,
    limit,
    filterIds,
    selectionChoice,
    merchantSelection,
    isMerchantSinglePage,
    merchantSingleFilters,
    labelFilter
  } = props;

  if (isMerchantSinglePage) {
    // If it's the merchant single page, we fetch all merchants based on filters.
    // If the result is 0 or less than the amount of merchants we want to show, we fetch more merchants.

    let merchants: OutMerchantDTO[] | [] = [];
    merchants =
      (await getFeaturedMerchantsMapper({
        filterIds: merchantSingleFilters,
        platformId,
        limit: limit,
        labelFilter
      })) || [];

    if (!merchants || merchants.length === 0 || merchants.length < (limit || 0)) {
      const newMerchants =
        (await getFeaturedMerchantsMapper({
          platformId,
          limit: limit,
          labelFilter
        })) || [];

      merchants = getUniqueFromArray([...merchants, ...newMerchants], "id");
    }

    return merchants;
  }

  return await getFeaturedMerchantsMapper({
    filterIds,
    platformId,
    limit,
    selectionChoice,
    merchantSelection,
    labelFilter
  });
};

export const mapMerchantsToFeaturedMerchants = (merchants: OutMerchantDTO[]): IOutFeaturedMerchantDTO[] =>
  merchants.map(merchant => {
    const mappedMerchant: IOutFeaturedMerchantDTO = {
      id: merchant.id,
      name: merchant.name,
      logoImage: { url: merchant.logoImage?.url || "", title: merchant.logoImage?.title, alt: merchant.logoImage?.alt },
      slug: merchant.slug,
      campaign: {
        featuredFilter: {
          name: merchant.campaigns?.[0]?.featuredFilter?.name || "",
          label: merchant.campaigns?.[0]?.featuredFilter?.label
        },
        excerpt: merchant.campaigns?.[0]?.excerpt,
        subtitle: merchant.campaigns?.[0]?.subtitle,
        durationStatus: merchant.campaigns?.[0]?.durationStatus
      }
    };
    return mappedMerchant;
  });
