import * as React from "react";

import { HeaderContentComponent } from "@app/core/header-content";
import { MediumHeroModule } from "@app/api/modules/medium-hero/medium-hero";
import { ImageComponent, USP } from "@app/core";
import { getOptimizedImageUrl } from "@app/util/image-optimization";

import styles from "./medium-hero-component.module.scss";

export interface IMediumHeroComponentProps {
  mediumHeroModule: MediumHeroModule;
}

const MediumHeroComponent = (props: IMediumHeroComponentProps) => {
  const { image, usps, headerContent } = props.mediumHeroModule;

  return (
    <div className={styles["medium-hero"]}>
      <div className="uk-container">
        <div className={styles["top"]}>
          <USP uspModule={usps} />
        </div>
        <div className={styles["holder"]}>
          {image && (
            <div className={styles["image"]}>
              <ImageComponent
                src={getOptimizedImageUrl({
                  url: image.src,
                  height: 260
                })}
                title={image.title}
                isBlocking
                alt={image.alt}
              />
            </div>
          )}
          {headerContent && (
            <div className={styles["content"]}>
              <HeaderContentComponent
                title={headerContent.title}
                subtitle={headerContent.subtitle}
                text={headerContent.text}
                breadcrumbProps={headerContent.breadcrumbProps}
                headerMobilePosition="center"
                useDarkText
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export { MediumHeroComponent };
