import { FilterItem } from "@app/api/core/filter/filter-item";
import { MerchantsApi, DealsApi } from "@app/bf-api";

export const getMerchantCount = async (
  platformId: number,
  categoryFilters?: FilterItem[],
  brandFilters?: FilterItem[]
): Promise<number> => {
  const api = new MerchantsApi();
  const categoryFiltersIds =
    categoryFilters && categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const brandFiltersIds = brandFilters && brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const filtersIds = categoryFiltersIds && brandFiltersIds && [...categoryFiltersIds, ...brandFiltersIds];
  const filters = filtersIds && filtersIds.join(";");

  const amountOfMerchants = await api.merchantsCountGet(platformId, undefined, filters);

  return amountOfMerchants;
};

export const getDealCount = async (
  platformId: number,
  categoryFilters?: FilterItem[],
  brandFilters?: FilterItem[],
  merchantFilters?: FilterItem[]
): Promise<number> => {
  const api = new DealsApi();
  const categoryFiltersIds =
    categoryFilters && categoryFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const brandFiltersIds = brandFilters && brandFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const filtersIds = categoryFiltersIds && brandFiltersIds && [...categoryFiltersIds, ...brandFiltersIds];
  const filters = filtersIds && filtersIds.join(";");

  const merchantFilterIds =
    merchantFilters && merchantFilters.filter(filter => filter.isSelected).map(filter => filter.id);
  const filtersMerchantIds = merchantFilterIds && [...merchantFilterIds];
  const joinedMerchantFilters = filtersMerchantIds && filtersMerchantIds.join(";");

  const amountOfDeals = await api.dealsCountGet(platformId, joinedMerchantFilters, filters);

  return amountOfDeals;
};
