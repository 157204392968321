import React, { useRef } from "react";

import { getComponent } from "@app/util/get-modules-from-page";

import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { Background } from "@app/api/core/background";
import { IScreenSize, ViewType } from "@app/redux/reducers/settings";

const objectFitImages = require("object-fit-images");
export interface IModuleComponentProps {
  screenSize: IScreenSize | undefined;
  wordPressModule: WordPressPostModule;
}

export interface IBaseModuleProps {
  rootRef?: React.RefObject<HTMLDivElement>;
}

const ModuleComponent = (props: IModuleComponentProps) => {
  const component = getComponent(props.wordPressModule);
  const rootRef = useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    // https://github.com/fregante/object-fit-images && https://github.com/ronik-design/postcss-object-fit-images
    // In case the used brosware does not support object-fit the object-fit-images polyfill will assign to the tag <img />
    // the css property background-image as well as background-size: cover/contain etc.. in this way they brosware will always render the picture well
    objectFitImages(); // activation call object-fit-images polyfill
  }, []);
  const isMobile =
    props.screenSize &&
    (props.screenSize.viewType === ViewType.Mobile ||
      props.screenSize.viewType === ViewType.MobileBig ||
      props.screenSize.viewType === ViewType.Tablet)
      ? true
      : false;

  const styleContainerModule = {
    ...getBackgroundStyleProperties(props.wordPressModule.background as Background),
    paddingTop: props.wordPressModule.topPadding
      ? isMobile
        ? props.wordPressModule.topPadding.mobileSpacing
        : props.wordPressModule.topPadding.desktopSpacing
      : "0px",
    paddingBottom: props.wordPressModule.bottomPadding
      ? isMobile
        ? props.wordPressModule.bottomPadding.mobileSpacing
        : props.wordPressModule.bottomPadding.desktopSpacing
      : "0px"
  };

  const marginContainerModule = {
    marginTop: props.wordPressModule.topMargin
      ? isMobile
        ? props.wordPressModule.topMargin.mobileSpacing
        : props.wordPressModule.topMargin.desktopSpacing
      : "0px",
    marginBottom: props.wordPressModule.bottomMargin
      ? isMobile
        ? props.wordPressModule.bottomMargin.mobileSpacing
        : props.wordPressModule.bottomMargin.desktopSpacing
      : "0px"
  };

  return (
    <div
      key={props.wordPressModule.name}
      style={{
        marginTop: marginContainerModule.marginTop,
        marginBottom: marginContainerModule.marginBottom,
        position: "relative"
      }}
      ref={rootRef}
    >
      <div style={styleContainerModule}>{React.cloneElement(component || <></>, { rootRef })}</div>
    </div>
  );
};

const getBackgroundStyleProperties = (backgroundModule: Background) => {
  let backgroundProperty;

  if (backgroundModule) {
    if (backgroundModule.backgroundPattern) {
      backgroundProperty = {
        background: `url(${backgroundModule.backgroundPattern})`,
        backgroundPosition: "50% 50%",
        backgroundRepeat: `repeat`
      };
    } else {
      backgroundProperty = {
        background: backgroundModule.backgroundColour
      };
    }
  } else {
    backgroundProperty = {
      background: "#fff"
    };
  }

  return backgroundProperty;
};

export { ModuleComponent };
