import React, { useState, useEffect } from "react";

import styles from "./searchable-checkbox-dropdown-component.module.scss";
import { FilterItem } from "@app/api/core/filter/filter-item";
import { DropdownComponent } from "../dropdown-new/dropdown.component";
import { ClickableComponent } from "../clickable";
import Search from "@assets/icons/search.svg";
import { CheckboxCount } from "../checkbox-count/checkbox-count.component";
import { Input } from "@app/core/input/input.component";
import { ResourceTextComponent } from "../resource-text";
import { useDebounce } from "@app/util/use-debounce";
import { deepClone, usePrevious } from "@app/util";
import { formatMessage } from "@app/translations/intl";

export interface ISearchableCheckboxDropdownProps {
  deleteFilterLabel: string;
  items: FilterItem[];
  onChange: (items: FilterItem[]) => void;
  onSearch?: (value: string) => void;
  onSelection?: (item: FilterItem) => void;
  onSelectionCleared?: () => void;
  overviewType?: "deals" | "stores";
  searchPlaceholder: string;
  selectedItems: FilterItem[];
  showFilterName: string;
  title: string;
  totalSelectedItems?: number;
  isFilterDropDown?: boolean;
  isRounded?: boolean;
}

export const SearchableCheckboxDropdown = (props: ISearchableCheckboxDropdownProps) => {
  const [search, setSearch] = useState<string>("");
  const [internalItems, setInternalItems] = useState<FilterItem[]>([]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const overviewType = props.overviewType ? props.overviewType : "winkels";
  const debouncedSearchTerm = useDebounce(search, 300);

  const prevSearchTerm = usePrevious(debouncedSearchTerm) || "";
  useEffect(() => {
    if (debouncedSearchTerm === prevSearchTerm) return undefined;

    if (props.onSearch) {
      props.onSearch(search);
    }
  }, [debouncedSearchTerm]);

  useEffect(() => {
    const newInternalItems = deepClone<FilterItem>(props.items);
    if (props.selectedItems && props.selectedItems.length > 0) {
      const extraNewInternalItems = newInternalItems.map(item => {
        const findItem = props.selectedItems.findIndex(selectedItem => selectedItem.id === item.id) > -1;

        return {
          ...item,
          isSelected: findItem
        };
      });
      setInternalItems(extraNewInternalItems);
    } else {
      setInternalItems(newInternalItems);
    }
  }, [props.items]);

  useEffect(() => {
    if (!isOpen) {
      props.onChange(internalItems);

      setSearch("");
    }
  }, [isOpen]);

  const onChange = (id: string) => {
    let result = internalItems.map(item => {
      if (item.id === id) {
        item.isSelected = !item.isSelected;
      }

      return item;
    });

    result = result.filter(item => item.displayName.toLowerCase().includes(search));

    setInternalItems([...result]);

    const selectedItem = result.find(item => item.id === id);
    if (selectedItem && props.onSelection) {
      props.onSelection(selectedItem);
    }
  };

  const getFilterCountString = () => {
    const count = props.selectedItems.length;
    if (count > 0) {
      return `(${count})`;
    }

    return null;
  };

  const hasSelectedItems = internalItems.filter(item => item.isSelected).length > 0;

  const clearFilters = () => {
    const result = internalItems.map(item => {
      if (item.isSelected) {
        item.isSelected = false;
      }

      return item;
    });

    setInternalItems([...result]);
    setSearch("");

    if (props.onSelectionCleared) {
      props.onSelectionCleared();
    }
  };

  const persistFilters = () => {
    setIsOpen(false);
  };

  return (
    <div>
      <DropdownComponent
        title={props.title}
        hasSelectedItems={hasSelectedItems}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        isFilterDropDown={props.isFilterDropDown}
        isRounded={props.isRounded}
      >
        <div className={styles["content"]}>
          <div className={styles["dropdown-head"]}>
            <Input
              value={search}
              onChange={setSearch}
              placeholder={props.searchPlaceholder}
              icon={Search}
              name={"search"}
            />
          </div>
          <div className={styles["dropdown-body"]}>
            {internalItems.length > 0 ? (
              internalItems.map((item, key) => <CheckboxCount key={key} item={item} onChecked={onChange} />)
            ) : (
              <div>
                <ResourceTextComponent resourceKey={"searchableCheckboxDropdown.noResults"} />
              </div>
            )}
          </div>
          <div className={styles["dropdown-bottom"]}>
            <ul className={styles["dropdown-bottom__action"]}>
              <li>
                {getFilterCountString() && (
                  <ClickableComponent
                    dataCy="searchable-checkbox-dropdown_button-remove-filters"
                    title={`${props.deleteFilterLabel} ${!!getFilterCountString() ? getFilterCountString() : ""}`}
                    variant="link-secondary"
                    onClick={clearFilters}
                  />
                )}
              </li>

              <li>
                <ClickableComponent
                  dataCy="searchable-checkbox-dropdown_button-show-stores"
                  title={
                    props.totalSelectedItems && overviewType === "winkels"
                      ? formatMessage(
                          { id: "searchableCheckboxDropdown.storeButtonWithAmount" },
                          { totalStores: props.totalSelectedItems ? props.totalSelectedItems : "" }
                        )
                      : props.totalSelectedItems && overviewType === "deals"
                      ? formatMessage(
                          { id: "searchableCheckboxDropdown.dealButtonWithAmount" },
                          { totalDeals: props.totalSelectedItems ? props.totalSelectedItems : "" }
                        )
                      : overviewType === "winkels"
                      ? formatMessage({ id: "searchableCheckboxDropdown.storeButtonAll" })
                      : formatMessage({ id: "searchableCheckboxDropdown.dealButtonAll" })
                  }
                  variant="primary-brand"
                  onClick={persistFilters}
                />
              </li>
            </ul>
          </div>
        </div>
      </DropdownComponent>
    </div>
  );
};
