import React, { useMemo } from "react";
import clsx from "clsx";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";

import { INavBarModule } from "@app/api/modules/nav-bar/navbar.module";
import { ImageComponent } from "@app/core";
import { useScrollToTop } from "@app/util/hooks/use-scroll-to-top";
import useScrollDirection, { Direction } from "@app/util/hooks/use-scroll-direction";
import { NAVIGATION_HEIGHT_DEFAULT } from "@app/constants/overviews-numbers";
import { formatMessage } from "@app/translations/intl";

import { MenuItems } from "./menu-items/menu-items.component";
import { NavbarSearch } from "./navbar-search/navbar-search.component";
import styles from "./new-nav-bar-component.module.scss";

export interface INewNavBarComponentProps {
  navBarModule: INavBarModule;
  className?: string;
}

const NavBar = (props: INewNavBarComponentProps & RouteComponentProps) => {
  const { scrollToTop } = useScrollToTop();

  return (
    <div className={clsx(styles.navbar, props.className)}>
      <div className="uk-container">
        <div className={styles.top}>
          <div className={styles.logo}>
            <Link to={formatMessage({ id: "global.homepageLink" })} onClick={scrollToTop}>
              <ImageComponent
                src={props.navBarModule.logo.src}
                alt={props.navBarModule.logo.alt}
                title={props.navBarModule.logo.title}
                isBlocking
              />
            </Link>
          </div>
          <NavbarSearch
            popularMerchants={props.navBarModule.popularMerchants}
            popularDeals={props.navBarModule.popularDeals}
          />
          <Link to={props.navBarModule.navBarLink || ""} className={styles.rightText}>
            <span className={styles.title}>{props.navBarModule.navBarTitle}</span>
            <span className={styles.subtitle}>{props.navBarModule.navBarSubTitle}</span>
          </Link>
        </div>
      </div>
      <div className={styles.bottom}>
        <div className="uk-container">
          <MenuItems items={props.navBarModule.menuItems} />
        </div>
      </div>
    </div>
  );
};

const component = (props: INewNavBarComponentProps & RouteComponentProps) => {
  const { scrollDir, scrollPosition } = useScrollDirection({ thr: 100 });

  const navBar = useMemo(() => <NavBar {...props} />, []);

  return (
    <div
      style={{
        marginBottom: `${NAVIGATION_HEIGHT_DEFAULT}px` // Height of navbar
      }}
    >
      <div
        className={clsx(
          styles.navBarWrapper,
          (scrollDir === Direction.Up || scrollPosition.top <= NAVIGATION_HEIGHT_DEFAULT) && styles.sticky
        )}
      >
        {navBar}
      </div>
    </div>
  );
};

export const DesktopNavBar = withRouter(component);
