import { IWPHeaderSmallImageModuleFull, IWPHeaderSmallUspGroup } from "./header-small-image-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { MediumHeroModule } from "@app/api/modules/medium-hero/medium-hero";
import { IconLink } from "@app/api/core/icon-link";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";

export interface IHeaderSmallImageModuleMapper extends IModuleFetcher {
  module: IWPHeaderSmallImageModuleFull;
}

export const headerSmallImageModuleMapper = async (
  props: IHeaderSmallImageModuleMapper
): Promise<MediumHeroModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in headerSmallImageModuleMapper");

    const showHeaderContent = data.title || data.undertitle;

    return {
      id: "3",
      name: "MediumHeroModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      ...(showHeaderContent && {
        headerContent: {
          title: data.title || "",
          subtitle: data.undertitle || ""
        }
      }),
      image: {
        src: data.headerImage?.url || "",
        alt: data.headerImage?.alt || "",
        title: data.headerImage?.title || ""
      },
      usps: { uniqueSellingPoints: mapUsps(data.uspGroup) }
    };
  } catch (e) {
    Logger.logError(e, "Error in: headerSmallImageModuleMapper");

    return undefined;
  }
};

const mapUsps = (usps?: IWPHeaderSmallUspGroup[]): IconLink[] | [] => {
  if (!usps || usps.length === 0) return [];

  return usps.map(usp => ({
    icon: usp.icon?.url || "",
    link: { url: usp.link?.url || "", title: usp.link?.title || "" }
  }));
};
