import { IWPBlogOverviewModuleFull } from "./blog-overview-module.interface";
import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { BlogOverviewModule } from "@app/api/modules/blog-overview/blog-overview";
import { sidebarComponentMapper } from "@app/api/wp-page-fetcher/module-selector/component-mappers/sidebar-component";
import { getFeaturedBlogs } from "../featured-blogs-module";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";

export interface IBlogOverviewMapper extends IModuleFetcher {
  module: IWPBlogOverviewModuleFull;
}

export const blogOverviewModuleMapper = async (props: IBlogOverviewMapper): Promise<BlogOverviewModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res,
      pageUrl
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in blogOverviewModuleMapper");

    const blogs = await getFeaturedBlogs(platformId, pageUrl, true, 20);
    if (!blogs || blogs === 404 || blogs === 503)
      return moduleMapperThrowMessage("No featured blogs found in blogOverviewModuleMapper");

    const sidebar = await sidebarComponentMapper({ platformId, sidebarData: data.blogOverviewSidebar, res });

    return {
      id: "15",
      name: "BlogOverviewModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      ...(sidebar && { sidebarItems: sidebar }),
      ...(blogs && { posts: blogs })
    };
  } catch (e) {
    Logger.logError(e, "Error in: blogOverviewModuleMapper");
    return undefined;
  }
};
