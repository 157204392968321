import React from "react";
import { BannerModule } from "@app/api/modules/banner/banner.module";

import { FeaturedSidebarItems, IFeaturedSidebarItemsProps } from "./components/featured-sidebar-items";
import { BannerWrapper } from "../banner-wrapper";
import styles from "./sidebar-component.module.scss";

export interface ISidebarComponentProps {
  bannerModule?: BannerModule;
  featuredDealsModule?: IFeaturedSidebarItemsProps;
  featuredMerchantsModule?: IFeaturedSidebarItemsProps;
}

export const SidebarComponent = (props: ISidebarComponentProps) => {
  const featuredMerchants = props.featuredMerchantsModule?.featuredItems || [];
  const featuredDeals = props.featuredDealsModule?.featuredItems || [];

  return (
    <div className={styles.sidebar}>
      {props.bannerModule && <BannerWrapper bannerModule={props.bannerModule} />}

      {featuredMerchants && featuredMerchants.length > 0 && (
        <FeaturedSidebarItems title={props.featuredMerchantsModule?.title || ""} featuredItems={featuredMerchants} />
      )}

      {featuredDeals && featuredDeals.length > 0 && (
        <FeaturedSidebarItems title={props.featuredDealsModule?.title || ""} featuredItems={featuredDeals} />
      )}
    </div>
  );
};
