import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { IWPAuthorityModuleFull } from "./authority-module.interface";
import { AuthorityModule } from "@app/api/modules/authority/authority.module";

export interface IAuthorityModuleMapper extends IModuleFetcher {
  module: IWPAuthorityModuleFull;
}

export const authorityModuleMapper = async (props: IAuthorityModuleMapper): Promise<AuthorityModule | undefined> => {
  try {
    const {
      module,
      module: { data }
    } = props;
    if (!data) return moduleMapperThrowMessage("No data found in authorityModuleMapper");

    return {
      id: "24",
      name: "authorityModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      logos:
        (data.logos || [])?.map(item => ({
          logo: { src: item.logoImage?.url || "", alt: item.logoImage?.alt || "", title: item.logoImage?.title || "" },
          link: item.link?.url || ""
        })) || [],
      title: data.title || ""
    };
  } catch (e) {
    Logger.logError(e, "Error in: authorityModuleMapper");
    return undefined;
  }
};
