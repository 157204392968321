import { IPlatformSpecificInfo } from "../platform";
import { WEBSITE_COLORS } from "../colors";
import FavIconSe from "@assets/favicons/favicon-bf-se.png";
import Paper from "@assets/icons/paper.svg";
import instagramIcon from "@assets/icons/new-instagram.svg";
import facebookIcon from "@assets/icons/new-facebook.svg";
import mailIcon from "@assets/icons/new-mail.svg";
import bfSwedenLogo from "@assets/logos/bf-se.svg";
import CheckmarkIcon from "@assets/icons/usp-check-mark.svg";

export const swedenPlatform: IPlatformSpecificInfo = {
  id: 11,
  currency: "SEK",
  name: "Black Friday Expert",
  country: "Sverige",
  domain: "blackfriday.expert",
  merchantOverviewLink: "/se/butiker/",
  dealOverviewLink: "/se/deals/",
  urlAddition: "se",
  isLandingPage: false,
  defaultBackButtonText: "Tillbaka",
  breadcrumbHomeName: "Hem",
  breadcrumbHomeUrl: "/se/",
  readingTimeText: {
    singular: "minut läsningstid",
    plural: "minuter läsningstid"
  },
  newsletterButtonTitle: "Registrera",
  newsletterModalTitle: "Prenumerera på nyhetsbrevet",
  fallBackHeaderImage: "https://media.blackfridaynederland.nl/wordpress/20200430114412/default-blog-image-be.jpg",
  bottomFooter: {
    items: [
      {
        title: "Kontakta oss",
        url: "/se/kontakt/"
      },
      {
        title: "Privacy",
        url: "/se/privacy/"
      },
      {
        title: "Cookies",
        url: "/se/cookies/"
      },
      {
        title: "Sitemap",
        url: "/se/sitemap/"
      }
    ],
    logo: {
      src: bfSwedenLogo,
      alt: "Black Friday Logo Sverige",
      title: "Black Friday Logo Sverige"
    },
    logoLink: "/se/"
  },
  middleFooter: {
    links: [
      {
        id: "TRENDING_BLOCK",
        title: "Om Black Friday",
        links: []
      },
      {
        id: "SHOPS_BLOCK",
        title: "Mest visade sidor",
        links: []
      },
      {
        id: "THIRD_BLOCK",
        title: "Butiker med de bästa deals",
        links: []
      }
    ],
    newsletter: {
      title: "Prenumerera på nyhetsbrevet",
      text: "och få de bästa erbjudandena",
      buttonText: "Nyhetsbrev",
      buttonIcon: Paper
    },
    socialMedia: {
      id: "SOCIAL_BLOCK",
      title: "Vill du följa oss?",
      socialItem: [
        {
          image: {
            src: facebookIcon,
            alt: "Facebook icon",
            title: "Facebook icon"
          },
          link: "https://www.facebook.com/Black-Friday-Sverige-108841114635982/"
        },
        {
          image: {
            src: instagramIcon,
            alt: "Instagram icon",
            title: "Instagram icon"
          },
          link: "https://www.instagram.com/blackfridayexpert_se/"
        },
        {
          link: "info@blackfriday.expert",
          image: {
            src: mailIcon,
            alt: "Mail icon",
            title: "Mail icon"
          }
        }
      ]
    }
  },
  fallbackMerchantHeader: {
    src: "https://media.blackfridaynederland.nl/wordpress/20200421102650/Black-Friday-Winkels-Nederland.jpg",
    alt: "Lagra rubrik",
    title: "Sidhuvud för huvudet"
  },
  logo: {
    src: bfSwedenLogo,
    alt: "Black Friday Logo Sverige",
    title: "Black Friday Logo Sverige"
  },
  uspModule: {
    uniqueSellingPoints: [
      {
        icon: CheckmarkIcon,
        link: {
          url: "/se/butiker/",
          title: "Alla kampanjer i en översikt"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/se/deals/",
          title: "Få enkelt de bästa erbjudandena"
        }
      },
      {
        icon: CheckmarkIcon,
        link: {
          url: "/se/black-friday/",
          title: "Missa inte Black Friday"
        }
      }
    ]
  },
  storeTabs: [
    {
      href: "/se/butiker/",
      isSelected: true,
      title: "Butiker"
    },
    {
      href: "/se/deals/",
      title: "Erbjudanden"
    }
  ],
  dealTabs: [
    {
      href: "/se/butiker/",
      title: "Butiker"
    },
    {
      href: "/se/deals/",
      isSelected: true,
      title: "Erbjudanden"
    }
  ],
  colors: {
    "--colour-version-website": WEBSITE_COLORS.SE_BLUE,
    "--colour-version-website-RGB": WEBSITE_COLORS.SE_BLUE_RGB,
    "--colour-version-website-darken7": WEBSITE_COLORS.SE_BLUE_DARKEN_7,
    "--colour-version-website-darken16": WEBSITE_COLORS.SE_BLUE_DARKEN_16,
    "--colour-version-website-darken35": WEBSITE_COLORS.SE_BLUE_DARKEN_35,
    "--colour-version-website-lighten51": WEBSITE_COLORS.SE_BLUE_LIGHTEN_51,
    "--colour-version-website-lightAccent": WEBSITE_COLORS.SE_LIGHT_ACCENT
  },
  locale: "sv-SE",
  tagManagerScriptLocation: "/static/googletagmanager-sv-se.js",
  weCanTrackScriptLocation: "/static/wecantrack-sv-se.js",
  favIcon: FavIconSe,
  favIconPublic: "https://media.blackfridaynederland.nl/static/FavIcons/favicon-bf-se.png",
  gtm: "GTM-PWMS57Z"
};
