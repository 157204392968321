import { getWPModuleSpacing } from "@app/api/wp-page-fetcher/utils/get-wp-module-spacing";
import { IModuleFetcher } from "@app/api/wp-page-fetcher/module-selector/module-selector";
import Logger from "@app/util/logger";
import { WordPressPostModule } from "@app/api/modules/wordpress-module/wordpress-module";
import { moduleMapperThrowMessage } from "@app/api/wp-page-fetcher/utils/module-mapper-error-handling";
import { SearchItemsModulesMap, SearchResultsModule } from "@app/api/modules/search-results/search-results.module";
import { SEARCH_QUERY_PARAM } from "@app/util/search-page-link";
import { getSearchItems, SearchItemsModules } from "@app/redux/thunks/navbar-search.thunk";
import { getFeaturedPagesMapper } from "@app/middleware/api/featured-pages-get.api";
import { getFeaturedProductsApi } from "@app/middleware/api/featured-products-get.api";

import { IWPSearchResultsModuleFull, SearchOptions } from "./search-results-module.interface";
import { getFeaturedDealsMapperWithDto } from "../featured-deals-module";
import { getFeaturedMerchantsMapperWithDto } from "../featured-merchants-module";

export const DEFAULT_SEARCH_LIMIT = 24;

export interface ISearchResultsMapper extends IModuleFetcher {
  module: IWPSearchResultsModuleFull;
}

export const searchResultsModuleMapper = async (
  props: ISearchResultsMapper
): Promise<SearchResultsModule | WordPressPostModule | undefined> => {
  try {
    const {
      module,
      module: { data },
      platformId,
      res
    } = props;

    if (!data) return moduleMapperThrowMessage("No data found in searchResultsModuleMapper");

    let searchValue: string | undefined =
      typeof res?.req?.query?.[SEARCH_QUERY_PARAM] === "string"
        ? (res?.req?.query?.[SEARCH_QUERY_PARAM] as string)
        : undefined;

    if (!res && typeof window !== "undefined") {
      const query = window.location.search.replace(`?${SEARCH_QUERY_PARAM}=`, "");

      if (query) {
        searchValue = query;
      }
    }

    const modules = mapSearchOptionsToModules(data.searchOptions);

    const [searchResults, defaults] = await Promise.all([
      searchValue
        ? await getSearchItems({
            limit: {
              deals: data.dealResultLimit || DEFAULT_SEARCH_LIMIT,
              merchants: data.shopResultLimit || DEFAULT_SEARCH_LIMIT,
              products: data.productResultLimit || DEFAULT_SEARCH_LIMIT,
              pages: data.pageResultLimit || DEFAULT_SEARCH_LIMIT
            },
            searchValue,
            platformId,
            modules
          })
        : undefined,
      getDefaults(platformId, modules, data)
    ]);

    return {
      id: "31",
      name: "SearchResultsModule",
      ...getWPModuleSpacing(module.data, module.setMargins),
      background: { backgroundColour: data.bgColor },
      searchResults,
      defaults,
      modules,
      isCollapsible: {
        deals: !data.dealShowAllResults,
        merchants: !data.shopShowAllResults,
        products: !data.productShowAllResults,
        pages: !data.pageShowAllResults
      },
      resultLimits: {
        deals: data.dealResultLimit || DEFAULT_SEARCH_LIMIT,
        merchants: data.shopResultLimit || DEFAULT_SEARCH_LIMIT,
        products: data.productResultLimit || DEFAULT_SEARCH_LIMIT,
        pages: data.pageResultLimit || DEFAULT_SEARCH_LIMIT
      },
      collapsedLimits: {
        deals: data.dealResultCollapsedLimit || 6,
        merchants: data.shopResultCollapsedLimit || 6,
        products: data.productResultCollapsedLimit || 8,
        pages: data.pageResultCollapsedLimit || 8
      },
      translations: {
        anchorLabel: data.directingToText,
        searchBarPlaceholder: data.placeholderText,
        merchants: {
          title: data.shopTitle,
          showMore: data.shopShowMoreText,
          showLess: data.shopShowLessText,
          anchorLabel: data.shopLabel,
          defaultTitle: data.shopStandardTitle
        },
        products: {
          title: data.productTitle,
          showMore: data.productShowMoreText,
          showLess: data.productShowLessText,
          anchorLabel: data.productLabel,
          defaultTitle: data.productStandardTitle
        },
        deals: {
          title: data.dealTitle,
          showMore: data.dealShowMoreText,
          showLess: data.dealShowLessText,
          anchorLabel: data.dealLabel,
          defaultTitle: data.dealStandardTitle
        },
        pages: {
          title: data.pageTitle,
          showMore: data.pageShowMoreText,
          showLess: data.pageShowLessText,
          anchorLabel: data.pageLabel,
          defaultTitle: data.pageStandardTitle
        }
      }
    };
  } catch (e) {
    Logger.logError(e, "searchResultsModuleMapper went wrong");

    return undefined;
  }
};

const getDefaults = async (
  platformId: number,
  modules?: SearchItemsModules[],
  data?: IWPSearchResultsModuleFull["data"]
): Promise<SearchResultsModule["defaults"] | undefined> => {
  if (!modules || modules.length === 0) return undefined;

  const [deals, merchants, products, pages] = await Promise.all([
    modules.includes("deals")
      ? getFeaturedDealsMapperWithDto({ platformId, limit: data?.dealDefaultLimit || 3 })
      : undefined,
    modules.includes("merchants")
      ? getFeaturedMerchantsMapperWithDto({ platformId, limit: data?.shopDefaultLimit || 3 })
      : undefined,
    modules.includes("products")
      ? getFeaturedProductsApi({ platformId, take: data?.productDefaultLimit || 4 })
      : undefined,
    modules.includes("pages") ? getFeaturedPagesMapper({ platformId, limit: data?.pageDefaultLimit || 4 }) : undefined
  ]);

  return {
    deals,
    merchants,
    products,
    pages: pages !== 404 && pages !== 503 ? pages?.data : undefined
  };
};

const mapSearchOptionsToModules = (searchOptions?: SearchOptions[]): SearchItemsModules[] | undefined => {
  if (!searchOptions) return undefined;

  return searchOptions.map(option => SearchItemsModulesMap[option]);
};
