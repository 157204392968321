import React from "react";
import styles from "./share-social-dropdown-component.module.scss";
import { DropdownComponent } from "../dropdown-new/dropdown.component";
import { shareSocialNative } from "@app/util/share-social";
import ShareIcon from "@assets/icons/share.svg";
import { ClickableComponent } from "../clickable";
import { ShareSocialComponent } from "../share-social/share-social.component";

export interface IShareSocialDropdownComponentProps {
  animatedIcon?: boolean;
  buttonTitle: string;
  icon?: string;
}

const ShareSocialDropdownComponent = (props: IShareSocialDropdownComponentProps) => {
  const [isNativeSharingCompatible, setIsNativeSharingCompatible] = React.useState<boolean>(false);
  const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { buttonTitle } = props;

  React.useEffect(() => {
    const navigatorInstance: any = navigator;
    setIsNativeSharingCompatible(navigatorInstance.share);
  }, []);

  const onClickHandler = () => {
    const title = "";
    const url = typeof window !== "undefined" ? window.location.href : "";
    const content = "";

    shareSocialNative(title, content, url);
  };

  return (
    <div className={styles["share-social-dropdown"]}>
      {isNativeSharingCompatible ? (
        <ClickableComponent
          iconRight={ShareIcon}
          iconStyle="outline"
          variant="secondary"
          onClick={onClickHandler}
          title={buttonTitle}
        />
      ) : (
        <>
          <div className={styles["dropdown"]}>
            <DropdownComponent
              iconLeft={ShareIcon}
              title={buttonTitle}
              animated={props.animatedIcon}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              isShareDropDown={true}
            >
              <div className={styles["content"]}>
                <ShareSocialComponent />
              </div>
            </DropdownComponent>
          </div>
          {/* Disabled on mobile because Pablo said nobody uses it */}
          {/* <div className={styles["badges"]}>
            <ShareSocialComponent withTitles={false} />
          </div> */}
        </>
      )}
    </div>
  );
};

export { ShareSocialDropdownComponent };
