import * as React from "react";
import { useIntl } from "react-intl";
import clsx from "clsx";
import { CampaignDurationStatusDTO } from "@app/bf-api";

import styles from "./campaign-status-label-component.module.scss";
import { getCampaignStatus } from "@app/util/campaign-status";

export interface ICampaignStatusProps {
  durationStatus?: CampaignDurationStatusDTO;
}

const CampaignStatusComponent = (props: ICampaignStatusProps) => {
  const intl = useIntl();
  const status = getCampaignStatus(intl, props.durationStatus);

  return (
    <>
      {status.title && status.showStatus && (
        <span className={clsx(styles.status, status.color && styles[status.color])}>{status.title}</span>
      )}
    </>
  );
};

export { CampaignStatusComponent };
