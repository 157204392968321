import React, { forwardRef } from "react";
import style from "./checkbox-component.module.scss";

export interface ICheckboxComponentProps {
  borderRadius?: boolean;
  children: any;
  id?: string;
  isChecked?: boolean;
  onClick?: (event: any, value: any) => void;
}

const CheckboxComponent = forwardRef((props: ICheckboxComponentProps, ref: any) => {
  const radioButtonStyle = props.borderRadius
    ? {
        borderRadius: "100px",
        backgroundSize: "4px",
        backgroundImage:
          'url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNyIgaGVpZ2h0PSI3IiB2aWV3Qm94PSIwIDAgNyA3IiBmaWxsPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPgo8Y2lyY2xlIGN4PSIzLjUiIGN5PSIzLjUiIHI9IjMuNSIgZmlsbD0id2hpdGUiLz4KPC9zdmc+Cg==")'
      }
    : {};

  const onClick = (event: any, value: any) => {
    if (props.onClick) {
      props.onClick(event, value);
    }
  };

  return (
    <React.Fragment>
      <div className={style["checkbox"]} ref={ref}>
        <label className={style["label"]}>
          <input
            aria-checked="true"
            type="checkbox"
            role="checkbox"
            id={props.id}
            className={style["input"]}
            onChange={e => onClick(e, props.children)}
            checked={props.isChecked}
            style={radioButtonStyle}
          />
          {props.children}
        </label>
      </div>
    </React.Fragment>
  );
});

export { CheckboxComponent };
