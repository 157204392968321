/* tslint:disable */
/* eslint-disable */
/**
 * BlackFriday API
 * The API Documentation for BlackFriday
 *
 * The version of the OpenAPI document: 0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from "../runtime";
import { OutDealDTO, OutDealDTOFromJSON, OutDealDTOFromJSONTyped, OutDealDTOToJSON } from "./";

/**
 *
 * @export
 * @interface DealArrayDTO
 */
export interface DealArrayDTO {
  /**
   *
   * @type {Array<OutDealDTO>}
   * @memberof DealArrayDTO
   */
  deals: Array<OutDealDTO>;
}

export function DealArrayDTOFromJSON(json: any): DealArrayDTO {
  return DealArrayDTOFromJSONTyped(json, false);
}

export function DealArrayDTOFromJSONTyped(json: any, ignoreDiscriminator: boolean): DealArrayDTO {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    deals: (json["deals"] as Array<any>).map(OutDealDTOFromJSON)
  };
}

export function DealArrayDTOToJSON(value?: DealArrayDTO | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    deals: (value.deals as Array<any>).map(OutDealDTOToJSON)
  };
}
